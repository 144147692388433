import React from 'react'

import Modal from 'common/components/Modal'

const Confirmation = ({
  title,
  isOpen,
  onClose,
  text,
  actionText,
  onSubmit,
}) => {
  return (
    <Modal
      className="main-layout__modal"
      title={title || 'Вы уверены?'}
      isOpen={isOpen}
      onClose={onClose}
      size="auto"
    >
      <div className="text">{text}</div>
      <div className="text text--right text--top">
        <button
          type="button"
          className="button button--warning"
          onClick={() => {
            onSubmit()
            onClose()
          }}
        >
          {actionText || 'Да'}
        </button>
      </div>
    </Modal>
  )
}

export default Confirmation
