import { fromJS } from 'immutable'
import {
  REQUEST_RESET_INSTRUCTIONS,
  RESET_INSTRUCTIONS_SUCCESS,
  RESET_INSTRUCTIONS_ERROR,
} from 'main/actions/reset-instructions'
import appRoutes from 'main/constants/app-routes'

const defaultState = {
  isLoading: false,
  error: false,
  success: false,
}

const resetInstructions = (state = fromJS(defaultState), action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      if (action.payload.pathname === appRoutes.resetInstructions) {
        return fromJS(defaultState)
      }
      return state
    case REQUEST_RESET_INSTRUCTIONS:
      return state.merge({
        isLoading: true,
        error: false,
      })
    case RESET_INSTRUCTIONS_SUCCESS:
      return state.merge({
        isLoading: false,
        success: true,
      })
    case RESET_INSTRUCTIONS_ERROR:
      return state.merge({
        isLoading: false,
        error: true,
      })
    default:
      return state
  }
}

export default resetInstructions
