import { connect } from 'react-redux'
import { getCurrentScript } from 'main/selectors/sales-scripts/current-script'
import { setCurrentCard } from 'main/actions/sales-scripts/current-card'

import Cards from 'main/components/sales-scripts/cards'

const mapStateToProps = state => {
  const currentScript = getCurrentScript(state)
  let cards = []

  if (currentScript && currentScript.cards) {
    cards = currentScript.cards
  }

  return {
    cards,
  }
}

const mapDispatchToProps = dispatch => ({
  setCurrentCard: cardId => dispatch(setCurrentCard(cardId)),
})

const ScriptCardsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Cards)

export default ScriptCardsContainer
