const MILLISECONDS_IN_DAY = 86400000
const DAY_OF_WEEK_NAMES = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
]
const MONTH_NAMES = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]
const MONTH_NAMES_IN_GENITIVE = [
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря',
]

export const showDate = dateString => {
  const date = dateOrEmpty(dateString)
  return date ? getDateString(date) : ''
}

export const showDateTime = (dateString, options = {}) => {
  const date = dateOrEmpty(dateString)
  if (!date) return ''

  let dateStr = options.todayOrYesterday
    ? getDateWithTodayOrYesterday(date)
    : getDateString(date)

  return `${dateStr} в ${getTimeString(date)}`
}

export const getYearMonthDay = dateString => {
  const date = dateOrEmpty(dateString)
  return date
    ? `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`
    : date
}

export const getDateWithTodayOrYesterday = date => {
  const dateMidnight = getMidnightDate(date)
  const today = getMidnightDate(new Date())

  if (today.getTime() === dateMidnight.getTime()) return 'Сегодня'
  if (today - dateMidnight === MILLISECONDS_IN_DAY) return 'Вчера'
  return getDateString(date)
}

export const getDateWithTodayYesterdayOrDayOfWeek = date => {
  const dateMidnight = getMidnightDate(date)
  const today = getMidnightDate(new Date())

  if (today.getTime() === dateMidnight.getTime())
    return `Сегодня, ${date.getDate()} ${getMonthNameInGenetive(date)}`

  if (today - dateMidnight === MILLISECONDS_IN_DAY)
    return `Вчера, ${date.getDate()} ${getMonthNameInGenetive(date)}`

  if (today - dateMidnight < 0)
    return `${date.getDate()} ${getMonthNameInGenetive(
      date
    )} ${date.getFullYear()}`

  if (today - dateMidnight <= 6 * MILLISECONDS_IN_DAY)
    return `${getDayOfWeekName(
      date
    )}, ${date.getDate()} ${getMonthNameInGenetive(date)}`

  return `${date.getDate()} ${getMonthNameInGenetive(
    date
  )} ${date.getFullYear()}`
}

export const getMonthNameAndYear = date =>
  `${getMonthName(date)} ${date.getFullYear()}`

const dateOrEmpty = dateString => {
  let date = null
  if (!dateString) return ''

  try {
    date = new Date(dateString)
  } catch (e) {
    return ''
  }

  return date
}

const getDateString = date =>
  `${pad(date.getDate())}.${pad(date.getMonth() + 1)}.${date.getFullYear()}`

const getTimeString = date =>
  `${pad(date.getHours())}:${pad(date.getMinutes())}`

const getDayOfWeekName = date => DAY_OF_WEEK_NAMES[date.getDay()]
const getMonthNameInGenetive = date => MONTH_NAMES_IN_GENITIVE[date.getMonth()]
const getMonthName = date => MONTH_NAMES[date.getMonth()]

export const getFirstDayOfMonth = date => new Date(new Date(date).setDate(1))

export const getLastDayOfMonth = date =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0)

export const getMidnightDate = date =>
  new Date(new Date(date).setHours(0, 0, 0, 0))

export const lessThan24HoursFromNow = date =>
  new Date() - MILLISECONDS_IN_DAY < new Date(date)

export const formatVideoTime = time => {
  const intTime = parseInt(time, 10)
  if (!intTime) return '0:00'

  let seconds = intTime
  let minutes = 0
  let hours = 0

  if (seconds > 59) {
    seconds = intTime % 60
    minutes = Math.floor(intTime / 60)
  }

  if (minutes > 59) {
    hours = Math.floor(minutes / 60)
    minutes = minutes % 60
  }

  if (hours) return `${hours}:${pad(minutes)}:${pad(seconds)}`

  return `${minutes}:${pad(seconds)}`
}

const pad = n => {
  let s = n + ''
  while (s.length < 2) s = '0' + s
  return s
}
