import React from 'react'

import ProductsListItem from './item'
import HighlightedBlock from '../HighlightedBlock'

const ProductsList = ({ products }) => {
  if (!products || !products.length) return renderPlaceholder()

  return (
    <div className="products main-layout__products">
      <HighlightedBlock />
      <ul className="products__list">
        {products.map(product => (
          <ProductsListItem key={product.code} product={product} />
        ))}
      </ul>
    </div>
  )
}

const renderPlaceholder = () => (
  <div className="main-layout__block center-block">
    <div className="card">
      <div className="placeholder-card placeholder-card--materials">
        <div className="placeholder-card__title">Материалы не найдены</div>
        <div className="placeholder-card__text">
          Здесь вы могли бы увидеть различные ролики, курсы, скринкасты или
          документы по продуктам
        </div>
      </div>
    </div>
  </div>
)

export default ProductsList
