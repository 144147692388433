export const url = url => {
  // eslint-disable-next-line
  const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i
  return regex.test(url)
}

export const hasErrors = errors =>
  Object.keys(errors).reduce((acc, key) => (errors[key] ? acc + 1 : acc), 0)

export default {
  url,
}
