import { createAction } from 'redux-act'
import api from 'common/modules/api'

export const fetchBranchesStart = createAction('fetch branches start')
export const fetchBranchesSuccess = createAction('fetch branches success')
export const fetchBranchesError = createAction('fetch branches error')

export const fetchBranches = () => dispatch => {
  dispatch(fetchBranchesStart())

  return api
    .fetch(api.routes.branches.index())
    .then(response => {
      const branches = response.json
      const result = {
        macroregionalDivisions: {
          all: [],
          byName: {},
        },
        branches: {
          all: [],
          byId: {},
        },
      }

      branches.forEach(branch => {
        result.branches.all.push(branch.id)
        result.branches.byId[branch.id] = branch
        if (
          !result.macroregionalDivisions.byName[branch.macroregionalDivision]
        ) {
          result.macroregionalDivisions.byName[branch.macroregionalDivision] = {
            name: branch.macroregionalDivision,
            branches: [],
          }
        }
        result.macroregionalDivisions.byName[
          branch.macroregionalDivision
        ].branches.push(branch.id)
      })
      result.macroregionalDivisions.all = Object.keys(
        result.macroregionalDivisions.byName
      )

      dispatch(fetchBranchesSuccess(result))
      return result
    })
    .catch(() => dispatch(fetchBranchesError()))
}
