import React from 'react'
import { formatPrice } from 'common/modules/price-helper'
import naming from 'constructors/modules/naming'
import config from 'entry-specific/config'

const InvoiceTotal = ({ once, monthly, annually }) => {
  return (
    <div className="proposal-info__block proposal-info__block--total">
      <div className="proposal-info__item">
        <div className="proposal-info__title">Итого (с учетом НДС)</div>
        <div className="proposal-info__subblock">
          <div className="proposal-info__row">
            <span className="proposal-info__name proposal-info__name--total">
              {naming('invoice.totalOnce', 'Разовый платеж')}
            </span>
            <span className="proposal-info__price proposal-info__price--total">
              {formatPrice(once)} руб.
            </span>
          </div>
          <div className="proposal-info__row">
            <span className="proposal-info__name proposal-info__name--total">
              {naming('invoice.totalMonthly', 'Регулярный платеж')}
            </span>
            <span className="proposal-info__price proposal-info__price--total">
              {formatPrice(monthly)} руб.
            </span>
          </div>
          {!config('constructor.invoice.totals.hideAnnually') && (
            <div className="proposal-info__row">
              <span className="proposal-info__name proposal-info__name--total">
                {naming('invoice.totalAnnually', 'Ежегодный платеж')}
              </span>
              <span className="proposal-info__price proposal-info__price--total">
                {formatPrice(
                  config(
                    'constructor.invoice.totals.annually',
                    ({ monthly }) => monthly * 12
                  )({
                    once,
                    monthly,
                    annually,
                  })
                )}{' '}
                руб.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InvoiceTotal
