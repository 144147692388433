import React from 'react'

import Landscape from './landscape'
import Regular from './regular'
import PdfPreview from './pdf-preview'

const ProductSectionDocuments = ({ type, section }) => {
  const Section = (function(type) {
    switch (type) {
      case 'landscape':
        return Landscape
      default:
        return Regular
    }
  })(type)

  return (
    <PdfPreview.Provider>
      <Section section={section} />
      <PdfPreview.Component />
    </PdfPreview.Provider>
  )
}

export default ProductSectionDocuments
