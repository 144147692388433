import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import toJS from 'common/HOC/to-js'
import debounce from 'common/modules/debounce'

import {
  getProposalFromCursor,
  getSalesScriptCursor,
} from 'main-v2/ducks/proposals'
import {
  getScriptByPath,
  getCurrentScriptPath,
  getCurrentScript,
} from 'main/selectors/sales-scripts/current-script'
import { getCurrentCard } from 'main/selectors/sales-scripts/current-card'
import { setCurrentCard } from 'main/actions/sales-scripts/current-card'

import Header from 'main-v2/components/proposal-script/Header'
import ScriptCards from 'main/components/sales-scripts/cards'
import ClientForm from 'main-v2/components/proposals/ClientForm'
import ContentsList from 'main/components/sales-scripts/contents/list'
import Toggler from 'main-v2/components/proposals/Toggler'

const mapStateToProps = state => {
  const currentScript = getCurrentScript(state)
  let cards = []

  if (currentScript && currentScript.cards) {
    cards = currentScript.cards
  }

  return {
    cursor: getSalesScriptCursor(state),
    proposal: getProposalFromCursor(state),
    script: getScriptByPath(state, [0, 0]),
    cards,
    currentScriptPath: getCurrentScriptPath(state),
    currentCard: getCurrentCard(state),
  }
}

const mapDispatchToProps = dispatch => ({
  setCurrentCard: cardId => dispatch(setCurrentCard(cardId)),
})

const adjustClientHeight = headerRef => {
  const el = document.getElementById('client-form-cmpltly-rndm')
  if (!el) return

  el.style.height =
    window.innerHeight - headerRef.current.offsetHeight - 133 < 666
      ? window.innerHeight - headerRef.current.offsetHeight - 133 + 'px'
      : '666px'
}

const ProposalScript = props => {
  const {
    proposal,
    cursor,
    script = {},
    cards,
    currentScriptPath,
    currentCard,
    setCurrentCard,
  } = props
  if (!proposal) return null

  const headerRef = useRef()

  const [toggleState, setToggleState] = useState('0')

  useEffect(() => {
    const debouncedAdjustClientHeight = debounce(
      adjustClientHeight.bind(null, headerRef),
      400
    )
    adjustClientHeight(headerRef)
    window.addEventListener('resize', debouncedAdjustClientHeight)

    return () =>
      window.removeEventListener('resize', debouncedAdjustClientHeight)
  }, [])

  return (
    <div className="main-layout">
      <Header wrapperRef={headerRef} />
      <div className="workspace-container center-block">
        <div className="workspace-container__client">
          <div className="card">
            <Toggler
              value={toggleState}
              onChange={value => {
                setToggleState(value)
                setTimeout(() => adjustClientHeight(headerRef), 10)
              }}
            />
            {toggleState === '0' && (
              <div className="card__content">
                <div
                  id="client-form-cmpltly-rndm"
                  className="inputs card__inputs"
                >
                  <ClientForm
                    cursor={cursor}
                    proposal={proposal}
                    autosave
                    callClient={() => alert('Данный функционал не подключен')}
                  />
                </div>
              </div>
            )}
            {toggleState === '1' && (
              <div className="card__content card__contents">
                <ContentsList
                  heightAdjust={180}
                  cards={cards}
                  currentCard={currentCard}
                  currentScriptPath={currentScriptPath}
                />
              </div>
            )}
          </div>
        </div>
        <div className="workspace-container__script">
          <ScriptCards
            cards={script.cards || []}
            setCurrentCard={setCurrentCard}
          />
        </div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(ProposalScript))
