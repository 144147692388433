import { connect } from 'react-redux'
import { login } from 'main/actions/auth'
import Login from 'main/components/authorization/login'

const mapStateToProps = state => ({
  isLoading: state.getIn(['auth', 'isLoading']),
  error: state.getIn(['auth', 'error']),
})

const mapDispatchToProps = dispatch => ({
  onSubmit: (email, password) => {
    dispatch(login(email, password))
  },
})

const Auth = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

export default Auth
