import api from 'common/modules/api'
import { normalize } from 'normalizr'
import { createAction, createReducer } from 'redux-act'
import proposalSchema from 'main-v2/schemas/proposal'
import { Map, fromJS } from 'immutable'
import { createSelector } from 'reselect'
import {
  getYearMonthDay,
  getDateWithTodayYesterdayOrDayOfWeek,
} from 'common/modules/date-helper'
import config from 'entry-specific/config'
import { hasErrors } from 'common/modules/validations'
import { isEmail } from 'main/modules/validations'

const initialState = {
  loaders: {
    search: false,
    data: false,
    nextPage: false,
  },
  salesScriptCursor: -1,
  byId: {},
  allIds: [],
  total: 0,
  filter: {
    page: 1,
    perPage: 20,
    q: null,
    date: null,
    decision: null,
    sort: '-created_at',
  },
}

// ACTIONS
export const fetchProposalStart = createAction('start fetch proposal')
export const fetchProposalSuccess = createAction('fetch proposal success')
export const fetchProposalError = createAction('fetch proposal error')

export const setLoader = createAction('set loader', (key, value) => ({
  key,
  value,
}))
const setProposals = createAction(
  'set proposals',
  (byId, allIds, options = {}) => ({
    byId,
    allIds,
    options,
  })
)
export const setFilter = createAction(
  'proposals - set filter',
  (key, value) => ({
    key,
    value,
  })
)
const setTotal = createAction('proposals - set total')
const setProposal = createAction('proposal - set')
export const setSalesScriptCursor = createAction(
  'proposals - set sales script cursor'
)
export const dropSalesScriptCursor = createAction(
  'proposals - drop sales script cursor'
)

export const fetchProposals = ({ append = false } = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const queryParams = getFilter(state).toJS()

  return api
    .fetch(api.routes.proposals.index(), { queryParams })
    .then(response => {
      dispatch(setTotal(parseInt(response.headers.get('x-total'), 10)))

      const normalizedProposals = normalize(response.json, [proposalSchema])

      return dispatch(
        setProposals(
          normalizedProposals.entities.proposal,
          normalizedProposals.result,
          { append }
        )
      )
    })
}

export const fetchNextPage = () => (dispatch, getState) => {
  const state = getState()
  if (!getHasNextPage(state)) return Promise.resolve()

  const filter = getFilter(state)

  dispatch(setLoader('nextPage', true))
  dispatch(setFilter('page', filter.get('page') + 1))
  return dispatch(fetchProposals({ append: true }))
    .then(() => dispatch(setLoader('nextPage', false)))
    .catch(() => dispatch(setLoader('nextPage', false)))
}

export const fetchProposal = proposalId => dispatch => {
  dispatch(fetchProposalStart())

  return api
    .fetch(api.routes.proposals.show(proposalId))
    .then(response => {
      dispatch(fetchProposalSuccess(response.json))
      return response.json
    })
    .catch(error => {
      return dispatch(fetchProposalError(error))
    })
}

export const deleteProposal = proposalId => dispatch =>
  api
    .fetch(api.routes.proposals.delete(proposalId))
    .then(() => dispatch(fetchProposals()))

export const saveDecision = ({ id, decision }) => dispatch => {
  return api
    .fetch(api.routes.proposals.changeDecision(id), { body: { decision } })
    .then(response => {
      dispatch(setProposal(response.json))
      return response.json
    })
}

export const saveClient = ({ id, client }) => dispatch => {
  const errors = validateClient(client)

  if (hasErrors(errors)) return Promise.reject(errors)

  const body = config('constructor.beforeSave', a => a)(
    id
      ? { id, client }
      : {
          ...getDefaultProposalValues(),
          client,
        }
  )

  const route = id
    ? api.routes.proposals.changeClient(id)
    : api.routes.proposals.new()

  return api
    .fetch(route, { body })
    .then(response =>
      id
        ? dispatch(setProposal({ id, client: response.json }))
        : dispatch(fetchProposals())
    )
}

// SELECTORS
export const getAllProposalIds = state => state.getIn(['proposals', 'allIds'])

export const getProposalIdsGroupedByDate = state =>
  state.getIn(['proposals', 'allIds']).groupBy(id => {
    const proposal = getProposal(state, id)
    return getYearMonthDay(new Date(proposal.get('createdAt')))
  })

export const getProposal = (state, id) => state.getIn(['proposals', 'byId', id])

export const getIndexById = createSelector(
  (_, id) => id,
  getAllProposalIds,
  (id, ids) => ids.indexOf(id)
)

export const getProposalStats = createSelector(getProposal, proposal =>
  proposal ? proposal.get('stats') : Map()
)

const getLoaders = state => state.getIn(['proposals', 'loaders'])
export const getLoader = createSelector(
  (_, name) => name,
  getLoaders,
  (name, loaders) => loaders.get(name)
)

export const getTotal = state => state.getIn(['proposals', 'total'])
export const getFilter = state => state.getIn(['proposals', 'filter'])
export const getHasNextPage = createSelector(
  getTotal,
  getFilter,
  (total, filter) => total > filter.get('page') * filter.get('perPage')
)

export const getFilterText = createSelector(getFilter, filter =>
  [
    formatStatus(filter.get('decision')),
    formatDate(filter.get('date')),
    formatSearch(filter.get('q')),
  ]
    .filter(item => item)
    .join(', ')
)

export const getSalesScriptCursor = state =>
  state.getIn(['proposals', 'salesScriptCursor'])

export const getProposalFromCursor = createSelector(
  state => state,
  getAllProposalIds,
  getSalesScriptCursor,
  (state, ids, cursor) => getProposal(state, ids.get(cursor))
)

export const getDefaultProposalValues = () => ({
  constructor_code: 'general',
  values: {
    products: ['general:business'],
  },
  client: {
    company_name: '',
    first_name: '',
    phone: '',
    email: '',
    tin: '',
    note: '',
  },
})

// FORMATTERS
export const statusMapping = config('proposals.decisions', {
  accepted: 'Принято',
  refused: 'Отказ',
})

export const filterStatusMapping = Object.assign({}, statusMapping, {
  none: 'Без статуса',
})

export const formatStatus = status => {
  if (!status) return 'Любой статус'

  return filterStatusMapping[status] || 'Неизвестный статус'
}

export const formatDate = date =>
  date ? getDateWithTodayYesterdayOrDayOfWeek(new Date(date)) : 'За всё время'

const formatSearch = q => (q ? `Поиск «${q}»` : null)

// VALIDATORS
export const validateClient = (client = {}) => {
  const errors = {
    companyName: !client.companyName,
  }

  if (config('proposals.willSendEmails')) {
    errors.email = !isEmail(client.email)
  } else {
    errors.firstName = !client.firstName
  }

  return errors
}

// REDUCER
const reducer = createReducer(
  {
    [setLoader]: (state, payload) =>
      state.setIn(['loaders', payload.key], payload.value),
    [setProposal]: (state, payload) =>
      state.update('byId', byId => byId.mergeDeep({ [payload.id]: payload })),
    [setProposals]: (state, payload = {}) =>
      state
        .update('byId', byId => byId.merge(fromJS(payload.byId)))
        .update('allIds', allIds =>
          payload.options.append
            ? allIds.concat(fromJS(payload.allIds))
            : fromJS(payload.allIds)
        ),
    [setTotal]: (state, payload = 0) => state.set('total', payload),
    [setFilter]: (state, payload = {}) =>
      state.setIn(['filter', payload.key], payload.value),
    [setSalesScriptCursor]: (state, payload) =>
      state.set('salesScriptCursor', payload),
    [dropSalesScriptCursor]: state => state.set('salesScriptCursor', -1),
  },
  fromJS(initialState)
)

export default reducer
