import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'
import { replace } from 'react-router-redux'

import appRoutes from 'main/constants/app-routes'
import newAppRoutes from 'main-v2/pages/routes'
import currentUser from 'common/modules/current-user'
import currentUserActions from 'common/ducks/current-user/actions'
import { isCurrentUserInitialized } from 'common/ducks/current-user/selectors'
import { startApplicationTimeTracking } from 'main/modules/application-time-tracking'
import config from 'entry-specific/config'

import Auth from 'main/containers/authorization/auth'
import ResetInstructions from 'main/containers/authorization/reset-instructions'
import PasswordReset from 'main/containers/authorization/password-reset'
import AccountActivation from 'main/containers/authorization/account-activation'
import Constructor from 'constructors/components/ConstructorContainer'
import SalesScripts from 'main/containers/sales-scripts'
import Proposals from 'main-v2/components/proposals'
import Products from 'main/containers/products'
import NewsCompilations from 'main/containers/news-compilations'
import NotFound from 'main/components/not-found'
import Adaptation from 'main-v2/pages/adaptation'
import AdaptationTest from 'main-v2/pages/adaptation-test'
import Profile from 'main-v2/pages/profile'
import Showroom from 'main/showroom'
import MainLayout from 'main/containers/layout'
import Blocked from 'main-v2/pages/blocked'

class App extends Component {
  constructor(props) {
    super(props)

    this.logoutAndNotFoundRedrects = this.logoutAndNotFoundRedrects.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    this.logoutAndNotFoundRedrects()
    dispatch(currentUserActions.initializeCurrentUser())
    startApplicationTimeTracking()
  }

  logoutAndNotFoundRedrects() {
    const { dispatch, location } = this.props

    if (appRoutes.notFound() === location.pathname) return
    if (location.pathname.indexOf('/showroom') !== -1) return

    const authRoutes = [
      appRoutes.auth,
      appRoutes.resetInstructions,
      appRoutes.passwordReset,
      // appRoutes.blocked(),
    ]
    const isLoggedIn = currentUser.isLoggedIn()

    if (location.pathname === appRoutes.accountActivation) {
      return localStorage.removeItem('token')
    }

    if (authRoutes.indexOf(location.pathname) === -1 && !isLoggedIn) {
      dispatch(currentUserActions.set(null))
      return dispatch(currentUser.logout())
    }

    if (authRoutes.indexOf(location.pathname) !== -1 && isLoggedIn) {
      return dispatch(replace(appRoutes.root))
    }
  }

  render() {
    const { isCurrentUserInitialized } = this.props
    if (!isCurrentUserInitialized) return null
    return (
      <Switch>
        <Route path={appRoutes.blocked()} component={Blocked} />
        <Route path={appRoutes.auth} component={Auth} />
        <Route
          path={appRoutes.resetInstructions}
          component={ResetInstructions}
        />
        <Route path={appRoutes.passwordReset} component={PasswordReset} />
        <Route
          path={appRoutes.accountActivation}
          component={AccountActivation}
        />

        <Route path={appRoutes.showroom} component={Showroom} />

        {currentUser.roleId && (
          <Route
            path={newAppRoutes.adaptation.test()}
            component={AdaptationTest}
          />
        )}

        <Route
          exact
          path={[appRoutes.proposals.index, newAppRoutes.proposals.stats()]}
          component={Proposals}
        />
        <Route
          exact
          path={appRoutes.root}
          render={props =>
            config('rootPath') === appRoutes.proposals.index ? (
              <Proposals {...props} />
            ) : (
              <MainLayout>
                <SalesScripts {...props} />
              </MainLayout>
            )
          }
        />

        <MainLayout>
          <Switch>
            <Route path={appRoutes.salesScripts} component={SalesScripts} />
            <Route path={appRoutes.products.index} component={Products} />
            <Route
              path={newAppRoutes.proposals.new({ resetParams: true })}
              component={Constructor}
            />
            <Route
              path={newAppRoutes.proposals.edit(undefined, {
                resetParams: true,
              })}
              component={Constructor}
            />
            {currentUser.roleId && (
              <Route
                path={newAppRoutes.adaptation.index()}
                component={Adaptation}
              />
            )}
            <Route
              path={newAppRoutes.profile.index({}, { resetParams: true })}
              component={Profile}
            />
            <Route
              path={appRoutes.newsCompilations.index}
              component={NewsCompilations}
            />
            <Route component={NotFound} />
          </Switch>
        </MainLayout>
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  routing: state.get('routing'),
  isCurrentUserInitialized: isCurrentUserInitialized(state),
})

export default withRouter(connect(mapStateToProps)(App))
