import { createAction } from 'redux-act'
import { normalize } from 'normalizr'
import { fromJS } from 'immutable'
import constructorSchema from 'main-v2/schemas/constructor'
import { push } from 'react-router-redux'
import appRoutes from 'main-v2/pages/routes'
import constructorsInitialState from 'entry-specific/apps/manager/constructors'
import {
  setProducts,
  resetAllProducts,
} from 'constructors/ducks/products/actions'
import { setOptions, resetAllOptions } from 'constructors/ducks/options/actions'
import { setModel, resetModel } from 'constructors/ducks/model/actions'
import { setPreviewConfigOptions } from 'constructors/ducks/preview-config-options/actions'
import config from 'entry-specific/config'
import { setClient } from 'constructors/ducks/client/actions'
import { setStats } from 'constructors/ducks/stats/actions'
import {
  setInitialValues,
  resetAllValues,
} from 'constructors/ducks/values/actions'
import { setStages } from 'constructors/ducks/stages/actions'
import { redirectToSutableStage } from 'constructors/ducks/stages/actions'
import {
  getInitialModel,
  getInitialClient,
  getRawConstructor,
  getInitialValues,
  getInitialStats,
  getConstructorId,
} from './selectors'
import currentUser from 'common/modules/current-user'

export const setConstructor = createAction('set constructor')
export const setInitialization = createAction('set initialization')

export const init = proposal => (dispatch, getState) => {
  dispatch(setInitialization(true))
  dispatch(resetAllValues())
  dispatch(resetAllProducts())
  dispatch(resetAllOptions())
  dispatch(setStages())
  dispatch(resetModel())

  const processedProposal = dispatch(processProposal(proposal))
  if (
    !constructorsInitialState[
      processedProposal.constructorCode ||
        processedProposal.model.constructorCode
    ]
  ) {
    return dispatch(push(appRoutes.proposals.index()))
  }

  return dispatch(setInitialData(processedProposal)).then(() => {
    dispatch(redirectToSutableStage())
    dispatch(setInitialization(false))
  })
}

const processProposal = proposal => (dispatch, getState) => {
  const state = getState()
  const initialProposal = {
    constructorCode: getConstructorId(state),
    model: getInitialModel(state),
    client: getInitialClient(state),
    stats: getInitialStats(state),
  }
  if (currentUser.isProposalWizard) {
    initialProposal.model.branchId = null
    initialProposal.model.branch = null
    initialProposal.model.mrd = null
  }

  const modelAttr = [
    'branch',
    'branchId',
    'constructorCode',
    'briefVersion',
    'createdAt',
    'id',
    'mrd',
    'previewConfig',
    'updatedAt',
  ]

  proposal.model = {}
  modelAttr.forEach(attr => {
    if (proposal[attr]) proposal.model[attr] = proposal[attr]
    delete proposal[attr]
  })

  const mergedProposal = fromJS(initialProposal)
    .mergeDeep(proposal)
    .toJS()

  return mergedProposal
}

const setInitialData = proposal => (dispatch, getState) => {
  // TODO: порядок имеет значение. Иначе не сработают mutations.
  if (config('previewConfigOptions'))
    dispatch(setPreviewConfigOptions(config('previewConfigOptions')))

  // TODO: порядок имеет значение. Далее данные забираются из модели
  dispatch(setModel(proposal.model))

  const state = getState()
  const rawConstructor = getRawConstructor(state, proposal.constructorCode)

  const normalizedConstructor = normalize(rawConstructor, constructorSchema)
  dispatch(
    setConstructor({
      id: normalizedConstructor.result,
      products:
        normalizedConstructor.entities.constructors[
          normalizedConstructor.result
        ].products,
    })
  )
  dispatch(
    setProducts({
      byId: normalizedConstructor.entities.products,
    })
  )
  dispatch(
    setOptions({
      byId: normalizedConstructor.entities.options,
    })
  )
  dispatch(setStages(rawConstructor.stages))
  dispatch(setClient(proposal.client))
  dispatch(setStats(proposal.stats))
  // TODO: значения должны быть получены на этапе processProposal
  const initialValues = proposal.model.id
    ? proposal.values
    : getInitialValues(state)
  dispatch(setInitialValues(initialValues))

  return Promise.resolve()
}

export default {
  setConstructor,
}
