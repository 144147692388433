import React from 'react'
import PropTypes from 'prop-types'

const Actions = ({
  prevText,
  nextText,
  isDemoMode,
  changeToPreviousStage,
  changeToNextStage,
  isLoading,
}) => {
  return (
    <div className="actions card__actions">
      {prevText && (
        <button className="actions__prev" onClick={changeToPreviousStage}>
          {prevText}
        </button>
      )}
      {nextText &&
        getNextButton(nextText, changeToNextStage, isLoading, isDemoMode)}
    </div>
  )
}

const getNextButton = (nextText, changeToNextStage, isLoading, isDemoMode) => {
  let className = 'actions__next'
  if (isLoading) className += ' button button--loading'
  if (isDemoMode) className += ' button button--error'

  let textClassName = 'button__text'
  if (isDemoMode) textClassName = 'button__error'

  return (
    <button
      type="button"
      className={className}
      disabled={isLoading}
      onClick={changeToNextStage}
    >
      <span className={textClassName} dangerouslySetInnerHTML={{__html: nextText}}></span>
      {isLoading && <span className="button__loader" />}
    </button>
  )
}

Actions.propTypes = {
  prevText: PropTypes.string,
  nextText: PropTypes.string,
  changeToPreviousStage: PropTypes.func,
  changeToNextStage: PropTypes.func,
}

export default Actions
