import { SET_CURRENT_CARD } from 'main/actions/sales-scripts/current-card'

const initialState = null

const currentCard = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CARD:
      return action.cardId

    default:
      return state
  }
}

export default currentCard
