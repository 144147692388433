import React from 'react'

const CalculationMapping = ({ content, result }) => {
  return (
    <div className="script__result script__block">
      <div className="script__step">{content.name}</div>
      {content.resultText}
      <span className="script__result-val">{result}</span>
    </div>
  )
}

export default CalculationMapping
