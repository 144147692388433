import {
  NEWS_CATEGORIES_FETCH_START,
  NEWS_CATEGORIES_FETCH_SUCCESS,
  NEWS_CATEGORIES_FETCH_ERROR,
} from 'main/actions/news-categories'
import { fromJS } from 'immutable'

const initialState = {
  isLoading: false,
  records: [],
}

const newsCategoriesList = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case NEWS_CATEGORIES_FETCH_START:
      return state.merge({
        isLoading: true,
      })
    case NEWS_CATEGORIES_FETCH_SUCCESS:
      return state.merge({
        isLoading: false,
        records: action.records,
      })
    case NEWS_CATEGORIES_FETCH_ERROR:
      return state.merge({
        isLoading: false,
      })
    default:
      return state
  }
}

export default newsCategoriesList
