export const SET_AUTOFILL = 'SALES_SCRIPTS_SET_AUTOFILL'
export const RESET_AUTOFILL = 'SALES_SCRIPTS_RESET_AUTOFILL'

export const setAutofill = (path, value) => ({
  type: SET_AUTOFILL,
  path,
  value,
})

export const resetAutofill = () => ({
  type: RESET_AUTOFILL,
})
