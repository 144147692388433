import { fromJS, List } from 'immutable'

export const buildConstructorInitialData = (constructor, params) => {
  constructor = fromJS(constructor)
  if (!constructor.get('id')) throw new Error('Initial state must have id')

  const result = constructor
    .update('disabled', disabled =>
      processDisabled(disabled, constructor.get('id'), params)
    )
    .update('products', products =>
      processProducts(products, constructor.get('id'), params)
    )
    .update('stages', stages =>
      processStages(stages, constructor.get('id'), params)
    )
    .toJS()

  return result
}

const processDisabled = (disabled, constructorId, params) => {
  if (disabled === true) return true
  if (typeof disabled === 'function') {
    return disabled(params)
  }
  return false
}

const processProducts = (products, constructorId, params) => {
  return products.map(product => {
    const resolvedProduct = resolveProduct(product, params)

    if (!resolvedProduct.get('id'))
      throw new Error(`${resolvedProduct.get('name')} must have id`)
    const productId = concatIds([constructorId, resolvedProduct.get('id')])

    return resolvedProduct.set('id', productId).update('options', options =>
      processOptions(options, productId, {
        ...params,
        vat: resolvedProduct.get('vat'),
      })
    )
  })
}

const resolveProduct = (product, params) => {
  return typeof product === 'function'
    ? fromJS(product(params))
    : fromJS(product)
}

const concatIds = ids => ids.reduce((str, id) => `${str}:${id}`)

const processOptions = (options, parentId, params) => {
  const result = options
    .map(option => {
      const resolvedOption = resolveOption(option, params)
      if (!resolvedOption) return null
      if (List.isList(resolvedOption)) {
        return processOptions(resolvedOption, parentId, params)
      }

      return processOption(resolvedOption, parentId, params)
    })
    .filter(o => o !== null)
    .flatMap(v => (List.isList(v) ? v : [v]))

  return result
}

const processOption = (option, parentId, params) => {
  const optionId = concatIds([parentId, option.get('id')])
  option = option.set('id', optionId)

  if (option.get('price')) {
    option = option.set(
      'price',
      processPrice(option.get('price'), !!params.vat)
    )
  }

  if (option.get('inputType') === 'radio') {
    option = option.update('values', values =>
      processOptions(values, optionId, params)
    )
  }

  return option
}

const resolveOption = (option, params) => {
  return typeof option === 'function' ? fromJS(option(params)) : option
}

const processPrice = (price, vat) => {
  if (price.get('monthly')) {
    price = price.setIn(['monthly', 'vat'], vat)
  }
  if (price.get('once')) {
    price = price.setIn(['once', 'vat'], vat)
  }
  if (price.get('annually')) {
    price = price.setIn(['annually', 'vat'], vat)
  }
  return price
}

const processStages = (stages, constructorId, params) => {
  return stages.reduce((acc, stage) => {
    if (!stage) return acc

    if (typeof stage === 'function') {
      stage = fromJS(stage(params))
    }

    if (!stage) return acc

    if (stage.get('productIds')) {
      stage = stage.update('productIds', productIds =>
        productIds.map(productId => concatIds([constructorId, productId]))
      )
    }

    if (stage.get('productId')) {
      stage = stage.set(
        'productId',
        concatIds([constructorId, stage.get('productId')])
      )
    }

    if (stage.get('optionId')) {
      stage = stage.set(
        'optionId',
        concatIds([stage.get('productId'), stage.get('optionId')])
      )
    }

    if (stage.get('optionIds')) {
      stage = stage.update('optionIds', optionIds =>
        optionIds.map(optionId => concatIds([stage.get('productId'), optionId]))
      )
    }

    if (stage.get('blocks')) {
      stage = stage.set(
        'blocks',
        processStages(stage.get('blocks'), constructorId, params)
      )
    }
    return acc.push(stage)
  }, List())
}

export default buildConstructorInitialData
