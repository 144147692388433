import React from 'react'

const Title = ({ option }) => (
  <tr className="options__row options__row-title">
    <td className="options__field options__field--title" colSpan="2">
      {option.name}
    </td>
  </tr>
)

export default Title
