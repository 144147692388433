import { formatPrice } from 'common/modules/price-helper'
import config from 'entry-specific/config'

const InvoicePrice = ({ option, price, value }) => {
  if (!price) return null

  const priceSource = price.monthly || price.once || price.annually
  if (!priceSource) {
    console.error(`wrong price ${JSON.stringify(price)}`)
    return null
  }

  if (option.inputType === 'number') {
    return `${formatPrice(value)} ${priceSource.caption}`
  }

  if (config('constructor.invoice.priceFormatter')) {
    return config('constructor.invoice.priceFormatter')({
      value,
      price: priceSource.value,
      caption: priceSource.caption,
    })
  }

  let result = ''
  if (value > 1) result = `${value} x `
  result += `${formatPrice(priceSource.value)} ${priceSource.caption}`

  return result
}

export default InvoicePrice
