import { connect } from 'react-redux'
import { getRadioOptions } from 'constructors/ducks/options/selectors'
import Radio from './Radio'
import toJS from 'common/HOC/to-js'

const mapStateToProps = (state, ownProps) => ({
  radioOptions: getRadioOptions(state, ownProps.option.id),
})

const RadioContainer = connect(mapStateToProps)(toJS(Radio))

export default RadioContainer
