import React, { Component } from 'react'
import Ticker from './Ticker'

class NumberOption extends Component {
  state = {
    price: '',
  }

  onChange = e => {
    const newValue = this.formatPriceValue(e.target.value)
    this.setState({ price: newValue })

    const { option, value, onChange } = this.props
    if (value !== parseFloat(newValue)) {
      onChange(option.id, parseFloat(newValue))
    }
  }

  formatPriceValue = value => {
    let newValue = value.replace(/[^0-9.]/g, '').replace(/^0+/, '')
    const split = newValue.split('.')
    const int = split[0]
    const decimal = split[1]
    if (!decimal) return newValue

    return `${int}.${decimal.substr(0, 2)}`
  }

  getPriceCaption = () => {
    const {
      option: { price },
    } = this.props
    if (price.monthly) return price.monthly.caption
    if (price.once) return price.once.caption
    return ''
  }

  getVatCaption = () => {
    const {
      option: { price },
    } = this.props
    const vat = price.monthly ? price.monthly.vat : price.once.vat
    return vat ? '(с учетом НДС)' : '(без учета НДС)'
  }

  render() {
    const { option } = this.props
    let { price } = this.state

    if (price === '') price = this.formatPriceValue(this.props.value + '')

    return (
      <tr className="options__row options__row--active">
        <td className="options__field">
          <Ticker text={`${option.name} ${this.getVatCaption()}`} />
        </td>
        <td className="options__price">
          <div className="options__price-block">
            <div className="options__price-counter">
              <input
                className="options__input"
                value={price || ''}
                onChange={this.onChange}
                placeholder="0 000"
              />
            </div>
            <div className="options__font">
              <div className="options__text">{this.getPriceCaption()}</div>
            </div>
          </div>
        </td>
      </tr>
    )
  }
}

export default NumberOption
