import { connect } from 'react-redux'
import { getOptionPrice } from 'constructors/ducks/options/selectors'
import { getOptionValue } from 'constructors/ducks/values/selectors'
import toJS from 'common/HOC/to-js'
import Price from './Price'

const mapStateToProps = (state, ownProps) => ({
  price: getOptionPrice(state, ownProps.optionId),
  value: getOptionValue(state, ownProps.optionId),
})

const PriceContainer = connect(mapStateToProps)(toJS(Price))

export default PriceContainer
