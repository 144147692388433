import React, { Component } from 'react'
import { connect } from 'react-redux'
import appRoutes from 'main-v2/pages/routes'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { fetchAdaptation } from 'main-v2/ducks/adaptation/actions'
import { getAdaptationDayIndex } from 'main-v2/ducks/adaptation/selectors'
import { getAdaptationTest } from 'main-v2/ducks/adaptation/selectors'
import { saveAnswers, removeAnswers } from 'main-v2/ducks/answers/actions'
import { getRemainAnswersCount } from 'main-v2/ducks/answers/selectors'
import Test from 'main-v2/components/test/Test'
import { numberAsWord } from 'common/modules/number-helper'
import toJS from 'common/HOC/to-js'
import Jivosite from 'main-v2/components/Jivosite'

const mapStateToProps = (state, ownProps) => ({
  dayId: ownProps.match.params.dayId,
  dayIndex: getAdaptationDayIndex(state, ownProps.match.params.dayId),
  test: getAdaptationTest(state, ownProps.match.params.dayId),
  remainAnswersCount: getRemainAnswersCount(state, ownProps.match.params.dayId),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchAdaptation: () => dispatch(fetchAdaptation()),
  removeAnswers: () => dispatch(removeAnswers()),
  saveAnswers: (testId, testType) =>
    dispatch(saveAnswers(testId)).then(() => {
      if (testType === 'quiz')
        return dispatch(
          push(
            appRoutes.adaptation.dayResult({
              dayId: ownProps.match.params.dayId,
            })
          )
        )
      dispatch(
        push(appRoutes.adaptation.day({ dayId: ownProps.match.params.dayId }))
      )
    }),
})

class AdaptationTest extends Component {
  componentDidMount() {
    const { fetchAdaptation, day, removeAnswers } = this.props
    if (!day) fetchAdaptation()
    removeAnswers()

    document.body.style.backgroundColor = '#27313D'
  }
  componentWillUnmount() {
    document.body.style.backgroundColor = 'rgb(243, 245, 247)'
  }

  saveAnswers = () => {
    const { test, saveAnswers } = this.props
    saveAnswers(test.id, test.type)
  }

  render() {
    const { dayId, dayIndex, test, remainAnswersCount } = this.props

    if (!test) return null

    document.title = `Рабочее пространство - День ${numberAsWord(
      dayIndex + 1
    )}: ${getTestType(test)}`

    return (
      <div className="test-layout">
        <div className="test-nav">
          <div className="test-nav__block">
            <Link
              to={appRoutes.adaptation.day({ dayId })}
              className="button test-nav__back"
            >
              ← Закрыть<span> и вернуться к обучению</span>
            </Link>
            <div className="test-nav__title">
              День {numberAsWord(dayIndex + 1)}: {getTestType(test)}
            </div>
            <div className="test-nav__actions">
              <button
                className="button button--warning"
                disabled={remainAnswersCount === null || remainAnswersCount}
                onClick={this.saveAnswers}
              >
                Завершить и отправить
              </button>
            </div>
          </div>
        </div>
        <Test test={test} remainAnswersCount={remainAnswersCount} />
        <Jivosite />
      </div>
    )
  }
}

const getTestType = test => {
  return test.type === 'quiz' ? 'тестирование' : 'анкетирование'
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(AdaptationTest))
