import React from 'react'
import Option from './OptionContainer'
import { getVisibilityAt } from 'constructors/modules/option-visibility'

const Options = ({ options, onChange, visibilityPosition = 1 }) => {
  const getIsHiddenBySpoiler = memoizableIsHiddenBySpoiler()

  return (
    <table className="options proposal-row__content">
      <tbody>
        {options.map(option => {
          return (
            <Option
              key={option.id}
              optionId={option.id}
              onChange={onChange}
              isHiddenBySpoiler={getIsHiddenBySpoiler(option)}
              nestedOptionIds={getNestedOptionIds(options, option)}
              visibilityPosition={visibilityPosition}
            />
          )
        })}
      </tbody>
    </table>
  )
}

const getNestedOptionIds = (options, option) => {
  if (option.inputType !== 'delimiter') return []

  let nestedScope = false
  return options.reduce((acc, o) => {
    if (o.inputType === 'delimiter') nestedScope = false
    if (o.id === option.id) nestedScope = true
    if (
      o.inputType !== 'delimiter' &&
      nestedScope &&
      !getVisibilityAt(o.visibility, 1)
    )
      acc.push(o.id)
    return acc
  }, [])
}

const memoizableIsHiddenBySpoiler = () => {
  let spoilerScope = false
  let spoilerIsOpen = false

  return option => {
    if (option.inputType === 'delimiter') spoilerScope = false
    if (option.isSpoiler) spoilerScope = true
    if (option.isSpoiler) spoilerIsOpen = option.isOpen
    if (option.inputType === 'delimiter') return false
    return spoilerScope && !spoilerIsOpen ? true : false
  }
}

export default Options
