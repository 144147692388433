import React from 'react'

const Search = props => {
  const { value, onChange, onReset } = props

  return (
    <div className={getClassName(props)}>
      <input
        type="text"
        className="input input--search"
        placeholder="Поиск..."
        value={value}
        onChange={onChange}
      />
      <div className="search__close" onClick={onReset} />
      <div className="search__loader" />
    </div>
  )
}

const getClassName = ({ isLoading, value }) => {
  let className = 'search'

  if (isLoading) {
    className += ' search--loading'
    return className
  }

  if (value) {
    className += ' search--active'
  }

  return className
}

export default Search
