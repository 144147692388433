import React from 'react'
import OptionContainer from './OptionContainer'
import Price from './Price'
import { isHiddenInInvoice } from 'constructors/modules/option-visibility'

const Option = ({ option, value }) => {
  if (option.inputType === 'delimiter') return null
  if (option.inputType === 'radio') {
    return <OptionContainer optionId={option.values[value]} />
  }
  if (!value) return null
  if (isHiddenInInvoice(option.visibility)) return null

  return (
    <div className="proposal-info__row">
      <span className="proposal-info__name">{option.name}</span>
      <span className="proposal-info__price">
        <Price option={option} price={option.price} value={value} />
      </span>
    </div>
  )
}

export default Option
