export const isEmail = email => {
  // eslint-disable-next-line
  const emailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  return emailRegExp.test(email)
}

export const isTin = tin => {
  if (!tin) return false
  const tinRegExp = /^\d{10,12}$/
  return tinRegExp.test(tin)
}

export const isPhone = phone => !!phone

export const isValidPassword = password => {
  return password !== ''
}

export const isNotBlank = value => {
  return !!value
}

export const isPasswordConfirmed = (password, passwordConfirmation) => {
  return password === passwordConfirmation
}

export const getErrorTextFromMapping = (
  mapping,
  key,
  defaultValue = 'Неизвестная ошибка'
) => mapping[key] || defaultValue
