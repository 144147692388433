import React from 'react'
import SelectBranch from './Stages/SelectBranchContainer'
import SelectOneOption from './Stages/SelectOneOptionContainer'
import SelectOneProduct from './Stages/SelectOneProductContainer'
import MultipleProducts from './Stages/MultipleProductsContainer'
import Blocks from './Stages/Blocks'
import CustomOptions from './Stages/CustomOptionsContainer'
import Client from './Stages/Client'

const CurrentStage = ({ stage = {} }) => {
  switch (stage.type) {
    case 'selectBranch':
      return <SelectBranch stage={stage} />
    case 'selectOneOption':
      return <SelectOneOption stage={stage} />
    case 'selectOneProduct':
      return <SelectOneProduct stage={stage} />
    case 'multipleProducts':
      return <MultipleProducts stage={stage} />
    case 'blocks':
      return <Blocks stage={stage} />
    case 'customOptions':
      return <CustomOptions stage={stage} />
    case 'client':
      return <Client stage={stage} />
    default:
      return null
  }
}

export default CurrentStage
