import React, { Fragment } from 'react'

const RenderByLines = ({ children }) => {
  return children
    ? children.split('\n').map((line, index) => (
        <Fragment key={index}>
          {line}
          <br />
        </Fragment>
      ))
    : null
}

export default RenderByLines
