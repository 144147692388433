import React from 'react'

const NotFound = () => {
  return (
    <div className="error-404 center-block">
      <div className="error-404__title">404</div>
      <div className="error-404__text">
        Похоже, что вы нашли несуществующую страницу
      </div>
    </div>
  )
}

export default NotFound
