import React from 'react'
import Options from 'constructors/components/Options/Container'

const Product = ({ product }) => {
  return (
    <div key={product.id} className="proposal-row card__row">
      <div className="proposal-row__title">{product.name}</div>
      <Options productId={product.id} visibilityPosition={2} />
    </div>
  )
}

export default Product
