import React from 'react'
import config from 'entry-specific/config'
import Typeform from 'main-v2/components/Typeform'

const Blocked = () => (
  <div className="fullscreen-layout">
    <div className="blocked">
      <div className="blocked__title">
        {config('blocked.title', 'Доступ заблокирован')}
        {config('blocked.info') && (
          <div className="blocked__info">{config('blocked.info')}</div>
        )}
      </div>
      <Typeform url={config('blocked.typeform')} />
      {config('blocked.link') && (
        <div className="blocked__logo">
        <a
          className="small-logo "
          target="_blank"
          rel="noopener noreferrer"
          href={config('blocked.link')}
        >
          {' '}
        </a>
        </div>
      )}
    </div>
  </div>
)

export default Blocked
