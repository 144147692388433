import React from 'react'
import SimpleMarkdown from 'simple-markdown'

var rules = {
  paragraph: SimpleMarkdown.defaultRules.paragraph,
  em: SimpleMarkdown.defaultRules.em,
  strong: SimpleMarkdown.defaultRules.strong,
  br: SimpleMarkdown.defaultRules.br,
  text: SimpleMarkdown.defaultRules.text,
}

var parser = SimpleMarkdown.parserFor(rules)
var reactOutput = SimpleMarkdown.reactFor(
  SimpleMarkdown.ruleOutput(rules, 'react')
)

const Markdown = ({ text }) => (
  <div className="markdown">{reactOutput(parser(text))}</div>
)

export default Markdown
