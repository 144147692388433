import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import {
  addProductAction,
  addProductToPositionAction,
  removeProductAction,
  changeOptionValue,
  removeOptionValueAction,
  resetAllValues,
} from './actions'

export const initialState = {
  products: [],
  options: {},
}

const reducer = createReducer(
  {
    [addProductAction]: (state, payload = {}) => {
      return state.update('products', products =>
        products.push(payload.productId)
      )
    },
    [addProductToPositionAction]: (state, payload = {}) => {
      return state.update('products', products =>
        products.insert(payload.position, payload.productId)
      )
    },
    [removeProductAction]: (state, payload = {}) => {
      return state.update('products', products =>
        products.filter(p => p !== payload.productId)
      )
    },
    [changeOptionValue]: (state, payload = {}) => {
      return state.update('options', options =>
        options.set(payload.optionId, payload.value)
      )
    },
    [removeOptionValueAction]: (state, payload = {}) => {
      return state.update('options', options =>
        options.delete(payload.optionId)
      )
    },
    [resetAllValues]: () => {
      return fromJS(initialState)
    },
  },
  fromJS(initialState)
)

export default reducer
