import React from 'react'

import Documents from './documents'
import Interactives from './interactives'
import Videos from './videos'

const ProductSection = ({ section }) => {
  return <div className="product-layout__content">{renderSection(section)}</div>
}

const renderSection = section => {
  if (section.code === 'ic') return <Interactives section={section} />

  if (['docs', 'prices', 'comp_overall', 'clients'].indexOf(section.code) !== -1) {
    return <Documents section={section} />
  }

  if (
    [
      'tc',
      'proposal',
      'modules',
      'use_cases',
      'comp_detailed',
      'comp_CRM',
      'client',
      'sales',
      'battlecard',
      'price',
    ].indexOf(section.code) !== -1
  ) {
    return <Documents section={section} type={'landscape'} />
  }

  if (['screencasts', 'clips'].indexOf(section.code) !== -1) {
    return <Videos section={section} />
  }

  return null
}

export default ProductSection
