import React from 'react'
import { connect } from 'react-redux'
import { getModel } from 'constructors/ducks/model/selectors'
import { setPreviewConfig } from 'constructors/ducks/model/actions'
import toJS from 'common/HOC/to-js'

import CardBlock from 'constructors/components/CardBlock'

import { getPreviewConfigOptions } from 'constructors/ducks/preview-config-options/selectors'

const mapStateToProps = state => ({
  model: getModel(state),
  previewConfigOptions: getPreviewConfigOptions(state),
})

const mapDispatchToProps = dispatch => ({
  onChange: e =>
    dispatch(
      setPreviewConfig({
        [e.target.name]: e.target.value,
      })
    ),
})

const PreviewConfig = ({ model, previewConfigOptions, onChange }) => {
  if (!previewConfigOptions || !Object.keys(previewConfigOptions).length)
    return null
  const previewConfigValues = model.previewConfig

  return Object.keys(previewConfigOptions)
    .map(key => ({ id: key, ...previewConfigOptions[key] }))
    .filter(item => !item.hidden)
    .map(item => {
      if (!previewConfigValues[item.id])
        onChange({ target: { name: item.id, value: item.options[0].id } })

      return (
        <CardBlock key={item.id} title={item.name}>
          <div className="proposal-row__content">
            <ul className="summary">
              {item.options.map(option => (
                <li className="summary__item" key={option.id}>
                  <label
                    className={`summary__label ${
                      option.id === previewConfigValues[item.id]
                        ? 'summary__label--active'
                        : ''
                    }`}
                  >
                    <div className="radio-input summary__radio-input">
                      <input
                        type="radio"
                        className="radio-input__input"
                        name={item.id}
                        checked={option.id === previewConfigValues[item.id]}
                        value={option.id}
                        onChange={onChange}
                      />
                      <div className="radio-input__block" />
                    </div>
                    <div className="summary__text">{option.name}</div>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </CardBlock>
      )
    })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(PreviewConfig))
