import { createSelector } from 'reselect'

export const getAdaptation = state => state.getIn(['adaptation', 'data'])
export const getAdaptationDay = (state, dayId) => {
  const days = state.getIn(['adaptation', 'data', 'days'])
  if (!days) return null
  return days.find(day => day.get('id') === dayId)
}
export const getAdaptationDayIndex = (state, dayId) => {
  const days = state.getIn(['adaptation', 'data', 'days'])
  if (!days) return -1
  return days.findIndex(day => day.get('id') === dayId)
}
export const getAdaptationTest = createSelector(
  getAdaptationDay,
  day => day && day.getIn(['tests', 0])
)
