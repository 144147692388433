import { Component } from 'react'
import PropTypes from 'prop-types'
import throttle from 'common/modules/throttle'

class Scrollspy extends Component {
  componentDidMount() {
    this.currentItemIndex = -1
    document.addEventListener('scroll', this.handleSpy)
    this.spy()
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleSpy)
  }

  handleSpy = () => {
    throttle(this.spy(), 100)
  }

  spy = () => {
    if (
      window.innerHeight + window.pageYOffset >=
      document.body.offsetHeight - 2
    ) {
      const { items } = this.props
      return this.update(items.length - 1)
    }
    if (this.isInFocus(this.currentItemIndex)) return
    if (this.currentItemIndex < 0) return this.findForAll()
    this.findForNeighbours()
  }

  findForAll = () => {
    const { items } = this.props

    for (let i = 0; i < items.length; i++) {
      if (this.isInFocus(i)) {
        return this.update(i)
      }
    }

    this.setInitial()
  }

  findForNeighbours = () => {
    // check next item
    if (this.isInFocus(this.currentItemIndex + 1)) {
      return this.update(this.currentItemIndex + 1)
    }

    // check prev item
    if (this.isInFocus(this.currentItemIndex - 1)) {
      return this.update(this.currentItemIndex - 1)
    }

    this.findForAll()
  }

  update = index => {
    const { items } = this.props
    this.currentItemIndex = index
    this.props.onUpdate(items[index])
  }

  setInitial = () => {
    this.currentItemIndex = -1
    this.props.onUpdate(null)
  }

  isInFocus(i) {
    if (i < 0) return false

    const { items } = this.props
    const el = document.getElementById(items[i])
    if (!el) {
      return false
    }

    const { rootEl, offset = 0, rootHeight = 0 } = this.props
    let rootRect

    if (rootEl) {
      rootRect = document.querySelector(rootEl).getBoundingClientRect()
    }

    const rect = el.getBoundingClientRect()
    // const winH = rootEl ? rootRect.height : window.innerHeight
    const { scrollTop } = this.getScrollDimension()
    const scrollBottom = scrollTop + rootHeight
    const elTop = rootEl
      ? rect.top + scrollTop - rootRect.top + offset
      : rect.top + scrollTop + offset
    const elBottom = elTop + el.offsetHeight

    return elTop < scrollBottom && elBottom > scrollTop
  }

  getScrollDimension = () => {
    const doc = document
    const { rootEl } = this.props
    const scrollTop = rootEl
      ? doc.querySelector(rootEl).scrollTop
      : doc.documentElement.scrollTop ||
        doc.body.parentNode.scrollTop ||
        doc.body.scrollTop
    const scrollHeight = rootEl
      ? doc.querySelector(rootEl).scrollHeight
      : doc.documentElement.scrollHeight ||
        doc.body.parentNode.scrollHeight ||
        doc.body.scrollHeight

    return {
      scrollTop,
      scrollHeight,
    }
  }

  render() {
    const { children } = this.props

    return children
  }
}

Scrollspy.propTypes = {
  items: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default Scrollspy
