import { fromJS } from 'immutable'
import { createReducer } from 'redux-act'
import { setFailedQuestions, setFirstAttempt } from './actions'

const initialState = {
  firstAttempts: {},
  fails: {},
}

const reducer = createReducer(
  {
    [setFailedQuestions]: (state, payload) => {
      return state.setIn(['fails', payload.testId], payload.failedQuestions)
    },
    [setFirstAttempt]: (state, payload) => {
      return state.setIn(
        ['firstAttempts', payload.testId],
        payload.firstAttempt
      )
    },
  },
  fromJS(initialState)
)

export default reducer
