import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

class Showroom extends Component {
  componentWillUnmount() {
    document.body.style.backgroundColor = null
  }

  componentDidMount() {
    document.body.style.backgroundColor = '#F3F5F7'
    // document.body.style.backgroundColor = "#27313D";

    window.onresize = rresize

    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        rresize()
      }
    }

    function rresize() {
      if (document.getElementsByClassName('contents')[0])
        document.getElementsByClassName('contents')[0].style.height =
          window.innerHeight - 370 - 46 + 'px'
    }
  }

  render() {
    if (process.env.NODE_ENV === 'production') {
      return null
    } else {
      return (
        <Switch>
          <Route
            path={'/showroom/start'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./start.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/login'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./login.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/login_reset'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./login_reset.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/reset_sent'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./reset_sent.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/login_new'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./login_new.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/scripts'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./scripts.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/workspace'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./workspace.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/scripts_empty'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./scripts_empty.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/404'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./404.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/docs'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./docs.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/links'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./links.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/proposals_list'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposals_list.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_stage1'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_stage1.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_stage1_selected'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_stage1_selected.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_stage2'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_stage2.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_stage2_wifi'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_stage2_wifi.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_stage2_tv'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_stage2_tv.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_stage2_ats'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_stage2_ats.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_stage2_sms'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_stage2_sms.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_stage2_video'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_stage2_video.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_stage3'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_stage3.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_stage4'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_stage4.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_stage_more'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_stage_more.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_mono-sms'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_mono-sms.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_mono-vats'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_mono-vats.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_mono-tv'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_mono-tv.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_mono-oktaplan'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_mono-oktaplan.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_mono-video'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_mono-video.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_mono-wifi'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_mono-wifi.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_mono-mobile_biznes'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_mono-mobile_biznes.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_mono-mobile_vizov'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_mono-mobile_vizov.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_mono-internet'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_mono-internet.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/proposal_mono-8800'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./proposal_mono-8800.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/preview'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./preview.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/view-mts-vats'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./view-mts-vats.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/preview_another'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./preview_another.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./view.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/view_sms'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./view_sms.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/view_vats'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_vats.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_vats-drive'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_vats-drive.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_mobile-drive'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_mobile-drive.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_internet-drive'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_internet-drive.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_ats_rt'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_ats_rt.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_ats_rt_accepted'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_ats_rt_accepted.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_ats_rt_edit'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_ats_rt_edit.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_ats_rt_apply'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_ats_rt_apply.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/order'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./order.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/block'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./block.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_video'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_video.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_8800'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_8800.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_8800-silver'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_8800-silver.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_tv'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./view_tv.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/view_wifi'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_wifi.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_plus'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_plus.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_plus2'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_plus2.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_beecloud'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_beecloud.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_mob-biz'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_mob-biz.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_mob-viz'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_mob-viz.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_internet'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_internet.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/view_oktaplan'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./view_oktaplan.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/products'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./products.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/product'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./product.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/product_docs'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./product_docs.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/news'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{ __html: require('./news.html') }}
              />
            )}
          />
          <Route
            path={'/showroom/videoplayer'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./videoplayer.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/adapt_days'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./adapt_days.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/adapt_day'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./adapt_day.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/adapt_test'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./adapt_test.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/adapt_review'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./adapt_review.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/adapt_test_res'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./adapt_test_res.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/profile'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./profile.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/profile_new_visit_empty'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./profile_new_visit_empty.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/profile_edit_visit'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./profile_edit_visit.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/profile_update_result'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./profile_update_result.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/profile_new_visit_full'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./profile_new_visit_full.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/profile_delete_visit'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./profile_delete_visit.html'),
                }}
              />
            )}
          />
          <Route
            path={'/showroom/constructor'}
            render={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: require('./constructor.html'),
                }}
              />
            )}
          />
        </Switch>
      )
    }
  }
}

export default Showroom
