import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import { setStages, removeStage, addStage, changeStageContent } from './actions'

export const initialState = []

const reducer = createReducer(
  {
    [setStages]: (state, payload = []) => {
      return fromJS(payload)
    },
    [removeStage]: (state, payload) => {
      return state.delete(payload)
    },
    [addStage]: (state, payload = {}) => {
      return state.splice(payload.index, 0, fromJS(payload.stage))
    },
    [changeStageContent]: (state, payload = {}) =>
      state.mergeDeepIn(payload.path, fromJS(payload.content)),
  },
  fromJS(initialState)
)

export default reducer
