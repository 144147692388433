import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getAll,
  getIsLoading,
  fetchList,
  getTotalPages,
  getPage,
} from 'main-v2/ducks/visits/list'
import appRoutes from 'main-v2/pages/routes'
import toJS from 'common/HOC/to-js'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import Item from 'main-v2/components/visits/Item'
import Pagination from 'main-v2/components/common/Pagination'

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  page: getPage(state),
  totalPages: getTotalPages(state),
  ids: getAll(state),
})

const mapDispatchToProps = dispatch => ({
  fetchList: options => dispatch(fetchList(options)),
})

class VisitsList extends Component {
  componentDidMount() {
    const { location } = this.props
    this.props.fetchList({ page: queryString.parse(location.search).page || 1 })
  }

  onPageChange = page => {
    const { history } = this.props
    const route = appRoutes.profile.index({}, { params: { page } })
    history.push(route)
    this.props.fetchList({ page })
  }

  render() {
    const { isLoading, ids, page, totalPages } = this.props

    return (
      <div className="visits">
        <div className="visits__head">
          <div className="visits__title">Встречи с клиентами</div>
          <div className="visits__new">
            <Link
              className="button button--big"
              to={appRoutes.profile.visits.new()}
            >
              <span className="button__text">Я пришел на встречу</span>
              <span className="button__icon">
                <svg
                  height="14"
                  viewBox="0 0 14 14"
                  width="14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    transform="translate(-5 -5)"
                  >
                    <path d="m0 0h24v24h-24z" />
                    <path d="m19 13h-6v6h-2v-6h-6v-2h6v-6h2v6h6z" fill="#fff" />
                  </g>
                </svg>
              </span>
            </Link>
          </div>
        </div>
        {!ids.length && (
          <div className="visits__placeholder">
            Все ваши отметки о визитах к клиентам будут отображаться здесь
          </div>
        )}
        {ids.map(id => (
          <Item key={id} id={id} />
        ))}
        {totalPages > 1 && (
          <div className="visits__pagination">
            <Pagination
              isLoading={isLoading}
              current={page}
              totalPages={totalPages}
              onChange={this.onPageChange}
            />
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(withRouter(VisitsList)))
