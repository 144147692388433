import React, { Component } from 'react'

class Ticker extends Component {
  constructor(props) {
    super(props)

    this.textRef = React.createRef()
  }

  componentDidMount() {
    this.textRef.current.addEventListener('mouseover', this.scrollToLeft)
    this.textRef.current.addEventListener('mouseout', this.resetScroll)
  }

  componentWillUnmount() {
    this.textRef.current.removeEventListener('mouseover', this.scrollToLeft)
    this.textRef.current.removeEventListener('mouseout', this.resetScroll)
  }

  scrollToLeft = e => {
    const toScroll = e.target.scrollWidth - e.target.offsetWidth
    if (toScroll < 1) return
    const stepWidth = 3

    this.interval = setInterval(() => {
      if (e.target.scrollLeft >= toScroll) clearInterval(this.interval)
      e.target.scrollLeft = e.target.scrollLeft + stepWidth
    }, 20)
  }

  resetScroll = e => {
    if (this.interval) clearInterval(this.interval)
    e.target.scrollLeft = 0
  }

  render() {
    const { text } = this.props
    return (
      <div className="options__font">
        <div ref={this.textRef} className="options__text">
          {text}
        </div>
      </div>
    )
  }
}

export default Ticker
