import { connect } from 'react-redux'
import { fetchNewsCategories } from 'main/actions/news-categories'
import { getNewsCompilations } from 'main/selectors/news-compilations'
import { fetchNewsCompilations } from 'main/actions/news-compilations'
import NewsCompilations from 'main/components/news-compilations'

const mapStateToProps = state => ({
  ...getNewsCompilations(state),
})

const mapDispatchToProps = dispatch => ({
  fetchNewsCompilations: () => {
    dispatch(fetchNewsCategories()).then(() =>
      dispatch(fetchNewsCompilations())
    )
  },
})

const NewsCompilationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsCompilations)

export default NewsCompilationsContainer
