import { connect } from 'react-redux'
import { addProduct, removeProduct } from 'constructors/ducks/values/actions'
import { isProductSelected } from 'constructors/ducks/values/selectors'
import { getProduct } from 'constructors/ducks/products/selectors'
import toJS from 'common/HOC/to-js'
import Product from './Product'

const mapStateToProps = (state, ownProps) => ({
  product: getProduct(state, ownProps.productId),
  isSelected: isProductSelected(state, ownProps.productId),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectProduct: () => dispatch(addProduct(ownProps.productId)),
  unselectProduct: () => dispatch(removeProduct(ownProps.productId)),
})

const ProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Product))

export default ProductContainer
