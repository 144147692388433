import { changeOptionValue } from 'constructors/ducks/values/actions'
import { getNestedOption } from 'constructors/ducks/values/selectors'
import {
  getCurrentStage,
  getCurrentStageIndex,
} from 'constructors/ducks/stages/selectors'
import { changeStageContent } from 'constructors/ducks/stages/actions'

export const mutations = store => next => action => {
  next(action)

  adjustBusinessSize(store, action)
}

const adjustBusinessSize = (store, action) => {
  if (action.type !== changeOptionValue.getType()) return
  if (
    action.payload.optionId.indexOf(
      'complete-solutions:complete-solutions:industry'
    ) === -1
  )
    return

  const state = store.getState()
  const industrySelectedOption = getNestedOption(
    state,
    'complete-solutions:complete-solutions:industry'
  )

  if (
    [
      'complete-solutions:complete-solutions:industry:transportation',
      'complete-solutions:complete-solutions:industry:public-service-corporation',
    ].indexOf(industrySelectedOption.get('id')) !== -1
  )
    return hideBusinessSize(store, action)

  return showBusinessSize(store, action)
}

const hideBusinessSize = (store, action) => {
  const { dispatch, getState } = store
  const state = getState()

  const currentStage = getCurrentStage(state)
  if (currentStage.getIn(['blocks', 1, 'isHidden'])) return

  const stageIndex = getCurrentStageIndex(state)
  dispatch(changeStageContent([stageIndex, 'blocks', 1], { isHidden: true }))
  dispatch(
    changeOptionValue({
      optionId: 'complete-solutions:complete-solutions:business-size',
      value: 0,
    })
  )
}

const showBusinessSize = (store, action) => {
  const { dispatch, getState } = store
  const state = getState()

  const currentStage = getCurrentStage(state)
  if (!currentStage.getIn(['blocks', 1, 'isHidden'])) return

  const stageIndex = getCurrentStageIndex(state)
  dispatch(changeStageContent([stageIndex, 'blocks', 1], { isHidden: false }))
  dispatch(
    changeOptionValue({
      optionId: 'complete-solutions:complete-solutions:business-size',
      value: 1,
    })
  )
}

export default [
  {
    id: 'complete-solutions',
    code: 'complete-solutions',
    name: 'Комплексные решения',
    shortName: 'Комплексные решения',
    vat: true,
    options: [
      {
        id: 'industry',
        inputType: 'radio',
        name: 'Выберите отрасль',
        values: [
          {
            id: 'retail',
            inputType: 'mandatory',
            name: 'Ритейл. Автоматизация торгового зала',
            visibility: 1,
          },
          {
            id: 'services',
            inputType: 'mandatory',
            name: 'Сфера услуг',
            visibility: 1,
          },
          {
            id: 'services-retail',
            inputType: 'mandatory',
            name: 'Ритейл. Работа с клиентами',
            visibility: 1,
          },
          {
            id: 'transportation',
            inputType: 'mandatory',
            name: 'Транспорт',
            visibility: 1,
          },
          {
            id: 'public-service-corporation',
            inputType: 'mandatory',
            name: 'ЖКХ',
            visibility: 1,
          },
        ],
      },
      {
        id: 'business-size',
        inputType: 'radio',
        name: 'Выберите тип клиента',
        value: 1,
        values: [
          {
            id: 'none',
            inputType: 'mandatory',
            name: '',
            visibility: 3,
          },
          {
            id: 'big',
            inputType: 'mandatory',
            name: 'Крупный бизнес',
            visibility: 1,
          },
          {
            id: 'mid',
            inputType: 'mandatory',
            name: 'Средний и малый бизнес',
            visibility: 1,
          },
        ],
      },
    ],
  },
]
