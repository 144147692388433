export default [
  {
    name: 'Тип бизнеса',
    type: 'blocks',
    productId: 'complete-solutions',
    blocks: [
      {
        type: 'smallRadioButton',
        productId: 'complete-solutions',
        title: 'Выберите отрасль',
        optionId: 'industry',
      },
      {
        type: 'smallRadioButton',
        productId: 'complete-solutions',
        title: 'Выберите тип клиента',
        optionId: 'business-size',
      },
    ],
  },
  {
    name: 'Клиент',
    type: 'client',
  },
]
