import { Map, List } from 'immutable'
import {
  getSelectedProductsIds,
  isProductVisible,
  getNestedOption,
  getOptionValue,
} from 'constructors/ducks/values/selectors'
import {
  getProduct,
  getProductServices,
  getProductEquipment,
} from 'constructors/ducks/products/selectors'
import { getOption } from 'constructors/ducks/options/selectors'
import { getStages } from 'constructors/ducks/stages/selectors'
import { isHiddenInInvoice } from 'constructors/modules/option-visibility'
import config from 'entry-specific/config'

// used for final commercial-proposal object only
// never used in ui
export const getFormattedInvoice = state => {
  return getSelectedProductsIds(state).map(productId => {
    if (!isProductVisible(state, productId)) return null

    const product = getProduct(state, productId)
    return Map({
      name: product.get('name'),
      services: getFormattedOptions(
        state,
        getProductServices(state, productId),
        {
          type: 'service',
        }
      ),
      equipment: getFormattedOptions(
        state,
        getProductEquipment(state, productId),
        {
          type: 'equipment',
        }
      ),
    })
  })
}

const getFormattedOptions = (state, options, { type } = {}) => {
  let formattedOptions = options
    .map(option => {
      if (isHiddenInInvoice(option.get('visibility'))) return null
      option = getNestedOption(state, option.get('id'))
      if (isHiddenInInvoice(option.get('visibility'))) return null
      const value = getOptionValue(state, option.get('id'))
      if (!value && option.get('inputType') !== 'delimiter') return null

      return config('constructor.invoice.formatOption', formatOption)(
        option,
        value,
        type
      )
    })
    .filter(filterTitles)

  if (config('constructor.invoice.includeStageTitles'))
    formattedOptions = addStageTitles(state, formattedOptions)

  if (config('constructor.invoice.combineMonthlyAndOnceOptions'))
    formattedOptions = combineMonthlyAndOnceOptions(state, formattedOptions)

  return formattedOptions
}

const combineMonthlyAndOnceOptions = (state, options) => {
  const combinedOptionIds = []
  const res = options.reduce((acc, option) => {
    if (combinedOptionIds.indexOf(option.get('id')) !== -1) return acc
    if (!option.get('id')) return acc.push(option)

    const nameIndex = option
      .get('name')
      .search(/( - подключение$| - ежемесячная плата$)/)
    if (nameIndex === -1) return acc.push(option)

    const subName = option.get('name').substring(0, nameIndex)
    const relatedOption = options.find(
      o =>
        o.get('name') !== option.get('name') &&
        o.get('name').indexOf(subName) !== -1
    )

    let resultOption = option.set('name', subName)
    if (!relatedOption) return acc.push(resultOption)

    resultOption = resultOption.mergeDeepIn(
      ['price'],
      relatedOption.get('price')
    )
    combinedOptionIds.push(relatedOption.get('id'))
    return acc.push(resultOption)
  }, List())

  return res
}

const addStageTitles = (state, options) =>
  getStages(state)
    .map(convertStageToOptions(state))
    .map(table =>
      table.set(
        'selectedOptions',
        options.filter(
          option => table.get('optionIds').indexOf(option.get('id')) !== -1
        )
      )
    )
    .reduce((acc, stage) => {
      if (!stage.get('selectedOptions').size) return acc

      return acc
        .concat([
          Map({
            type: 'title',
            name: stage.get('name'),
          }),
        ])
        .concat(stage.get('selectedOptions'))
    }, List())

const formatOption = (option, newValue, type) => {
  let name = option.get('name')
  let price = option.get('price')
  let value = newValue

  if (option.get('inputType') === 'number') {
    if (price.get('monthly')) price = price.setIn(['monthly', 'value'], value)
    if (price.get('once')) price = price.setIn(['once', 'value'], value)
    if (price.get('annually')) price = price.setIn(['annually', 'value'], value)
    value = 1
  }
  if (price && price.getIn(['monthly', 'value']) === '')
    price = price.setIn(['monthly', 'value'], 0)
  if (price && price.getIn(['once', 'value']) === '')
    price = price.setIn(['once', 'value'], 0)

  return Map({
    id: option.get('id'),
    type: value ? type : 'title',
    name,
    price,
    value,
  })
}

const filterTitles = (option, i, options) => {
  if (option === null) return false
  if (!config('constructor.invoice.includeTitles'))
    return option.get('type') !== 'title'

  if (option.get('type') !== 'title') return true
  return (
    option.get('type') === 'title' &&
    options.get(i + 1) &&
    options.getIn([i + 1, 'type']) !== 'title'
  )
}

// converts only for blocks stages
const convertStageToOptions = state => stage => {
  const blocks = stage.get('blocks') || List()

  const radioReducer = (acc, id) => {
    const option = getOption(state, id)
    if (option.get('inputType') === 'radio')
      return acc.concat(option.get('values'))
    return acc.concat(id)
  }

  return Map({
    name: stage.get('name'),
    optionIds: blocks.reduce((acc, block) => {
      return acc
        .concat(
          ((block.get('optionId') && [block.get('optionId')]) || []).reduce(
            radioReducer,
            List()
          )
        )
        .concat((block.get('optionIds') || []).reduce(radioReducer, List()))
    }, List()),
  })
}
