import React from 'react'
import { createPortal } from 'react-dom'

import cn from 'classnames'

const modalRoot = document.getElementById('modal-root')

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
    this.containerRef = React.createRef()
  }

  componentDidMount() {
    modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el)
  }

  closeByOverlay = e => {
    if (e.target === this.containerRef.current) this.props.onClose()
  }

  render() {
    return createPortal(this.renderWrapper(), this.el)
  }

  renderWrapper = () => {
    const { isOpen, title, onClose, children, size, className } = this.props
    if (!isOpen) return null

    return (
      <div className={cn('modal', className)}>
        <div className="modal__overlay" />
        <div
          ref={this.containerRef}
          className="modal__container"
          onMouseDown={this.closeByOverlay}
        >
          <div
            className={cn('modal__content', {
              'modal__content--small': size === 'small',
              'modal__content--auto': size === 'auto',
              'modal__content--mid': size === 'mid',
            })}
          >
            <div className="modal__title">
              {title} <span className="modal__close" onClick={onClose} />
            </div>
            <div className="modal__body">{children}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal
