import { itemTypes } from './helpers'
import generateScript from './generate-script'

const cards = [
  {
    id: '2',
    name: 'Предложение услуги',
    subCards: [
      {
        id: '2.1',
        name: 'Распределение вызовов',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `Как связаны ваши рабочие программы с телефонами сотрудников?

              Мы можем настроить систему телефонных контактов таким образом, что все входящие вызовы будут распределяться внутри компании по заданной вам логике . А при исходящих звонках ваших сотрудников будет производится подстановка единого номера компании. Т.о. вы не потеряете ни одного звонка., а сотрудники не смогут проводить переговоры за спиной руководства.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Экономия ФОТ: за счет оптимизации работы - сотрудники начинают работать более эффективно. 
                  Увеличение доходов: за счет сокращения времени дозвона и грамотного распределения звонков внутри компании.  Чем меньше потерянных звонков - тем выше оценка технической службы компании
                  Мотивация: отчеты по звонкам можно использовать при оценке эффективности работы подразделений компании`,
              },
            ],
          },
        ],
      },
      {
        id: '2.2',
        name: 'Статистика по звонкам',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `Как вы анализируете, сколько входящих вызовов поступило в вашу компанию и сколько не дошло до сотрудников (сброс на стороне абонента, сброс на стороне сотрудника, "занято", "не отвечает" и т.д.)

              Мы можем настроить для вас статистику по всем входящим звонкам (принятым и непринятым) - в разрезе до каждого сотрудника`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Экономия ФОТ: за счет оптимизации работы - сотрудники начинают работать более эффективно.
                Увеличение доходов: за счет оптимизации кол-ва исполнения заказов. Чем меньше потерянных звонков - тем выше лояльность дозвонившихся клиентов и выше продажи
                Мотивация: отчеты по звонкам можно использовать в мотивационных программах используя статистику как элемент управления сотрудником.`,
              },
            ],
          },
        ],
      },
      {
        id: '2.3',
        name: 'Удаленное управление офисами',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. В вашей компании часто проводятся удаленные совещания/собрания?
              2. Как вы обеспечиваете техническую поддержку таких совещаний?

              Возможности многоканальных номеров от МТС позволяют быстро собирать на аудио-конференции неограниченное кол-во сотрудников. Также можно создавать множество переговорных аудио-комнат  и проводить  несколько совещаний одновременно`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение эффективности бизнеса: оперативное получение информации даже от удаленных офисов и представительств. 
                Сокращение расходов: на командировки`,
              },
            ],
          },
        ],
      },
      {
        id: '2.4',
        name: 'Повышение лояльности клиентов',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. Ваша компания заинтересована в лояльных клиентах? 
              2. Вы задумывались, что можете легко настроить замеры удовлетворенности ваших клиентов и значительно повлиять на рост доходов всей компании?

              Мы можем настроить для вас функцию автоматических опросов клиентов. Вы всегда будете в курсе, пришел ли сотрудник к клиенту, насколько качественно выполнил работу, доволен ли клиент и готов ли он рекомендовать вас своему окружению.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение лояльности клиентов, имиджа компании.
                Повышение выручки:  лояльные клиенты возвращаются к вам снова и рекомендую вашу компанию своим друзьям.`,
              },
            ],
          },
        ],
      },
      {
        id: '2.5',
        name: 'Повышение лояльности клиентов',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `Как вы обеспечиваете технические  условия для работы (связь, интернет, доступ  к удаленным ресурсам) новых сотрудников и сотрудников, работающих удаленно (к примеру, надомные)?

              Виртуальная АТС не имеет ограничений по кол-ву линий. Вы можете включить всех новых и удаленных сотрудников в свою сеть БЕЗ ДОПОЛНИТЕЛЬНЫХ КАПИТАЛЬНЫХ РАСХОДОВ (не нужно протягивать телефонные линии и приотбретать дополнительные платы для АТС).`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Экономия ресурсов: внедрение многоканального номера не требует капитальных затрат.
                Простое внедрение: неограниченое кол-во телефонных линий - даже в условия отсутствия свободных портов. Легкое внедрение и настройка.`,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default generateScript({ cards })
