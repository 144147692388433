import React from 'react'
import List from './list'

const Contents = ({ cards, currentCard, currentScriptPath }) => {
  if (!cards || !cards.length) return null

  return (
    <div className="card scripts-container__row scripts-container__contents-card">
      <div className="card__title">Содержание</div>
      <div className="card__content card__contents">
        <List
          cards={cards}
          currentCard={currentCard}
          currentScriptPath={currentScriptPath}
        />
      </div>
    </div>
  )
}

export default Contents
