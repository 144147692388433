import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { init } from 'constructors/ducks/constructor/actions'
import { saveIframeCommercialProposal } from 'constructors/ducks/model/actions'
import { fetchProposal } from 'main-v2/ducks/proposals'
import Constructor from './Constructor'
import { getConstructorId } from 'constructors/ducks/constructor/selectors'

const mapStateToProps = state => ({
  constructorId: getConstructorId(state),
})

const mapDispatchToProps = (dispatch, { match }) => ({
  init: () => {
    if (match.params.id) {
      dispatch(fetchProposal(match.params.id)).then(proposal =>
        dispatch(init(proposal))
      )
    } else {
      dispatch(init({}))
    }
  },
  saveIframeCommercialProposal: data => {
    return dispatch(saveIframeCommercialProposal(data))
  },
})

const ConstructorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Constructor)

export default withRouter(ConstructorContainer)
