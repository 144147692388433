import React from 'react'

const VideoItem = ({ item, openVideo }) => {
  return (
    <div className="doc doc--video docs__item">
      <img
        alt={item.title}
        src={item.preview2Url}
        className="doc__img doc__img--video"
      />
      <div className="doc__content">
        <div className="doc__name">{item.title}</div>
        <div className="doc__info">{item.description}</div>
        <div className="doc__actions doc__actions--video">
          <div className="action action--video">
            <div
              onClick={() => openVideo(item)}
              className="action__button action__button--video"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoItem
