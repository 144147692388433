import commercialDirector from './commercial-director'
import technicalDirector from './technical-director'
import financialDirector from './financial-director'
import marketingDirector from './marketing-director'
import logisticsDirector from './logistics-director'

const script = [
  {
    code: 'Транспорт',
    name: 'Транспорт',
    items: getItems('Транспорт'),
  },
  {
    code: 'Строительство',
    name: 'Строительство',
    items: getItems('Строительство'),
  },
  {
    code: 'Охранный бизнес',
    name: 'Охранный бизнес',
    items: getItems('Охранный бизнес'),
  },
  {
    code: 'Торговля',
    name: 'Торговля',
    items: getItems('Торговля'),
  },
  {
    code: 'Производство',
    name: 'Производство',
    items: getItems('Производство'),
  },
  {
    code: 'Банки и страхование',
    name: 'Банки и страхование',
    items: getItems('Банки и страхование'),
  },
  {
    code: 'Сельское хозяйство',
    name: 'Сельское хозяйство',
    items: getItems('Сельское хозяйство'),
  },
  {
    code: 'Топливно-энергетические компании',
    name: 'Топливно-энергетические компании',
    items: getItems('Топливно-энергетические компании'),
  },
  {
    code: 'Отели и рестораны',
    name: 'Отели и рестораны',
    items: getItems('Отели и рестораны'),
  },
  {
    code: 'Сфера услуг',
    name: 'Сфера услуг',
    items: getItems('Сфера услуг'),
  },
]

function getItems(parentCode) {
  return [
    {
      code: `${parentCode}-Коммерческий директор`,
      name: 'Коммерческий директор',
      cards: commercialDirector,
    },
    {
      code: `${parentCode}-Технический директор`,
      name: 'Технический директор',
      cards: technicalDirector,
    },
    {
      code: `${parentCode}-Директор по логистике`,
      name: 'Директор по логистике',
      cards: logisticsDirector,
    },
    {
      code: `${parentCode}-Финансовый директор`,
      name: 'Финансовый директор',
      cards: financialDirector,
    },
    {
      code: `${parentCode}-Директор по маркетингу`,
      name: 'Директор по маркетингу',
      cards: marketingDirector,
    },
  ]
}

export default script
