import React, { Fragment, Component } from 'react'

class TextQuestion extends Component {
  handleChange = e => {
    const { question, onChange } = this.props
    onChange(question.id, 'comment', e.target.value)
  }

  render() {
    const { question, answer = { comment: '' } } = this.props

    return (
      <Fragment>
        <div className="test-card__question">{question.text}</div>
        <ul className="test-card__answers">
          <li className="test-card__item">
            <textarea
              name={question.id}
              rows="5"
              className="test-card__textarea"
              placeholder="Введите текст..."
              value={answer.comment}
              onChange={this.handleChange}
            />
          </li>
        </ul>
      </Fragment>
    )
  }
}

export default TextQuestion
