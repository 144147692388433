import routes from './routes'
import {
  camelcaseKeys,
  underscoreCaseKeys,
} from 'common/modules/camelcase-keys'
import currentUser from 'common/modules/current-user'
import appRoutes from 'main/constants/app-routes'

export const fetch = (route, options = {}) => {
  options.headers = getHeaders(options)
  options.method = getMethod(route, options)
  if (options.body) {
    options.body = JSON.stringify(underscoreCaseKeys(options.body))
  }
  options.cache = 'no-cache'

  return window
    .fetch(getUrl(route, options), options)
    .then(parseJSON)
    .then(throwNotOkStatus)
    .catch(checkAuthorizationRedirects)
}

const getHeaders = options => {
  const headers = {
    'Content-Type': 'application/json',
  }

  if (currentUser.token) {
    headers['Authorization'] = `Bearer ${currentUser.token}`
    if (localStorage.getItem('disguiseAs'))
      headers['X-Disguise-As'] = localStorage.getItem('disguiseAs')
  }

  return Object.assign({}, headers, options.headers)
}

const getMethod = (route, options) => {
  if (options.method) return options.method
  if (route.method) return route.method
  return 'GET'
}

const getUrl = (route, options) => {
  let url = route.url ? route.url : route

  if (options.queryParams) {
    url +=
      (url.indexOf('?') === -1 ? '?' : '&') +
      getQueryParamsString(options.queryParams)
  }

  return url
}

const getQueryParamsString = params => {
  params = underscoreCaseKeys(params)
  return Object.keys(params)
    .reduce((arr, k) => {
      if (params[k] === null || params[k] === undefined) return arr
      return [
        `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`,
        ...arr,
      ]
    }, [])
    .join('&')
}

const parseJSON = response => {
  try {
    return response
      .json()
      .then(json => {
        response.json = camelcaseKeys(json)
        return response
      })
      .catch(e => {
        if (e.message !== 'Unexpected end of JSON input')
          window.Sentry.captureException(e)

        response.json = {}
        return response
      })
  } catch (error) {
    response.json = {}
    return response
  }
}

const throwNotOkStatus = response => {
  if (!response.ok) throw response
  return response
}

const checkAuthorizationRedirects = error => {
  if (error.status === 401 && document.location.pathname !== appRoutes.auth) {
    localStorage.removeItem('token')
    return document.location.replace(appRoutes.auth)
  }

  if (
    error.status === 402 &&
    document.location.pathname !== appRoutes.blocked()
  ) {
    return document.location.replace(appRoutes.blocked())
  }
  throw error
}

export default {
  fetch,
  routes,
}
