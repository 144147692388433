import { connect } from 'react-redux'
import { getClient } from 'constructors/ducks/client/selectors'
import toJS from 'common/HOC/to-js'
import Client from './Component'
import { changeClient } from 'constructors/ducks/client/actions'
import { getIsLoading } from 'constructors/ducks/ui/selectors'

const mapStateToProps = state => ({
  client: getClient(state),
  isLoading: getIsLoading(state),
})

const mapDispatchToProps = dispatch => ({
  onChange: e =>
    dispatch(
      changeClient({
        [e.target.name]: e.target.value,
      })
    ),
})

const ClientContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Client))

export default ClientContainer
