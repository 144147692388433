import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import { setPreviewConfigOptions } from './actions'

export const initialState = {}

const reducer = createReducer(
  {
    [setPreviewConfigOptions]: (state, payload = {}) => {
      return state.mergeDeep(fromJS(payload))
    },
  },
  fromJS(initialState)
)

export default reducer
