import { createSelector } from 'reselect'
import queryString from 'query-string'

export const getLocation = state => state.getIn(['routing', 'location'])

export const getQueryParams = createSelector(getLocation, location =>
  queryString.parse(location.search)
)

// TODO: return params
export const getParams = createSelector(getLocation, location => location)

export const getPage = createSelector(getQueryParams, queryParams => {
  const page = parseInt(queryParams.page, 10)
  if (!page || page < 1) return 1
  return page
})

export const getSearch = createSelector(
  getQueryParams,
  queryParams => queryParams.q
)
