import React, { Component } from 'react'
import { connect } from 'react-redux'
import Question from './Question'
import { getAnswers } from 'main-v2/ducks/answers/selectors'
import { changeAnswer } from 'main-v2/ducks/answers/actions'
import toJS from 'common/HOC/to-js'

const mapStateToProps = state => ({
  answers: getAnswers(state),
})

const mapDispatchToProps = dispatch => ({
  onChange: (questionId, name, value) =>
    dispatch(changeAnswer(questionId, name, value)),
})

class Test extends Component {
  render() {
    const { test, answers, onChange, remainAnswersCount } = this.props
    if (!test) return

    return (
      <div className="test test-layout__test">
        <ul className="test__cards">
          {test.questions &&
            test.questions.map((question, index) => (
              <li key={question.id} className="test__item">
                <div className="test-card">
                  {test.type === 'quiz' && (
                    <div className="test-card__num">Вопрос {index + 1}</div>
                  )}
                  <Question
                    question={question}
                    answer={answers[question.id]}
                    onChange={onChange}
                  />
                </div>
              </li>
            ))}
        </ul>
        <div className="test__info">
          {!!remainAnswersCount &&
            `Осталось вопросов без ответа: ${remainAnswersCount}`}
          {!remainAnswersCount &&
            'Вы ответили на все вопросы, самое время отправить тест на проверку!'}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Test))
