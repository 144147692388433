import { getPriceListFromCSV } from 'constructors/modules/price-list-helpers'
import { changeOptionValue } from 'constructors/ducks/values/actions'
import { changeOption } from 'constructors/ducks/options/actions'
import {
  getAllOptionIds,
  getOption,
} from 'constructors/ducks/options/selectors'
import {
  getOptionValue,
  getNestedOption,
} from 'constructors/ducks/values/selectors'

let priceList = getPriceListFromCSV(`Опция,Многоканальный номер,ВАТС базовая,ВАТС стандартная,ВАТС расширенная,Стартап,Лайт про
Городской номер ABC - ежемесячная плата,250,1250,3400,8150,,
Городской номер ABC - подключение,3500,3200,5500,9000,,
Прямой городской номер DEF/ABC - ежемесячная плата,405,1305,3455,8205,,
Прямой городской номер DEF/ABC - подключение,2500,1200,3500,7000,,
Федеральный номер DEF - ежемесячная плата,100,1000,3150,7900,,
Федеральный номер DEF - подключение,2500,1200,3500,7000,,
Номер 8-800 - подключение,,,,,1000,
Номер 8-800 - ежемесячная плата,,,,,900,1800
Дополнительный номер ABC - подключение,2500,2000,2000,2000,,
Дополнительный номер ABC - ежемесячная плата,550,550,550,550,,
Дополнительный номер DEF/ABC - подключение,2500,2000,2000,2000,,
Дополнительный номер DEF/ABC - ежемесячная плата,605,605,605,605,,
Дополнительный номер DEF - подключение,1500,1000,1000,1000,,
Дополнительный номер DEF - ежемесячная плата,300,300,300,300,,
Дополнительный номер 8-800 - подключение,8000,8000,4000,,,
Дополнительный номер 8-800 - ежемесячная плата,1200,1000,600,300,,
Дополнительная линия (сверх пакета),350,300,300,250,350,350
Голосовое меню,,400,300,300,700,700
Групповые ЧБС - подключение,600,600,600,600,600,600
Групповые ЧБС - ежемесячная плата,700,500,500,0,700,700
Музыка на удержании групповая - подключение,,300,300,300,300,300
Музыка на удержании групповая - ежемесячная плата,,200,150,150,300,300
Очередь базовая,,60,60,60,80,80
Очередь стандартная,,120,120,120,160,160
Очередь расширенная,,360,360,360,,
Ресепшионист малый бизнес - подключение,,1500,1500,1500,1500,1500
Ресепшионист малый бизнес - ежемесячная плата,,800,800,800,800,800
Ресепшионист крупный бизнес - подключение,,3000,3000,3000,3000,3000
Ресепшионист крупный бизнес - ежемесячная плата,,1500,1500,1500,1500,1500
Организация транка по IP - подключение,1000,1000,1000,1000,1000,1000
Организация транка по IP - ежемесячная плата,550,500,500,500,550,550
Организация транка по E1 - подключение,10000,10000,10000,10000,10000,10000
Организация транка по E1 - ежемесячная плата,3500,3000,3000,3000,3500,3500
Линия подключения к УАТС,200,100,100,100,350,350
Пользователь внутренний стандартный,,40,40,40,80,80
Пользователь внутренний расширенный,,80,80,80,160,160
Программный телефон,,40,40,40,60,60
Ограничения входящих вызовов,,45,45,45,45,45
Пользователь мобильный стандартный,,45,45,45,,
Пользователь мобильный расширенный,,85,85,85,,
Золотой номер,1500,1500,1500,1500,25000,25000
Платиновый номер,5000,5000,5000,5000,50000,50000
VIP номер,15000,15000,15000,15000,,
Номер Престиж,20000,20000,20000,20000,,
Номер Премиум,25000,25000,25000,25000,,
Номер Инфинити,150000,150000,150000,150000,700000,700000
Номер ТОП Инфинити,500000,500000,500000,500000,1200000,1200000
Пакет минут с ВАТС на МТС 3000 мин.,,2900,2850,2800,,
Пакет минут с ВАТС на МТС 6000 мин.,,5700,5550,5400,,
Пакет минут с ВАТС на МТС 12000 мин.,,11000,10600,10200,,
Пакет минут с ВАТС на ДР 3000 мин.,,3600,3600,3600,,
Пакет минут с ВАТС на ДР 6000 мин.,,6900,6900,6900,,
Пакет минут с ВАТС на ДР 12000 мин.,,13600,13200,12800,,
Пакет минут с ВАТС на ДР 24000 мин.,,28300,27200,26100,,
Пакет минут с ВАТС на ДР и МГ 3000 мин.,,4650,4650,4650,,
Пакет минут с ВАТС на ДР и МГ 6000 мин.,,9000,9000,9000,,
Пакет минут с ВАТС на ДР и МГ 12000 мин.,,17400,16800,16800,,
Пакет минут с ВАТС на ДР и МГ 24000 мин.,,33600,32400,31200,,
Пакет минут с ВАТС и моб. на МТС 3000 мин.,3700,3600,3500,3450,,
Пакет минут с ВАТС и моб. на МТС 6000 мин.,7100,6900,6750,6600,,
Пакет минут с ВАТС и моб. на МТС 12000 мин.,13800,13400,13000,12600,,
Пакет минут с ВАТС и моб. на ДР 3000 мин.,6150,6000,6000,6000,,
Пакет минут с ВАТС и моб. на ДР 6000 мин.,12000,11700,11700,11700,,
Пакет минут с ВАТС и моб. на ДР 12000 мин.,22800,22200,21600,21600,,
Пакет минут с ВАТС и моб. на ДР 24000 мин.,43200,42000,40800,39600,,
Пакет минут с ВАТС и моб. на ДР и МГ 3000 мин.,8400,8100,8100,8100,,
Пакет минут с ВАТС и моб. на ДР и МГ 6000 мин.,15600,15000,15000,15000,,
Пакет минут с ВАТС и моб. на ДР и МГ 12000 мин.,29400,28200,27600,27600,,
Пакет минут с ВАТС и моб. на ДР и МГ 24000 мин.,52800,50400,49200,48000,,
Пакет минут с ВАТС на МГ 500 мин.,1050,1000,1000,1000,,
Пакет минут с ВАТС на МГ 1000 мин.,1900,1800,1800,1800,,
Пакет минут с ВАТС на МГ 2000 мин.,,3500,3400,3400,,
Пакет минут с ВАТС на МГ 5000 мин.,8500,8250,8000,8000,,
Пакет минут 8-800 500 мин.,,,,,2000,
Пакет минут 8-800 1000 мин.,,,,,,3300
Пакет минут 8-800 2500 мин.,,,,,,7750
Пакет минут 8-800 5000 мин.,,,,,,14500
Пакет минут 8-800 10000 мин.,,,,,,27000
Пакет минут Аудиоконференция 1000 мин.,550,550,550,550,550,550
Пакет минут Аудиоконференция 2000 мин.,1100,1100,1100,1100,1100,1100
Пакет минут Аудиоконференция 3000 мин.,1650,1650,1650,1650,1650,1650
Пакет минут Аудиоконференция 5000 мин.,2750,2750,2750,2750,2750,2750
Пакет минут Аудиоконференция 10000 мин.,5000,5000,5000,5000,5000,5000
Пакет минут Аудиоконференция 20000 мин.,10000,10000,10000,10000,10000,10000
Пакет минут Аудиоконференция 50000 мин.,25000,25000,25000,25000,25000,25000
Пакет минут Аудиоконференция 100000 мин.,45000,45000,45000,45000,45000,45000
Пакет минут Аудиоконференция 200000 мин.,80000,80000,80000,80000,80000,80000
Пакет минут Аудиоконференция 500000 мин.,175000,175000,175000,175000,175000,175000
Пакет минут Аудиоконференция 1000000 мин.,300000,300000,300000,300000,300000,300000
Пакет минут Запись и Факс 1000 мин.,550,550,550,550,550,550
Пакет минут Запись и Факс 2000 мин.,1100,1100,1100,1100,1100,1100
Пакет минут Запись и Факс 3000 мин.,1650,1650,1650,1650,1650,1650
Пакет минут Запись и Факс 5000 мин.,2750,2750,2750,2750,2750,2750
Пакет минут Запись и Факс 10000 мин.,5000,5000,5000,5000,5000,5000
Пакет минут Запись и Факс 20000 мин.,10000,10000,10000,10000,10000,10000
Пакет минут Запись и Факс 50000 мин.,25000,25000,25000,25000,25000,25000
Пакет минут Запись и Факс 100000 мин.,45000,45000,45000,45000,45000,45000
Пакет минут Запись и Факс 200000 мин.,80000,80000,80000,80000,80000,80000
Пакет минут Запись и Факс 500000 мин.,175000,175000,175000,175000,175000,175000
Пакет минут Запись и Факс 1000000 мин.,300000,300000,300000,300000,300000,300000`)

const TARIFF_NOTES_DATA = getPriceListFromCSV(`,Многоканальный номер,ВАТС базовая,ВАТС стандартная,ВАТС расширенная,Стартап,Лайт про
Номеров,1,1,1,1,1,1
Кол-во одновременных вызовов (кол-во линий),1,3,10,30,50,50
Кол-во внутренних сотрудников (стандартных пользователей),,10,30,100,20,30
Кол-во минут исходящего трафика в пакете,,,500,2000,,
Кол-во минут входящего трафика в пакете,,,,,200,500`)

const PRODUCT_ID = 'general:vpbx'
const TARIFF_NAMES = Object.keys(priceList[Object.keys(priceList)[0]])
const NUMBER_NAMES = [
  'Федеральный номер DEF', //3
  'Прямой городской номер DEF/ABC', //2
  'Городской номер ABC', //1
  'Номер 8-800', //0
]

export const mutations = store => next => action => {
  next(action)
  if (action.type !== changeOptionValue.getType()) return
  if (action.payload.optionId.indexOf(PRODUCT_ID) === -1) return

  initPriceList(store, action) // have to be at the beginning

  setPricesByTariff(store, action)
  syncNumberWithSubscription(store, action)
  syncTariffAndNumberName(store, action)
  showPossibleTariffsAndBeautifulNumberByChoosenNumber(store, action)
  syncAllSubscriptionsWithConnections(store, action)
  syncTariffNotesWithNumberAndClient(store, action)
  syncMaxWithNumbersAmount(store, action)
  syncNumberAmountWithMaxMinutePackages(store, action)
  syncNumberAmountWithMaxUATSConnection(store, action)
  syncUATSConnectionWithConnectionLines(store, action)
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

let INITIALIZED = false
const initPriceList = store => {
  if (INITIALIZED) return

  const state = store.getState()
  const allOptionIds = getAllOptionIds(state)

  Object.entries(priceList).forEach(([priceListId, priceListOption]) => {
    priceListOption.id = allOptionIds.find(findOptionByName(priceListId))
    priceListOption.defaultVisibility =
      getOption(state, priceListOption.id).get('visibility') || 0
  })

  INITIALIZED = true
}

const findOptionByName = priceListId => id => {
  if (id.indexOf(PRODUCT_ID) === -1) return
  const regex = new RegExp(`:${escapeRegExp(priceListId)}$`)
  return id.search(regex) !== -1
}

const syncNumberWithSubscription = (store, action) => {
  if (action.payload.optionId.search(/:Номер$/) === -1) return

  store.dispatch(
    changeOptionValue({
      optionId: action.payload.optionId.replace(
        ':Номер',
        ':Номер - ежемесячная плата'
      ),
      value: action.payload.value,
    })
  )
}

const syncAllSubscriptionsWithConnections = (store, action) => {
  if (action.payload.optionId.search(/ - ежемесячная плата$/) === -1) return

  store.dispatch(
    changeOptionValue({
      optionId: action.payload.optionId.replace(
        ' - ежемесячная плата',
        ' - подключение'
      ),
      value: action.payload.value,
    })
  )
}

const syncTariffAndNumberName = (store, action) => {
  if (action.payload.optionId.search(/:tariff$/) === -1) return

  const state = store.getState()
  const tariffName = getNestedOption(state, action.payload.optionId).get('name')
  const types = ['подключение', 'ежемесячная плата']

  types.forEach(type => {
    NUMBER_NAMES.forEach(numberName => {
      store.dispatch(
        changeOption({
          optionId: priceList[`${numberName} - ${type}`].id,
          content: {
            name: `${tariffName} - ${numberName} - ${type}`,
          },
        })
      )
    })
  })
}

const showPossibleTariffsAndBeautifulNumberByChoosenNumber = (
  store,
  action
) => {
  if (action.payload.optionId.search(/:Номер$/) === -1) return

  const state = store.getState()
  const selectedNumberId = getNestedOption(state, action.payload.optionId).get(
    'id'
  )
  const tariffIds = getOption(state, `${PRODUCT_ID}:tariff`).get('values')
  const tariffValue = getOptionValue(state, `${PRODUCT_ID}:tariff`)
  const is8800 = selectedNumberId.indexOf(`:${NUMBER_NAMES[3]}`) !== -1

  tariffIds.map((tariffId, index) =>
    store.dispatch(
      changeOption({
        optionId: tariffId,
        content: {
          visibility: getTariffVisibility({ is8800, index }),
        },
      })
    )
  )

  let newTariffValue = tariffValue
  if (is8800 && tariffValue <= 3) newTariffValue = 4
  if (!is8800 && tariffValue > 3) newTariffValue = 0

  if (newTariffValue !== tariffValue)
    store.dispatch(
      changeOptionValue({
        optionId: `${PRODUCT_ID}:tariff`,
        value: newTariffValue,
      })
    )

  const beautifulNumberValue = getOptionValue(
    state,
    `${PRODUCT_ID}:Красивый номер`
  )
  if (
    [3, 4, 5].indexOf(beautifulNumberValue) !== -1 &&
    [4, 5].indexOf(newTariffValue) !== -1
  ) {
    store.dispatch(
      changeOptionValue({
        optionId: `${PRODUCT_ID}:Красивый номер`,
        value: 0,
      })
    )
  }
}

const getTariffVisibility = ({ is8800, index }) => {
  if (!is8800 && index > 3) return 3
  if (!is8800 && index <= 3) return 1
  if (is8800 && index > 3) return 1
  if (is8800 && index <= 3) return 3
}

const setPricesByTariff = (store, action) => {
  if (action.payload.optionId.search(/:tariff$/) === -1) return

  const state = store.getState()

  Object.entries(priceList).forEach(([priceListId, priceListOption]) => {
    const option = getOption(state, priceListOption.id)
    const priceType = option.getIn(['price', 'monthly']) ? 'monthly' : 'once'
    const priceValue = getPriceByTariff(store, { ...action, priceListOption })

    store.dispatch(
      changeOption({
        optionId: priceListOption.id,
        content: {
          price: {
            [priceType]: {
              value: parseFloat(priceValue, 10),
            },
          },
          visibility: getOptionVisibility(store, {
            ...action,
            priceValue,
            priceListOption,
          }),
        },
      })
    )
  })
}

const getOptionVisibility = (_, action) => {
  const { priceValue, priceListOption } = action
  return priceValue ? priceListOption.defaultVisibility : 3
}

const getPriceByTariff = (store, action) => {
  const state = store.getState()
  const currentTariffValue = getOptionValue(state, `${PRODUCT_ID}:tariff`)
  const currentTariffPriceId = TARIFF_NAMES[currentTariffValue]
  return action.priceListOption[currentTariffPriceId] || 0
}

const TARIFF_NOTES = {}
const syncTariffNotesWithNumberAndClient = (store, action) => {
  if (action.payload.optionId.search(/:Номер$/) === -1) return

  const state = store.getState()
  TARIFF_NAMES.forEach(tariffName => {
    if (!TARIFF_NOTES[tariffName]) {
      TARIFF_NOTES[tariffName] = getOption(
        state,
        `${PRODUCT_ID}:tariff:${tariffName}`
      )
        .get('notes')
        .toJS()
    }
    store.dispatch(
      changeOption({
        optionId: `${PRODUCT_ID}:tariff:${tariffName}`,
        content: {
          notes: null,
        },
      })
    )
    store.dispatch(
      changeOption({
        optionId: `${PRODUCT_ID}:tariff:${tariffName}`,
        content: {
          notes: getTariffNotes(store, { ...action, tariffName }),
        },
      })
    )
  })
}

const getTariffNotes = (store, action) => {
  const state = store.getState()
  const currentNumberValue = getOptionValue(state, `${PRODUCT_ID}:Номер`)
  const currentNumberPriceId = NUMBER_NAMES[currentNumberValue]

  let result = []
  const subscriptionPrice =
    priceList[`${currentNumberPriceId} - ежемесячная плата`][action.tariffName]
  if (subscriptionPrice) {
    result.push({
      title: 'Ежемесячная плата',
      value: `${subscriptionPrice} руб./мес.`,
    })
  }

  const connectionPrice =
    priceList[`${currentNumberPriceId} - подключение`][action.tariffName]
  if (connectionPrice) {
    result.push({
      title: 'Подключение',
      value: `${connectionPrice} руб.`,
    })
  }

  return result.concat(TARIFF_NOTES[action.tariffName])
}

const INFLUENTIAL_NUMBER_IDS = [
  'Дополнительный номер 8-800 - ежемесячная плата',
  'Дополнительный номер DEF - ежемесячная плата',
  'Дополнительный номер DEF/ABC - ежемесячная плата',
  'Дополнительный номер ABC - ежемесячная плата',
].map(name => `${PRODUCT_ID}:${name}`)

const syncMaxWithNumbersAmount = (store, action) => {
  if (INFLUENTIAL_NUMBER_IDS.indexOf(action.payload.optionId) === -1) return

  const state = store.getState()
  const currentNumbersAmount = getCurrentNumbersAmount(state)
  const setMax = setMaxToAllOptions(store.dispatch)

  setMax(
    [
      `${PRODUCT_ID}:Пользователь внутренний стандартный`,
      `${PRODUCT_ID}:Пользователь внутренний расширенный`,
      `${PRODUCT_ID}:Ограничения входящих вызовов`,
      `${PRODUCT_ID}:Программный телефон`,
    ],
    currentNumbersAmount * 255
  )
  setMax([`${PRODUCT_ID}:Голосовое меню`], currentNumbersAmount * 7)
  setMax(
    [
      `${PRODUCT_ID}:Очередь базовая`,
      `${PRODUCT_ID}:Очередь стандартная`,
      `${PRODUCT_ID}:Очередь расширенная`,
    ],
    currentNumbersAmount * 15
  )
  setMax(
    [
      `${PRODUCT_ID}:Ресепшионист малый бизнес - абоненская плата`,
      `${PRODUCT_ID}:Ресепшионист крупный бизнес - ежемесячная плата`,
    ],
    currentNumbersAmount * 3
  )
  setMax(
    [
      `${PRODUCT_ID}:Музыка на удержании групповая - ежемесячная плата`,
      `${PRODUCT_ID}:Групповые ЧБС - ежемесячная плата`,
    ],
    currentNumbersAmount
  )
}

const getCurrentNumbersAmount = state =>
  INFLUENTIAL_NUMBER_IDS.reduce(
    (acc, optionId) => acc + getOptionValue(state, optionId),
    1
  ) // one number always in tariff

const setMaxToAllOptions = dispatch => (options, max) =>
  options.forEach(optionId =>
    dispatch(
      changeOption({
        optionId,
        content: {
          max,
        },
      })
    )
  )

let PACKAGE_IDS = null
let PACKAGE_GROUPS = null
const syncNumberAmountWithMaxMinutePackages = (store, action) => {
  if (
    action.payload.optionId.indexOf(':Пакет минут ') === -1 &&
    INFLUENTIAL_NUMBER_IDS.indexOf(action.payload.optionId) === -1
  )
    return

  const state = store.getState()
  if (!PACKAGE_IDS || !PACKAGE_GROUPS) {
    const allIds = getAllOptionIds(state)
    PACKAGE_IDS = allIds.filter(optionId => {
      const option = getOption(state, optionId)
      return option.getIn(['meta', 'connectedWithNumbersAmountGroup'])
    })

    PACKAGE_GROUPS = {}
    PACKAGE_IDS.forEach(optionId => {
      const option = getOption(state, optionId)
      const metaGroup = option.getIn([
        'meta',
        'connectedWithNumbersAmountGroup',
      ])
      if (!PACKAGE_GROUPS[metaGroup]) PACKAGE_GROUPS[metaGroup] = []

      const groupName = optionId.match(/(.+) \d+ мин\./)[1]
      if (!PACKAGE_GROUPS[metaGroup][groupName])
        PACKAGE_GROUPS[metaGroup][groupName] = []
      PACKAGE_GROUPS[metaGroup][groupName].push(optionId)
    })
  }

  const currentNumbersAmount = getCurrentNumbersAmount(state)

  Object.keys(PACKAGE_GROUPS).forEach(metaGroup => {
    const currentPackagesAmount = Object.keys(PACKAGE_GROUPS[metaGroup]).reduce(
      (acc, groupName) =>
        acc +
        Math.max(
          ...PACKAGE_GROUPS[metaGroup][groupName].map(optionId =>
            getOptionValue(state, optionId)
          )
        ),
      0
    )

    if (currentNumbersAmount < currentPackagesAmount)
      return PACKAGE_IDS.forEach(optionId =>
        store.dispatch(changeOptionValue({ optionId, value: 0 }))
      )

    Object.keys(PACKAGE_GROUPS[metaGroup]).forEach(groupName =>
      PACKAGE_GROUPS[metaGroup][groupName].forEach(optionId =>
        store.dispatch(
          changeOption({
            optionId,
            content: {
              max: getMaxMinutePackages({
                state,
                optionId,
                optionGroupIds: PACKAGE_GROUPS[metaGroup][groupName],
                currentPackagesAmount,
                currentNumbersAmount,
              }),
            },
          })
        )
      )
    )
  })
}

const getMaxMinutePackages = ({
  state,
  optionGroupIds,
  currentPackagesAmount,
  currentNumbersAmount,
}) => {
  if (currentNumbersAmount < currentPackagesAmount) return currentNumbersAmount
  const maxValueInGroup = Math.max(
    ...optionGroupIds.map(optionId => getOptionValue(state, optionId))
  )
  return maxValueInGroup + (currentNumbersAmount - currentPackagesAmount)
}

let UATS_CONNECTION_IDS = [
  'Организация транка по IP - ежемесячная плата',
  'Организация транка по E1 - ежемесячная плата',
].map(n => `${PRODUCT_ID}:${n}`)
const syncNumberAmountWithMaxUATSConnection = (store, action) => {
  if (
    UATS_CONNECTION_IDS.indexOf(action.payload.optionId) === -1 &&
    INFLUENTIAL_NUMBER_IDS.indexOf(action.payload.optionId) === -1
  )
    return

  UATS_CONNECTION_IDS.forEach(optionId => {
    store.dispatch(
      changeOption({
        optionId,
        content: { max: getMaxUATS(store, { ...action, optionId }) },
      })
    )
  })
}

const getMaxUATS = (store, action) => {
  const state = store.getState()
  const currentNumbersAmount = getCurrentNumbersAmount(state)
  const currentUATSAmount = UATS_CONNECTION_IDS.reduce(
    (acc, optionId) => acc + getOptionValue(state, optionId),
    0
  )
  const possibleUATS = currentNumbersAmount - currentUATSAmount
  const currentOptionValue = getOptionValue(state, action.optionId)
  return possibleUATS + currentOptionValue
}

const syncUATSConnectionWithConnectionLines = (store, action) => {
  if (UATS_CONNECTION_IDS.indexOf(action.payload.optionId) === -1) return

  const state = store.getState()
  const currentUATSAmount = UATS_CONNECTION_IDS.reduce(
    (acc, optionId) => acc + getOptionValue(state, optionId),
    0
  )
  store.dispatch(
    changeOption({
      optionId: `${PRODUCT_ID}:Линия подключения к УАТС`,
      content: {
        min: currentUATSAmount,
        max: currentUATSAmount * 127,
      },
    })
  )

  const lineValue = getOptionValue(
    state,
    `${PRODUCT_ID}:Линия подключения к УАТС`
  )
  if (lineValue > currentUATSAmount) {
    store.dispatch(
      changeOptionValue({
        optionId: `${PRODUCT_ID}:Линия подключения к УАТС`,
        value: lineValue - 1,
      })
    )
  }
}

const getInitialTariffNotes = tariff =>
  Object.keys(TARIFF_NOTES_DATA).reduce((acc, title) => {
    const value = TARIFF_NOTES_DATA[title][tariff]
    if (!value) return acc
    return acc.concat({
      title,
      value,
    })
  }, [])

export default {
  id: 'vpbx',
  code: 'vpbx',
  name: 'Виртуальная АТС',
  shortName: 'Виртуальная АТС',
  vat: true,
  options: [
    {
      id: 'Номер',
      inputType: 'radio',
      name: 'Выберите тип номера для базового тарифа',
      visibility: 1,
      values: [
        {
          id: NUMBER_NAMES[0],
          inputType: 'mandatory',
          name: NUMBER_NAMES[0],
          visibility: 1,
        },
        {
          id: NUMBER_NAMES[1],
          inputType: 'mandatory',
          name: NUMBER_NAMES[1],
          visibility: 1,
        },
        {
          id: NUMBER_NAMES[2],
          inputType: 'mandatory',
          name: NUMBER_NAMES[2],
          visibility: 1,
        },
        {
          id: NUMBER_NAMES[3],
          inputType: 'mandatory',
          name: NUMBER_NAMES[3],
          visibility: 1,
        },
      ],
    },
    {
      id: 'Номер - ежемесячная плата',
      inputType: 'radio',
      name: 'Выберите тип номера для базового тарифа',
      values: [
        {
          id: `${NUMBER_NAMES[0]} - ежемесячная плата`,
          inputType: 'mandatory',
          name: `${NUMBER_NAMES[0]} - ежемесячная плата`,
          visibility: 2,
          price: {
            monthly: {
              value: 0,
              caption: 'руб./мес.',
            },
          },
        },
        {
          id: `${NUMBER_NAMES[1]} - ежемесячная плата`,
          inputType: 'mandatory',
          name: `${NUMBER_NAMES[1]} - ежемесячная плата`,
          visibility: 2,
          price: {
            monthly: {
              value: 0,
              caption: 'руб./мес.',
            },
          },
        },
        {
          id: `${NUMBER_NAMES[2]} - ежемесячная плата`,
          inputType: 'mandatory',
          name: `${NUMBER_NAMES[2]} - ежемесячная плата`,
          visibility: 2,
          price: {
            monthly: {
              value: 0,
              caption: 'руб./мес.',
            },
          },
        },
        {
          id: `${NUMBER_NAMES[3]} - ежемесячная плата`,
          inputType: 'mandatory',
          name: `${NUMBER_NAMES[3]} - ежемесячная плата`,
          visibility: 2,
          price: {
            monthly: {
              value: 0,
              caption: 'руб./мес.',
            },
          },
        },
      ],
    },
    {
      id: 'Номер - подключение',
      inputType: 'radio',
      name: 'Выберите тип номера для базового тарифа',
      values: [
        {
          id: `${NUMBER_NAMES[0]} - подключение`,
          inputType: 'mandatory',
          name: `${NUMBER_NAMES[0]} - подключение`,
          visibility: 2,
          price: {
            once: {
              value: 0,
              caption: 'руб.',
            },
          },
        },
        {
          id: `${NUMBER_NAMES[1]} - подключение`,
          inputType: 'mandatory',
          name: `${NUMBER_NAMES[1]} - подключение`,
          visibility: 2,
          price: {
            once: {
              value: 0,
              caption: 'руб.',
            },
          },
        },
        {
          id: `${NUMBER_NAMES[2]} - подключение`,
          inputType: 'mandatory',
          name: `${NUMBER_NAMES[2]} - подключение`,
          visibility: 2,
          price: {
            once: {
              value: 0,
              caption: 'руб.',
            },
          },
        },
        {
          id: `${NUMBER_NAMES[3]} - подключение`,
          inputType: 'mandatory',
          name: `${NUMBER_NAMES[3]} - подключение`,
          visibility: 2,
          price: {
            once: {
              value: 0,
              caption: 'руб.',
            },
          },
        },
      ],
    },
    {
      id: 'Красивый номер',
      inputType: 'radio',
      name: 'Красивый номер',
      values: [
        {
          id: 'Очередной номер',
          inputType: 'mandatory',
          name: 'Очередной номер',
          visibility: 1,
          price: {
            once: {
              value: 0,
              caption: 'руб.',
            },
          },
        },
        {
          id: 'Золотой номер',
          inputType: 'mandatory',
          name: 'Золотой номер',
          price: {
            once: {
              value: 25000,
              caption: 'руб.',
            },
          },
        },
        {
          id: 'Платиновый номер',
          inputType: 'mandatory',
          name: 'Платиновый номер',
          price: {
            once: {
              value: 50000,
              caption: 'руб.',
            },
          },
        },
        {
          id: 'VIP номер',
          inputType: 'mandatory',
          name: 'VIP номер',
          price: {
            once: {
              value: 15000,
              caption: 'руб.',
            },
          },
        },
        {
          id: 'Номер Престиж',
          inputType: 'mandatory',
          name: 'Номер Престиж',
          price: {
            once: {
              value: 20000,
              caption: 'руб.',
            },
          },
        },
        {
          id: 'Номер Премиум',
          inputType: 'mandatory',
          name: 'Номер Премиум',
          price: {
            once: {
              value: 25000,
              caption: 'руб.',
            },
          },
        },
        {
          id: 'Номер Инфинити',
          inputType: 'mandatory',
          name: 'Номер Инфинити',
          price: {
            once: {
              value: 700000,
              caption: 'руб.',
            },
          },
        },
        {
          id: 'Номер ТОП Инфинити',
          inputType: 'mandatory',
          name: 'Номер ТОП Инфинити',
          price: {
            once: {
              value: 1200000,
              caption: 'руб.',
            },
          },
        },
      ],
    },
    {
      id: 'tariff',
      inputType: 'radio',
      name: 'Базовый тариф',
      visibility: 1,
      values: [
        {
          id: TARIFF_NAMES[0],
          inputType: 'mandatory',
          name: TARIFF_NAMES[0],
          visibility: 1,
          notes: getInitialTariffNotes(TARIFF_NAMES[0]),
        },
        {
          id: TARIFF_NAMES[1],
          inputType: 'mandatory',
          name: TARIFF_NAMES[1],
          visibility: 1,
          notes: getInitialTariffNotes(TARIFF_NAMES[1]),
        },
        {
          id: TARIFF_NAMES[2],
          inputType: 'mandatory',
          name: TARIFF_NAMES[2],
          visibility: 1,
          notes: getInitialTariffNotes(TARIFF_NAMES[2]),
        },
        {
          id: TARIFF_NAMES[3],
          inputType: 'mandatory',
          name: TARIFF_NAMES[3],
          visibility: 1,
          notes: getInitialTariffNotes(TARIFF_NAMES[3]),
        },
        {
          id: TARIFF_NAMES[4],
          inputType: 'mandatory',
          name: TARIFF_NAMES[4],
          visibility: 1,
          notes: getInitialTariffNotes(TARIFF_NAMES[4]),
        },
        {
          id: TARIFF_NAMES[5],
          inputType: 'mandatory',
          name: TARIFF_NAMES[5],
          visibility: 1,
          notes: getInitialTariffNotes(TARIFF_NAMES[5]),
        },
      ],
    },
    {
      id: 'Дополнительные номера и линии',
      inputType: 'delimiter',
      name: 'Дополнительные номера и линии',
      isSpoiler: true,
    },
    {
      id: 'Дополнительный номер 8-800 - ежемесячная плата',
      inputType: 'counter',
      name: 'Дополнительный номер 8-800 - ежемесячная плата',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Дополнительный номер 8-800 - подключение',
      inputType: 'counter',
      name: 'Дополнительный номер 8-800 - подключение',
      disabled: true,
      price: {
        once: {
          value: 0,
          caption: 'руб.',
        },
      },
    },
    {
      id: 'Дополнительный номер DEF - ежемесячная плата',
      inputType: 'counter',
      name: 'Дополнительный номер DEF - ежемесячная плата',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Дополнительный номер DEF - подключение',
      inputType: 'counter',
      name: 'Дополнительный номер DEF - подключение',
      disabled: true,
      price: {
        once: {
          value: 0,
          caption: 'руб.',
        },
      },
    },
    {
      id: 'Дополнительный номер DEF/ABC - ежемесячная плата',
      inputType: 'counter',
      name: 'Дополнительный номер DEF/ABC - ежемесячная плата',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Дополнительный номер DEF/ABC - подключение',
      inputType: 'counter',
      name: 'Дополнительный номер DEF/ABC - подключение',
      disabled: true,
      price: {
        once: {
          value: 0,
          caption: 'руб.',
        },
      },
    },
    {
      id: 'Дополнительный номер ABC - ежемесячная плата',
      inputType: 'counter',
      name: 'Дополнительный номер ABC - ежемесячная плата',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Дополнительный номер ABC - подключение',
      inputType: 'counter',
      name: 'Дополнительный номер ABC - подключение',
      disabled: true,
      price: {
        once: {
          value: 0,
          caption: 'руб.',
        },
      },
    },
    {
      id: 'Дополнительная линия (сверх пакета)',
      inputType: 'counter',
      name: 'Дополнительная линия (сверх пакета)',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },

    {
      id: 'Дополнительные сотрудники',
      inputType: 'delimiter',
      name: 'Дополнительные сотрудники',
      isSpoiler: true,
    },
    {
      id: 'Пользователь внутренний стандартный',
      inputType: 'counter',
      name: 'Пользователь внутренний стандартный',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Пользователь внутренний расширенный',
      inputType: 'counter',
      name: 'Пользователь внутренний расширенный',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Пользователь мобильный стандартный',
      inputType: 'counter',
      name: 'Пользователь мобильный стандартный',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Пользователь мобильный расширенный',
      inputType: 'counter',
      name: 'Пользователь мобильный расширенный',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },

    {
      id: 'Функционал ВАТС',
      inputType: 'delimiter',
      name: 'Функционал ВАТС',
      isSpoiler: true,
    },
    {
      id: 'Голосовое меню',
      inputType: 'counter',
      name: 'Голосовое меню',
      price: {
        monthly: {
          value: 700,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Очередь базовая',
      inputType: 'counter',
      name: 'Очередь базовая',
      price: {
        monthly: {
          value: 80,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Очередь стандартная',
      inputType: 'counter',
      name: 'Очередь стандартная',
      price: {
        monthly: {
          value: 160,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Очередь расширенная',
      inputType: 'counter',
      name: 'Очередь расширенная',
      price: {
        monthly: {
          value: 400,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Ресепшионист малый бизнес - ежемесячная плата',
      inputType: 'counter',
      name: 'Ресепшионист малый бизнес - ежемесячная плата',
      price: {
        monthly: {
          value: 800,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Ресепшионист малый бизнес - подключение',
      inputType: 'counter',
      name: 'Ресепшионист малый бизнес - подключение',
      disabled: true,
      price: {
        once: {
          value: 1500,
          caption: 'руб.',
        },
      },
    },
    {
      id: 'Ресепшионист крупный бизнес - ежемесячная плата',
      inputType: 'counter',
      name: 'Ресепшионист крупный бизнес - ежемесячная плата',
      price: {
        monthly: {
          value: 1500,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Ресепшионист крупный бизнес - подключение',
      inputType: 'counter',
      name: 'Ресепшионист крупный бизнес - подключение',
      disabled: true,
      price: {
        once: {
          value: 3000,
          caption: 'руб.',
        },
      },
    },
    {
      id: 'Музыка на удержании групповая - ежемесячная плата',
      inputType: 'counter',
      name: 'Музыка на удержании групповая - ежемесячная плата',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Музыка на удержании групповая - подключение',
      inputType: 'counter',
      name: 'Музыка на удержании групповая - подключение',
      disabled: true,
      price: {
        once: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Групповые ЧБС - ежемесячная плата',
      inputType: 'counter',
      name: 'Групповые ЧБС - ежемесячная плата',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Групповые ЧБС - подключение',
      inputType: 'counter',
      name: 'Групповые ЧБС - подключение',
      disabled: true,
      price: {
        once: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Ограничения входящих вызовов',
      inputType: 'counter',
      name: 'Ограничения входящих вызовов',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Программный телефон',
      inputType: 'counter',
      name: 'Программный телефон',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },

    {
      id: 'Подключение УАТС',
      inputType: 'delimiter',
      name: 'Подключение УАТС',
      isSpoiler: true,
    },
    {
      id: 'Организация транка по IP - ежемесячная плата',
      inputType: 'counter',
      name: 'Организация транка по IP - ежемесячная плата',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Организация транка по IP - подключение',
      inputType: 'counter',
      name: 'Организация транка по IP - подключение',
      disabled: true,
      price: {
        once: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Организация транка по E1 - ежемесячная плата',
      inputType: 'counter',
      name: 'Организация транка по E1 - ежемесячная плата',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Организация транка по E1 - подключение',
      inputType: 'counter',
      name: 'Организация транка по E1 - подключение',
      disabled: true,
      price: {
        once: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },
    {
      id: 'Линия подключения к УАТС',
      inputType: 'counter',
      name: 'Линия подключения к УАТС',
      price: {
        monthly: {
          value: 0,
          caption: 'руб./мес.',
        },
      },
    },

    {
      id: 'Пакеты минут',
      inputType: 'delimiter',
      name: 'Пакеты минут',
      visibility: 3,
    },
    {
      id: 'Входящие на 8-800',
      inputType: 'delimiter',
      name: 'Входящие на 8-800',
      isSpoiler: true,
    },
    {
      id: 'Пакет минут 8-800 500 мин.',
      inputType: 'counter',
      name: 'Пакет минут 8-800 500 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут 8-800 1000 мин.',
      inputType: 'counter',
      name: 'Пакет минут 8-800 1000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут 8-800 2500 мин.',
      inputType: 'counter',
      name: 'Пакет минут 8-800 2500 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут 8-800 5000 мин.',
      inputType: 'counter',
      name: 'Пакет минут 8-800 5000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут 8-800 10000 мин.',
      inputType: 'counter',
      name: 'Пакет минут 8-800 10000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },

    {
      id: 'Исходящие на МТС',
      name: 'Исходящие на МТС',
      inputType: 'delimiter',
      isSpoiler: true,
    },
    {
      id: 'Пакет минут с ВАТС на МТС 3000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на МТС 3000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС на МТС 6000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на МТС 6000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС на МТС 12000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на МТС 12000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС и моб. на МТС 3000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС и моб. на МТС 3000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС и моб. на МТС 6000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС и моб. на МТС 6000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС и моб. на МТС 12000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС и моб. на МТС 12000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },

    {
      id: 'Исходящие на все сети ДР',
      name: 'Исходящие на все сети ДР',
      inputType: 'delimiter',
      isSpoiler: true,
    },
    {
      id: 'Пакет минут с ВАТС на ДР 3000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на ДР 3000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС на ДР 6000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на ДР 6000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС на ДР 12000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на ДР 12000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС на ДР 24000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на ДР 24000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС и моб. на ДР 3000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС и моб. на ДР 3000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС и моб. на ДР 6000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС и моб. на ДР 6000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС и моб. на ДР 12000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС и моб. на ДР 12000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС и моб. на ДР 24000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС и моб. на ДР 24000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },

    {
      id: 'Исходящие на все сети ДР и МГ',
      name: 'Исходящие на все сети ДР и МГ',
      inputType: 'delimiter',
      isSpoiler: true,
    },
    {
      id: 'Пакет минут с ВАТС на ДР и МГ 3000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на ДР и МГ 3000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС на ДР и МГ 6000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на ДР и МГ 6000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС на ДР и МГ 12000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на ДР и МГ 12000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС на ДР и МГ 24000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на ДР и МГ 24000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС и моб. на ДР и МГ 3000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС и моб. на ДР и МГ 3000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС и моб. на ДР и МГ 6000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС и моб. на ДР и МГ 6000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС и моб. на ДР и МГ 12000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС и моб. на ДР и МГ 12000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Пакет минут с ВАТС и моб. на ДР и МГ 24000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС и моб. на ДР и МГ 24000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 1,
      },
    },
    {
      id: 'Исходящие на МГ',
      name: 'Исходящие на МГ',
      inputType: 'delimiter',
      isSpoiler: true,
    },
    {
      id: 'Пакет минут с ВАТС на МГ 500 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на МГ 500 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 2,
      },
    },
    {
      id: 'Пакет минут с ВАТС на МГ 1000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на МГ 1000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 2,
      },
    },
    {
      id: 'Пакет минут с ВАТС на МГ 2000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на МГ 2000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 2,
      },
    },
    {
      id: 'Пакет минут с ВАТС на МГ 5000 мин.',
      inputType: 'counter',
      name: 'Пакет минут с ВАТС на МГ 5000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 2,
      },
    },
    {
      id: 'Аудиоконференция',
      name: 'Аудиоконференция',
      inputType: 'delimiter',
      isSpoiler: true,
    },
    {
      id: 'Пакет минут Аудиоконференция 1000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Аудиоконференция 1000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 3,
      },
    },
    {
      id: 'Пакет минут Аудиоконференция 2000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Аудиоконференция 2000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 3,
      },
    },
    {
      id: 'Пакет минут Аудиоконференция 3000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Аудиоконференция 3000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 3,
      },
    },
    {
      id: 'Пакет минут Аудиоконференция 5000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Аудиоконференция 5000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 3,
      },
    },
    {
      id: 'Пакет минут Аудиоконференция 10000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Аудиоконференция 10000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 3,
      },
    },
    {
      id: 'Пакет минут Аудиоконференция 20000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Аудиоконференция 20000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 3,
      },
    },
    {
      id: 'Пакет минут Аудиоконференция 50000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Аудиоконференция 50000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 3,
      },
    },
    {
      id: 'Пакет минут Аудиоконференция 100000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Аудиоконференция 100000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 3,
      },
    },
    {
      id: 'Пакет минут Аудиоконференция 200000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Аудиоконференция 200000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 3,
      },
    },
    {
      id: 'Пакет минут Аудиоконференция 500000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Аудиоконференция 500000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 3,
      },
    },
    {
      id: 'Пакет минут Аудиоконференция 1000000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Аудиоконференция 1000000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 3,
      },
    },
    {
      id: 'Запись и Факс',
      name: 'Запись и Факс',
      inputType: 'delimiter',
      isSpoiler: true,
    },
    {
      id: 'Пакет минут Запись и Факс 1000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Запись и Факс 1000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 4,
      },
    },
    {
      id: 'Пакет минут Запись и Факс 2000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Запись и Факс 2000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 4,
      },
    },
    {
      id: 'Пакет минут Запись и Факс 3000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Запись и Факс 3000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 4,
      },
    },
    {
      id: 'Пакет минут Запись и Факс 5000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Запись и Факс 5000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 4,
      },
    },
    {
      id: 'Пакет минут Запись и Факс 10000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Запись и Факс 10000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 4,
      },
    },
    {
      id: 'Пакет минут Запись и Факс 20000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Запись и Факс 20000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 4,
      },
    },
    {
      id: 'Пакет минут Запись и Факс 50000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Запись и Факс 50000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 4,
      },
    },
    {
      id: 'Пакет минут Запись и Факс 100000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Запись и Факс 100000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 4,
      },
    },
    {
      id: 'Пакет минут Запись и Факс 200000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Запись и Факс 200000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 4,
      },
    },
    {
      id: 'Пакет минут Запись и Факс 500000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Запись и Факс 500000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 4,
      },
    },
    {
      id: 'Пакет минут Запись и Факс 1000000 мин.',
      inputType: 'counter',
      name: 'Пакет минут Запись и Факс 1000000 мин.',
      price: {
        monthly: {
          caption: 'руб./мес.',
          value: 0,
        },
      },
      meta: {
        connectedWithNumbersAmountGroup: 4,
      },
    },
  ],
}
