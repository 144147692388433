import React, { Fragment } from 'react'
import CardBlock from 'constructors/components/CardBlock'
import ClientFormContainer from 'constructors/components/Client/Container'
import PreviewConfig from 'constructors/components/PreviewConfig'

const Client = ({ stage }) => {
  return (
    <Fragment>
      <CardBlock title={stage.name}>
        <ClientFormContainer />
      </CardBlock>
      <PreviewConfig />
    </Fragment>
  )
}

export default Client
