import { fromJS } from 'immutable'
import { createReducer } from 'redux-act'
import { set, isInitialized, isNotInitialized } from './actions'

const initialState = {
  isInitialized: false,
  name: '',
  email: '',
  expired: '',
  token: '',
  branch: '',
  mrd: '',
}

const reducer = createReducer(
  {
    [set]: (state, payload = {}) => {
      if (!payload) return fromJS(initialState)
      return state.merge(fromJS(payload))
    },
    [isInitialized]: state => {
      return state.set('isInitialized', true)
    },
    [isNotInitialized]: state => {
      return state.set('isInitialized', false)
    },
  },
  fromJS(initialState)
)

export default reducer
