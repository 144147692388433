import React from 'react'
import Total from './TotalContainer'
import Blocks from './Blocks'

const Invoice = () => {
  return (
    <div className="card proposal-info proposal-container__row">
      <div className="proposal-info__block">
        <Blocks />
      </div>
      <Total />
    </div>
  )
}

export default Invoice
