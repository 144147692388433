import React from 'react'

const CardBlock = ({ title, children }) => {
  return (
    <div className="proposal-row card__block">
      {title && <div className="proposal-row__title">{title}</div>}
      {children}
    </div>
  )
}

export default CardBlock
