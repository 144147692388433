import React from 'react'
import { connect } from 'react-redux'

import { getCalculations } from 'main/selectors/sales-scripts/calculations'
import Item from 'main/components/sales-scripts/card/item'

const mapStateToProps = (state, { content }) => ({
  item: content.calc(getCalculations(state).toJS()),
})

const CalculatedBlock = ({ item }) => <Item content={item} />

export default connect(mapStateToProps)(CalculatedBlock)
