import animateScrollTo from 'animated-scroll-to'

export const getCardAnchor = cardId => `card_${cardId}`
export const getCardId = cardId => `card_wrap_${cardId}`
export const getContentItemId = cardId => `content_item_${cardId}`

export const scrollToLink = (e, linkId) => {
  e.preventDefault()

  const scrollTo = document
    .getElementById(`card_wrap_${linkId}`)
    .getBoundingClientRect().y

  const currentScroll =
    document.documentElement.scrollTop || document.body.scrollTop

  animateScrollTo(currentScroll + scrollTo - 111, {
    element: window,
    maxDuration: 0,
  })
}
