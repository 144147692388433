import { createAction } from 'redux-act'
import { getAnswers } from './selectors'
import { underscoreCaseKeys } from 'common/modules/camelcase-keys'
import api from 'common/modules/api'

export const changeAnswer = createAction(
  'change answer',
  (questionId, name, value) => ({ questionId, name, value })
)

export const removeAnswers = createAction('remove answers')

export const saveAnswers = testId => (dispatch, getState) => {
  const state = getState()
  const result = []

  getAnswers(state).map((answer, questionId) =>
    result.push(answer.set('questionId', questionId).toJS())
  )

  // TODO: add loader
  return api.fetch(api.routes.tests.answers({ testId }), {
    body: underscoreCaseKeys(result),
  })
}
