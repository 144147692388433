import { connect } from 'react-redux'
import {
  changeToPreviousStage,
  changeToNextStage,
} from 'constructors/ducks/stages/actions'
import { getNextText, isDemoMode } from 'constructors/ducks/stages/selectors'
import Actions from 'constructors/components/Actions'
import { getIsLoading } from 'constructors/ducks/ui/selectors'

const mapStateToProps = state => ({
  prevText: 'Назад',
  nextText: getNextText(state),
  isLoading: getIsLoading(state),
  isDemoMode: isDemoMode(state),
})

const mapDispatchToProps = dispatch => ({
  changeToPreviousStage: () => dispatch(changeToPreviousStage()),
  changeToNextStage: () => dispatch(changeToNextStage()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Actions)
