import React, { Component } from 'react'
import Layout from './LayoutContainer'
import CurrentStage from './CurrentStageContainer'
import config from 'entry-specific/config'
import appRoutes from 'main-v2/pages/routes'

class Constructor extends Component {
  componentDidMount() {
    document.title =
      'Рабочее пространство - Конструктор коммерческих предложений'

    if (config(`constructor.iframe.${this.props.constructorId}`)) {
      ;(function(d, w, c) {
        window.OktaplanId = 'mts'
        var s = d.createElement('script')
        w[c] =
          w[c] ||
          function() {
            ;(w[c].q = w[c].q || []).push(arguments)
          }
        s.src = 'https://epc.oktaplan.ru/widget.js'
        d.head.appendChild(s)
      })(document, window, 'Oktaplan')

      window.Oktaplan(
        'render',
        config(`constructor.iframe.${this.props.constructorId}`),
        {
          target: '#constructor',
          onSuccess: data => {
            this.props.saveIframeCommercialProposal(data).then(json => {
              if (json.id || json.shortId) {
                window.location.assign(
                  appRoutes.proposals.preview(json.shortId || json.id)
                )
              }
            })
          },
        }
      )
    } else {
      this.props.init()
    }
  }

  render() {
    return config(`constructor.iframe.${this.props.constructorId}`) ? (
      <div id="constructor" style={{ margin: '0 auto' }} />
    ) : (
      <Layout>
        <CurrentStage />
      </Layout>
    )
  }
}

export default Constructor
