import React, { Component, Fragment } from 'react'
import Card from 'main/components/sales-scripts/card'
import Scrollspy from 'main/components/common/scrollspy'
import { getCardId } from 'main/modules/cards-helper'
import config from 'entry-specific/config'
import Markdown from 'common/components/Markdown'

class ScriptCards extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  setCurrentCard = cardId => {
    const { setCurrentCard } = this.props
    if (!setCurrentCard) return

    if (!cardId) return setCurrentCard(null)

    setCurrentCard(cardId.substring(10, cardId.length))
  }

  render() {
    const { cards } = this.props

    if (!cards || !cards.length)
      return (
        <Fragment>
          <div className="card scripts-container__row">
            <div
              className={`scripts-container__placeholder ${config(
                'naming.scripts.placeholderAdditionalClassName',
                ''
              )}`}
            >
              <Markdown
                text={config(
                  'naming.scripts.placeholder',
                  'Выберите скрипт и начните работу!'
                )}
              />
            </div>
          </div>
        </Fragment>
      )

    return (
      <Fragment>
        <Scrollspy
          items={getCardsIds(cards)}
          onUpdate={this.setCurrentCard}
          offset={-110}
          rootHeight={280}
        >
          {cards.map((card, index) => (
            <Card key={card.id} card={card} num={`${index + 1}.`} />
          ))}
        </Scrollspy>
      </Fragment>
    )
  }
}

const getCardsIds = cards => {
  let result = []

  cards.forEach(card => {
    if (card.content) result.push(getCardId(card.id))

    if (card.subCards && card.subCards.length) {
      result = result.concat(getCardsIds(card.subCards))
    }
  })

  return result
}

export default ScriptCards
