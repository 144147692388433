import React from 'react'
import Title from './Title'
import Spoiler from './Spoiler'

const Delimiter = props => {
  const { option, nestedOptionIds } = props
  if (!nestedOptionIds.length) return null
  return option.isSpoiler ? <Spoiler {...props} /> : <Title {...props} />
}

export default Delimiter
