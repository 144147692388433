import React, { Component } from 'react'
import { wordWithCase } from 'common/modules/number-helper'

class TestResult extends Component {
  onClose = e => {
    if (e.target.classList.contains('modal__container')) this.props.onClose()
  }

  render() {
    const { isOpen, onClose, test, failedQuestions } = this.props
    if (
      !isOpen ||
      !test ||
      !test.questions ||
      !test.questions.length ||
      !failedQuestions
    )
      return null

    const failsCount = test.questions.length - failedQuestions.length

    return (
      <div className="modal main-layout__modal">
        <div className="modal__overlay" />
        <div className="modal__container" onClick={this.onClose}>
          <div className="modal__content modal__content--auto">
            <div className="modal__title">
              Результаты тестирования{' '}
              <span className="modal__close" onClick={onClose} />
            </div>
            <div className="modal__body">
              <div className="text">
                Вы ответили правильно на {failsCount}{' '}
                {wordWithCase(failsCount, ['Вопрос', 'Вопроса', 'Вопросов'])} из{' '}
                {test.questions.length}
              </div>
              <ul className="test-res">
                {test.questions.map(question =>
                  renderQuestion(question, failedQuestions)
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const renderQuestion = (question, failedQuestions) => {
  let className = 'test-res__item'
  if (failedQuestions.indexOf(question.id) !== -1)
    className += ' test-res__item--bad'

  return (
    <li key={question.id} className={className}>
      {question.text}
    </li>
  )
}

export default TestResult
