const block = () => {
  document.body.classList.add('noscroll')
}

const unblock = () => {
  document.body.classList.remove('noscroll')
}

const disableWindowScroll = () => {
  const scrollTop = getScrollTop()
  const html = document.getElementsByTagName('html')[0]

  html.style.top = `${-scrollTop}px`
  html.style.width = '100%'
  html.style.position = 'fixed'
  html.style['overflow-y'] = 'scroll'
}

const enableWindowScroll = () => {
  const html = document.getElementsByTagName('html')[0]
  const scrollTop = Math.abs(parseInt(html.style.top, 10))

  html.style['overflow-y'] = 'auto'
  html.style.width = 'auto'
  html.style.position = 'static'
  document.body.scrollTop = scrollTop
  html.scrollTop = scrollTop
}

const getScrollTop = () => {
  const d = document
  const html = document.getElementsByTagName('html')[0]
  if (html.style.position === 'fixed') {
    return Math.abs(parseInt(html.style.top, 10))
  }

  return (
    d.documentElement.scrollTop ||
    d.body.parentNode.scrollTop ||
    d.body.scrollTop
  )
}

export default {
  block,
  unblock,
  disableWindowScroll,
  enableWindowScroll,
}
