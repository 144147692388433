import React, { useState, useEffect } from 'react'
import { matchPath } from 'react-router'

import config from 'entry-specific/config'
import { showDateTime } from 'common/modules/date-helper'
import api from 'common/modules/api'
import appRoutes from 'main-v2/pages/routes'

const Stats = props => {
  const { proposal: { stats: propsStats = {} } = {} } = props
  const [stats, setStats] = useState(propsStats)

  useEffect(() => {
    if (props.proposal && props.proposal.stats) return
    const match = matchPath(window.location.pathname, {
      path: appRoutes.proposals.stats(),
    })

    if (!match || !match.params.id) return
    api
      .fetch(api.routes.proposals.show(match.params.id))
      .then(response => setStats(response.json.stats))
  }, [])

  const pieData = createPie(stats.totalViews, stats.uniqueViews)

  return (
    <div className="view-analytics">
      <table className="view-analytics__table">
        <tbody>
          <tr>
            <td>Последний просмотр</td>
            <td>
              {stats.totalViews
                ? showDateTime(stats.lastRead, { todayOrYesterday: true })
                : '-'}
            </td>
          </tr>
          <tr>
            <td>Средняя длительность</td>
            <td>
              {stats.totalViews ? `${stats.averageViewDuration} сек` : '-'}
            </td>
          </tr>
          <tr>
            <td>Скачиваний</td>
            <td>{stats.downloads}</td>
          </tr>
          <tr>
            <td>Прочитано полностью</td>
            <td>{stats.isCompletelyRead ? 'Да' : 'Нет'}</td>
          </tr>
        </tbody>
      </table>
      <div className="pie-graph view-analytics__graph">
        <svg viewBox="-1 -1 2 2" className="pie-graph__image">
          {pieData.map((data, key) => (
            <path key={key} d={data.d} fill={data.fill} />
          ))}
        </svg>
        <div className="pie-graph__center-legend">
          <div className="pie-graph__legend-title">всего</div>
          <div className="pie-graph__legend-val pie-graph__legend-val--big">
            {stats.totalViews}
          </div>
        </div>
        <div className="pie-graph__bottom-legend">
          <span className="pie-graph__legend-image" />{' '}
          <span className="pie-graph__legend-val">{stats.uniqueViews}</span>{' '}
          <span className="pie-graph__legend-title">уникальных</span>
        </div>
      </div>
    </div>
  )
}

const createPie = (all = 0, uniq = 0) => {
  var render_val = 0
  if (all !== 0) render_val = uniq / all

  const slices = [
    { percent: render_val, color: config('colors.main', '#FFF') },
    { percent: 1 - render_val, color: '#E5E5E5' },
  ]
  let cumulativePercent = 0

  function getCoordinatesForPercent(percent) {
    const x = Math.cos(2 * Math.PI * percent)
    const y = Math.sin(2 * Math.PI * percent)
    return [x, y]
  }

  return slices.map(slice => {
    const [startX, startY] = getCoordinatesForPercent(cumulativePercent)
    cumulativePercent += slice.percent
    const [endX, endY] = getCoordinatesForPercent(cumulativePercent)
    const largeArcFlag = slice.percent > 0.5 ? 1 : 0

    return {
      d: [
        `M${startX} ${startY}`,
        `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`,
        `L 0 0`,
      ].join(' '),
      fill: slice.color,
    }
  })
}

export default Stats
