import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import { setConstructor, setInitialization } from './actions'
import {
  fetchProposalStart,
  fetchProposalSuccess,
  fetchProposalError,
} from 'main-v2/ducks/proposals'

const initialState = {
  isLoading: false,
  isInitializing: false,
  products: [],
}

const reducer = createReducer(
  {
    [setConstructor]: (state, payload = {}) => {
      return state.mergeDeep(payload)
    },
    [fetchProposalStart]: state => {
      return state.set('isLoading', true)
    },
    [fetchProposalSuccess]: state => {
      return state.set('isLoading', false)
    },
    [fetchProposalError]: state => {
      return state.set('isLoading', false)
    },
    [setInitialization]: (state, payload = false) => {
      return state.set('isInitializing', payload)
    },
  },
  fromJS(initialState)
)

export default reducer
