import React, { Fragment, Component } from 'react'
import copyToClipboard from 'kp-preview/modules/copy-to-clipboard'
import config from 'entry-specific/config'
import RenderByLines from 'common/components/RenderByLines'

class LinkAndEmailTemplate extends Component {
  constructor(props) {
    super(props)
    this.linkRef = React.createRef()
    this.textRef = React.createRef()
  }

  state = {
    linkCopied: false,
    textCopied: false,
  }

  copyLink = () => {
    if (!this.linkRef.current) return

    copyToClipboard(this.linkRef.current.innerText)
    this.setState({
      linkCopied: true,
      textCopied: false,
    })
  }

  copyText = () => {
    if (!this.textRef.current) return

    copyToClipboard(this.textRef.current.innerText)
    this.setState({
      linkCopied: false,
      textCopied: true,
    })
  }

  render() {
    const { proposal } = this.props
    if (!proposal) return null

    return (
      <Fragment>
        {this.renderLink()}
        <div className="split modal__split">
          <div className="split__text">или</div>
          <div className="split__line" />
        </div>
        {this.renderEmailTemplate()}
      </Fragment>
    )
  }

  renderLink = () => {
    const { proposal } = this.props
    const { linkCopied } = this.state

    return (
      <div className="text text--center">
        <div className="modal__subtitle">
          Скопируйте ссылку и отправьте её клиенту
        </div>
        <div ref={this.linkRef} className="text text--medium">
          {getLink(proposal)}
        </div>
        <div className="text text--top">
          <button
            className="button"
            disabled={linkCopied}
            onClick={this.copyLink}
          >
            {linkCopied ? 'Скопировано' : 'Скопировать ссылку'}
          </button>
        </div>
      </div>
    )
  }

  renderEmailTemplate = () => {
    const { proposal, currentUser } = this.props
    const { textCopied } = this.state
    const signatureLine = getSignatureLine(currentUser)

    return (
      <Fragment>
        <div className="modal__subtitle">
          Скопируйте шаблон письма и отправьте его по электронной почте
        </div>
        <div ref={this.textRef} className="well">
          <p className="well__p">{getGreeting(config, proposal)}</p>
          <p className="well__p">{this.renderEmailText()}</p>
          <p className="well__p text text--medium">{getLink(proposal)}</p>
          {signatureLine && <p className="well__p">{signatureLine}</p>}
        </div>
        <div className="text text--right text--top">
          <button
            className="button"
            disabled={textCopied}
            onClick={this.copyText}
          >
            {textCopied ? 'Скопировано' : 'Скопировать шаблон'}
          </button>
        </div>
      </Fragment>
    )
  }

  renderEmailText = () => {
    const { proposal } = this.props
    return <RenderByLines>{getEmailText(config, proposal)}</RenderByLines>
  }
}

const getLink = proposal =>
  window.location.origin + `/p/${proposal.shortId || proposal.id}`

const getGreeting = (config, proposal) =>
  config('naming.kpPreview.emailTemplate.greeting', () => 'Добрый день,')(
    proposal.client
  )

const getEmailText = (config, proposal) =>
  config('naming.kpPreview.emailTemplate.text', () => '')(proposal)

const getSignatureLine = currentUser => {
  const signature = config(
    'naming.kpPreview.emailTemplate.signatureLine',
    currentUser => (
      <Fragment>
        С уважением,
        <br />
        {currentUser.lastName} {currentUser.firstName}
        <br />
        {currentUser.phoneNumber && (
          <Fragment>
            {currentUser.phoneNumber} <br />
          </Fragment>
        )}
        {currentUser.email}
      </Fragment>
    )
  )(currentUser)

  if (typeof signature === 'string')
    return <span dangerouslySetInnerHTML={{ __html: signature }} />

  return signature
}

export default LinkAndEmailTemplate
