import { connect } from 'react-redux'
import { getStageByType } from 'constructors/ducks/stages/selectors'
import CustomOptions from './CustomOptions'
import toJS from 'common/HOC/to-js'

const mapStateToProps = state => ({
  productIds: getStageByType(state, 'customOptions').get('productIds'),
})

const CustomOptionsContainer = connect(mapStateToProps)(toJS(CustomOptions))

export default CustomOptionsContainer
