import { itemTypes } from './helpers'
import generateScript from './generate-script'

const cards = [
  {
    id: '2',
    name: 'Предложение услуги',
    subCards: [
      {
        id: '2.1',
        name: 'Связь с сотрудниками',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `Как осуществляется связь разъездных сотрудников с диспетчерами/офисными сотрудниками?

              Мы можем  реализовать для всех сотрудников звонок на единый многокнальный номер и настроить прием звонков по разным условиями (например, звонок от водителя Петрова- всегда на диспечера 1, а от Смирнова- на диспетчера 2. Или растроить переадресацию в зависимости от темы обращения сотрудника.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение эффективности логистики: оперативное получение информации и корректировка действий водителей и экспидиторов
                Сокращение расходов и времени: на дозвон с использованием обычной телефонии (мобильной или фиксированной)`,
              },
            ],
          },
        ],
      },
      {
        id: '2.2',
        name: 'Удаленное управление офисами',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `Как осуществляется связь с подразделениями компании, которые расположены по другим адресам?

              Возможности виртуальной АТС позволяют быстро собирать на аудио-конференции неограниченное кол-во сотрудников. Также можно создавать множество переговорных аудио-комнат  и проводить  несколько совещаний одновременно`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение эффективности бизнеса: оперативное получение информации даже от удаленных офисов и представительств.
                Сокращение расходов: на командировки`,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default generateScript({ cards })
