import React from 'react'
import { inputTypes, speech } from 'entry-specific/apps/manager/scripts/helpers'
import TextInput from './input-types/text'

const CardInput = props => {
  const { type, keyPath, who, onChange } = props

  let className = 'script__speech'
  if (who === speech.K) {
    className += ' script__speech--client'
  }

  let InputType = null

  switch (type) {
    case inputTypes.text:
      InputType = TextInput
      break
    default:
      break
  }

  if (!InputType) return null

  return (
    <div className={className}>
      {who && <div className="script__man">{who}</div>}
      <div className="script__input">
        <InputType {...props} onChange={carriedOnChange(onChange, keyPath)} />
      </div>
    </div>
  )
}

const carriedOnChange = (onChange, keyPath) => e => {
  const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

  return onChange(keyPath, value)
}

export default CardInput
