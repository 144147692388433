const isObject = x =>
  typeof x === 'object' &&
  x !== null &&
  !(x instanceof RegExp) &&
  !(x instanceof Error) &&
  !(x instanceof Date)

module.exports = function mapObj(obj, fn, opts, seen) {
  opts = Object.assign(
    {
      deep: false,
      target: {},
    },
    opts
  )

  seen = seen || new WeakMap()

  if (seen.has(obj)) {
    return seen.get(obj)
  }

  seen.set(obj, opts.target)

  const target = opts.target
  delete opts.target

  Object.keys(obj).forEach(key => {
    const val = obj[key]
    const res = fn(key, val, obj)
    let newVal = res[1]

    if (opts.deep && isObject(newVal)) {
      if (Array.isArray(newVal)) {
        newVal = newVal.map(x => (isObject(x) ? mapObj(x, fn, opts, seen) : x))
      } else {
        newVal = mapObj(newVal, fn, opts, seen)
      }
    }

    target[res[0]] = newVal
  })

  return target
}
