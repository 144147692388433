import { fromJS } from 'immutable'
import { createReducer } from 'redux-act'
import { changeAnswer, removeAnswers } from './actions'

const initialState = {}

const reducer = createReducer(
  {
    [changeAnswer]: (state, payload) => {
      let answer = state.get(payload.questionId)
      !answer ? (answer = {}) : (answer = answer.toJS())
      answer[payload.name] = payload.value
      return state.set(payload.questionId, fromJS(answer))
    },
    [removeAnswers]: () => {
      return fromJS(initialState)
    },
  },
  fromJS(initialState)
)

export default reducer
