import React, { Fragment } from 'react'
import ProductSelector from 'constructors/components/ProductSelector'
import Options from 'constructors/components/Options/Container'
import CardBlock from 'constructors/components/CardBlock'

const SelectOneProduct = ({
  products,
  selectedProductId,
  hasOptions,
  onProductChange,
}) => {
  return (
    <Fragment>
      <CardBlock
        title={moreThanOneProduct(products) ? 'Выберите продукт' : 'Продукт'}
      >
        <ProductSelector
          products={products}
          selectedProductId={selectedProductId}
          onChange={onProductChange}
        />
      </CardBlock>
      {hasOptions && (
        <CardBlock
          title={
            moreThanOneProduct(products)
              ? 'Укажите опции выбранной услуги'
              : 'Укажите опции'
          }
        >
          <Options productId={selectedProductId} />
        </CardBlock>
      )}
    </Fragment>
  )
}

const moreThanOneProduct = products =>
  !!products && !!products.length && products.length !== 1

export default SelectOneProduct
