import safeGet from 'common/modules/safe-get'
import api from './api'
import naming from './naming'
import constructor from './constructor'

const config = {
  api,
  colors: {
    main: '#E30611',
  },
  entryVideoUrl: null,
  authorizationLayout: {
    clientLink: 'https://mts.ru/',
  },
  jivosite: {
    widgetId: '4A4RmJMNkq',
  },
  constructor,
  naming,
}

export default safeGet.bind(null, config)
