import React from 'react'
import { Link } from 'react-router-dom'

import appRoutes from 'main/constants/app-routes'

const ProductNav = ({ match, product }) => {
  const { sectionId } = match.params

  return (
    <ul className="sidenav product-layout__nav">
      {product.sections &&
        product.sections.map(section => {
          const isActive = section.code === sectionId

          return (
            <li
              key={section.code}
              className={getActiveClass('sidenav__item', isActive)}
            >
              <Link
                to={appRoutes.products.section(product.code, section.code)}
                className={getActiveClass('sidenav__link', isActive)}
              >
                {section.title}
              </Link>
            </li>
          )
        })}
    </ul>
  )
}

const getActiveClass = (className, isActive) => {
  return `${className} ${isActive ? className + '--active' : ''}`
}

export default ProductNav
