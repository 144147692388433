import { connect } from 'react-redux'
import { getAdaptation } from 'main-v2/ducks/adaptation/selectors'
import Adaptation from './Adaptation'
import toJS from 'common/HOC/to-js'

const mapStateToProps = state => ({
  adaptation: getAdaptation(state),
})

const AdaptationContainer = connect(mapStateToProps)(toJS(Adaptation))

export default AdaptationContainer
