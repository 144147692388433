import { schema } from 'normalizr'
import tariff from './tariff'
import product from './product'
// import constructorConstants from 'common/constants/contstructor'

const constructor = new schema.Entity('constructors', {
  tariffs: [tariff],
  products: [product],
  additionalProducts: [product],
})
// , {
//   processStrategy: (entity) => ({
//     ...entity,
//     productsOptionsIds: mapProductsOptionsIdsByConstructorCode(entity)
//   })
// })

// const mapProductsOptionsIdsByConstructorCode = entity => {
//   if (entity.code === constructorConstants.codes.mono) {
//     return mapProductsOptionsIds(entity.products)
//   }

//   if (entity.code === constructorConstants.codes.plus) {
//     return {
//       ...mapProductsOptionsIds(entity.products),
//       ...mapProductsOptionsIds(entity.additionalProducts)
//     }
//   }

//   return null
// }

// const mapProductsOptionsIds = products => {
//   if (!products) return {}
//   let result = {}
//   for (let product of products) {
//     result[product.id] = product.options.map(o => o.id)
//   }
//   return result
// }

export default constructor
