import React from 'react'

const ProductSelector = ({ products, selectedProductId, onChange }) => {
  return (
    <div className="services">
      {products.map(product => (
        <label
          key={product.id}
          className={getClassName(product, selectedProductId)}
        >
          <div className="radio-input services__radio-input">
            <input
              type="radio"
              className="radio-input__input"
              name="service"
              onChange={carriedOnChange(onChange, product.id)}
              checked={product.id === selectedProductId}
            />
            <div className="radio-input__block" />
          </div>
          <div className="services__name">
            {product.name}
            {product.hint && ` ${product.hint}`}
          </div>
        </label>
      ))}
    </div>
  )
}

const getClassName = (product, selectedProductId) => {
  let className = `services__item services__item--${product.code}`
  if (product.id === selectedProductId) className += ' services__item--active'
  return className
}

const carriedOnChange = (onChange, productId) => () => onChange(productId)

export default ProductSelector
