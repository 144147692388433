import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import toJS from 'common/HOC/to-js'

import {
  getProposalFromCursor,
  deleteProposal,
  dropSalesScriptCursor,
} from 'main-v2/ducks/proposals'
import api from 'common/modules/api'

import Dropdown from 'main/components/common/dropdown/dropdown'
import DropdownItem from 'main/components/common/dropdown/dropdown-item'
import DropdownDelimiter from 'main/components/common/dropdown/dropdown-delimiter'
import Modal from 'common/components/Modal'
import Confirmation from 'common/components/Confirmation'
import Stats from 'main-v2/components/proposals/Stats'
import SendEmail from 'main-v2/components/proposal-script/SendEmail'

const mapStateToProps = state => ({
  proposal: getProposalFromCursor(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDelete: id => {
    dispatch(deleteProposal(id)).then(() => dispatch(dropSalesScriptCursor()))
  },
})

const ProposalScriptActions = props => {
  const { proposal } = props

  return (
    <Dropdown>
      <SendEmail {...props} />
      <DropdownItem onClick={download(proposal.id)}>Скачать PDF</DropdownItem>
      <StatsButton {...props} />
      <DropdownDelimiter />
      <DeleteButton {...props} />
    </Dropdown>
  )
}

const StatsButton = props => {
  const { proposal } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Fragment>
      <DropdownItem onClick={() => setIsOpen(true)}>
        Открыть аналитику
      </DropdownItem>
      <Modal
        className="main-layout__modal"
        title="Аналитика просмотров предложения"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="auto"
      >
        <Stats proposal={proposal} />
      </Modal>
    </Fragment>
  )
}

const DeleteButton = props => {
  const [isOpen, setIsOpen] = useState(false)
  const { proposal, onDelete } = props

  return (
    <Fragment>
      <DropdownItem
        type="warning"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        Удалить
      </DropdownItem>
      <Confirmation
        title="Удаление коммерческого предложения"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        text={`Вы действительно хотите удалить коммерческое предложение для компании «${
          proposal.client.companyName
        }»?`}
        actionText="Удалить"
        onSubmit={() => onDelete(proposal.id)}
      />
    </Fragment>
  )
}

const download = previewWholeId => () => {
  window.open(`${api.routes.proposals.file(previewWholeId)}`, '_blank')
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(ProposalScriptActions))
