import React, { Component, Fragment } from 'react'
import VideoModal from 'main/components/modals/video'
import config from 'entry-specific/config'

class HighlightedBlock extends Component {
  state = {
    video: null,
  }

  closeVideo = () => {
    this.setState({ video: null })
  }

  openVideo = () => {
    this.setState({
      video: {
        fileUrl: config('introVideoUrl'),
      },
    })
  }

  // hide = e => {
  //   e.stopPropagation();
  //   localStorage.setItem('highlightedProductsBlockIsHidden', true)
  //   this.forceUpdate()
  // }

  render() {
    if (!config('introVideoUrl')) return null
    // if (localStorage.getItem('highlightedProductsBlockIsHidden')) return null

    const { video } = this.state

    return (
      <Fragment>
        <div
          className="products__start-video start-video"
          onClick={this.openVideo}
        >
          <div className="start-video__text">
            Просмотреть скринкаст<span> «Сила Ума»</span>
          </div>
          {/* <div
            className="start-video__close"
            onClick={this.hide}
          ></div> */}
        </div>
        <VideoModal video={video} close={this.closeVideo} />
      </Fragment>
    )
  }
}

export default HighlightedBlock
