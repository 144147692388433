import React from 'react'
import config from 'entry-specific/config'

const AuthorizationLayout = ({ children }) => {
  return (
    <div className="authorization-layout">
      <a
        className="logo authorization-layout__logo"
        rel="noopener noreferrer"
        href="/"
      >
        {' '}
      </a>
      {children}
      <a
        className="small-logo authorization-layout__small-logo"
        target="_blank"
        rel="noopener noreferrer"
        href={config('authorizationLayout.clientLink', '/')}
      >
        {' '}
      </a>
    </div>
  )
}

export default AuthorizationLayout
