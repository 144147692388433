export default {
  showroom: '/showroom',
  pdfPreview: (url = ':url') =>
    `${window.location.origin}/pdf-preview?url=${url}`,

  root: '/',
  notFound: () => '/not-found',
  blocked: () => '/blocked',

  auth: '/auth',
  resetInstructions: '/reset-instructions',
  passwordReset: '/password-reset',
  accountActivation: '/account-activation',

  salesScripts: '/sales-scripts',
  proposals: {
    index: '/commercial-proposals',
    constructor: {
      index: '/commercial-proposals/constructor',
      new: stage => `/commercial-proposals/constructor/new/${stage}`,
      edit: (id, stage) => `/commercial-proposals/constructor/${id}/${stage}`,
    },
    preview: (id = ':id') => `/p/${id}`,
    previewLegacy: (id = ':id') => `/preview/${id}`,
    stats: (id = ':id') => `/commercial-proposals/stats/${id}`,
    script: (id = ':id') => `/commercial-proposals/${id}/scripts`,
  },

  products: {
    index: '/products',
    item: (id = ':id') => `/products/${id}`,
    section: (id = ':id', sectionId = ':sectionId') =>
      `/products/${id}/sections/${sectionId}`,
  },

  newsCompilations: {
    index: '/news',
    item: (id = ':id') => `/news/${id}`,
  },
}
