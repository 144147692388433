const mapObj = require('./map-obj')

const camelcaseKeys = (input, opts) => {
  if (Array.isArray(input)) {
    return Object.keys(input).map(key => camelCaseConvert(input[key], opts))
  }
  return camelCaseConvert(input, opts)
}

const underscoreCaseKeys = (input, opts) => {
  if (Array.isArray(input)) {
    return Object.keys(input).map(key =>
      underscoreCaseConvert(input[key], opts)
    )
  }
  return underscoreCaseConvert(input, opts)
}

const camelCaseConvert = (input, opts) => {
  return keysConvert(input, toCamelCase, opts)
}

const toCamelCase = str => {
  return str.replace(/_([a-z])/g, g => g[1].toUpperCase())
}

const underscoreCaseConvert = (input, opts) => {
  return keysConvert(input, toUnderscoreCase, opts)
}

const toUnderscoreCase = str => {
  return str.replace(/([A-Z])/g, $1 => `_${$1.toLowerCase()}`)
}

const keysConvert = (input, convertor, opts) => {
  opts = Object.assign(
    {
      deep: true,
    },
    opts
  )

  const exclude = opts.exclude

  return mapObj(
    input,
    (key, val) => {
      if (!(exclude && has(exclude, key))) {
        const ret = convertor(key)

        key = ret
      }

      return [key, val]
    },
    { deep: opts.deep }
  )
}

const has = (arr, key) =>
  arr.some(x => (typeof x === 'string' ? x === key : x.test(key)))

module.exports.camelcaseKeys = camelcaseKeys
module.exports.underscoreCaseKeys = underscoreCaseKeys
