import { createSelector } from 'reselect'
import { getAdaptationTest } from 'main-v2/ducks/adaptation/selectors'

export const getAnswers = state => state.get('answers')
export const getRemainAnswersCount = createSelector(
  getAdaptationTest,
  getAnswers,
  (test, answers) => {
    if (!test) return null
    if (!test.get('questions')) return null

    return test.get('questions').count(question => {
      if (question.get('optional')) return false
      const answer = answers.get(question.get('id'))
      if (!answer) return true
      return !(
        (answer.get('variantIds') && answer.get('variantIds').size) ||
        answer.get('comment')
      )
    })
  }
)
