import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import { setStats } from './actions'

export const initialState = {
  averageViewDuration: 0,
  downloads: 0,
  isCompletelyRead: false,
  lastRead: '0001-01-01T00:00:00Z',
  total_views: 0,
  unique_views: 0,
}

const reducer = createReducer(
  {
    [setStats]: (state, payload = {}) => {
      return state.mergeDeep(payload)
    },
  },
  fromJS(initialState)
)

export default reducer
