import React, { Component } from 'react'
import Nav from 'main/components/nav'
import Jivosite from 'main-v2/components/Jivosite'
import IntroVideo from 'main/components/modals/IntroVideo'

class Layout extends Component {
  componentWillUnmount() {
    document.body.style.backgroundColor = null
  }

  componentDidMount() {
    document.body.style.backgroundColor = '#F3F5F7'
  }

  render() {
    const { name, children, logout, isLoggedIn } = this.props

    return (
      <div className="main-layout">
        <Nav name={name} logout={logout} isLoggedIn={isLoggedIn()} />
        {children}
        <Jivosite />
        <IntroVideo />
      </div>
    )
  }
}

export default Layout
