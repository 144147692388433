import { connect } from 'react-redux'
import { getProduct } from 'constructors/ducks/products/selectors'
import toJS from 'common/HOC/to-js'
import Product from './Product'

const mapStateToProps = (state, ownProps) => ({
  product: getProduct(state, ownProps.productId),
})

const ProductContainer = connect(mapStateToProps)(toJS(Product))

export default ProductContainer
