import { connect } from 'react-redux'
import { getRadioOptions } from 'constructors/ducks/options/selectors'
import { getRadioValueId } from 'constructors/ducks/values/selectors'
import { changeOptionValue } from 'constructors/ducks/values/actions'
import toJS from 'common/HOC/to-js'
import SmallRadioButton from './SmallRadioButton'

const mapStateToProps = (state, ownProps) => ({
  options: getRadioOptions(state, ownProps.stage.optionId),
  currentId: getRadioValueId(state, ownProps.stage.optionId),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: value =>
    dispatch(
      changeOptionValue({
        optionId: ownProps.stage.optionId,
        value,
      })
    ),
})

const SmallRadioButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(SmallRadioButton))

export default SmallRadioButtonContainer
