import config from 'entry-specific/config'

export default ({ history, site }) => {
  if (process.env.NODE_ENV !== 'production') return
  if (!config('gaTrackingId')) return

  const ReactGA = require('react-ga')
  ReactGA.initialize(config('gaTrackingId'), {
    gaOptions: {
      name: `${window.location.hostname
        .split('.')
        .slice(0, -2)
        .slice(-1)[0] || 'oktaplan'}/${site}`,
    },
  })

  if (history) {
    history.listen(function(location) {
      ReactGA.pageview(location.pathname + location.search)
    })
  } else {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}
