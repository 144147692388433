const autofillRegexp = /{{(.*?)}}/g

export const hasAutofill = text => text.search(autofillRegexp) !== -1

export const keyPathToArr = keyPath => {
  if (!keyPath) return []
  if (typeof keyPath !== 'string') return []
  return keyPath.split('.')
}

export const autofillText = (text, autofill) => {
  let matches = []
  let match = null

  while ((match = autofillRegexp.exec(text)) !== null) {
    matches.push(match)
  }

  return replaceTextByMatches(text, matches, autofill)
}

const replaceTextByMatches = (text, matches, autofill) => {
  let lengthCorrection = 0

  matches.forEach(match => {
    const [keyPath, placeholder] = match[1].split('|')
    const prevLength = text.length

    text = replaceAt(
      text,
      match,
      getReplaceText(autofill, keyPathToArr(keyPath)) || placeholder || '<>',
      lengthCorrection
    )

    if (prevLength > text.length) {
      lengthCorrection += -(prevLength - text.length)
    } else {
      lengthCorrection += text.length - prevLength
    }
  })

  return text
}

const getReplaceText = (autofill, keyPath) => {
  let text = autofill

  for (let i = 0; i < keyPath.length; i++) {
    text = text[keyPath[i]]
    if (!text) break
  }

  return text
}

const replaceAt = (input, match, replace, lengthCorrection) => {
  const start = match.index + lengthCorrection
  const end = match.index + match[0].length

  return (
    input.slice(0, start) +
    input.slice(start, end).replace(match[0], replace) +
    input.slice(end)
  )
}
