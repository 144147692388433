import { createAction } from 'redux-act'
import api from 'common/modules/api'
import currentUser from 'common/modules/current-user'
import { fetchBranches } from 'main-v2/ducks/branches/actions'

export const set = createAction('set current user')
export const isInitialized = createAction('current user is initialized')
export const isNotInitialized = createAction('current user is not initialized')

export const initializeCurrentUser = () => dispatch => {
  if (!currentUser.isLoggedIn()) return dispatch(isInitialized())

  let userData = {}
  return api
    .fetch(api.routes.currentUser.index())
    .then(response => {
      userData = response.json
      return dispatch(fetchBranches())
    })
    .then(result => {
      if (result) {
        userData.branchId = Object.keys(result.branches.byId).find(
          key => result.branches.byId[key].name === userData.branchName
        )
      }

      currentUser.setUserData(userData)
      userData.email &&
        window.Sentry &&
        window.Sentry.configureScope(scope =>
          scope.setTag('userEmail', userData.email)
        )
      dispatch(isInitialized())
    })
    .catch(error => {
      dispatch(isInitialized())
      throw error
    })
}

export default {
  set,
  isInitialized,
  isNotInitialized,
  initializeCurrentUser,
}
