import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import appRoutes from 'main/constants/app-routes'
import newAppRoutes from 'main-v2/pages/routes'
import currentUser from 'common/modules/current-user'
import config from 'entry-specific/config'

import ScriptsIcon from 'styles/assets/svg/nav/scripts.svg'
import ProposalsIcon from 'styles/assets/svg/nav/proposals.svg'
import AdaptationIcon from 'styles/assets/svg/nav/adaptation.svg'
import ProductsIcon from 'styles/assets/svg/nav/products.svg'
import NewsIcon from 'styles/assets/svg/nav/news.svg'
import ProfileIcon from 'styles/assets/svg/nav/profile.svg'

const ROOT_ROUTES = [appRoutes.root, config('rootPath', appRoutes.salesScripts)]

const isLinkActive = link => (match, location) => {
  if (location.pathname === appRoutes.root)
    return ROOT_ROUTES.indexOf(link) !== -1

  return !!match
}

const Nav = ({ isLoggedIn }) => {
  if (!isLoggedIn) return null

  return (
    <div className="nav">
      <div className="nav__block">
        <Link to={appRoutes.root} className="small-logo nav__logo" />

        <ul className="nav__links">
          {config('navigation.scripts', true) && (
            <li className="nav__item">
              <NavLink
                to={appRoutes.salesScripts}
                className="nav__link"
                activeClassName="nav__link--active"
                isActive={isLinkActive(appRoutes.salesScripts)}
              >
                <span className="nav__item-text">Скрипты продаж</span>
                <span className="nav__item-icon">
                  <ScriptsIcon />
                </span>
              </NavLink>
            </li>
          )}
          {config('navigation.commercialProposals', true) && (
            <li className="nav__item">
              <NavLink
                to={appRoutes.proposals.index}
                className="nav__link"
                activeClassName="nav__link--active"
                isActive={isLinkActive(appRoutes.proposals.index)}
              >
                <span className="nav__item-text">
                  {config(
                    'naming.commercialProposals.name',
                    'Коммерческие предложения'
                  )}
                </span>
                <span className="nav__item-icon">
                  <ProposalsIcon />
                </span>
              </NavLink>
            </li>
          )}
          {config('navigation.products', true) && (
            <li className="nav__item">
              <NavLink
                to={appRoutes.products.index}
                className="nav__link"
                activeClassName="nav__link--active"
                isActive={isLinkActive(appRoutes.products.index)}
              >
                <span className="nav__item-text">
                  {config('naming.products.name', 'Продукты')}
                </span>
                <span className="nav__item-icon">
                  <ProductsIcon />
                </span>
              </NavLink>
            </li>
          )}
          {config('navigation.adaptation', true) && currentUser.roleId && (
            <li className="nav__item">
              <NavLink
                to={newAppRoutes.adaptation.index()}
                className="nav__link nav__link--adaptation"
                activeClassName="nav__link--active"
                isActive={isLinkActive(newAppRoutes.adaptation.index())}
              >
                <span className="nav__item-text">
                  {config('naming.adaptation.name', 'Обучение')}
                </span>
                <span className="nav__item-icon">
                  <AdaptationIcon />
                </span>
              </NavLink>
            </li>
          )}
          {config('navigation.news', true) && (
            <li className="nav__item">
              <NavLink
                to={appRoutes.newsCompilations.index}
                className="nav__link"
                activeClassName="nav__link--active"
                isActive={isLinkActive(appRoutes.newsCompilations.index)}
              >
                <span className="nav__item-text">Новости</span>
                <span className="nav__item-icon">
                  <NewsIcon />
                </span>
              </NavLink>
            </li>
          )}
          <li className="nav__item nav__item--user">
            <NavLink
              to={newAppRoutes.profile.index({}, { resetParams: true })}
              className="nav__link"
              activeClassName="nav__link--active"
              isActive={isLinkActive(
                newAppRoutes.profile.index({}, { resetParams: true })
              )}
            >
              <span className="nav__item-icon">
                <ProfileIcon />
              </span>
              <span className="nav__item-text">{currentUser.name}</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Nav
