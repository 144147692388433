import React, { Component } from 'react'
import { showDate } from 'common/modules/date-helper'
import placeholderImg from 'styles/assets/news_placeholder@2x.png'
import bodyNoscroll from 'common/modules/body-noscroll'
import PdfPreview from 'main/components/products/sections/documents/pdf-preview'

class NewsCompilations extends Component {
  constructor(props) {
    super(props)

    this.previewCloseRef = React.createRef()
    this.previewContainerRef = React.createRef()
  }

  state = {
    news: null,
  }

  componentDidMount() {
    document.title = `Рабочее пространство - Новости`
    this.props.fetchNewsCompilations()
  }

  openNews = news => {
    this.setState({ news })
    bodyNoscroll.block()
  }

  closeNews = e => {
    if (
      e.target !== this.previewContainerRef.current &&
      e.target !== this.previewCloseRef.current
    )
      return
    this.setState({ news: null })
    bodyNoscroll.unblock()
  }

  render() {
    const { records, isLoading } = this.props
    if ((!records || !records.length) && !isLoading)
      return this.renderPlaceholder()

    return (
      <PdfPreview.Provider>
        {this.renderMain()}
        {this.renderPopup()}
        <PdfPreview.Component />
      </PdfPreview.Provider>
    )
  }

  renderPlaceholder = () => (
    <div className="main-layout__block center-block">
      <div className="card">
        <div className="placeholder-card placeholder-card--news">
          <div className="placeholder-card__title">Материалы не найдены</div>
          <div className="placeholder-card__text">
            Здесь вы могли бы увидеть актуальные подборки новостей по разным
            тематикам
          </div>
        </div>
      </div>
    </div>
  )

  renderMain() {
    const { records } = this.props

    return (
      <div className="main-layout__block main-layout__block--small center-block">
        {records.map((compilation, index) => (
          <div key={compilation.id} className="news main-layout__news">
            <div className="news__top">
              <div className="news__title">{compilation.title}</div>
              <div className="news__date">{showDate(compilation.date)}</div>
            </div>
            <div className="news__content">
              {compilation.categories.map(category => (
                <div key={category.id} className="news__group">
                  {category.name && (
                    <div className="news__subtitle">{category.name}</div>
                  )}
                  <PdfPreview.Consumer>
                    {({ open }) =>
                      category.news.map(news => (
                        <div key={news.id} className="news-item news__item">
                          {this.isPdf(news.url)
                            ? this.renderData(news, () =>
                                open({
                                  isNotDownloadable: true,
                                  fileUrl: news.url,
                                })
                              )
                            : this.renderData(
                                news,
                                this.openNews.bind(this, news)
                              )}

                          {news.url && (
                            <a
                              className="news-item__link"
                              href={news.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {news.domain}
                            </a>
                          )}
                        </div>
                      ))
                    }
                  </PdfPreview.Consumer>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  isPdf(url) {
    return url.search(/.pdf$/) !== -1
  }

  renderData(news, open) {
    return (
      <div className="news-item__data" onClick={open}>
        <div
          className="news-item__image"
          style={{
            backgroundImage: `url('${news.leadImageUrl || placeholderImg}')`,
          }}
        />
        <div className="news-item__info">
          <div className="news-item__name">{news.title}</div>
          <div className="news-item__desc">{news.excerpt}</div>
        </div>
      </div>
    )
  }

  renderPopup() {
    const { news } = this.state
    if (!news) return null
    if (this.isPdf(news.url)) return null

    return (
      <div className="preview-box preview-box--small preview-box--loaded main-layout__preview-box">
        <div className="preview-box__overlay" />
        <div
          ref={this.previewContainerRef}
          className="preview-box__container"
          onClick={this.closeNews}
        >
          <div className="preview-box__actions">
            <span
              ref={this.previewCloseRef}
              className="preview-box__action preview-box__action--close"
              onClick={this.closeNews}
            />
            {news.url && (
              <a
                className="preview-box__action preview-box__action--open"
                href={news.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
              </a>
            )}
          </div>
          <div className="preview-box__content">
            <div className="news-preview preview-box__card">
              <div className="news-preview__title">{news.title}</div>
              <div
                className="news-preview__container"
                dangerouslySetInnerHTML={{ __html: news.content }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewsCompilations
