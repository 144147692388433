import { createSelector } from 'reselect'
import { List } from 'immutable'
import { isHiddenInInvoice } from 'constructors/modules/option-visibility'

export default {
  iframe: {
    general: 'mts/vpbx/v1',
  },
  invoice: {
    totals: {
      hideAnnually: true,
    },
    includeStageTitles: true,
    combineMonthlyAndOnceOptions: true,
    customHasBlocks: state => true,
    customGetBlocks: createSelector(
      state => state,
      state => state.getIn(['currentProposal', 'values', 'products']),
      (state, productIds) =>
        productIds.map(productId => getBlock(state, productId))
    ),
  },
  beforeSave: (proposal, state) => {
    if (proposal.constructorCode !== 'complete-solutions') return proposal

    const industryId = 'complete-solutions:complete-solutions:industry'
    const businessSizeId = 'complete-solutions:complete-solutions:business-size'
    proposal.previewConfig.variant = [
      getNestedOption(state, industryId)
        .get('id')
        .split(':')
        .slice(-1)[0],
      getNestedOption(state, businessSizeId)
        .get('id')
        .split(':')
        .slice(-1)[0],
    ].join('-')

    proposal.productNames = [
      [
        getNestedOption(state, industryId).get('name'),
        getNestedOption(state, businessSizeId).get('name'),
      ]
        .filter(n => n)
        .join(', '),
    ]

    proposal.previewConfig.rigid = true
    proposal.values.products = ['complete-solutions:complete-solutions']

    return proposal
  },
}

const getBlock = createSelector(
  state => state,
  (state, productId) =>
    state.getIn(['currentProposal', 'products', 'byId', productId]),
  (state, product) => ({
    name: product.get('name'),
    hasBlocks: true,
    blocks: getSubBlocks(state, product.get('id')),
  })
)

const getSubBlocks = createSelector(
  state => state,
  state => state.getIn(['currentProposal', 'stages']),
  (state, stages) =>
    stages
      .filter(stage => stage.get('name') !== 'Клиент')
      .map(stage => {
        const optionIds = getStageOptions(stage)
        return {
          name: stage.get('name'),
          hasOptions: isStageHasOptions(state, optionIds),
          optionIds,
        }
      })
)

const getStageOptions = stage => {
  let res = List()
  if (stage.get('optionIds')) res = res.concat(stage.get('optionIds'))
  if (stage.get('optionId')) res = res.concat(List(stage.get('optionId')))
  if (stage.get('blocks'))
    res = res.concat(
      stage
        .get('blocks')
        .reduce(
          (acc, block) =>
            acc.concat(block.get('optionIds') || block.get('optionId')),
          List()
        )
    )
  return res
}

const isStageHasOptions = (state, optionIds) => {
  return !!optionIds.find(optionId => {
    const option = getNestedOption(state, optionId)
    if (isHiddenInInvoice(option.get('visibility'))) return false
    return getOptionValue(state, option.get('id'))
  })
}

const getOption = (state, optionId) =>
  state.getIn(['currentProposal', 'options', 'byId', optionId])

const getNestedOption = createSelector(
  state => state,
  getOption,
  (state, option) => {
    if (option.get('inputType') !== 'radio') return option

    const value = getOptionValue(state, option.get('id'))
    return getOption(state, option.getIn(['values', value]))
  }
)

const getOptionValue = (state, optionId) =>
  state.getIn(['currentProposal', 'values', 'options', optionId]) || 0
