import { connect } from 'react-redux'
import { sendResetInstructions } from 'main/actions/reset-instructions'
import ResetInstructionsComponent from 'main/components/authorization/reset-instructions.js'

const mapStateToProps = state => ({
  isLoading: state.getIn(['resetInstructions', 'isLoading']),
  error: state.getIn(['resetInstructions', 'error']),
  success: state.getIn(['resetInstructions', 'success']),
})

const mapDispatchToProps = dispatch => ({
  onSubmit: email => {
    dispatch(sendResetInstructions(email))
  },
})

const ResetInstructions = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetInstructionsComponent)

export default ResetInstructions
