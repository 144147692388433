import { connect } from 'react-redux'
import { getOption } from 'constructors/ducks/options/selectors'
import { getOptionValue } from 'constructors/ducks/values/selectors'
import { changeOptionValue } from 'constructors/ducks/values/actions'
import SelectOneOption from './SelectOneOption'
import toJS from 'common/HOC/to-js'

const mapStateToProps = (state, ownProps) => ({
  title: ownProps.stage.optionTitle,
  option: getOption(state, ownProps.stage.optionId),
  value: getOptionValue(state, ownProps.stage.optionId),
})

const mapDispatchToProps = dispatch => ({
  onChange: (optionId, value) =>
    dispatch(
      changeOptionValue({
        optionId,
        value,
      })
    ),
})

const SelectOneOptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(SelectOneOption))

export default SelectOneOptionContainer
