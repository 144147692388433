import React from 'react'
import Mandatory from './Mandatory'
import Unknown from './Unknown'
import Blank from './Blank'
import Radio from './RadioContainer'
import Counter from './Counter'
import Delimiter from './Delimiter'
import Checkbox from './Checkbox'
import NumberOption from './Number'
import { getVisibilityAt } from 'constructors/modules/option-visibility'

const Option = props => {
  const { option, isHiddenBySpoiler } = props
  if (isHiddenBySpoiler) return true
  if (getVisibilityAt(option.visibility, props.visibilityPosition)) return null

  const Component = getComponent(option)
  return <Component {...props} />
}

const getComponent = option => {
  switch (option.inputType) {
    case 'mandatory':
      return Mandatory
    case 'radio':
      return Radio
    case 'counter':
      return Counter
    case 'delimiter':
      return Delimiter
    case 'checkbox':
      return Checkbox
    case 'blank':
      return Blank
    case 'number':
      return NumberOption
    default:
      return Unknown
  }
}

export default Option
