import { List, Map } from 'immutable'
import { createSelector } from 'reselect'
import {
  getProductEquipment,
  getProductServices,
  getProductOptions,
  getProductOptionIds,
} from 'constructors/ducks/products/selectors'
import { getOption } from 'constructors/ducks/options/selectors'
import { getProduct } from 'constructors/ducks/products/selectors'
import {
  isHiddenInInvoice,
  isHiddenInConstructor,
} from 'constructors/modules/option-visibility'
import { getModel } from 'constructors/ducks/model/selectors'
import { getCurrentStage } from 'constructors/ducks/stages/selectors'
import config from 'entry-specific/config'

export const getSelectedProductIdByPosition = (state, position) =>
  state.getIn(['currentProposal', 'values', 'products', position])
export const getFirstSelectedProduct = state =>
  state.getIn(['currentProposal', 'values', 'products', '0'])
export const getSelectedProductsIds = state =>
  state.getIn(['currentProposal', 'values', 'products'])
export const getFirstSelectedProductId = state =>
  state.getIn(['currentProposal', 'values', 'products', 0])

export const getProductPosition = (state, productId) => {
  return getSelectedProductsIds(state).indexOf(productId)
}

export const isProductSelected = (state, productId) => {
  return getSelectedProductsIds(state).includes(productId)
}

export const getProductIdsWithoutSelectedOnPosition = (
  state,
  productIds,
  position
) => {
  const selectedProductId = getSelectedProductsIds(state).get(position)
  return productIds.filter(productId => productId !== selectedProductId)
}

export const getFirstProductOptionIds = createSelector(
  state => state,
  getFirstSelectedProduct,
  (state, productId) => {
    const product = getProduct(state, productId)
    if (!product) return List()
    return product.get('options')
  }
)

export const getOptionValue = (state, id) =>
  state.getIn(['currentProposal', 'values', 'options', id]) || 0
export const getAllOptionValues = state =>
  state.getIn(['currentProposal', 'values', 'options'])
export const getAllValues = state => state.getIn(['currentProposal', 'values'])

export const getNestedOption = createSelector(
  state => state,
  getOption,
  (state, option) => {
    if (option.get('inputType') !== 'radio') return option

    const value = getOptionValue(state, option.get('id'))
    return getOption(state, option.getIn(['values', value]))
  }
)

export const getRadioValueId = createSelector(
  state => state,
  getOption,
  (state, option) => {
    const value = getOptionValue(state, option.get('id'))
    return option.getIn(['values', value])
  }
)

export const isAnyProductVisible = createSelector(
  state => state,
  getSelectedProductsIds,
  getCurrentStage,
  (state, productIds, currentStage) => {
    if (currentStage.get('type') === 'selectBranch') return false
    return productIds.some(productId => isProductVisible(state, productId))
  }
)

export const isProductVisible = createSelector(
  state => state,
  getProductOptions,
  (state, options) => {
    return isAnyOptionVisible(state, options)
  }
)

export const isProductServicesVisible = createSelector(
  state => state,
  getProductServices,
  (state, options) => {
    return isAnyOptionVisible(state, options)
  }
)

export const isProductEquipmentVisible = createSelector(
  state => state,
  getProductEquipment,
  (state, options) => {
    return isAnyOptionVisible(state, options)
  }
)

const isAnyOptionVisible = (state, options) => {
  return !!options
    .map(option => {
      if (option.get('inputType') === 'radio') {
        option = getNestedOption(state, option.get('id'))
      }
      if (isHiddenInInvoice(option.get('visibility'))) return 0

      return getOptionValue(state, option.get('id'))
    })
    .filter(value => value > 0).size
}

export const isProductHasAnySelectedOptions = createSelector(
  state => state,
  getProductOptionIds,
  (state, optionIds) =>
    optionIds.some(optionId => isOptionSelectedInConstructor(state, optionId))
)

const isOptionSelectedInConstructor = createSelector(
  state => state,
  getOption,
  (state, option) => {
    if (option.get('inputType') === 'radio') {
      option = getNestedOption(state, option.get('id'))
    }
    if (isHiddenInConstructor(option.get('visibility'))) return false

    return !!getOptionValue(state, option.get('id'))
  }
)

// Нельзя считать просто по списку опций, так как там содержатся опции radio
// поэтому нужно проходиться по всем продуктам и считать только их опции
// Сумма считается с НДС см. priceWithVat
export const getTotalOncePrice = createSelector(
  state => state,
  getSelectedProductsIds,
  (state, products) =>
    products.reduce(
      (count, productId) => count + getProductPrice('once')(state, productId),
      0
    )
)

export const getTotalMonthlyPrice = createSelector(
  state => state,
  getSelectedProductsIds,
  (state, products) =>
    products.reduce(
      (count, productId) =>
        count + getProductPrice('monthly')(state, productId),
      0
    )
)

export const getTotalAnnuallyPrice = createSelector(
  state => state,
  getSelectedProductsIds,
  (state, products) =>
    products.reduce(
      (count, productId) =>
        count + getProductPrice('annually')(state, productId),
      0
    )
)

const getProductPrice = type =>
  createSelector(state => state, getProduct, (state, product) =>
    product
      .get('options')
      .reduce(
        (count, optionId) => count + getOptionPrice(type)(state, optionId),
        0
      )
  )

const getOptionPrice = type =>
  createSelector(state => state, getNestedOption, (state, option) => {
    if (option.get('inputType') === 'delimiter') return 0
    if (isHiddenInInvoice(option.get('visibility'))) return 0

    const price = option.getIn(['price', type])
    if (!price) return 0

    const value = getOptionValue(state, option.get('id'))
    if (value === 0) return 0
    return priceWithVat(price) * value
  })

const priceWithVat = price => {
  if (price.get('vat')) return price.get('value')
  return price.get('value') * 1.18
}

export const getPackageName = createSelector(
  state => state,
  getModel,
  (state, model) => {
    // TODO: entry зависимая вещь
    if (['plus', 'plus2'].indexOf(model.get('constructorCode')) !== -1)
      return 'Быть в плюсе'

    return getSelectedProductsIds(state)
      .map(productId => getProduct(state, productId).get('name'))
      .join(', ')
  }
)

export const getAdditionalInfo = state =>
  Map({
    tariffs: getAdditionalInfoTariffs(state),
  })

const getAdditionalInfoTariffs = createSelector(
  state => state,
  getModel,
  (state, model) => {
    if (model.get('constructorCode') !== 'plus') return List()

    // TODO: entry зависимо
    return getOption(state, 'plus:internet:tariff')
      .get('values')
      .map(optionId => {
        const variant = getOption(state, optionId)

        return Map({
          speed: variant.getIn(['notes', 0, 'value']),
          price: variant.getIn(['price', 'monthly', 'value']),
        })
      })
  }
)

export const getSelectedProductNames = createSelector(
  state => state,
  getSelectedProductsIds,
  getModel,
  (state, productIds, model) => {
    if (!productIds.size) return ''

    const getProductName = (productId, index) => {
      if (model.get('constructorCode') === 'plus' && index === 0) {
        const productName = getProduct(state, productId).get('shortName')
        const tariffName = getNestedOption(
          state,
          getProductOptionIds(state, productId).get(0)
        ).get('shortName')
        return `${productName} ${tariffName}`
      }
      return getProduct(state, productId).get('shortName')
    }

    return productIds.map((productId, index) =>
      getProductName(productId, index)
    )
  }
)

export const hasInvoiceBlocks = config(
  'constructor.invoice.customHasBlocks',
  isAnyProductVisible
)

export const getInvoiceBlocks = config(
  'constructor.invoice.customGetBlocks',
  createSelector(state => state, getSelectedProductsIds, (state, productIds) =>
    productIds.map(productId => {
      const product = getProduct(state, productId)
      return Map({
        name: product.get('name'),
        caption: product.get('vat') ? '(с учетом НДС)' : '(без учета НДС)',
        hasBlocks: isProductVisible(state, productId),
        blocks: getInvoiceSubBlocks(state, productId),
      })
    })
  )
)

export const getInvoiceSubBlocks = createSelector(
  isProductEquipmentVisible,
  isProductServicesVisible,
  getProductEquipment,
  getProductServices,
  (equipmentHasValues, servicesHasValues, equipment, services) =>
    List([
      Map({
        name: 'Оборудование',
        hasOptions: equipmentHasValues,
        optionIds: equipment.map(o => o.get('id')),
      }),
      Map({
        name: 'Услуги',
        hasOptions: servicesHasValues,
        optionIds: services.map(o => o.get('id')),
      }),
    ])
)
