import React from 'react'
import cn from 'classnames'

const DropdownItem = ({ children, onClick, active, anyContent, type = '' }) => (
  <li
    className={cn('dropdown__item', {
      'dropdown__item--active': active,
      'dropdown__item--any-content': anyContent,
      [`dropdown__item--${type}`]: type,
    })}
    onClick={onClick}
  >
    {children}
  </li>
)

export default DropdownItem
