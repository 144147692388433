import api from 'common/modules/api'
import currentUser from 'common/modules/current-user'
import throttle from 'common/modules/throttle'

const TRACK_INTERVAL = 60 * 1000
const SLEEP_TIMEOUT = 60 * 1000
const ALLOWED_PATHS = [
  '/sales-scripts',
  '/commercial-proposals',
  '/products',
  '/adaptation',
  '/news',
]
const POSSIBLE_USER_EVENTS = [
  'mousedown',
  'mousemove',
  'touchstart',
  'keydown',
  'scroll',
]

export const startApplicationTimeTracking = () => {
  if (localStorage.getItem('disguiseAs')) {
    if (!window.confirm('Включена маскировка. Продолжить?')) {
      localStorage.removeItem('disguiseAs')
      document.location.reload()
    }

    return
  }

  let timeTrackingInterval = null

  userActionsObserver({
    onAction: () => {
      timeTrackingInterval = setInterval(trackTime, TRACK_INTERVAL)
    },
    onSleep: () => {
      clearInterval(timeTrackingInterval)
    },
    sleepAfter: SLEEP_TIMEOUT,
  })
}

const userActionsObserver = ({
  onAction,
  onSleep,
  sleepAfter = SLEEP_TIMEOUT,
}) => {
  let sleepTimeout = null
  let isSleep = true

  const actionsListener = () => {
    if (isSleep) onAction()
    isSleep = false
    clearTimeout(sleepTimeout)
    sleepTimeout = setTimeout(beforeSleep, sleepAfter)
  }

  const beforeSleep = () => {
    isSleep = true
    clearTimeout(sleepTimeout)
    onSleep()
  }

  const throttledActionsListener = throttle(actionsListener, 1000)

  for (let i = 0; i < POSSIBLE_USER_EVENTS.length; i++) {
    document.addEventListener(POSSIBLE_USER_EVENTS[i], throttledActionsListener)
  }
}

const trackTime = () => {
  if (!currentUser.isLoggedIn()) return

  ALLOWED_PATHS.forEach(allowedPath => {
    if (window.location.pathname.indexOf(allowedPath) === -1) return

    api.fetch(api.routes.track.user(), {
      body: {
        name: 'user_working',
        params: {
          section_name: allowedPath.slice(1, allowedPath.length),
          duration: TRACK_INTERVAL,
        },
      },
    })
  })
}
