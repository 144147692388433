import React from 'react'

// import SimpleFormat from 'main/components/common/simple-format'
import ActionButtons from './action-buttons'
import Actions from './actions'
// import Image from './image'
import { getExtClassName } from './helpers'

const RegularDocs = ({ section }) => {
  return (
    <div className="card card--lite">
      {section.items &&
        section.items.map((item, i) => (
          <div key={i} className="doc-line card__item">
            <div className={getExtClassName(item.fileType)}>
              {item.fileType}
            </div>
            <Actions item={item}>
              {({ open, downloadUrl }) => {
                if (open)
                  return (
                    <div className="doc-line__name" onClick={open}>
                      {item.title}
                    </div>
                  )
                return (
                  <a
                    className="doc-line__name"
                    href={window.location.origin+downloadUrl}
                    rel="noopener noreferrer"
                    download
                  >
                    {item.title}
                  </a>
                )
              }}
            </Actions>
            <div className="doc-line__size">{item.size}</div>
            <ActionButtons className="doc-line__actions" item={item} />
          </div>
        ))}
    </div>
  )
}

export default RegularDocs
