import api from 'common/modules/api'

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const REQUEST_PRODUCTS_SUCCESS = 'REQUEST_PRODUCTS_SUCCESS'
export const REQUEST_PRODUCTS_ERROR = 'REQUEST_PRODUCTS_ERROR'

export const requestProducts = () => {
  return dispatch => {
    dispatch(requestProductsAction())

    api
      .fetch(api.routes.products.index())
      .then(response => {
        dispatch(requestProductsSuccessAction(response.json))
        return response.json
      })
      .catch(() => dispatch(requestProductsErrorAction()))
  }
}

const requestProductsAction = () => ({
  type: REQUEST_PRODUCTS,
})

const requestProductsSuccessAction = products => ({
  type: REQUEST_PRODUCTS_SUCCESS,
  products,
})

const requestProductsErrorAction = () => ({
  type: REQUEST_PRODUCTS_ERROR,
})
