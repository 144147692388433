import products from './products'
import stages from './stages'

const id = 'complete-solutions'

export default {
  id,
  name: 'Комплексные решения',
  products,
  stages,
}
