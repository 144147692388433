import { connect } from 'react-redux'

import { getCalculations } from 'main/selectors/sales-scripts/calculations'
import { setCalculations } from 'main/actions/sales-scripts/calculations'

import Choice from 'main/components/sales-scripts/card/item-types/choice'

const mapStateToProps = (state, ownProps) => ({
  currentValue: getCalculations(state).get(ownProps.content.calculationName),
})

const mapDispatchToProps = dispatch => ({
  onChange: (inputName, value) => {
    dispatch(setCalculations({ [inputName]: value }))
  },
})

const ChoiceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Choice)

export default ChoiceContainer
