import { Map } from 'immutable'

import {
  SET_AUTOFILL,
  RESET_AUTOFILL,
} from 'main/actions/sales-scripts/autofill'

const initialState = {}

const autofill = (state = Map(initialState), action) => {
  switch (action.type) {
    case SET_AUTOFILL:
      return state.setIn(action.path, action.value)
    case RESET_AUTOFILL:
      return Map(initialState)

    default:
      return state
  }
}

export default autofill
