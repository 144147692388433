import { itemTypes } from './helpers'
import generateScript from './generate-script'

const cards = [
  {
    id: '2',
    name: 'Предложение услуги',
    subCards: [
      {
        id: '2.1',
        name: 'Статистика по звонкам',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. Часто случается, что клиент не может дозвониться в компанию (например, линия занята или менеджер отошел от телефона и т.д.). 
              2. Как вы оцениваете упущенную выгоду (например, потери от непринятых звонков и потерянных заказов)?

              Мы можем настроить для вас статистику по всем входящим звонкам (принятым и непринятым) - в разрезе до каждого сотрудника`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Экономия ФОТ: за счет оптимизации работы - сотрудники начинают работать более эффективно.
                Увеличение доходов: за счет оптимизации кол-ва исполнения заказов. Чем меньше потерянных звонков - тем выше лояльность дозвонившихся клиентов и выше продажи
                Мотивация: отчеты по звонкам можно использовать в мотивационных программах используя статистику как элемент управления сотрудником.`,
              },
            ],
          },
        ],
      },
      {
        id: '2.2',
        name: 'Защита клиентской базы',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. Уверены ли вы, что сотрудники не проводят двойные сделки или не "уводят" сделки из компании?
              2. Вы сталкивались со случаями, когда сотрудник увольняется из компании и забирает клиентскую базу? Или закрывает сделку  за спиной работодателя так, что выручка в компанию не поступает? Вы оценивали, сколько дохода теряете от таких сделок?

              При совершении исходящего звонка с телефона сотрудника производится подстановка единого номера компании. Клиент не видит фактический номер сотрудника и не может звонить ему напрямую.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение выручки:  за счет сохранения всех клиентов (партнеров) в компании.
                Сокращение убытков: за счет борьбы с недобросовестными сотрудниками, которые могут проводить сделки "за спиной" работодателя.
                Повышение лояльности клиентов, имиджа компании.`,
              },
            ],
          },
        ],
      },
      {
        id: '2.3',
        name: 'Удаленное управление офисами',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `Вам приходится проводить удаленные совещания/собрания?

              Возможности многоканальных номеров от МТС позволяют быстро собирать на аудио-конференции неограниченное кол-во сотрудников. Также можно создавать множество переговорных аудио-комнат  и проводить  несколько совещаний одновременно.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение эффективности бизнеса: оперативное получение информации даже от удаленных офисов и представительств. 
                Сокращение расходов: на командировки`,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default generateScript({ cards })
