import { connect } from 'react-redux'
import { getCurrentStage } from 'constructors/ducks/stages/selectors'
import CurrentStage from './CurrentStage'
import toJS from 'common/HOC/to-js'

const mapStateToProps = state => ({
  stage: getCurrentStage(state),
})

const CurrentStageContainer = connect(mapStateToProps)(toJS(CurrentStage))

export default CurrentStageContainer
