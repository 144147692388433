import React from 'react'

const TextInput = ({ placeholder, value, onChange }) => {
  return (
    <input
      type="text"
      className="input"
      placeholder={placeholder}
      value={value || ''}
      onChange={onChange}
    />
  )
}

export default TextInput
