import { connect } from 'react-redux'
import {
  getFailedQuestionsByDayId,
  getIsFirstAttemptByDayId,
} from 'main-v2/ducks/failed-questions/selectors'
import { fetchFailedQuestions } from 'main-v2/ducks/failed-questions/actions'
import AdaptationItem from './AdaptationItem'
import toJS from 'common/HOC/to-js'

const mapStateToProps = (state, ownProps) => {
  const dayId = ownProps.day.id
  return {
    failedQuestions: getFailedQuestionsByDayId(state, dayId),
    isFirstAttempt: getIsFirstAttemptByDayId(state, dayId),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchFailedQuestions: () => dispatch(fetchFailedQuestions(ownProps.day.id)),
})

const AdaptationItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(AdaptationItem))

export default AdaptationItemContainer
