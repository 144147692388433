import { fromJS } from 'immutable'

import {
  SET_CALCULATIONS,
  RESET_CALCULATIONS,
} from 'main/actions/sales-scripts/calculations'

const initialState = fromJS({})

const calculations = (state = initialState, action) => {
  switch (action.type) {
    case SET_CALCULATIONS:
      return state.merge(action.calculations)
    case RESET_CALCULATIONS:
      return fromJS({})

    default:
      return state
  }
}

export default calculations
