export const getPriceListFromCSV = csv => {
  const rows = csvToArray(csv)
  const headers = rows[0]
  const result = {}

  for (let rowIndex = 1; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex]
    result[row[0]] = {}

    for (let columnIndex = 1; columnIndex < headers.length; columnIndex++) {
      result[row[0]][headers[columnIndex]] =
        parseFloat(row[columnIndex]) || row[columnIndex]
    }
  }

  return result
}

export const parseCsvWithHeaders = string => {
  const rows = csvToArray(string)
  const headers = rows[0]
  const result = []

  for (let rowIndex = 1; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex]
    const rowWithHeaders = {}

    for (let columnIndex = 0; columnIndex < headers.length; columnIndex++) {
      rowWithHeaders[headers[columnIndex]] = row[columnIndex]
    }

    result.push(rowWithHeaders)
  }

  return result
}

function csvToArray(text) {
  let p = '',
    row = [''],
    result = [row],
    i = 0,
    r = 0,
    s = !0,
    l

  for (var j = 0; j < text.length; j++) {
    l = text.charAt(j)
    if ('"' === l) {
      if (s && l === p) row[i] += l
      s = !s
    } else if (',' === l && s) l = row[++i] = ''
    else if ('\n' === l && s) {
      if ('\r' === p) row[i] = row[i].slice(0, -1)
      row = result[++r] = [(l = '')]
      i = 0
    } else row[i] += l
    p = l
  }
  return result
}

export const mapValue = (map, value) => {
  map = keysToLowerCase(map)
  const newValue = map[value.toString().toLowerCase()]
  if (newValue === undefined) {
    if (map['default'] === undefined) {
      console.error(
        `can't find value for map: ${JSON.stringify(map)} value: ${value}`
      )
    }
    return map['default']
  }

  return newValue
}

const keysToLowerCase = obj => {
  var key,
    keys = Object.keys(obj)
  var n = keys.length
  var newObj = {}
  while (n--) {
    key = keys[n]
    newObj[key.toLowerCase()] = obj[key]
  }
  return newObj
}
