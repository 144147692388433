import products from './products'

const vpbx = products[0]

export default [
  {
    name: 'Тариф',
    type: 'blocks',
    productId: vpbx.id,
    blocks: [
      {
        type: 'smallRadioButton',
        productId: vpbx.id,
        title: 'Выберите тип номера',
        optionId: 'Номер',
        optionIds: ['Номер - ежемесячная плата', 'Номер - подключение'],
      },
      {
        type: 'options',
        title: 'Выберите категорию номера',
        productId: vpbx.id,
        optionIds: ['Красивый номер'],
      },
      {
        type: 'selectOneOption',
        productId: vpbx.id,
        optionTitle: 'Выберите тариф',
        optionId: 'tariff',
      },
    ],
  },
  {
    name: 'Опции',
    type: 'blocks',
    blocks: [
      {
        type: 'options',
        title: '',
        productId: vpbx.id,
        optionIds: vpbx.options
          .slice(
            vpbx.options.findIndex(
              o => o.id === 'Дополнительные номера и линии'
            ),
            vpbx.options.findIndex(o => o.id === 'Пакеты минут')
          )
          .map(o => o.id),
      },
    ],
  },
  {
    name: 'Пакеты минут',
    type: 'blocks',
    blocks: [
      {
        type: 'options',
        title: 'Выберите дополнительные пакеты минут',
        productId: vpbx.id,
        optionIds: vpbx.options
          .slice(vpbx.options.findIndex(o => o.id === 'Пакеты минут'))
          .map(o => o.id),
      },
    ],
  },
  {
    name: 'Клиент',
    type: 'client',
  },
]
