import React from 'react'

import { speech } from 'entry-specific/apps/manager/scripts/helpers'
import { autofillText } from 'main/modules/autofill-helper'

const Speech = ({ content, autofill }) => {
  if (!content.value) return null

  let className = 'script__speech'
  if (content.value.who === speech.K) {
    className += ' script__speech--client'
  }

  return (
    <div className={className}>
      <div className="script__man">{content.value.who}</div>
      <div className="script__words">
        {autofillText(content.value.text, autofill)
          .split('\n')
          .map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
      </div>
    </div>
  )
}

export default Speech
