import { combineReducers } from 'redux-immutable'

import model from './ducks/model/reducers'
import previewConfigOptions from './ducks/preview-config-options/reducers'
import values from './ducks/values/reducers'
import constructor from './ducks/constructor/reducers'
import products from './ducks/products/reducers'
import options from './ducks/options/reducers'
import client from './ducks/client/reducers'
import stats from './ducks/stats/reducers'
import stages from './ducks/stages/reducers'
import ui from './ducks/ui/reducers'

const reducers = combineReducers({
  model,
  previewConfigOptions,
  values,
  constructor,
  products,
  options,
  stages,
  client,
  stats,
  ui,
})

export default reducers
