import React from 'react'
import { connect } from 'react-redux'
import toJS from 'common/HOC/to-js'
import { getMidnightDate, getYearMonthDay } from 'common/modules/date-helper'

import Datepicker from 'common/components/Datepicker'
import NewButton from 'main-v2/components/proposals/NewButtonContainer'
import Dropdown from 'main/components/common/dropdown/dropdown'
import DropdownItem from 'main/components/common/dropdown/dropdown-item'
import DropdownDelimiter from 'main/components/common/dropdown/dropdown-delimiter'
import Search from 'main-v2/components/common/Search'

import {
  getFilter,
  getLoader,
  setLoader,
  setFilter,
  fetchProposals,
  filterStatusMapping,
  formatStatus,
  formatDate,
} from 'main-v2/ducks/proposals'
import debounce from 'common/modules/debounce'

const mapStateToProps = state => ({
  isLoading: getLoader(state, 'search'),
  filter: getFilter(state),
})

const mapDispatchToProps = dispatch => {
  const debouncedFetchProposals = debounce(
    () =>
      dispatch(fetchProposals())
        .then(() => dispatch(setLoader('search', false)))
        .catch(() => dispatch(setLoader('search', false))),
    1000
  )

  return {
    changeStatus: status => {
      dispatch(setFilter('decision', status))
      dispatch(setFilter('page', 1))
      dispatch(fetchProposals())
    },
    changeSearch: q => {
      dispatch(setFilter('q', q))
      dispatch(setFilter('page', 1))
      dispatch(setLoader('search', true))
      debouncedFetchProposals()
    },
    changeDate: date => {
      dispatch(setFilter('page', 1))
      dispatch(setFilter('date', getYearMonthDay(date)))
      dispatch(fetchProposals())
    },
  }
}

const Header = ({
  isLoading,
  filter,
  changeStatus,
  changeSearch,
  changeDate,
}) => {
  return (
    <div className="main-layout__title">
      <div className="main-layout__title-filters">
        <Dropdown
          className={'dropdown--filter'}
          text={formatStatus(filter.decision)}
        >
          <DropdownItem
            active={!filter.decision}
            onClick={() => changeStatus(null)}
          >
            {formatStatus()}
          </DropdownItem>
          {Object.entries(filterStatusMapping).map(([decision, name]) => (
            <DropdownItem
              key={decision}
              active={filter.decision === decision}
              onClick={() => changeStatus(decision)}
            >
              {name}
            </DropdownItem>
          ))}
        </Dropdown>

        <Dropdown className={'dropdown--filter'} text={formatDate(filter.date)}>
          <DropdownItem anyContent>
            <Datepicker
              date={filter.date ? new Date(filter.date) : null}
              onChange={changeDate}
              maxDate={getMidnightDate(new Date())}
            />
          </DropdownItem>
          <DropdownDelimiter />
          <DropdownItem
            onClick={() => (filter.date ? changeDate(null) : null)}
            active={!filter.date}
          >
            {formatDate()}
          </DropdownItem>
        </Dropdown>

        <Search
          value={filter.q || ''}
          isLoading={isLoading}
          onChange={e => changeSearch(e.target.value)}
          onReset={() => changeSearch(null)}
        />
      </div>
      <div className="main-layout__title-actions">
        <NewButton />
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Header))
