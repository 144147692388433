import { createSelector } from 'reselect'

export const getAllProducts = state => state.getIn(['productsInfo', 'data'])
export const getIsLoading = state => state.getIn(['productsInfo', 'isLoading'])

export const getProduct = createSelector(
  getAllProducts,
  (state, productId) => productId,
  (products, productId) => {
    return products.find(p => p.get('code') === productId)
  }
)

export const getProductSection = createSelector(
  getAllProducts,
  getProduct,
  (state, productId, sectionId) => sectionId,
  (products, product, sectionId) => {
    return product.get('sections').find(p => p.get('code') === sectionId)
  }
)
