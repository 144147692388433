import React from 'react'
import { Link } from 'react-router-dom'
import appRoutes from 'main/constants/app-routes'

const ProductsListItem = ({ product }) => {
  return (
    <li className="products__item">
      <div className={`products__name products__name--${product.code}`}>
        {getTitle(product.title)}
      </div>
      <ul className="products__links">
        {product.sections &&
          product.sections.map(section => (
            <li key={section.code} className="products__link-item">
              <Link
                to={appRoutes.products.section(product.code, section.code)}
                className="products__link"
              >
                {section.title}
              </Link>
            </li>
          ))}
      </ul>
      {product.actions && product.actions.length && (
        <ul className="products__actions">
          {product.actions.map(action => (
            <li key={action.title} className="products__actions-item">
              <a
                href={action.link}
                target="_blank"
                rel="noopener noreferrer"
                className="products__action"
              >
                {action.title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}

const getTitle = title => {
  if (title === 'Кибербезопасность') return 'Кибер-безопасность'

  if (title === 'Видеонаблюдение') return 'Видео-наблюдение'

  return title
}

export default ProductsListItem
