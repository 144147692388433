import React, { Fragment } from 'react'
import MaterialActions from './MaterialActions'

const Material = ({ material }) => {
  return (
    <li className="adapt-day__item">
      <div className="doc-line">
        <div className={`doc-line__ext ${getExtClassName(material)}`}>
          {isExternalUrl(material.downloadUrl) ? 'LINK' : material.ext}
        </div>

        <MaterialActions item={material}>
          {({ open, downloadUrl }) => (
            <Fragment>
              {open && (
                <a className="doc-line__name" onClick={open}>
                  {material.name}
                </a>
              )}
              {!open && (
                <a
                  className="doc-line__name"
                  href={downloadUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {isExternalUrl(material.downloadUrl) ? <span className="doc-line__href-placeholer">Недоступно в мобильной версии</span> : null}
                  <span className="doc-line__href-text">{material.name}</span>

                </a>
              )}
            </Fragment>
          )}
        </MaterialActions>

        <div className="doc-line__size">{material.size}</div>

        <MaterialActions item={material}>
          {({ open, downloadUrl }) => (
            <div className="doc-line__actions">
              {open && (
                <div className="action doc-line__action" onClick={open}>
                  <div className="action__button action__button--preview" />
                </div>
              )}
              {downloadUrl && (
                <a
                  className="action doc-line__action"
                  href={downloadUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={`action__button ${getDownloadClass(
                      downloadUrl
                    )}`}
                  />
                </a>
              )}
            </div>
          )}
        </MaterialActions>
      </div>
    </li>
  )
}

export const getExtClassName = ({ ext, downloadUrl }) => {
  let className = 'doc-line__ext'

  if (['pdf', 'ppt', 'pptx', 'pptm'].indexOf(ext.toLowerCase()) !== -1)
    className += '--media'
  if (isExternalUrl(downloadUrl)) className += '--link'

  return className
}

export const getDownloadClass = url => {
  return isExternalUrl(url)
    ? 'action__button--link'
    : 'action__button--download'
}

const isExternalUrl = url => {
  var tempLink = document.createElement('a')
  tempLink.href = url
  return tempLink.hostname !== window.location.hostname
}

export default Material
