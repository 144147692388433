import { connect } from 'react-redux'
import Layout from 'main/components/layout'
import currentUser from 'common/modules/current-user'

const mapStateToProps = state => ({
  name: currentUser.name,
})

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(currentUser.logout())
  },
  isLoggedIn: () => {
    return currentUser.isLoggedIn()
  },
})

const LayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)

export default LayoutContainer
