import { connect } from 'react-redux'
import { getProductSection } from 'main/selectors/products'

import Sections from 'main/components/products/sections'

const mapStateToProps = (state, ownProps) => {
  const productId = ownProps.match.params.id
  const sectionId = ownProps.match.params.sectionId

  return {
    section: getProductSection(state, productId, sectionId).toJS(),
  }
}

export default connect(mapStateToProps)(Sections)
