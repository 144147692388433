import { formatPrice } from 'common/modules/price-helper'

const Price = ({ price, value = 1 }) => {
  if (!price) return '\u00A0'
  if (value === 0) value = 1

  if (price.monthly) {
    return `${formatPrice(price.monthly.value * value)} ${
      price.monthly.caption
    }`
  }

  if (price.once) {
    return `${formatPrice(price.once.value * value)} ${price.once.caption}`
  }

  if (price.annually) {
    return `${formatPrice(price.annually.value * value)} ${
      price.annually.caption
    }`
  }

  if (price.caption) {
    return price.caption
  }

  return '\u00A0'
}

export default Price
