import { itemTypes } from './helpers'

function generate({ cards = [], actionCardId = '2' }) {
  return [
    {
      id: '1',
      name: 'Вступление',
      subCards: [
        {
          id: '1.1',
          name: 'Установление контакта',
          content: [
            {
              type: itemTypes.text,
              value:
                'Стандартное приветствие в рамках вашей функциональной роли',
            },
          ],
        },
        {
          id: '1.2',
          name: 'Структура компании',
          content: [
            {
              type: itemTypes.text,
              value:
                'Для формирования персонализированного коммерческого предложения отметьте все пункты, что Вы знаете о клиенте. При необходимости задайте уточняющие вопросы.',
            },
            {
              type: itemTypes.speech,
              value: { text: 'Филиалы/представительства/холдинги' },
            },
            {
              type: itemTypes.choice,
              calculationName: 'Филиалы/представительства/холдинги',
              inputType: 'radio',
              name: '1',
              options: [
                {
                  label: 'Да',
                  value: 1,
                },
                {
                  label: 'Нет',
                  value: 0,
                },
              ],
            },
            {
              type: itemTypes.speech,
              value: { text: 'Партнеры/контрагенты' },
            },
            {
              type: itemTypes.choice,
              calculationName: 'Партнеры/контрагенты',
              inputType: 'radio',
              name: '2',
              options: [
                {
                  label: 'Да',
                  value: 1,
                },
                {
                  label: 'Нет',
                  value: 0,
                },
              ],
            },
            {
              type: itemTypes.speech,
              value: { text: 'Офисные сотрудники' },
            },
            {
              type: itemTypes.choice,
              calculationName: 'Офисные сотрудники',
              inputType: 'radio',
              name: '3',
              options: [
                {
                  label: 'Да',
                  value: 1,
                },
                {
                  label: 'Нет',
                  value: 0,
                },
              ],
            },
            {
              type: itemTypes.speech,
              value: {
                text:
                  'Разъездные сотрудники (продавцы, курьеры, сервисные инженеры, монтажники и т.д.)',
              },
            },
            {
              type: itemTypes.choice,
              calculationName: 'Разъездные сотрудники',
              inputType: 'radio',
              name: '4',
              options: [
                {
                  label: 'Да',
                  value: 1,
                },
                {
                  label: 'Нет',
                  value: 0,
                },
              ],
            },
            {
              type: itemTypes.speech,
              value: { text: 'Контактный центр/обслуживание клиентов' },
            },
            {
              type: itemTypes.choice,
              calculationName: 'Контактный центр/обслуживание клиентов',
              inputType: 'radio',
              name: '5',
              options: [
                {
                  label: 'Да',
                  value: 1,
                },
                {
                  label: 'Нет',
                  value: 0,
                },
              ],
            },
            {
              type: itemTypes.speech,
              value: { text: 'Отдел продаж/привлечение клиентов' },
            },
            {
              type: itemTypes.choice,
              calculationName: 'Отдел продаж/привлечение клиентов',
              inputType: 'radio',
              name: '6',
              options: [
                {
                  label: 'Да',
                  value: 1,
                },
                {
                  label: 'Нет',
                  value: 0,
                },
              ],
            },
            {
              type: itemTypes.speech,
              value: { text: 'Многоканальные номера от другого оператора' },
            },
            {
              type: itemTypes.choice,
              calculationName: 'Многоканальные номера от другого оператора',
              inputType: 'radio',
              name: '7',
              options: [
                {
                  label: 'Да',
                  value: 1,
                },
                {
                  label: 'Нет',
                  value: 0,
                },
              ],
            },
            {
              type: itemTypes.speech,
              value: { text: 'Реклама (билборды, перетяжки, СМИ)' },
            },
            {
              type: itemTypes.choice,
              calculationName: 'Реклама',
              inputType: 'radio',
              name: '8',
              options: [
                {
                  label: 'Да',
                  value: 1,
                },
                {
                  label: 'Нет',
                  value: 0,
                },
              ],
            },
            {
              type: itemTypes.calculatedBlock,
              calc: calculations => {
                const sum = Object.keys(calculations).reduce(
                  (acc, key) => acc + calculations[key],
                  0
                )

                return {
                  type: itemTypes.actions,
                  value: [
                    {
                      link_id: sum > 1 ? actionCardId : '3',
                      text: 'Продолжить',
                    },
                  ],
                }
              },
            },
          ],
        },
      ],
    },
    ...addExplanations(cards),
    {
      id: '3',
      name: 'Комплексное предложение',
      content: [
        {
          type: itemTypes.text,
          value: `Вероятность интереса к ВАТС низкая.

Используйте комплексное КП и "карту переговоров"`,
        },
      ],
    },
  ]
}

const addExplanations = cards => {
  const card2 = cards.find(c => c.id === '2')
  card2.content = [
    {
      type: itemTypes.text,
      value: `Вероятность интереса к услуге ВАТС высокая. Список уточняющих вопросов для выявления потребностей см. в блоках 2.1-2.${
        card2.subCards.length
      }. Потребности сгруппированы по блокам, их возможно использовать в произвольном порядке.`,
    },
  ]
  return cards
}

export default generate
