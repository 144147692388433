import React, { Fragment, Component } from 'react'

class SingleQuestion extends Component {
  handleChange = e => {
    const { question, onChange } = this.props
    onChange(question.id, 'variantIds', [e.target.value])
  }

  render() {
    const { question, answer = { variantIds: [] } } = this.props

    return (
      <Fragment>
        <div className="test-card__question">{question.text}</div>
        <ul className="test-card__answers">
          {question.variants &&
            question.variants.map(variant => (
              <li key={variant.id} className="test-card__item">
                <label className="test-card__answer">
                  <input
                    type="radio"
                    name={question.id}
                    value={variant.id}
                    className="test-card__input"
                    checked={variant.id === answer.variantIds[0]}
                    onChange={this.handleChange}
                  />
                  <div className="test-card__radio" />
                  <div className="test-card__label">{variant.text}</div>
                </label>
              </li>
            ))}
        </ul>
      </Fragment>
    )
  }
}

export default SingleQuestion
