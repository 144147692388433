import { itemTypes } from './helpers'
import generateScript from './generate-script'

const cards = [
  {
    id: '2',
    name: 'Предложение услуги',
    subCards: [
      {
        id: '2.1',
        name: 'Распределение вызовов',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. Как вы информируете клиентов об акциях вашей компании?
              2. Как собираете оратную связь по рекламным акциям?

              Виртуальная АТС поможет принять и правильно распределить внутри компании звонки, которые поступают в ответ на вашу рекламу (например, при раздаче листовок или размещении рекламных щитов на улицах города)
              Также мы можем настроить автоматический обзвон вашей базы -и система проинформирует ваших клиентов об акции без каких-либо усилий с вашей стороны.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение лояльности клиентов, имиджа компании.
                  Повышение выручки:   за счет быстрой обработки звонков горячих лидов (откликов на рекламу)
                  Снижение ФОТ: за счет обработки звонков меньшим количеством сотрудников и использовании автомтатических обзвонов`,
              },
            ],
          },
        ],
      },
      {
        id: '2.2',
        name: 'Оценка эффективноси',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `Как оцениваете эффективность (нужность, своевременность)  товара или рекламной акции?

              Мы можем настроить звонки, которые поступают как отклик на рекламу, внутри компании по заданной вами логике . Например, звонки по рекламной СМС рассылке- на один номер, а по печатным листовкам или рекламным растяжкам - на другой. Можно настроить по географическому признаку (где висит реклама), по типу товара, виду акции  и т.д. Вы всегда сможете легко оценить эффективность рекламного носителя или востребованность продукта.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение выручки: за счет детального анализа интереса клиентов к вашему продукту и быстрого его изменения при необходимости.
                Снижение операционных затрат: быстрая оценка эффективности рекламного носителя позволит переносить бюджет и работать только с эффективной рекламой`,
              },
            ],
          },
        ],
      },
      {
        id: '2.3',
        name: 'Статистика по звонкам',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. Как вы взаимодействуете с клиентами при запуске продукта?
              2. Каким образом анализируете,  сколько входящих вызовов приняли ваши сотрудники, а сколько не успели принять (абонент отключился не дождавшись ответа)?

              Мы можем настроить для вас статистику по всем входящим звонкам (принятым и непринятым) - в разрезе до каждого сотрудника`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Экономия ФОТ: за счет оптимизации работы - сотрудники начинают работать более эффективно. 
                Увеличение доходов: за счет быстрой обработки звонков горячих лидов (откликов на рекламу). Чем меньше потерянных звонков - тем выше лояльность дозвонившихся клиентов и выше продажи
                Эффективность: аналитические отчеты по звонкам помогут оценить выявить слабые места продаж (например, продукт хороший, но звонок не доходит до сотрудника или сотрудник не может правильно объяснить его преимущества)`,
              },
            ],
          },
        ],
      },
      {
        id: '2.4',
        name: 'Контроль качества обслуживания',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `Как вы контролируете,  насколько корректно в телефонном разговоре сотрудники рассказывают клиентам преимущества продукта или его важные особенности?

              Функция "Запись разговоров"  позволяет записывать ВСЕ входящие звонки.
              Функция "Контроль качества" позволяет записывать ВСЕ входящие и исходящие звонки.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение доходов и качества услуг: сотрудники разговаривают вежливо и корректно предоставляют информацию.
                Повышение лояльности клиентов и статуса компании: наличие записи разговоров свидетельствует, что компания заботится о клиентах.`,
              },
            ],
          },
        ],
      },
      {
        id: '2.5',
        name: 'Имидж и узнаваемость',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `Вас заботит имидж и узнаваемость вашей компании?

              Часто бывает, что компания имеет единый контактный номер, на который звонят клиенты. При этом обратная связь (когда сотрудник компании звонит клиенту) определяются рабочие номера сотрудников, на которые клиент может не дозвониться. 
              Виртуальная АТС - это единый номер, который связан с вашей компанией в восприятии клиента. 
              Даже при совершении исходящего звонка с телефона сотрудника производится подстановка единого номера компании. Сегодня многие пользуются приложениями (дубль гис, гугл дайлер, номеротека), которые  в момент входящего звонка определяют незнакомые номера,  сверяют их с базой общего телефонного справочника и подписывают имя владельца или название компании. Т.е. клиент сразу видит, кто ему звонит и может совершить ответный звонок.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение доходов и сокращение убытков: все коммуникации осуществляются с одного номера.
                Повышение имиджа компании: единый номер - это узнаваемость в клиентской среде.`,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default generateScript({ cards })
