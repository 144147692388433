import { connect } from 'react-redux'
import { getProductIdsWithoutSelectedOnPosition } from 'constructors/ducks/values/selectors'
import MultipleProducts from './MultipleProducts'
import toJS from 'common/HOC/to-js'

const mapStateToProps = (state, ownProps) => ({
  productIds: getProductIdsWithoutSelectedOnPosition(
    state,
    ownProps.stage.productIds,
    ownProps.stage.withoutPosition
  ),
})

const MultipleProductsContainer = connect(mapStateToProps)(
  toJS(MultipleProducts)
)

export default MultipleProductsContainer
