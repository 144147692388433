import { fromJS } from 'immutable'
import { createReducer } from 'redux-act'
import { setProducts, resetAllProducts } from './actions'

const initialState = {
  byId: {},
}

const reducer = createReducer(
  {
    [setProducts]: (state, payload = {}) => {
      return state.mergeDeep(payload)
    },
    [resetAllProducts]: () => {
      return fromJS(initialState)
    },
  },
  fromJS(initialState)
)

export default reducer
