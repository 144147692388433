import { connect } from 'react-redux'
import { getOption } from 'constructors/ducks/options/selectors'
import { getOptionValue } from 'constructors/ducks/values/selectors'
import Option from './Option'
import toJS from 'common/HOC/to-js'

const mapStateToProps = (state, ownProps) => ({
  option: getOption(state, ownProps.optionId),
  value: getOptionValue(state, ownProps.optionId),
})

const OptionContainer = connect(mapStateToProps)(toJS(Option))

export default OptionContainer
