import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getProduct } from 'main/selectors/products'

import safeGet from 'common/modules/safe-get'
import toJS from 'common/HOC/to-js'

import Item from 'main/components/products/item'

const mapStateToProps = (state, ownProps) => {
  const code = safeGet(ownProps, 'match.params.id', ownProps.code)
  const product = getProduct(state, code)

  return {
    product,
  }
}

export default withRouter(connect(mapStateToProps)(toJS(Item)))
