import React from 'react'

const Button = ({ text, isLoading, onClick }) => {
  return (
    <button
      type="button"
      className="authorization-button"
      onClick={onClick}
      disabled={isLoading}
    >
      {!isLoading && <span className="authorization-button__text">{text}</span>}

      {isLoading && <span className="authorization-button__loader" />}
    </button>
  )
}

export default Button
