import React from 'react'

const Toggler = ({ onChange, value }) => {
  return (
    <div className="toggler">
      <label className="toggler__label">
        <input
          type="radio"
          name="toggler"
          className="toggler__input"
          checked={value === '0'}
          value="0"
          onChange={() => onChange('0')}
        />
        <div className="toggler__button">Карточка клиента</div>
      </label>
      <label className="toggler__label">
        <input
          type="radio"
          name="toggler"
          className="toggler__input"
          checked={value === '1'}
          value="1"
          onChange={() => onChange('1')}
        />
        <div className="toggler__button">Содержание</div>
      </label>
    </div>
  )
}

export default Toggler
