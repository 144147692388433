import React from 'react'
import { scrollToLink } from 'main/modules/cards-helper'

const Actions = ({ content }) => {
  if (!content.value) return null
  if (!Array.isArray(content.value)) return null

  return (
    <div className="script__actions">
      {content.value.map(button => (
        <a
          key={button.text}
          className="script__action"
          onClick={e => scrollToLink(e, button.link_id)}
        >
          {button.text}
        </a>
      ))}
    </div>
  )
}

export default Actions
