import { formatPrice } from 'common/modules/price-helper'

export default {
  scripts: {
    placeholderAdditionalClassName: 'scripts-container__placeholder--extended',
    placeholder: `*Выбрать в меню слева отрасль клиента и должность контактного лица по данным Siebel/Суперсправки или задать уточняющий вопрос клиенту, если информация неполная/отсутствует.*

Вопросы для уточнения:

К сожалению, у нас не указано, в каком отделе вы работаете и ваша должность. Уточните, пожалуйста, эту информацию. Я постараюсь построить разговор так, чтобы вам было интересно меня слушать.

У нас указано, что вы ___(указываем должность в Siebel/суперсправке), но не указано, какой отдел вы возглавляете. Уточните, пожалуйста, эту информацию. Я постараюсь построить разговор так, чтобы вам было интересно меня слушать.

У нас указано, что вы  работаете в ____ (указываем отдел в Siebel/суперсправке), но не указано, в какой должности.  Уточните, пожалуйста, эту информацию. Я постараюсь построить разговор так, чтобы вам было интересно меня слушать.`,
  },
  invoice: {
    totalMonthly: 'Ежемесячный платеж',
  },
  kpPreview: {
    emailTemplate: {
      greeting: client => `Добрый день${getClientFullName(client)}!`,
      text: proposal => {
        if (
          proposal.constructorCode === 'complete-solutions' ||
          proposal.briefVersion
        )
          return `Направляю Вам комплексное коммерческое предложение от МТС.

        Подробная информация по ссылке ниже`

        return `Направляю Вам персонализированное коммерческое предложение по услуге ${
          proposal.packageName
        }.

        Стоимость услуги:
        - Ежемесячная плата: ${formatPrice(
          sumPrice(proposal.invoice, 'monthly')
        )} руб./мес.
        - Разовая плата: ${formatPrice(sumPrice(proposal.invoice, 'once'))} руб.

        Подробная информация в коммерческом предложении по ссылке`
      },
      signatureLine: () => '',
    },
    versionsSelect: {
      whole: 'С чеком',
      brief: 'Без чека',
    },
  },
}

const getClientFullName = client => {
  let res = ''
  if (client.firstName) res += ' ' + client.firstName
  if (client.middleName) res += ' ' + client.middleName
  if (res) res = ',' + res
  return res
}

const sumPrice = (invoice, priceType) =>
  invoice.map(product => sumProduct(product, priceType))

const sumProduct = (product, priceType) =>
  sumOptions(product.equipment.concat(product.services), priceType)

const sumOptions = (options, priceType) =>
  options.reduce((sum, option) => {
    if (
      !option.value ||
      option.type === 'title' ||
      !option.price ||
      !option.price[priceType] ||
      !option.price[priceType].value
    )
      return sum
    return sum + option.value * option.price[priceType].value
  }, 0)
