import React, { Component, Fragment } from 'react'
import SmallRadioButton from 'constructors/components/Options/SmallRadioButton'
import CardBlock from 'constructors/components/CardBlock'

class SelectBranch extends Component {
  componentDidMount() {
    const { macroregionalDivisions, init } = this.props
    if (!macroregionalDivisions || !Object.keys(macroregionalDivisions).length)
      init()

    this.checkIfHasCurrentBranch()
  }

  componentDidUpdate() {
    this.checkIfHasCurrentBranch()
  }

  onBranchChange = index => {
    const { currentBranch, macroregionalDivisions, changeBranchId } = this.props

    let branchId = null
    try {
      branchId = macroregionalDivisions.find(
        d => d.name === currentBranch.macroregionalDivision
      ).branches[index]
    } catch (error) {
      console.error(error)
    }

    if (branchId) changeBranchId(branchId)
  }

  onMacroregionalDivisionChange = index => {
    const { macroregionalDivisions, changeBranchId } = this.props
    changeBranchId(macroregionalDivisions[index].branches[0])
  }

  checkIfHasCurrentBranch = () => {
    const { macroregionalDivisions, currentBranch, changeBranchId } = this.props

    if (
      !macroregionalDivisions ||
      !Object.keys(macroregionalDivisions).length
    ) {
      return null
    }
    if (!currentBranch || !Object.keys(currentBranch).length) {
      changeBranchId(macroregionalDivisions[0].branches[0])
    }
  }

  render() {
    const { macroregionalDivisions, branches, currentBranch } = this.props
    if (
      !macroregionalDivisions ||
      !macroregionalDivisions.length ||
      !currentBranch
    )
      return null

    return (
      <Fragment>
        <CardBlock title="Выберите макрорегиональный филиал">
          <SmallRadioButton
            options={macroregionalDivisions}
            currentId={currentBranch.macroregionalDivision}
            onChange={this.onMacroregionalDivisionChange}
          />
        </CardBlock>
        {branches && (
          <CardBlock title="Выберите региональный филиал">
            <SmallRadioButton
              options={branches}
              currentId={currentBranch.id}
              onChange={this.onBranchChange}
            />
          </CardBlock>
        )}
      </Fragment>
    )
  }
}

export default SelectBranch
