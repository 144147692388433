import React from 'react'

import { isHiddenInConstructor } from 'constructors/modules/option-visibility'

export default ({ options, currentId, onChange }) => (
  <ul className="small-radio-button">
    {options.map((option, index) => {
      if (isHiddenInConstructor(option.visibility)) return null

      return (
        <li key={option.id} className="small-radio-button__item">
          <label
            className={`small-radio-button__label ${
              currentId === option.id ? 'small-radio-button__label--active' : ''
            }`}
          >
            <div className="radio-input small-radio-button__radio-input">
              <input
                type="radio"
                className="radio-input__input"
                name={option.id}
                value={option.id}
                checked={currentId === option.id}
                onChange={() => onChange(index)}
              />
              <div className="radio-input__block" />
            </div>
            <div className="small-radio-button__text">{option.name}</div>
          </label>
        </li>
      )
    })}
  </ul>
)
