import React from 'react'
import SubBlock from './SubBlock'

const InvoiceBlock = ({ name, caption, hasBlocks, blocks }) => {
  if (!hasBlocks) return null

  return (
    <div className="proposal-info__item">
      <div className="proposal-info__title">
        {name} {caption ? <strong>{caption}</strong> : null}
      </div>
      {blocks.map(block => (
        <SubBlock key={block.name} {...block} />
      ))}
    </div>
  )
}

export default InvoiceBlock
