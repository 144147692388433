import React, { Component } from 'react'
import { Iterable } from 'immutable'

const toJS = WrappedComponent => {
  class TOJS extends Component {
    render() {
      const KEY = 0
      const VALUE = 1

      const propsJS = Object.entries(this.props).reduce(
        (newProps, wrappedComponentProp) => {
          newProps[wrappedComponentProp[KEY]] = Iterable.isIterable(
            wrappedComponentProp[VALUE]
          )
            ? wrappedComponentProp[VALUE].toJS()
            : wrappedComponentProp[VALUE]
          return newProps
        },
        {}
      )

      return <WrappedComponent {...propsJS} />
    }
  }
  TOJS.displayName = `toJS(${getDisplayName(WrappedComponent)})`
  return TOJS
}

const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default toJS
