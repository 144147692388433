import { createAction, createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import api from 'common/modules/api'

// initials
const initialState = {
  isLoading: false,
  page: 0,
  totalPages: 0,
  all: [],
  byId: {},
}

// actions
const setIsLoading = createAction('visits.list - set isLoading')
const setList = createAction('visits.list - set')
export const setById = createAction('visits.list - set by id')

// selectors
export const getIsLoading = state =>
  state.getIn(['visits', 'list', 'isLoading'])
export const getPage = state => state.getIn(['visits', 'list', 'page'])
export const getTotalPages = state =>
  state.getIn(['visits', 'list', 'totalPages'])
export const getAll = state => state.getIn(['visits', 'list', 'all'])
export const getById = (state, id) =>
  state.getIn(['visits', 'list', 'byId', id])

// action creators
export const fetchList = ({ page = 1 } = {}) => dispatch => {
  dispatch(setIsLoading(true))

  return api
    .fetch(api.routes.visits.index(), { queryParams: { page, per_page: 10 } })
    .then(response => {
      dispatch(
        setList({
          page: parseInt(response.headers.get('x-page'), 10),
          totalPages: parseInt(response.headers.get('x-total-pages'), 10),
          all: response.json.map(v => v.id),
          byId: response.json.reduce((acc, v) => {
            acc[v.id] = v
            return acc
          }, {}),
        })
      )
      dispatch(setIsLoading(false))
    })
}

export const deleteById = ({ id }) => (dispatch, getState) => {
  const all = getAll(getState())

  return api.fetch(api.routes.visits.delete({ id })).then(response => {
    if (!response.ok) return
    dispatch(
      setList({
        all: all.splice(all.indexOf(id), 1).toJS(),
      })
    )
  })
}

// reducer
export default createReducer(
  {
    [setIsLoading]: (state, payload) => state.set('isLoading', payload),
    [setList]: (state, payload) => state.merge(fromJS(payload)),
    [setById]: (state, payload) =>
      state.setIn(['byId', payload.id], fromJS(payload)),
  },
  fromJS(initialState)
)
