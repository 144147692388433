import { createAction } from 'redux-act'
import { push, replace } from 'react-router-redux'
import routes from 'main-v2/pages/routes'
import {
  getModel,
  getCurrentProposalId,
} from 'constructors/ducks/model/selectors'
import { getQueryParams } from 'common/ducks/routing/selectors'
import {
  getCurrentStageIndex,
  getCurrentStage,
} from 'constructors/ducks/stages/selectors'
import { saveCommercialProposal } from 'constructors/ducks/model/actions'
import { getStagesCount } from './selectors'
import { init as initConstructor } from 'constructors/ducks/constructor/actions'
import { getBranchById } from 'main-v2/ducks/branches/selectors'
import appRoutes from 'main-v2/pages/routes'

export const setStages = createAction('set stages')
export const removeStage = createAction('remove stage')
export const addStage = createAction('add stage')
export const changeStageContent = createAction(
  'change stage content',
  (path, content) => ({ path, content })
)

export const redirectToSutableStage = () => (dispatch, getState) => {
  dispatch(changeStage(0, { replace: true }))
}

export const changeToPreviousStage = () => (dispatch, getState) => {
  const currentStage = getCurrentStageIndex(getState())
  const newStage = currentStage - 1
  return dispatch(changeStage(newStage))
}

export const changeToNextStage = () => (dispatch, getState) => {
  const currentStage = getCurrentStageIndex(getState())
  const newStage = currentStage + 1
  return dispatch(changeStage(newStage))
}

export const changeStage = (
  stage,
  options = {
    replace: false,
  }
) => (dispatch, getState) => {
  const routerAction = options.replace ? replace : push

  if (stage < 0) return dispatch(routerAction(routes.proposals.index()))

  const state = getState()
  let stagesCount = getStagesCount(state)
  const currentStage = getCurrentStage(state)
  const proposalId = getCurrentProposalId(state)
  const queryParams = getQueryParams(state)
  queryParams.stage = stage
  if (currentStage.get('type') === 'selectBranch' && stage === 1) {
    queryParams.stage = 0
  }

  const route = proposalId
    ? routes.proposals.edit(proposalId, { params: queryParams })
    : routes.proposals.new({ params: queryParams })

  if (currentStage.get('type') === 'selectBranch') {
    const branch = getBranchById(state, getModel(state).get('branchId'))
    if (!branch) return

    return dispatch(
      initConstructor({
        branchId: branch.get('id'),
        branch: branch.get('name'),
        mrd: branch.get('macroregionalDivision'),
      })
    )
  }

  if (stage + 1 <= stagesCount) return dispatch(routerAction(route))

  if (stage === stagesCount) {
    return dispatch(saveCommercialProposal()).then(proposal => {
      if (proposal.shortId || proposal.id)
        window.location.assign(
          appRoutes.proposals.preview(proposal.shortId || proposal.id)
        )
    })
  }

  return console.error('cant route')
}
