import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Link, Redirect } from 'react-router-dom'

import appRoutes from 'main/constants/app-routes'

import { getAllProducts, getIsLoading } from 'main/selectors/products'
import { requestProducts } from 'main/actions/products'
import toJS from 'common/HOC/to-js'

import List from 'main/containers/products/list'
import Item from 'main/containers/products/item'
import Sections from 'main/containers/products/sections'

const mapStateToProps = state => ({
  products: getAllProducts(state),
  isLoading: getIsLoading(state),
})

class Products extends Component {
  componentDidMount() {
    document.title = 'Рабочее пространство - Продукты'

    const { dispatch, products } = this.props

    if (!products.size) dispatch(requestProducts())
  }

  render() {
    const { isLoading, products } = this.props
    if (isLoading) return null

    return (
      <Switch>
        {isNotList(products) && (
          <Redirect
            from={appRoutes.products.index}
            to={appRoutes.products.section(
              products[0].code,
              products[0].sections[0].code
            )}
            exact={true}
          />
        )}
        <Route
          path={appRoutes.products.item()}
          render={props => <Layout {...props} hasList={!isNotList(products)} />}
        />
        <Route path={appRoutes.products.index} component={List} />
      </Switch>
    )
  }
}

const Layout = props => (
  <div className="product-layout main-layout__block center-block">
    {props.hasList && (
      <div className="product-layout__back">
        <Link
          to={appRoutes.products.index}
          className="product-layout__back-button"
        >
          Все продукты
        </Link>
      </div>
    )}
    <div className="product-layout__block">
      <Item {...props} />
      <Route path={appRoutes.products.section()} component={Sections} />
    </div>
  </div>
)

const isNotList = products => products.length < 2

export default connect(mapStateToProps)(toJS(Products))
