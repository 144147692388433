import { connect } from 'react-redux'
import {
  getTotalOncePrice,
  getTotalMonthlyPrice,
  getTotalAnnuallyPrice,
} from 'constructors/ducks/values/selectors'
import Total from './Total'

const mapStateToProps = state => ({
  once: getTotalOncePrice(state),
  monthly: getTotalMonthlyPrice(state),
  annually: getTotalAnnuallyPrice(state),
})

const InvoiceTotalContainer = connect(mapStateToProps)(Total)

export default InvoiceTotalContainer
