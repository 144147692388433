import React, { Component } from 'react'
import VideoModal from 'main/components/modals/video'
import currentUser from 'common/modules/current-user'
import api from 'common/modules/api'
import config from 'entry-specific/config'

class IntroVideo extends Component {
  state = {
    video: {
      fileUrl: '/st/products/public/silauma.mp4',
    },
  }

  closeVideo = () => {
    api.fetch(api.routes.currentUser.disableIntro())
    this.setState({ video: null })
  }

  render() {
    if (!config('introVideoUrl')) return null

    const { video } = this.state
    if (!currentUser.isLoggedIn()) return null
    if (currentUser.isIntroViewed === true) return null

    return <VideoModal video={video} close={this.closeVideo} />
  }
}

export default IntroVideo
