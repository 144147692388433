import React from 'react'
import StageIndicator from './StageIndicatorContainer'
import Invoice from './Invoice'
import Actions from './ActionsContainer'

const ConstructorLayout = ({ isLoading, isInitializing, children }) => {
  return (
    <div className="proposal-container center-block">
      <div className="proposal-container__col">
        {!isLoading && !isInitializing && (
          <div className="card proposal-container__row proposal-container__stages">
            <StageIndicator />
          </div>
        )}
        <div className="card proposal-container__row">
          {(isLoading || isInitializing) && (
            <div className="proposal-row card__block">
              <div className="proposal-row__loader" />
            </div>
          )}
          {!isLoading && !isInitializing && children}
          {!isLoading && !isInitializing && <Actions />}
        </div>
      </div>
      <div className="proposal-container__col">
        {!isLoading && !isInitializing && <Invoice />}
      </div>
    </div>
  )
}

export default ConstructorLayout
