import React, { Fragment, useState } from 'react'
import currentUser from 'common/modules/current-user'

import DropdownItem from 'main/components/common/dropdown/dropdown-item'
import Modal from 'common/components/Modal'
import LinkAndEmailTemplate from 'kp-preview/components/Standart/LinkAndEmailTemplate'

const SendEmail = props => {
  const [isOpen, setIsOpen] = useState(false)
  const { proposal } = props

  return (
    <Fragment>
      <DropdownItem onClick={() => setIsOpen(true)}>
        Отправить предложение
      </DropdownItem>
      <Modal
        className="main-layout__modal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Отправить предложение'}
      >
        <LinkAndEmailTemplate proposal={proposal} currentUser={currentUser} />
      </Modal>
    </Fragment>
  )
}

export default SendEmail
