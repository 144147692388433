import React, { Component } from 'react'
import { formatVideoTime } from 'common/modules/date-helper'

class VideoModal extends Component {
  constructor(props) {
    super(props)

    this.videoInitialState = {
      isPlayed: props.autoPlay,
      isFullScreen: false,
      currentTime: 0,
      duration: 0,
      showControls: true,
    }
    this.state = this.videoInitialState
  }

  static defaultProps = {
    autoPlay: true,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  escFunction = event => {
    if (event.keyCode !== 27 || !this.props.video) return

    const { isFullScreen } = this.state
    if (isFullScreen) return
    this.closeVideo()
  }

  componentDidUpdate(prevProps) {
    if (this.props.video !== prevProps.video) this.toggleControls()
  }

  togglePlay = () => {
    const { isPlayed } = this.state
    isPlayed ? this.videoEl.pause() : this.videoEl.play()
  }

  toggleFullScreen = () => {
    const { isFullScreen } = this.state
    const { containerEl } = this

    if (isFullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozExitFullscreen) {
        document.mozExitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    } else {
      if (containerEl.requestFullscreen) {
        containerEl.requestFullscreen()
      } else if (containerEl.mozRequestFullScreen) {
        containerEl.mozRequestFullScreen()
      } else if (containerEl.webkitRequestFullscreen) {
        containerEl.webkitRequestFullscreen()
      } else if (containerEl.msRequestFullscreen) {
        containerEl.msRequestFullscreen()
      }
    }

    this.setState({ isFullScreen: !isFullScreen })
  }

  closeByOverlay = e => {
    if (e.target.id === 'video-modal') this.closeVideo()
  }

  closeVideo = () => {
    this.props.close()
  }

  registerVideoEl = el => {
    if (!el) return (this.videoEl = null)

    this.videoEl = el
    this.videoEl.addEventListener('timeupdate', this.timeUpdate)
    this.videoEl.addEventListener('canplaythrough', this.onCanPlay)
  }

  onCanPlay = () => {
    if (this.firstCanPlay) return

    this.firstCanPlay = true
    this.timeUpdate()
    this.videoEl.currentTime = 0.1
  }

  registerPlayRef = el => {
    if (!el) return (this.playToggle = null)

    this.playToggle = el
    this.playToggle.addEventListener('mousemove', this.toggleControls)
  }

  registerControlsRef = el => {
    if (!el) return (this.controlsRef = null)

    this.controlsRef = el
    this.controlsRef.addEventListener('mouseover', this.clearControlsTimer)
    this.controlsRef.addEventListener('mouseout', this.toggleControls)
  }

  setCurrentTime = e => {
    const { duration } = this.state
    if (!duration) return

    const clickX = e.nativeEvent.pageX
    const elementX = e.target.getBoundingClientRect().left
    const elementW = document.getElementById('videoplayer-bar').offsetWidth

    const newTime = ((clickX - elementX) / elementW) * duration
    this.videoEl.currentTime = newTime
  }

  toggleControls = () => {
    this.setState({
      showControls: true,
    })
    this.clearControlsTimer()

    this.controlsTimer = setTimeout(() => {
      this.setState({
        showControls: false,
      })
    }, 2500)
  }

  clearControlsTimer = () => {
    clearTimeout(this.controlsTimer)
  }

  registerContainerEl = el => {
    if (!el) return (this.containerEl = null)
    this.containerEl = el
    document.addEventListener(
      'webkitfullscreenchange',
      this.escFromFullsreen,
      false
    )
    document.addEventListener(
      'mozfullscreenchange',
      this.escFromFullsreen,
      false
    )
    document.addEventListener('fullscreenchange', this.escFromFullsreen, false)
    document.addEventListener(
      'MSFullscreenChange',
      this.escFromFullsreen,
      false
    )
  }

  escFromFullsreen = e => {
    if (
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement === null
    ) {
    } else {
      this.setState({ isFullScreen: false })
    }
  }

  timeUpdate = () => {
    this.setState({
      currentTime: this.videoEl.currentTime,
      duration: this.videoEl.duration,
      isPlayed: this.isPlayed(),
    })
  }

  isPlayed = () => {
    const videoEl = this.videoEl
    return !!(
      videoEl.currentTime > 0 &&
      !videoEl.paused &&
      !videoEl.ended &&
      videoEl.readyState > 2
    )
  }

  render() {
    const { video } = this.props

    if (!video) return null

    const { isFullScreen } = this.state

    const { autoPlay } = this.props

    const mainClass = `videoplayer
      ${isFullScreen ? 'videoplayer--fullscreen' : ''}`

    return (
      <div className={mainClass}>
        <div className="videoplayer__overlay" />
        <div
          ref={this.registerContainerEl}
          className="videoplayer__container"
          id="video-modal"
          onMouseDown={this.closeByOverlay}
        >
          <div className="videoplayer__content">
            <div className="videoplayer__close" onClick={this.closeVideo} />
            <video
              width="960"
              height="540"
              src={video.fileUrl}
              preload="auto"
              poster="posterimage.jpg"
              autoPlay={autoPlay}
              controls
            />
          </div>
        </div>
      </div>
    )
  }

  renderProgress = () => {
    const { duration, currentTime } = this.state
    const style = {
      width: currentTime
        ? `${(parseFloat(currentTime) / parseFloat(duration)) * 100}%`
        : '0',
    }

    return (
      <div
        className="videoplayer__bar videoplayer__bar--playing"
        style={style}
      />
    )
  }

  renderPlayButton = () => {
    const { isPlayed } = this.state
    const className = `videoplayer__play
    ${isPlayed ? 'videoplayer__play--pause' : ''}`

    return <div onClick={this.togglePlay} className={className} />
  }

  renderTime = () => {
    const { duration, currentTime } = this.state
    return (
      <div className="videoplayer__time">
        {`${formatVideoTime(currentTime)} / ${formatVideoTime(duration)}`}
      </div>
    )
  }

  renderFullScreenButton = () => {
    const { isFullScreen } = this.state
    const className = `videoplayer__fullscreen
      ${isFullScreen ? 'videoplayer__fullscreen--exitfull' : ''}`

    return <div className={className} onClick={this.toggleFullScreen} />
  }
}

export default VideoModal
