import { createAction } from 'redux-act'
import { getProduct } from 'constructors/ducks/products/selectors'
import { getOption } from 'constructors/ducks/options/selectors'
import {
  getSelectedProductIdByPosition,
  getSelectedProductsIds,
} from './selectors'

export const addProductAction = createAction('values: set product')
export const addProductToPositionAction = createAction(
  'values: add product to position'
)
export const removeProductAction = createAction('values: remove product')
export const changeOptionValue = createAction('values: change option')
export const removeOptionValueAction = createAction('values: remove option')
export const resetAllValues = createAction('values: reset all')

export const setInitialValues = values => (dispatch, getState) => {
  dispatch(resetAllValues())

  values.products.forEach(productId => {
    dispatch(addProductAction({ productId }))
    dispatch(setProductDefaultOptions(productId, values.options))
  })
}

export const addProduct = productId => (dispatch, getState) => {
  dispatch(addProductAction({ productId }))
  dispatch(setProductDefaultOptions(productId))
}

export const addProductToPosition = (productId, position) => (
  dispatch,
  getState
) => {
  const state = getState()
  const productIds = getSelectedProductsIds(state)

  if (position <= productIds.size - 1) {
    for (let i = position; i < productIds.size; i++) {
      dispatch(removeProduct(productIds.get(i)))
    }
  }
  dispatch(addProduct(productId))
}

export const removeProductByPosition = position => (dispatch, getState) => {
  const state = getState()
  const productId = getSelectedProductIdByPosition(state, position)
  if (!productId) return
  dispatch(removeProduct(productId))
}

export const removeProduct = productId => (dispatch, getState) => {
  dispatch(removeProductAction({ productId }))
  dispatch(removeProductOptions(productId))
}

const removeProductOptions = productId => (dispatch, getState) => {
  const product = getProduct(getState(), productId)
  product.get('options').map(optionId => dispatch(removeOptionValue(optionId)))
}

const removeOptionValue = optionId => (dispatch, getState) => {
  dispatch(removeOptionValueAction({ optionId }))

  const option = getOption(getState(), optionId)
  if (option.get('inputType') === 'radio') {
    option
      .get('values')
      .map(id => dispatch(removeOptionValueAction({ optionId: id })))
  }
}

const setProductDefaultOptions = (productId, defaultValues = {}) => (
  dispatch,
  getState
) => {
  const product = getProduct(getState(), productId)
  product
    .get('options')
    .map(optionId => dispatch(addOptionDefaultValue(optionId, defaultValues)))
}

export const addOptionDefaultValue = (optionId, defaultValues = {}) => (
  dispatch,
  getState
) => {
  const option = getOption(getState(), optionId)
  const value =
    typeof defaultValues[optionId] !== 'undefined'
      ? defaultValues[optionId]
      : getOptionDefaultValue(option)

  dispatch(
    changeOptionValue({
      optionId,
      value,
      isDefaultValue: true,
    })
  )

  if (option.get('inputType') === 'radio') {
    option
      .get('values')
      .map(id => dispatch(addOptionDefaultValue(id, defaultValues)))
  }
}

const getOptionDefaultValue = option => {
  if (option.get('min') !== undefined) return option.get('min')
  if (option.get('value') !== undefined) return option.get('value')
  if (option.get('inputType') === 'mandatory') return 1
  return 0
}

export const setRadioValueById = params => (dispatch, getState) => {
  const state = getState()
  const option = getOption(state, params.optionId)
  if (!option || option.get('inputType') !== 'radio') {
    console.error(`setRadioValueById: ${params.optionId} is not radio`)
    return
  }

  const index = option.get('values').findIndex(id => id === params.id)
  if (index === -1) return

  dispatch(
    changeOptionValue({
      optionId: params.optionId,
      value: index,
    })
  )
}
