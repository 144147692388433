import React from 'react'

class ErrorBoundary extends React.Component {
  componentDidCatch(error) {
    window.Sentry && window.Sentry.captureException(error)
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
