import React from 'react'

import { hasAutofill } from 'main/modules/autofill-helper'
import { itemTypes } from 'entry-specific/apps/manager/scripts/helpers'

import WithAutofill from 'main/containers/sales-scripts/with-autofill'
import Text from './item-types/text'
import Speech from './item-types/speech'
import Spoiler from './item-types/spoiler'
import Actions from './item-types/actions'
import Choice from 'main/containers/sales-scripts/card/item-types/choice'
import CalculationMapping from 'main/containers/sales-scripts/card/item-types/calculation-mapping'
import CardInput from 'main/containers/sales-scripts/card/item-types/input'
import Fork from './item-types/fork'
import CalculatedBlock from './item-types/calculated-block'

const Item = props => {
  const { content } = props
  const withAutofill = contentHasAutofill(content)
  let ItemType = null

  switch (content.type) {
    case itemTypes.text:
      ItemType = Text
      break
    case itemTypes.speech:
      ItemType = Speech
      break
    case itemTypes.spoiler:
      ItemType = Spoiler
      break
    case itemTypes.actions:
      ItemType = Actions
      break
    case itemTypes.choice:
      ItemType = Choice
      break
    case itemTypes.calculationMapping:
      ItemType = CalculationMapping
      break
    case itemTypes.input:
      ItemType = CardInput
      break
    case itemTypes.fork:
      ItemType = Fork
      break
    case itemTypes.calculatedBlock:
      ItemType = CalculatedBlock
      break
    default:
      break
  }

  if (!ItemType) return null
  if (withAutofill) {
    return (
      <WithAutofill>
        <ItemType {...props} />
      </WithAutofill>
    )
  }
  return <ItemType {...props} />
}

const contentHasAutofill = item => {
  if (item.type === itemTypes.speech) return hasAutofill(item.value.text)
  if (item.type === itemTypes.text) return hasAutofill(item.value)
  return false
}

export default Item
