import { fromJS } from 'immutable'
import { createReducer } from 'redux-act'
import {
  fetchAdaptationStart,
  fetchAdaptationSuccess,
  fetchAdaptationError,
} from './actions'

const initialState = {
  isLoading: false,
  data: {},
}

const reducer = createReducer(
  {
    [fetchAdaptationStart]: state => {
      return state.set('isLoading', true)
    },
    [fetchAdaptationSuccess]: (state, payload) => {
      return state.merge({
        isLoading: false,
        data: payload,
      })
    },
    [fetchAdaptationError]: state => {
      return state.set('isLoading', false)
    },
  },
  fromJS(initialState)
)

export default reducer
