import { connect } from 'react-redux'
import { resetPassword } from 'main/actions/password-reset'
import AccountActivationComponent from 'main/components/authorization/account-activation.js'

const mapStateToProps = state => ({
  isLoading: state.getIn(['passwordReset', 'isLoading']),
  error: state.getIn(['passwordReset', 'error']),
})

const mapDispatchToProps = dispatch => ({
  onSubmit: (password, resetToken) => {
    dispatch(resetPassword(password, resetToken))
  },
})

const AccountActivation = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountActivationComponent)

export default AccountActivation
