import React, { Component } from 'react'
import { numberAsWord } from 'common/modules/number-helper'
import { Link } from 'react-router-dom'
import appRoutes from 'main-v2/pages/routes'

class AdaptationItem extends Component {
  componentDidMount() {
    this.props.fetchFailedQuestions()
  }

  render() {
    const { isFirstAttempt, failedQuestions, index, day } = this.props

    const isDone = getIsDone(isFirstAttempt, failedQuestions, day)
    let numClassName = 'adapt-daylinks__num'
    if (isDone) numClassName += ' adapt-daylinks__num--done'

    return (
      <li className="adapt-daylinks__item">
        <Link
          className="adapt-daylinks__link"
          to={appRoutes.adaptation.day({ dayId: day.id })}
        >
          <div className={numClassName}>{isDone ? '' : index + 1}</div>
          <div className="adapt-daylinks__name">
            День {numberAsWord(index + 1)}
          </div>
          <div className="adapt-daylinks__info">
            {day.materials &&
              !!day.materials.length &&
              day.materials.map(material => material.name).join(', ')}
          </div>
        </Link>
      </li>
    )
  }
}

const getIsDone = (isFirstAttempt, failedQuestions, day) => {
  if (!day.tests || !day.tests.length) return false
  if (failedQuestions && failedQuestions.length !== 0) return false
  return !isFirstAttempt
}

export default AdaptationItem
