export default ({ site }) => {
  if (process.env.NODE_ENV !== 'production') return

  window.Sentry.init({
    // eslint-disable-next-line no-undef
    version: __webpack_hash__,
    dsn: 'https://cc4e58aae8d24c788c411b0afa5daf12@sentry.io/1328168',
  })
  window.Sentry.configureScope(scope => {
    scope.setTag('site', site)
    scope.setTag(
      'customer',
      window.location.hostname
        .split('.')
        .slice(0, -2)
        .slice(-1)[0] || 'no subdomain'
    )
  })
}
