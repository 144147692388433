import React, { Fragment } from 'react'

const Client = ({ client, onChange, isLoading }) => {
  return (
    <Fragment>
      <div className="proposal-row__content">
        <div className="inputs">
          <div className="inputs__row">
            <div className="inputs__item inputs__item--three">
              <input
                className="input"
                type="text"
                placeholder="Фамилия"
                name="lastName"
                value={client.lastName}
                onChange={onChange}
                disabled={isLoading}
              />
            </div>
            <div className="inputs__item inputs__item--three">
              <input
                className="input"
                type="text"
                placeholder="Имя*"
                name="firstName"
                value={client.firstName}
                onChange={onChange}
                disabled={isLoading}
              />
            </div>
            <div className="inputs__item inputs__item--three">
              <input
                className="input"
                type="text"
                placeholder="Отчество"
                name="middleName"
                value={client.middleName}
                onChange={onChange}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="inputs__row">
            <div className="inputs__item inputs__item--one">
              <input
                className="input"
                type="text"
                placeholder="Название организации*"
                name="companyName"
                value={client.companyName}
                onChange={onChange}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="inputs__row">
            <div className="inputs__item inputs__item--one">
              <input
                className="input"
                type="text"
                placeholder="ИНН"
                name="tin"
                value={client.tin}
                onChange={onChange}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="proposal-row__info">* Обязательные поля</div>
    </Fragment>
  )
}

export default Client
