import React from 'react'
import Price from './Price'
import { getVisibilityAt } from 'constructors/modules/option-visibility'

const BigRadio = ({ option, radioOptions = [], value, onChange }) => {
  return (
    <ul className="tariffs proposal-row__content">
      {radioOptions.map((radioOption, index) => {
        if (getVisibilityAt(radioOption.visibility, 1)) return null

        return (
          <li key={radioOption.id} className="tariffs__item">
            <label className={getLabelClassName(index, value)}>
              <div className="radio-input tariffs__radio-input">
                <input
                  type="radio"
                  className="radio-input__input"
                  name={option.id}
                  checked={index === value}
                  onChange={carriedOnChange(onChange, option.id, index)}
                />
                <div className="radio-input__block" />
              </div>
              <div className="tariffs__name">
                {radioOption.shortName || radioOption.name}
              </div>
              {radioOption.price && (
                <div className="tariffs__price">
                  <Price price={radioOption.price} />
                </div>
              )}
              {radioOption.notes &&
                radioOption.notes.map(note => {
                  if (!note) return null
                  return (
                    <div key={note.title} className="tariffs__desc">
                      <div className="tariffs__subtitle">{note.title}</div>
                      <div className="tariffs__info">
                        {note.value} {note.caption}
                      </div>
                    </div>
                  )
                })}
            </label>
          </li>
        )
      })}
    </ul>
  )
}

const getLabelClassName = (index, value) => {
  let className = 'tariffs__label'
  if (index === value) className += ' tariffs__label--active'
  return className
}

const carriedOnChange = (onChange, optionId, index) => () =>
  onChange(optionId, index)

export default BigRadio
