import React, { Component } from 'react'
import { connect } from 'react-redux'
import appRoutes from 'main-v2/pages/routes'
import toJS from 'common/HOC/to-js'
import { withRouter } from 'react-router'

import {
  initialData,
  fetchCurrent,
  setCurrent,
  mergeCurrent,
  geocode,
  getData,
  getIsLoading,
  save,
} from 'main-v2/ducks/visits/current'

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  data: getData(state),
})

const mapDispatchToProps = dispatch => ({
  fetchCurrent: options => dispatch(fetchCurrent(options)),
  setCurrent: data => dispatch(setCurrent({ data })),
  mergeCurrent: data => dispatch(mergeCurrent({ data })),
  geocode: payload => dispatch(geocode(payload)),
  save: data => dispatch(save(data)),
})

class VisitForm extends Component {
  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
  }

  state = {
    geocodeIsLoading: false,
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    if (!this.props.match.params.id) return this.setNew()
    this.props.fetchCurrent({ id: this.props.match.params.id })
  }

  setNew = () => {
    this.props.setCurrent(initialData)
  }

  closeByOverlay = e => {
    if (this.containerRef.current !== e.target) return
    this.close()
  }

  close = () => {
    this.props.history.push(appRoutes.profile.index())
  }

  geocode = () => {
    this.setState({ geocodeIsLoading: true })
    navigator.geolocation.getCurrentPosition(
      position => {
        this.props
          .geocode({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
          .then(() => this.setState({ geocodeIsLoading: false }))
      },
      () => {
        alert(
          'Не удалось получить координаты. Проверьте, что вы разрешили передачу геолокации в браузере.'
        )
        this.setState({ geocodeIsLoading: false })
      }
    )
  }

  onChange = name => e => {
    this.props.mergeCurrent({ [name]: e.target.value })
  }

  onSave = () => {
    const { save, data, history } = this.props
    save(data).then(() => {
      if (data.id) {
        history.push(appRoutes.profile.index())
      } else {
        history.push(appRoutes.profile.index({}, { resetParams: true }))
      }
    })
  }

  isValid = () => {
    const { data } = this.props

    return (
      data.companyName &&
      data.goal &&
      data.address &&
      data.geo &&
      data.geo.lat &&
      data.geo.lng
    )
  }

  render() {
    return (
      <div className="modal main-layout__modal">
        <div className="modal__overlay" />
        <div
          className="modal__container"
          ref={this.containerRef}
          onMouseDown={this.closeByOverlay}
        >
          {this.renderContent()}
        </div>
      </div>
    )
  }

  renderContent = () => {
    const { resultOnly, isLoading } = this.props

    if (isLoading) return null
    return resultOnly ? this.renderResult() : this.renderMain()
  }

  renderMain = () => {
    const { data } = this.props
    const { geocodeIsLoading } = this.state

    return (
      <div className="modal__content modal__content--small">
        <div className="modal__title">
          Я пришел на встречу{' '}
          <span className="modal__close" onClick={this.close} />
        </div>
        <div className="modal__body">
          <div className="modal__row">
            <div className="modal__row-title">Укажите данные встречи</div>
            <div className="modal__row-input">
              <input
                type="text"
                className="input"
                placeholder="Название компании"
                value={data.companyName}
                onChange={this.onChange('companyName')}
              />
            </div>
            <div className="modal__row-input">
              <textarea
                className="textarea"
                placeholder="Цель встречи"
                value={data.goal}
                onChange={this.onChange('goal')}
              />
            </div>
          </div>
          <div className="modal__row">
            <div className="modal__row-title">Место</div>
            {data.address && (
              <div className="modal__row-text">{data.address}</div>
            )}
            <button
              type="button"
              className="button button--ghost"
              onClick={this.geocode}
              disabled={geocodeIsLoading}
            >
              {geocodeIsLoading
                ? 'Получение геопозиции...'
                : data.address
                ? 'Обновить геопозицию'
                : 'Получить геопозицию'}
            </button>
          </div>
          <div className="modal__actions">
            <div className="modal__actions-text">Все поля обязательные</div>
            <button
              type="button"
              className="button"
              disabled={!this.isValid()}
              onClick={this.onSave}
            >
              {data.id ? 'Обновить отметку' : 'Добавить отметку'}
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderResult = () => {
    const { data } = this.props

    return (
      <div className="modal__content modal__content--small">
        <div className="modal__title">
          Результат встречи{' '}
          <span className="modal__close" onClick={this.close} />
        </div>
        <div className="modal__body">
          <div className="modal__address">
            <div className="modal__address-name">{data.companyName}</div>
            <div className="modal__address-place">{data.address}</div>
          </div>
          <div className="modal__row">
            <div className="modal__row-input">
              <textarea
                className="textarea"
                placeholder="Результат встречи"
                value={data.result}
                onChange={this.onChange('result')}
              />
            </div>
          </div>
          <div className="modal__actions">
            <button
              type="button"
              className="button"
              disabled={!data.result}
              onClick={this.onSave}
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(withRouter(VisitForm)))
