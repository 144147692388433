import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Select extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    optionGroups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
          })
        ),
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ),
    onClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.wrapperRef = React.createRef()
  }

  state = {
    isOpen: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.outsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.outsideClick)
  }

  outsideClick = event => {
    if (!this.state.isOpen) return
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.closeOptions()
    }
  }

  toggleOptions = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  openOptions = () => {
    this.setState({ isOpen: true })
  }

  closeOptions = () => {
    this.setState({ isOpen: false })
  }

  clickAndClose = option => {
    const { onClick } = this.props
    onClick(option)
    this.closeOptions()
  }

  render() {
    const { title, optionGroups = [], options = [] } = this.props
    const { isOpen } = this.state

    let dropdownClassName = 'select__dropdown'
    if (isOpen) dropdownClassName += ' select__dropdown--active'
    if (!optionGroups) dropdownClassName += ' select__dropdown--small'

    return (
      <div ref={this.wrapperRef} className="select">
        <div className="select__box" onClick={this.toggleOptions}>
          <span className="select__title">{title}</span>
        </div>
        <div className={dropdownClassName}>
          {options.map((option, i) => (
            <div key={i} className="select__dropdown-group">
              <div
                className="select__dropdown-item"
                onClick={() => this.clickAndClose(option)}
              >
                {option.name}
              </div>
            </div>
          ))}
          {optionGroups.map((group, i) => (
            <div key={i} className="select__dropdown-group">
              <div className="select__dropdown-label">{group.name}</div>
              {group.options.map((option, i2) => (
                <div
                  key={i2}
                  className="select__dropdown-item"
                  onClick={() => this.clickAndClose(option)}
                >
                  {option.name}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Select
