import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import appRoutes from 'main-v2/pages/routes'
import animateScrollTo from 'animated-scroll-to'
import toJS from 'common/HOC/to-js'

import {
  getSalesScriptCursor,
  getLoader,
  setLoader,
  fetchProposals,
  getAllProposalIds,
} from 'main-v2/ducks/proposals'

import MainLayout from 'main/containers/layout'
import Header from 'main-v2/components/proposals/Header'
import List from 'main-v2/components/proposals/List'
import ProposalScript from 'main-v2/components/proposal-script'
import Stats from 'main-v2/components/proposals/Stats'
import Modal from 'common/components/Modal'

const mapStateToProps = state => ({
  salesScriptCursor: getSalesScriptCursor(state),
  isLoading: getLoader(state, 'data'),
  proposalIds: getAllProposalIds(state),
})

const mapDispatchToProps = dispatch => ({
  fetchProposals: () => {
    dispatch(setLoader('data', true))
    return dispatch(fetchProposals())
      .then(() => dispatch(setLoader('data', false)))
      .catch(() => dispatch(setLoader('data', false)))
  },
})

const ProposalsPage = props => {
  useEffect(() => {
    if (props.proposalIds.length) return

    props.fetchProposals().then(() =>
      animateScrollTo(0, {
        element: window,
        maxDuration: 400,
      })
    )
  }, [])

  useEffect(() => {
    document.title = `Рабочее пространство - Коммерческие предложения`
  }, [])

  const { isLoading, proposalIds, salesScriptCursor } = props

  if (isLoading)
    return (
      <Layout>
        <div className="proposals__loading">
          <div className="proposals__loader" />
        </div>
      </Layout>
    )

  if (!proposalIds || !proposalIds.length)
    return (
      <Layout>
        <div className="proposals__notfound">Ничего не найдено</div>
      </Layout>
    )

  return salesScriptCursor > -1 ? (
    <ProposalScript />
  ) : (
    <Layout>
      <List />
    </Layout>
  )
}

const Layout = ({ children }) => (
  <MainLayout>
    <div className="main-layout__block center-block">
      <Header />
      <div className="proposals main-layout__content">{children}</div>
      <Route
        exact
        path={appRoutes.proposals.stats()}
        render={({ history }) => (
          <Modal
            className="main-layout__modal"
            title="Аналитика просмотров предложения"
            isOpen={true}
            size="auto"
            onClose={() => history.push(appRoutes.proposals.index())}
          >
            <Stats />
          </Modal>
        )}
      />
    </div>
  </MainLayout>
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(ProposalsPage))
