import { fromJS } from 'immutable'
import { REQUEST_LOGIN, LOGIN_SUCCESS, LOGIN_ERROR } from 'main/actions/auth'

const defaultState = {
  isLoading: false,
  error: false,
}

const auth = (state = fromJS(defaultState), action) => {
  switch (action.type) {
    case REQUEST_LOGIN:
      return state.merge({
        isLoading: true,
        error: false,
      })
    case LOGIN_SUCCESS:
      return state.merge({
        isLoading: false,
      })
    case LOGIN_ERROR:
      return state.merge({
        isLoading: false,
        error: true,
      })
    default:
      return state
  }
}

export default auth
