import { getAdaptationTest } from 'main-v2/ducks/adaptation/selectors'
import { createAction } from 'redux-act'
import api from 'common/modules/api'

export const setFailedQuestions = createAction(
  'set failed quesions',
  (testId, failedQuestions) => ({ testId, failedQuestions })
)

export const setFirstAttempt = createAction(
  'set first attempt',
  (testId, firstAttempt) => ({ testId, firstAttempt })
)

export const fetchFailedQuestions = dayId => (dispatch, getState) => {
  const state = getState()
  const test = getAdaptationTest(state, dayId)
  if (!test) return

  api
    .fetch(api.routes.tests.failedQuestions({ testId: test.get('id') }))
    .then(response => {
      dispatch(setFirstAttempt(test.get('id'), false))
      dispatch(setFailedQuestions(test.get('id'), response.json))
    })
    .catch(error => {
      if (error.status === 404) dispatch(setFirstAttempt(test.get('id'), true))
    })
}
