import React from 'react'
import BigRadio from '../Options/BigRadioContainer'
import CardBlock from 'constructors/components/CardBlock'

const SelectOneOption = ({ title, option, value, onChange }) => {
  if (!option) return null
  return (
    <CardBlock title={title}>
      <BigRadio option={option} value={value} onChange={onChange} />
    </CardBlock>
  )
}

export default SelectOneOption
