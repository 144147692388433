import { connect } from 'react-redux'
import { keyPathToArr } from 'main/modules/autofill-helper'
import { setAutofill } from 'main/actions/sales-scripts/autofill'
import { getInputValue } from 'main/selectors/sales-scripts/autofill'
import CardInput from 'main/components/sales-scripts/card/item-types/input'

const mapStateToProps = (state, ownProps) => {
  const keyPath = keyPathToArr(ownProps.content.value.keyPath)

  return {
    ...ownProps.content.value,
    value: getInputValue(state, keyPath),
    keyPath,
  }
}

const mapDispatchToProps = dispatch => ({
  onChange: (keyPath, value) => dispatch(setAutofill(keyPath, value)),
})

const CardInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CardInput)

export default CardInputContainer
