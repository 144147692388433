import React, { Fragment } from 'react'
import Price from './PriceContainer'
import { isHiddenInConstructor } from 'constructors/modules/option-visibility'
import CounterInput from './CounterInputContainer'
import Ticker from './Ticker'

const Radio = props => {
  const { option, radioOptions, value } = props

  return (
    <Fragment>
      {radioOptions.map((radioOption, index) => {
        if (isHiddenInConstructor(radioOption.visibility)) return null

        return (
          <tr
            key={radioOption.id}
            className={getClassName({
              option,
              radioOption,
              value,
              index,
            })}
          >
            {renderField(props, radioOption, index)}
            {radioOption.price && (
              <td className="options__price">
                <div className="options__font">
                  <div className="options__text">
                    <Price optionId={radioOption.id} />
                  </div>
                </div>
              </td>
            )}
          </tr>
        )
      })}
    </Fragment>
  )
}

const getClassName = ({ option, radioOption, value, index }) => {
  let className = 'options__row'
  if (option.disabled) {
    className += ' options__row--disabled'
    return className
  }
  if (radioOption.disabled) {
    className += ' options__row--noneditable'
  }

  if (value === index) className += ' options__row--active'
  return className
}

const renderField = (props, radioOption, index) => {
  const { option, onChange, value } = props
  const wrapperClass =
    radioOption.inputType === 'counter' ? 'options__count-block' : ''

  return (
    <td className="options__field" colSpan={radioOption.price ? 0 : 2}>
      <div className={wrapperClass}>
        <label className="options__label">
          <input
            className="options__radio-input"
            name={option.id}
            type="radio"
            checked={value === index}
            onChange={carriedOnChange(onChange, option.id, index)}
          />
          <div className="options__radio-block" />
          <Ticker text={radioOption.name} />
        </label>
        {radioOption.inputType === 'counter' && (
          <CounterInput optionId={radioOption.id} onChange={onChange} />
        )}
      </div>
    </td>
  )
}

const carriedOnChange = (onChange, optionId, value) => () =>
  onChange(optionId, value)

export default Radio
