import React from 'react'
import appRoutes from 'main-v2/pages/routes'

import { Switch, Route } from 'react-router-dom'
import UserCard from 'main-v2/components/profile/UserCard'
import List from 'main-v2/components/visits/List'
import Form from 'main-v2/components/visits/Form'

export default () => (
  <div className="main-layout__block center-block">
    <div className="profile-container">
      <UserCard />
      <List />
      <Switch>
        <Route
          path={appRoutes.profile.visits.result({}, { resetParams: true })}
          render={props => <Form {...props} resultOnly />}
        />
        <Route
          path={appRoutes.profile.visits.new({}, { resetParams: true })}
          component={Form}
        />
        <Route
          path={appRoutes.profile.visits.edit({}, { resetParams: true })}
          component={Form}
        />
      </Switch>
    </div>
  </div>
)
