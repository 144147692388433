import { withLocationParams } from 'common/modules/router'

export default {
  proposals: {
    index: options => {
      return withLocationParams(`/commercial-proposals`, options)
    },
    new: options => {
      return withLocationParams(`/commercial-proposals/new`, options)
    },
    edit: (id = ':id', options) => {
      return withLocationParams(`/commercial-proposals/edit/${id}`, options)
    },
    stats: (id = ':id', options) => {
      return withLocationParams(`/commercial-proposals/stats/${id}`, options)
    },
    notFound: options =>
      withLocationParams('/commercial-proposals/not-found', options),
    preview: (id = ':id') =>
      withLocationParams(`/p/${id}`, { resetParams: true }),
  },
  adaptation: {
    index: () => '/adaptation',
    day: (params = { dayId: ':dayId' }) => `/adaptation/day/${params.dayId}`,
    dayResult: (params = { dayId: ':dayId' }) =>
      `/adaptation/day/${params.dayId}/result`,
    test: (
      params = {
        dayId: ':dayId',
      }
    ) => `/adaptation/day/${params.dayId}/test`,
  },
  profile: {
    index: (params, options) => withLocationParams(`/profile`, options),
    visits: {
      new: (params, options) =>
        withLocationParams('/profile/visits/new', options),
      edit: ({ id = ':id' } = {}, options) =>
        withLocationParams(`/profile/visits/${id}`, options),
      result: ({ id = ':id' } = {}, options) =>
        withLocationParams(`/profile/visits/${id}/result`, options),
    },
  },
}
