import { connect } from 'react-redux'
import { setAutofill, resetAutofill } from 'main/actions/sales-scripts/autofill'
import { resetCalculations } from 'main/actions/sales-scripts/calculations'
import { setCurrentScriptPath } from 'main/actions/sales-scripts/current-script'
import SalesScripts from 'main/components/sales-scripts'

const mapDispatchToProps = dispatch => ({
  setManagerName: managerName => {
    dispatch(setAutofill(['managerName'], managerName))
  },
  resetAll: () => {
    dispatch(setCurrentScriptPath([]))
    dispatch(resetAutofill())
    dispatch(resetCalculations())
  },
})

const SalesScriptsContainer = connect(
  null,
  mapDispatchToProps
)(SalesScripts)

export default SalesScriptsContainer
