import { createSelector } from 'reselect'
import { List } from 'immutable'

export const getAllOptionsById = state =>
  state.getIn(['currentProposal', 'options', 'byId'])

export const getAllOptionIds = createSelector(getAllOptionsById, byIds =>
  byIds.keySeq().toList()
)

export const getOption = (state, id) =>
  state.getIn(['currentProposal', 'options', 'byId', id])

export const getOptions = (state, optionIds) =>
  List(optionIds).map(optionId => getOption(state, optionId))

export const getOptionPrice = (state, id) =>
  state.getIn(['currentProposal', 'options', 'byId', id, 'price'])

export const getRadioOptions = (state, id) => {
  const optionIds = state.getIn([
    'currentProposal',
    'options',
    'byId',
    id,
    'values',
  ])
  return optionIds.map(optionId => getOption(state, optionId))
}
