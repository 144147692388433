import 'common/modules/compatibility-check'
import 'common/modules/polyfills'
import unregisterServiceWorker from 'common/modules/unregister-service-worker'
import initSentry from 'common/modules/init-sentry'
import initGA from 'common/modules/init-ga'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import store, { history } from 'main/store'
import 'styles/main.scss'
import App from 'main/containers/app.js'
import ScrollToTop from 'common/components/ScrollToTop'
import ErrorBoundary from 'common/components/ErrorBoundary'

initSentry({ site: 'main' })
initGA({ history, site: 'main' })
unregisterServiceWorker()

const target = document.querySelector('#root')
render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>,
  target
)
