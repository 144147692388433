import React from 'react'

import { scrollToLink, getContentItemId } from 'main/modules/cards-helper'
import SubListItem from './list-item'

const ContentsList = ({ currentCard, card, num }) => {
  let className = 'contents__link'
  if (card.content) className += ' contents__link--active'
  if (!card.content) className += ' contents__link--disabled'
  if (card.id === currentCard) className += ' contents__link--focus'

  return (
    <li className="contents__item">
      <span className="contents__num">{num}</span>
      <a
        id={getContentItemId(card.id)}
        className={className}
        onClick={e => scrollToLink(e, card.id)}
      >
        {card.name}
      </a>
      {card.subCards && (
        <ol className="contents__list">
          {card.subCards.map((subCard, index) => (
            <SubListItem
              key={subCard.id}
              card={subCard}
              currentCard={currentCard}
              num={`${num}${index + 1}.`}
            />
          ))}
        </ol>
      )}
    </li>
  )
}

export default ContentsList
