import React from 'react'
import PropTypes from 'prop-types'

const StageIndicator = ({ stages, currentStageIndex = 0 }) => {
  return (
    <ul className="proposal-stages center-block">
      {stages.map((stage, i) => (
        <li key={i} className={getClassName(i, currentStageIndex)}>
          <span className="proposal-stages__text">{stage.name}</span>
        </li>
      ))}
    </ul>
  )
}

const getClassName = (index, currentStageIndex) => {
  let className = 'proposal-stages__item'
  if (index <= currentStageIndex) {
    className += ' proposal-stages__item--active'
  }
  return className
}

StageIndicator.propTypes = {
  stages: PropTypes.arrayOf(PropTypes.object),
  currentStageIndex: PropTypes.number,
}

export default StageIndicator
