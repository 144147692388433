import React, { Component, createRef } from 'react'
import cn from 'classnames'

class Dropdown extends Component {
  state = {
    isBottom: false,
    isHidden: true,
  }

  constructor(props) {
    super(props)

    this.dropdownRef = createRef()
    this.buttonRef = createRef()
    this.ulRef = createRef()
  }

  componentWillUnmount() {
    this.stopListenForClose()
  }

  showMenu = () => {
    this.setState({ isHidden: false, isBottom: this.getIsBottom() })
    this.listenForClose()
  }

  hideMenu = () => {
    this.setState({ isHidden: true, isBottom: false })
    this.stopListenForClose()
  }

  listenForClose = () => {
    document.addEventListener('click', this.handleClickOutside)
  }

  stopListenForClose = () => {
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = event => {
    if (!this.dropdownRef.current) return

    if (!this.dropdownRef.current.contains(event.target)) {
      this.hideMenu()
      return
    }

    if (event.target.classList.contains('dropdown__item')) {
      this.hideMenu()
    }
  }

  getIsBottom = () => {
    const windowHeight = window.innerHeight
    const buttonRect = this.buttonRef.current.getBoundingClientRect()
    const contentRect = this.ulRef.current.getBoundingClientRect()

    if (windowHeight > buttonRect.bottom + contentRect.height) return true

    return buttonRect.top - contentRect.height - buttonRect.height > 0 ? false : true
  }

  render() {
    const { children, className, text } = this.props
    const { isHidden, isBottom } = this.state

    return (
      <div ref={this.dropdownRef} className={cn('dropdown', className)}>
        <button
          ref={this.buttonRef}
          className="dropdown__button"
          onClick={this.showMenu}
        >
          {text}
        </button>

        <ul
          ref={this.ulRef}
          className={cn('dropdown__menu', {
            'dropdown__menu--hidden': isHidden,
            'dropdown__menu--bottom': isBottom,
          })}
        >
          {children}
        </ul>
      </div>
    )
  }
}

export default Dropdown
