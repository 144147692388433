import { connect } from 'react-redux'

import { getCalculations } from 'main/selectors/sales-scripts/calculations'

import CalculationMapping from 'main/components/sales-scripts/card/item-types/calculation-mapping'

const mapStateToProps = (state, ownProps) => {
  const calculation = getCalculations(state).toJS()
  const item = ownProps.content

  return {
    result: getResult(calculation, item),
  }
}

const getResult = (calculation, item) => {
  let mapResult = item.mapping || {}

  for (let i = 0, l = item.mapKeys.length; i < l; i++) {
    const key = item.mapKeys[i]
    if (!calculation[key]) return ''
    if (!mapResult) return ''
    mapResult = mapResult[calculation[key]]
  }

  return mapResult
}

const CalculationMappinContainer = connect(mapStateToProps)(CalculationMapping)

export default CalculationMappinContainer
