import { connect } from 'react-redux'
import { getProductsByIds } from 'constructors/ducks/products/selectors'
import { getSelectedProductIdByPosition } from 'constructors/ducks/values/selectors'
import { addProductToPosition } from 'constructors/ducks/values/actions'
import { getProductOptionIds } from 'constructors/ducks/products/selectors'
import toJS from 'common/HOC/to-js'
import SelectOneProduct from './SelectOneProduct'

const mapStateToProps = (state, ownProps) => {
  const selectedProductId = getSelectedProductIdByPosition(
    state,
    ownProps.stage.productPosition
  )

  const optionIds = getProductOptionIds(state, selectedProductId)

  return {
    products: getProductsByIds(state, ownProps.stage.productIds),
    selectedProductId,
    hasOptions: optionIds && !!optionIds.size,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onProductChange: productId =>
    dispatch(addProductToPosition(productId, ownProps.stage.productPosition)),
})

const SelectOneProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(SelectOneProduct))

export default SelectOneProductContainer
