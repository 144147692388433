import React, { Component } from 'react'
import { connect } from 'react-redux'

import currentUser from 'common/modules/current-user'

import Cards from 'main/containers/sales-scripts/cards'
import ScriptSelect from 'main/containers/sales-scripts/script-select'
import Contents from 'main/containers/sales-scripts/contents'

class SalesScripts extends Component {
  componentDidMount() {
    document.title = 'Рабочее пространство - Скрипты продаж'

    const { setManagerName, resetAll } = this.props

    resetAll()
    if (currentUser.isLoggedIn()) {
      setManagerName(currentUser.name)
    }
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <div className="scripts-container center-block">
        <div className="scripts-container__nav-place">
          <div className="scripts-container__nav">
            <ScriptSelect />
            <Contents />
          </div>
        </div>
        <div className="scripts-container__content">
          <Cards />
        </div>
      </div>
    )
  }
}

export default connect()(SalesScripts)
