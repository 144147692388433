import React from 'react'

// import OptionsGroup from './options-group'

const ScriptSelectOptions = ({ isOpen, selectedIndex, items, onChange }) => {
  let mainClassName = 'select__dropdown'
  if (isOpen) mainClassName += ' select__dropdown--active'

  return (
    <div className={mainClassName}>
      {(!items || !items.length) && (
        <div className="select__dropdown-placeholder">
          Нет доступных скриптов
        </div>
      )}
      {items.map((item, index) => (
        <div
          key={item.code}
          className={getItemClass(selectedIndex, index)}
          onClick={curriedOnChange(onChange, index)}
        >
          {item.name}
        </div>
        // <OptionsGroup
        //   key={item.code}
        //   currentScriptPath={currentScriptPath}
        //   group={group}
        //   onChange={onChange}
        // />
      ))}
    </div>
  )
}

const curriedOnChange = (onChange, index) => () => onChange(index)

const getItemClass = (selectedIndex, itemIndex) => {
  let className = 'select__dropdown-item'

  if (selectedIndex === itemIndex) {
    className += ' select__dropdown-item--active'
  }

  return className
}

export default ScriptSelectOptions
