import React, { Component } from 'react'
import { Link, Route } from 'react-router-dom'
import appRoutes from 'main-v2/pages/routes'
import { numberAsWord } from 'common/modules/number-helper'
import Material from './Material'
import PdfPreview from 'main/components/products/sections/documents/pdf-preview'
import Video from 'main/components/modals/VideoContext'
import TestResult from './TestResult'
import { wordWithCase } from 'common/modules/number-helper'

import config from 'entry-specific/config'

class AdaptationDay extends Component {
  componentDidMount() {
    this.props.fetchFailedQuestions()
  }

  render() {
    const {
      dayIndex,
      day,
      test,
      failedQuestions,
      closeTestResult,
      isFirstAttempt,
    } = this.props

    return (
      <PdfPreview.Provider>
        <Video.Provider>
          <div className="main-layout__block main-layout__block--small center-block">
            <div className="main-layout__back">
              <Link
                className="main-layout__back-button"
                to={appRoutes.adaptation.index()}
              >
                Список дней
              </Link>
            </div>
            <div className="adapt-day main-layout__adapt-day">
              <div className="adapt-day__name">
                {config('naming.adaptation.name', 'Обучение')}: день{' '}
                {numberAsWord(dayIndex + 1)}
              </div>
              {!test && (
                <div className="adapt-day__info">Изучите все материалы дня</div>
              )}
              {test && (
                <div className="adapt-day__info">
                  {test.type === 'quiz'
                    ? 'Изучите все материалы дня и пройдите тестирование'
                    : 'Изучите все материалы дня и заполните анкету'}
                </div>
              )}
              <ul className="adapt-day__materials">
                {day &&
                  day.materials &&
                  day.materials.map((material, index) => (
                    <Material
                      key={material.id}
                      index={index}
                      material={material}
                    />
                  ))}
              </ul>
              {test && (
                <div className="adapt-day__test">
                  {((failedQuestions && !!failedQuestions.length) ||
                    isFirstAttempt) && (
                    <div>
                      <Link
                        to={appRoutes.adaptation.test({ dayId: day.id })}
                        className="adapt-day__test-open"
                      >
                        {test.type === 'quiz'
                          ? 'Перейти к тестированию'
                          : 'Заполнить анкету'}
                      </Link>
                    </div>
                  )}
                  {failedQuestions && !!failedQuestions.length && (
                    <div>
                      <Link
                        className="adapt-day__test-res"
                        to={appRoutes.adaptation.dayResult({ dayId: day.id })}
                      >
                        Последняя попытка: {failedQuestions.length}{' '}
                        {wordWithCase(failedQuestions.length, [
                          'ошибка',
                          'ошибки',
                          'ошибок',
                        ])}
                      </Link>
                    </div>
                  )}
                  {failedQuestions && !failedQuestions.length && (
                    <div className="adapt-day__test-done">
                      {test.type === 'quiz'
                        ? 'Вы уже успешно прошли тестирование'
                        : 'Вы уже отправили анкету'}
                    </div>
                  )}
                </div>
              )}
            </div>
            <Route
              path={appRoutes.adaptation.dayResult()}
              render={() => (
                <TestResult
                  isOpen={true}
                  onClose={closeTestResult}
                  test={day.tests && !!day.tests.length && day.tests[0]}
                  failedQuestions={failedQuestions}
                />
              )}
            />
            <PdfPreview.Component />
            <Video.Component />
          </div>
        </Video.Provider>
      </PdfPreview.Provider>
    )
  }
}

export default AdaptationDay
