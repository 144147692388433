import React, { Component, Fragment } from 'react'
import currentUser from 'common/modules/current-user'

class Typeform extends Component {
  componentDidMount() {
    var js,
      q,
      d = document,
      gi = d.getElementById,
      ce = d.createElement,
      gt = d.getElementsByTagName,
      id = 'typef_orm',
      b = 'https://embed.typeform.com/'
    if (!gi.call(d, id)) {
      js = ce.call(d, 'script')
      js.id = id
      js.src = b + 'embed.js'
      q = gt.call(d, 'script')[0]
      q.parentNode.insertBefore(js, q)
    }
  }

  render() {
    const { url } = this.props
    if (!url) return null

    return (
      <Fragment>
        <div
          className="typeform-widget"
          data-url={`${url}?email=${currentUser.email}`}
          style={{ width: '100%', height: '100%', overflow: 'hidden'}}
        />

      </Fragment>
    )
  }
}

export default Typeform
