import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'
import Adaptation from 'main-v2/components/adaptation/AdaptationContainer'
import { fetchAdaptation } from 'main-v2/ducks/adaptation/actions'

import { Route } from 'react-router-dom'
import appRoutes from 'main-v2/pages/routes'
import AdaptationDay from 'main-v2/components/adaptation/AdaptationDayContainer'

import config from 'entry-specific/config'

class AdaptationPage extends Component {
  state = {
    isLoading: true,
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchAdaptation())
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }))

    document.title = `Рабочее пространство - ${config(
      'naming.adaptation.name',
      'Обучение'
    )}`
  }

  render() {
    const { isLoading } = this.state
    if (isLoading) return null

    return (
      <Switch>
        <Route path={appRoutes.adaptation.day()} component={AdaptationDay} />
        <Route path={appRoutes.adaptation.index()} component={Adaptation} />
      </Switch>
    )
  }
}

export default connect()(AdaptationPage)
