import config from 'entry-specific/config'

const root = `${config('api.url', '')}`

export default {
  tokens: () => ({ url: `${root}/tokens`, method: 'POST' }),
  passwordReset: () => ({
    url: `${root}/user`,
    method: 'PATCH',
  }),
  resetInstructions: () => ({
    url: `${root}/password-resetter`,
    method: 'PUT',
  }),
  currentUser: {
    index: () => `${root}/user`,
    disableIntro: () => ({
      url: `${root}/user-introduction`,
      method: 'PUT',
    }),
  },
  proposals: {
    index: () => `${root}/commercial-proposals`,
    show: id => `${root}/commercial-proposals/${id}`,
    new: () => ({
      url: `${root}/commercial-proposals`,
      method: 'POST',
    }),
    edit: id => ({
      url: `${root}/commercial-proposals/${id}`,
      method: 'PATCH',
    }),
    delete: id => ({
      url: `${root}/commercial-proposals/${id}`,
      method: 'DELETE',
    }),
    preview: (id = ':id') => `${root}/commercial-proposals/${id}/preview`,
    html: (id = ':id') => `${root}/commercial-proposals/${id}/html`,
    file: (id = ':id') => `${root}/commercial-proposals/${id}/file`,
    changeDecision: (id = ':id') => ({
      url: `${root}/commercial-proposals/${id}/decision`,
      method: 'PUT',
    }),
    changeClient: (id = ':id') => ({
      url: `${root}/commercial-proposals/${id}/client`,
      method: 'PUT',
    }),
  },
  branches: {
    index: () => `${root}/branches`,
  },
  adaptations: {
    forUser: (params = {}) => `${root}/adaptation`,
  },
  tests: {
    answers: (params = {}) => ({
      method: 'PUT',
      url: `${root}/tests/${params.testId}/answers`,
    }),
    failedQuestions: (params = {}) =>
      `${root}/tests/${params.testId}/failed_questions`,
  },
  track: {
    user: () => ({
      method: 'POST',
      url: `${root}/track-user`,
    }),
  },
  newsCompilations: {
    index: () => `${root}/news-compilations?include=news`,
  },
  newsCategories: {
    index: () => `${root}/news-categories`,
  },
  products: {
    index: () => `${root}/products`,
  },
  visits: {
    index: () => `${root}/visits`,
    show: ({ id }) => `${root}/visits/${id}`,
    new: () => ({
      url: `${root}/visits`,
      method: 'POST',
    }),
    edit: ({ id }) => ({
      url: `${root}/visits/${id}`,
      method: 'PATCH',
    }),
    delete: ({ id }) => ({
      url: `${root}/visits/${id}`,
      method: 'DELETE',
    }),
  },
  geocode: () => `${root}/geocode`,
}
