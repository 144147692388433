import { connect } from 'react-redux'
import { getProductOptionIds } from 'constructors/ducks/products/selectors'
import { getOptions } from 'constructors/ducks/options/selectors'
import Options from './Component'
import { changeOptionValue } from 'constructors/ducks/values/actions'
import toJS from 'common/HOC/to-js'

const mapStateToProps = (state, ownProps) => {
  const optionIds =
    ownProps.optionIds || getProductOptionIds(state, ownProps.productId)

  return {
    optionIds,
    options: getOptions(state, optionIds),
  }
}

const mapDispatchToProps = dispatch => ({
  onChange: (optionId, value) =>
    dispatch(
      changeOptionValue({
        optionId,
        value,
      })
    ),
})

const OptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Options))

export default OptionsContainer
