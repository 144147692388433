import React from 'react'
import Actions from './actions'

const Image = ({ item, className }) => {
  return (
    <Actions item={item}>
      {({ open }) => {
        if (open) {
          className += ' doc__img--clickable'
          return (
            <img
              src={item.preview2Url}
              alt={item.title}
              className={className}
              onClick={open}
            />
          )
        }
        return (
          <img src={item.preview2Url} alt={item.title} className={className} />
        )
      }}
    </Actions>
  )
}

export default Image
