import React from 'react'
import Product from './ProductContainer'

const MultipleProducts = ({ productIds }) => {
  return productIds.map(productId => (
    <Product key={productId} productId={productId} />
  ))
}

export default MultipleProducts
