import { createAction } from 'redux-act'
import { getOptionValue } from 'constructors/ducks/values/selectors'
import { changeOptionValue } from 'constructors/ducks/values/actions'

export const setOptions = createAction('set options')
export const changeOptionAction = createAction('change option')
export const resetAllOptions = createAction('reset all options')

export const changeOption = params => (dispatch, getState) => {
  dispatch(syncMinMaxValues(params))
  return dispatch(changeOptionAction(params))
}

const syncMinMaxValues = params => (dispatch, getState) => {
  if (!params.content.max && !params.content.min) return

  const { optionId } = params
  const state = getState()
  const optionValue = getOptionValue(state, params.optionId)

  if (params.content.min && optionValue < params.content.min)
    dispatch(changeOptionValue({ optionId, value: params.content.min }))

  if (params.content.max && optionValue > params.content.max)
    dispatch(changeOptionValue({ optionId, value: params.content.max }))
}

export default {
  setOptions,
  changeOption,
}
