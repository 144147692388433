import { connect } from 'react-redux'
import { getAllProducts } from 'main/selectors/products'

import List from 'main/components/products/list'

const mapStateToProps = state => ({
  products: getAllProducts(state).toJS(),
})

export default connect(mapStateToProps)(List)
