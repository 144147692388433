import { getCategoryNameById } from 'main/selectors/news-categories'

export const getNewsCompilations = state => {
  // TODO do with immutablejs. Move this to deserialization stage.
  const data = state.get('newsCompilations').toJS()

  return {
    ...data,
    records: data.records.map(record => ({
      ...record,
      categories: groupNewsByCategory(record.news, state),
    })),
  }
}

const groupNewsByCategory = (news, state) => {
  if (!news) return news

  const categories = news.reduce((r, item) => {
    r[item.categoryId] = r[item.categoryId] || []
    r[item.categoryId].push(item)
    return r
  }, Object.create(null))

  return Object.keys(categories).map(categoryId => ({
    id: categoryId,
    name: getCategoryNameById(state, categoryId),
    news: categories[categoryId],
  }))
}
