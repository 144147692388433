import config from 'entry-specific/config'

export default (path, defaultValue = '') => {
  let res = config('naming')

  try {
    path.split('.').forEach(key => (res = res[key]))
    return res || defaultValue
  } catch (error) {
    return defaultValue
  }
}
