import React, { Component } from 'react'
import NestedSelect from './nested-select'

class ScriptSelects extends Component {
  state = {
    inFocus: true,
  }

  componentDidMount() {
    document.addEventListener('scroll', this.checkFocus)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.checkFocus)
  }

  checkFocus = () => {
    const { currentScriptPath } = this.props
    if (!currentScriptPath || !currentScriptPath.length) return
    const { inFocus } = this.state
    const scrolled = window.pageYOffset || document.documentElement.scrollTop
    const isScrolled = scrolled > 0

    if (
      inFocus === isScrolled &&
      document.getElementsByTagName('html')[0].style.position !== 'fixed'
    ) {
      this.setState({ inFocus: !inFocus })
    }
  }

  onChange = (...args) => {
    this.props.onChange(...args)
    window.scrollTo(0, 0)
  }

  render() {
    const { currentScriptPath, salesScripts } = this.props
    const { inFocus } = this.state

    let wrapClassName = 'card scripts-container__row'
    if (inFocus) wrapClassName += ' card--focus'

    return (
      <div className={wrapClassName}>
        <div className="card__title">Выбранный скрипт</div>
        <div className="card__content">
          <div className="inputs card__inputs">
            <NestedSelect
              pathIndex={0}
              currentScriptPath={currentScriptPath}
              salesScripts={salesScripts}
              onChange={this.onChange}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ScriptSelects
