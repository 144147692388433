import { pad } from 'common/modules/string-helper'

const DIGITS = 3

// 10 -> hides in constructor
export const isHiddenInConstructor = visibility => {
  return getVisibilityAt(visibility, 1)
}

// 01 -> hides in invoice
export const isHiddenInInvoice = visibility => {
  return getVisibilityAt(visibility, 0)
}

// 100 -> shows in connection stage
export const getVisibilityAt = (visibilityNum = 0, digit = 0) => {
  const visibilityStr = getVisibilityBinaryString(visibilityNum)
  const visibility = parseInt(visibilityStr.charAt(DIGITS - 1 - digit), 2)

  if (digit === 2) return !visibility // inversion for connection stage
  return !!visibility
}

const getVisibilityBinaryString = visibility => {
  const binaryStr = (visibility >>> 0).toString(2)
  return pad(binaryStr, DIGITS)
}
