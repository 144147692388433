import React from 'react'

import ActionButtons from './action-buttons'
import Actions from './actions'
import Image from './image'
import { getExtClassName } from './helpers'

const LandscapeDocs = ({ section }) => {
  return (
    <div className="docs">
      <div className="docs__wrapper">
        {section.items &&
          section.items.map((item, i) => (
            <div key={i} className="doc doc--landscape docs__item">
              <Image item={item} className="doc__img doc__img--landscape" />
              <div className="doc__content doc__content--bottom">
                <div className={getExtClassName(item.fileType)}>
                  {item.fileType}
                </div>
                <Actions item={item}>
                  {({ open, downloadUrl }) => {
                    if (open)
                      return (
                        <div
                          className="doc__name doc__name--bottom"
                          onClick={open}
                        >
                          {item.title}
                        </div>
                      )
                    return (
                      <a
                        className="doc__name doc__name--bottom"
                        href={downloadUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.title}
                      </a>
                    )
                  }}
                </Actions>
                <div className="doc__size doc__size--bottom">{item.size}</div>
                <ActionButtons item={item} />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default LandscapeDocs
