import React from 'react'
import Price from './Price'
import Ticker from './Ticker'

const Mandatory = ({ option }) => {
  return (
    <tr className="options__row options__row--mandatory">
      <td className="options__field">
        <Ticker text={option.name} />
      </td>
      <td className="options__price">
        <div className="options__font">
          <div className="options__text">
            <Price price={option.price} value={1} />
          </div>
        </div>
      </td>
    </tr>
  )
}

export default Mandatory
