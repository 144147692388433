import React, { Component, Fragment } from 'react'
import NestedSelect from './nested-select'
import Options from './options'

class NestedScriptSelect extends Component {
  constructor(props) {
    super(props)
    this.selectRef = React.createRef()
  }

  state = {
    isOpen: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.outsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.outsideClick)
  }

  outsideClick = event => {
    if (!this.state.isOpen) return
    if (
      this.selectRef.current &&
      !this.selectRef.current.contains(event.target)
    ) {
      this.closeOptions()
    }
  }

  toggleOptions = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  openOptions = () => {
    this.setState({ isOpen: true })
  }

  closeOptions = () => {
    this.setState({ isOpen: false })
  }

  changeAndClose = selectedIndex => {
    const { pathIndex, currentScriptPath, onChange } = this.props

    let newPath = []
    for (let i = 0; i <= pathIndex; i++) {
      if (i === pathIndex) {
        newPath[i] = selectedIndex
      } else {
        newPath[i] = currentScriptPath[i]
      }
    }

    onChange(newPath)
    this.closeOptions()
  }

  getSelectedPart = () => {
    const { pathIndex } = this.props
    return this.getPartByNestIndex(pathIndex + 1)
  }

  getItems = () => {
    const { pathIndex } = this.props
    return this.getPartByNestIndex(pathIndex).items
  }

  getPartByNestIndex = nestIndex => {
    const { currentScriptPath, salesScripts } = this.props

    let selectedPart = {
      name: '...',
      items: salesScripts,
    }

    try {
      for (let i = 0; i < nestIndex; i++) {
        selectedPart = selectedPart.items[currentScriptPath[i]]
      }
    } catch (e) {
      return selectedPart
    }

    return selectedPart
  }

  hasNestedPath = () => {
    const selectedPart = this.getSelectedPart()

    if (selectedPart && selectedPart.items && selectedPart.items.length)
      return true

    return false
  }

  render() {
    const { pathIndex, currentScriptPath, salesScripts, onChange } = this.props

    const { isOpen } = this.state
    const selectedPart = this.getSelectedPart()

    return (
      <Fragment>
        <div className="inputs__row inputs__row--small">
          <div className="inputs__item inputs__item--one">
            <div ref={this.selectRef} className="select">
              <div className="select__box" onClick={this.toggleOptions}>
                {selectedPart ? selectedPart.name : '...'}
              </div>
              <Options
                isOpen={isOpen}
                items={this.getItems()}
                selectedIndex={currentScriptPath[pathIndex]}
                onClose={this.closeOptions}
                onChange={this.changeAndClose}
              />
            </div>
          </div>
        </div>
        {this.hasNestedPath() && (
          <NestedSelect
            pathIndex={pathIndex + 1}
            currentScriptPath={currentScriptPath}
            salesScripts={salesScripts}
            onChange={onChange}
          />
        )}
      </Fragment>
    )
  }
}

export default NestedScriptSelect
