import React from 'react'

import Item from 'main/components/sales-scripts/card/item'

const Fork = ({ content: { value } }) => {
  return (
    <div className="script__fork">
      {value.map((fork, scriptIndex) => (
        <div key={scriptIndex} className="script__fork-block">
          <div className="script__fork-title">{fork.title}</div>
          {fork.value.map((item, itemIndex) => (
            <Item key={itemIndex} content={item} />
          ))}
        </div>
      ))}
    </div>
  )
}

export default Fork
