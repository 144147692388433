import React, { Component } from 'react'

class Choice extends Component {
  render() {
    const { content, currentValue, onChange } = this.props

    return (
      <div className="script__choose">
        <div className="script__man">{content.who}</div>
        <div className="script__block">
          <div className="script__step">{content.name}</div>
          {content.options.map((option, index) => (
            <label key={option.value} className="script__label">
              <input
                type={content.inputType}
                className="script__radio-input"
                name={content.calculationName}
                value={option.value}
                checked={option.value === currentValue}
                onChange={onChange.bind(
                  null,
                  content.calculationName,
                  option.value
                )}
              />

              <div className="script__radio-block" />
              {option.label}
            </label>
          ))}
        </div>
      </div>
    )
  }
}

export default Choice
