import React from 'react'
import Actions from './actions'

const DocumentActionButtons = ({ className, item }) => {
  return (
    <Actions item={item}>
      {({ open, downloadUrl }) => (
        <div className={className ? className : 'doc__actions'}>
          {downloadUrl && (
            <a
              href={downloadUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="action doc__action"
            >
              <div className="action__button action__button--download" />
            </a>
          )}
          {open && (
            <span target="_blank" className="action doc__action" onClick={open}>
              <div className="action__button action__button--preview" />
            </span>
          )}
        </div>
      )}
    </Actions>
  )
}

export default DocumentActionButtons
