export const SET_CALCULATIONS = 'SALES_SCRIPTS_SET_CALCULATIONS'
export const RESET_CALCULATIONS = 'SALES_SCRIPTS_RESET_CALCULATIONS'

export const setCalculations = calculations => ({
  type: SET_CALCULATIONS,
  calculations,
})

export const resetCalculations = () => ({
  type: RESET_CALCULATIONS,
})
