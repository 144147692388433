import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import appRoutes from 'main-v2/pages/routes'
import {
  getAdaptationDay,
  getAdaptationDayIndex,
} from 'main-v2/ducks/adaptation/selectors'
import { fetchFailedQuestions } from 'main-v2/ducks/failed-questions/actions'
import { getAdaptationTest } from 'main-v2/ducks/adaptation/selectors'
import {
  getFailedQuestionsByDayId,
  getIsFirstAttemptByDayId,
} from 'main-v2/ducks/failed-questions/selectors'
import AdaptationDay from './AdaptationDay'
import toJS from 'common/HOC/to-js'

const mapStateToProps = (state, ownProps) => {
  const dayId = ownProps.match.params.dayId
  return {
    dayIndex: getAdaptationDayIndex(state, dayId),
    day: getAdaptationDay(state, dayId),
    test: getAdaptationTest(state, dayId),
    failedQuestions: getFailedQuestionsByDayId(state, dayId),
    isFirstAttempt: getIsFirstAttemptByDayId(state, dayId),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchFailedQuestions: () =>
    dispatch(fetchFailedQuestions(ownProps.match.params.dayId)),
  closeTestResult: () =>
    dispatch(
      push(appRoutes.adaptation.day({ dayId: ownProps.match.params.dayId }))
    ),
})

const AdaptationDayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(AdaptationDay))

export default AdaptationDayContainer
