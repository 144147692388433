import { Map, fromJS } from 'immutable'
import { createSelector } from 'reselect'
import { getQueryParams } from 'common/ducks/routing/selectors'
import {
  isProductHasAnySelectedOptions,
  getOptionValue,
  getNestedOption,
} from 'constructors/ducks/values/selectors'
import { clientIsValid } from 'constructors/ducks/client/selectors'
import { getModel } from 'constructors/ducks/model/selectors'
import currentUser from 'common/modules/current-user'

export const getStages = createSelector(
  state => state,
  getModel,
  (state, model) => {
    if (
      currentUser.isProposalWizard &&
      !model.get('id') &&
      !model.get('branch')
    ) {
      return fromJS([
        {
          name: 'Выбор региона',
          type: 'selectBranch',
        },
        ...state.getIn(['currentProposal', 'stages']).toJS(),
      ])
    }
    return state.getIn(['currentProposal', 'stages'])
  }
)
export const getStagesCount = createSelector(getStages, stages =>
  stages ? stages.size : 0
)

export const getStageByType = createSelector(
  getStages,
  (state, type) => type,
  (stages, type) => stages.find(s => s.get('type') === type)
)

export const getCurrentStageIndex = createSelector(
  getQueryParams,
  queryParams => parseInt(queryParams.stage, 10) || 0
)

export const getCurrentStage = createSelector(
  getStages,
  getCurrentStageIndex,
  (stages, index) => stages.get(index) || Map()
)

export const getNextText = createSelector(
  state => state,
  getStages,
  getCurrentStageIndex,
  (state, stages, currentStageIndex) => {
    if (!canBeOnNextStage(state)) return null

    return stages.size === currentStageIndex + 1
      ? getLastStageName(stages.get(currentStageIndex))
      : 'Продолжить'
  }
)

export const isDemoMode = createSelector(
  state => state,
  getStages,
  getCurrentStageIndex,
  (state, stages, currentStageIndex) =>
    stages.getIn([currentStageIndex, 'demo'])
)

const getLastStageName = stage => {
  if (!stage) return ''
  if (stage.get('type') === 'selectBranch') return 'Продолжить'
  return stage.get('demo')
    ? 'Демо-режим<span>, формирование КП недоступно</span>'
    : 'Сформировать<span> Коммерческое Предложение</span>'
}

export const canBeOnNextStage = createSelector(
  state => state,
  getCurrentStage,
  (state, stage) => {
    if (stage.get('type') === 'client') return clientIsValid(state)
    if (stage.get('type') === 'selectOneProduct') {
      if (stage.getIn(['productIds', 0]).indexOf('monoWithoutCheck') !== -1)
        return true

      return stage
        .get('productIds')
        .some(productId => isProductHasAnySelectedOptions(state, productId))
    }
    if (stage.get('type') === 'selectOneOption') {
      return !!getOptionValue(
        state,
        getNestedOption(state, stage.get('optionId')).get('id')
      )
    }
    return true
  }
)
