import React, { Component } from 'react'
import animateScrollTo from 'animated-scroll-to'
import { getContentItemId } from 'main/modules/cards-helper'
import ListItem from './list-item'
import debounce from 'common/modules/debounce'
// import bodyScroll from 'common/modules/body-noscroll'

class ContentsList extends Component {
  contentsRef = React.createRef()
  contentsScrollOffset = {
    top: 60,
    bottom: 60,
  }

  componentDidMount() {
    window.addEventListener('resize', this.adjustContentsHeight)
    this.adjustContentsHeight()

    this.debouncedAdjustLastCardMargin = debounce(adjustLastCardMargin, 700)
    window.addEventListener('resize', this.debouncedAdjustLastCardMargin)
    adjustLastCardMargin()
  }

  componentDidUpdate() {
    this.adjustContentsHeight()
    this.checkCountentsScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustContentsHeight)
    window.removeEventListener('resize', this.debouncedAdjustLastCardMargin)
  }

  adjustContentsHeight = () => {
    const { currentScriptPath, heightAdjust = 324 } = this.props

    this.contentsRef.current.style.height =
      window.innerHeight - heightAdjust - currentScriptPath.length * 46 + 'px'
  }

  checkCountentsScroll = () => {
    const contentsEl = this.contentsRef.current

    const { currentCard } = this.props
    if (!currentCard) return (contentsEl.scrollTop = 0)

    const listEl = document.getElementById(getContentItemId(currentCard))
    if (!listEl) return

    const contentsRect = contentsEl.getBoundingClientRect()
    const elRect = listEl.getBoundingClientRect()
    const scrollTop = contentsEl.scrollTop + this.contentsScrollOffset.top
    const scrollBottom =
      contentsEl.scrollTop +
      contentsRect.height -
      this.contentsScrollOffset.bottom
    const elTop = elRect.top + contentsEl.scrollTop - contentsRect.top
    const elBottom = elTop + listEl.offsetHeight

    if (elTop < scrollBottom && elBottom > scrollTop) return

    const adjustFromTop = elTop - scrollTop
    const adjustFromBottom = elBottom - scrollBottom

    if (Math.abs(adjustFromTop) > Math.abs(adjustFromBottom)) {
      return this.adjustContentsScroll(adjustFromBottom)
    }

    this.adjustContentsScroll(adjustFromTop)
  }

  adjustContentsScroll = adjustPX => {
    const {
      contentsRef: { current: contentsEl },
    } = this
    const scrollTo = contentsEl.scrollTop + adjustPX
    if (scrollTo <= 0) {
      animateScrollTo(0, {
        element: contentsEl,
        maxDuration: 200,
      })
    }

    animateScrollTo(scrollTo, {
      element: contentsEl,
      maxDuration: 200,
    })
  }

  render() {
    const { cards, currentCard } = this.props

    return (
      <div className="contents" ref={this.contentsRef}>
        <ol className="contents__list">
          {cards.map((card, index) => (
            <ListItem
              key={card.id}
              card={card}
              currentCard={currentCard}
              num={`${index + 1}.`}
            />
          ))}
        </ol>
      </div>
    )
  }
}

const adjustLastCardMargin = () => {
  const cardsEl = document.getElementsByClassName('scripts-container__row')
  if (!cardsEl.length) return

  const lastCardEl = cardsEl[cardsEl.length - 1]
  if (window.innerWidth < 1024) return (lastCardEl.style.marginBottom = 0)

  const targetMargin = window.outerHeight - 261 - lastCardEl.offsetHeight
  lastCardEl.style.marginBottom = `${targetMargin > 0 ? targetMargin : 0}px`
}

export default ContentsList
