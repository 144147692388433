import queryString from 'query-string'

export const withLocationParams = (url, options = {}) => {
  return `${url}${getQueryString(getParams(options))}`
}

const getParams = options => {
  return Object.assign({}, getLocationParams(options), getPassedParams(options))
}

const getLocationParams = options => {
  if (options.resetParams) return null

  return queryString.parse(
    window.location.search.slice(1, window.location.search.length)
  )
}

const getPassedParams = options => {
  return typeof options.params === 'string'
    ? queryString.parse(options.params)
    : options.params
}

const getQueryString = params => {
  const str = queryString.stringify(params)
  if (!str) return ''
  return `?${str}`
}
