import React from 'react'
import { connect } from 'react-redux'
import toJS from 'common/HOC/to-js'
import { getOptionValue } from 'constructors/ducks/values/selectors'
import { getOption } from 'constructors/ducks/options/selectors'
import { formatPrice } from 'common/modules/price-helper'

const mapStateToProps = (state, { optionId }) => ({
  option: getOption(state, optionId),
  value: getOptionValue(state, optionId),
})

const SpoilerNestedOptions = ({ option, value }) => {
  if (!value) return null

  return (
    <div className="options-info__row">
      <span className="options-info__name">{option.name}</span>
      <span className="options-info__price">
        {getValueText(value)}
        {getPriceText(option.price)}
      </span>
    </div>
  )
}

const getValueText = value => (value > 1 ? `${value} x ` : '')

const getPriceText = price => {
  if (price.monthly) {
    return `${formatPrice(price.monthly.value)} ${price.monthly.caption}`
  }

  if (price.once) {
    return `${formatPrice(price.once.value)} ${price.once.caption}`
  }
  return ''
}

export default connect(mapStateToProps)(toJS(SpoilerNestedOptions))
