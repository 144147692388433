import React from 'react'
import { connect } from 'react-redux'
import toJS from 'common/HOC/to-js'
import cn from 'classnames'

import {
  setSalesScriptCursor,
  dropSalesScriptCursor,
  getSalesScriptCursor,
  getTotal,
  getFilterText,
} from 'main-v2/ducks/proposals'

import LeftArrowIcon from 'styles/assets/svg/proposal-script/left-arrow.svg'
import RightArrowIcon from 'styles/assets/svg/proposal-script/right-arrow.svg'
import Actions from 'main-v2/components/proposal-script/Actions'

const mapStateToProps = state => ({
  total: getTotal(state),
  cursor: getSalesScriptCursor(state),
  filterText: getFilterText(state),
})

const mapDispatchToProps = dispatch => ({
  back: () => dispatch(dropSalesScriptCursor()),
  setCursor: cursor => dispatch(setSalesScriptCursor(cursor)),
})

const ProposalScriptHeader = props => {
  const { cursor, total, back, setCursor, filterText, wrapperRef } = props
  return (
    <div ref={wrapperRef} className="workspace-nav">
      <div className="workspace-nav__block">
        <button className="button workspace-nav__back" onClick={back}>
          Назад к списку
        </button>
        <div className="workspace-nav__title">
          <div className="workspace-cursor">
            <div
              className={cn('workspace-cursor__prev', {
                'workspace-cursor__prev--disabled': !hasPrev(cursor),
              })}
              onClick={() => (hasPrev(cursor) ? setCursor(cursor - 1) : null)}
            >
              <LeftArrowIcon />
            </div>
            <div className="workspace-cursor__data">
              <div className="workspace-cursor__position">
                Предложение {cursor + 1}/{total}
              </div>
              <div className="workspace-cursor__filters">
                Выборка: {filterText}
              </div>
            </div>
            <div
              className={cn('workspace-cursor__next', {
                'workspace-cursor__next--disabled': !hasNext(cursor, total),
              })}
              onClick={() =>
                hasNext(cursor, total) ? setCursor(cursor + 1) : null
              }
            >
              <RightArrowIcon />
            </div>
          </div>
        </div>
        <div className="workspace-nav__actions">
          <Actions />
        </div>
      </div>
    </div>
  )
}

const hasPrev = cursor => cursor > 0
const hasNext = (cursor, total) => cursor < total - 1

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(toJS(ProposalScriptHeader))
