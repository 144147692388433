import { fromJS } from 'immutable'
import { createReducer } from 'redux-act'
import {
  fetchBranchesStart,
  fetchBranchesSuccess,
  fetchBranchesError,
} from './actions'

const initialState = {
  isLoading: false,
  macroregionalDivisions: {
    all: [],
    byName: {},
  },
  branches: {
    all: [],
    byId: {},
  },
}

const reducer = createReducer(
  {
    [fetchBranchesStart]: state => {
      return state.set('isLoading', true)
    },
    [fetchBranchesSuccess]: (state, payload) => {
      return state.mergeDeep({
        isLoading: false,
        ...payload,
      })
    },
    [fetchBranchesError]: state => {
      return state.set('isLoading', false)
    },
  },
  fromJS(initialState)
)

export default reducer
