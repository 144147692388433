import { fromJS } from 'immutable'

import {
  REQUEST_PRODUCTS,
  REQUEST_PRODUCTS_SUCCESS,
  REQUEST_PRODUCTS_ERROR,
} from 'main/actions/products'

const initialState = fromJS({
  isLoading: true,
  data: [],
})

const products = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return state.merge({ isLoading: true })

    case REQUEST_PRODUCTS_SUCCESS:
      return state.merge({
        isLoading: false,
        data: action.products,
      })

    case REQUEST_PRODUCTS_ERROR:
      return state.set('isLoading', false)

    default:
      return state
  }
}

export default products
