import { fromJS } from 'immutable'
import { createReducer } from 'redux-act'
import { setOptions, changeOptionAction, resetAllOptions } from './actions'

const initialState = {
  byId: {},
}

const reducer = createReducer(
  {
    [changeOptionAction]: (state, payload = {}) => {
      return state.update('byId', options =>
        options.mergeDeep({
          [payload.optionId]: payload.content,
        })
      )
    },
    [setOptions]: (state, payload = {}) => {
      return state.mergeDeep(payload)
    },
    [resetAllOptions]: state => {
      return fromJS(initialState)
    },
  },
  fromJS(initialState)
)

export default reducer
