import {
  REQUEST_PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
} from 'main/actions/password-reset'
import { fromJS } from 'immutable'

const defaultState = {
  isLoading: false,
  error: '',
}

const passwordReset = (state = fromJS(defaultState), action) => {
  switch (action.type) {
    case REQUEST_PASSWORD_RESET:
      return state.merge({
        isLoading: true,
        error: '',
      })
    case PASSWORD_RESET_SUCCESS:
      return state.set('isLoading', false)
    case PASSWORD_RESET_ERROR:
      return state.merge({
        isLoading: false,
        error:
          'Срок действия ключа истек или он уже использован. Запросите новый ключ',
      })
    default:
      return state
  }
}

export default passwordReset
