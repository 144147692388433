import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import ListItem from './ListItem'

import {
  getProposalIdsGroupedByDate,
  getLoader,
  fetchNextPage,
} from 'main-v2/ducks/proposals'
import toJS from 'common/HOC/to-js'
import throttle from 'common/modules/throttle'
import { getDateWithTodayYesterdayOrDayOfWeek } from 'common/modules/date-helper'

const mapStateToProps = state => ({
  isLoading: getLoader(state, 'nextPage'),
  proposalIdsGroupedByDate: getProposalIdsGroupedByDate(state),
})

const mapDispatchToProps = dispatch => ({
  fetchNextPage: () => dispatch(fetchNextPage()),
})

const ProposalsList = props => {
  const { proposalIdsGroupedByDate, isLoading, fetchNextPage } = props

  useEffect(
    () => {
      const fetchNextPageIfScrollEnds = () => {
        if (isLoading) return
        if (
          document.body.scrollHeight -
            document.documentElement.scrollTop -
            document.documentElement.clientHeight >
          100
        )
          return

        fetchNextPage()
      }
      const throttledFetchNextPageIfScrollEnds = throttle(
        fetchNextPageIfScrollEnds,
        700
      )
      fetchNextPageIfScrollEnds()

      document.addEventListener('scroll', throttledFetchNextPageIfScrollEnds)
      return () =>
        document.removeEventListener(
          'scroll',
          throttledFetchNextPageIfScrollEnds
        )
    },
    [isLoading]
  )

  return Object.keys(proposalIdsGroupedByDate)
    .sort((a, b) => (a > b ? -1 : 1))
    .map(date => (
      <Fragment key={date}>
        <div className="proposals__title">
          {getDateWithTodayYesterdayOrDayOfWeek(new Date(date))}
        </div>
        {proposalIdsGroupedByDate[date].map((id, index) => (
          <ListItem key={id} proposalId={id} />
        ))}
      </Fragment>
    ))
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(ProposalsList))
