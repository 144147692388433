import React, { Component } from 'react'

import appRoutes from 'main/constants/app-routes'
import { isValidPassword, isPasswordConfirmed } from 'main/modules/validations'
import queryString from 'query-string'

import { Link } from 'react-router-dom'
import AuthorizationLayout from 'main/components/authorization/layout'
import Button from 'main/components/authorization/button'

class PasswordReset extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: '',
      passwordConfirmation: '',
      validationError: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleEnterPress = this.handleEnterPress.bind(this)
    this.submit = this.submit.bind(this)
  }

  handleInputChange(event) {
    this.setState({ validationError: false })
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({ [name]: value })
  }

  handleEnterPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.submit()
    }
  }

  submit() {
    const { onSubmit, location } = this.props
    const { password, passwordConfirmation } = this.state

    if (!this.fieldsIsValid(password, passwordConfirmation)) {
      return this.setState({ validationError: true })
    }

    const resetToken = queryString.parse(location.search).reset_token
    onSubmit(password, resetToken)
  }

  fieldsIsValid(password, passwordConfirmation) {
    return (
      isValidPassword(password) &&
      isPasswordConfirmed(password, passwordConfirmation)
    )
  }

  render() {
    const { error, isLoading } = this.props
    const { password, passwordConfirmation, validationError } = this.state

    const hasError = error.length || validationError
    const errorText = error.length ? error : 'Пароли не совпадают'

    return (
      <AuthorizationLayout>
        <form className="authorization-layout__form">
          <div className="authorization-layout__input-group">
            <input
              type="password"
              className="authorization-input authorization-layout__input-password"
              placeholder="Новый пароль"
              name="password"
              value={password}
              onChange={this.handleInputChange}
              disabled={isLoading}
              onKeyPress={this.handleEnterPress}
            />
            <input
              type="password"
              className="authorization-input authorization-layout__input-password"
              placeholder="Повторите новый пароль"
              name="passwordConfirmation"
              value={passwordConfirmation}
              onChange={this.handleInputChange}
              disabled={isLoading}
              onKeyPress={this.handleEnterPress}
            />
          </div>
          {hasError && (
            <div className="authorization-layout__error">{errorText}</div>
          )}
          <Button
            isLoading={isLoading}
            text="Сохранить и Войти в систему"
            onClick={this.submit}
          />
        </form>
        {!isLoading && (
          <div className="authorization-layout__more">
            Вспомнили пароль?{' '}
            <Link className="authorization-layout__link" to={appRoutes.auth}>
              Войти в систему
            </Link>
          </div>
        )}
      </AuthorizationLayout>
    )
  }
}

export default PasswordReset
