import { connect } from 'react-redux'
import SelectBranch from './SelectBranch'
import { Map } from 'immutable'
import toJS from 'common/HOC/to-js'
import { fetchBranches } from 'main-v2/ducks/branches/actions'
import {
  getAllMacroregionalDivisions,
  getBranchById,
  getRelativeBranches,
} from 'main-v2/ducks/branches/selectors'
import { getModel } from 'constructors/ducks/model/selectors'
import { setModel } from 'constructors/ducks/model/actions'

const mapStateToProps = state => {
  const model = getModel(state)
  let branches = getRelativeBranches(state, getModel(state).get('branchId'))
  let macroregionalDivisions = getAllMacroregionalDivisions(state)
    .valueSeq()
    .map(m =>
      Map({
        id: m.get('name'),
        name: m.get('name'),
        branches: m.get('branches'),
      })
    )
  let currentBranch = getBranchById(state, model.get('branchId')) || Map({})

  // TODO: entry-specific
  if (model.get('constructorCode') === 'monoWithoutCheck') {
    macroregionalDivisions = macroregionalDivisions.filter(
      division => division.get('name') === 'Юг'
    )
    branches = branches.filter(
      branch => branch.get('name') === 'Ростовский филиал'
    )
    currentBranch = branches.get(0)
  }

  return {
    currentBranch,
    branches,
    macroregionalDivisions,
  }
}

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(fetchBranches()),
  changeBranchId: branchId => dispatch(setModel({ branchId })),
})

const SelectBranchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(SelectBranch))

export default SelectBranchContainer
