import { createAction } from 'redux-act'
import api from 'common/modules/api'
import { camelcaseKeys } from 'common/modules/camelcase-keys'

export const fetchAdaptationStart = createAction('fetch adaptation start')
export const fetchAdaptationSuccess = createAction('fetch adaptation success')
export const fetchAdaptationError = createAction('fetch adaptation error')

export const fetchAdaptation = () => dispatch => {
  dispatch(fetchAdaptationStart())

  return api.fetch(api.routes.adaptations.forUser()).then(response => {
    dispatch(fetchAdaptationSuccess(camelcaseKeys(response.json)))
    return response.json
  })
}
