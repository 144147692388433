import { fromJS } from 'immutable'
import { SET_CURRENT_SCRIPT_PATH } from 'main/actions/sales-scripts/current-script'

const initialState = {
  path: [],
}

const currentScript = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case SET_CURRENT_SCRIPT_PATH:
      return state.merge({ path: action.path })

    default:
      return state
  }
}

export default currentScript
