import React, { Component } from 'react'
import cn from 'classnames'
import {
  getMidnightDate,
  getLastDayOfMonth,
  getFirstDayOfMonth,
  getMonthNameAndYear,
} from 'common/modules/date-helper'
import ArrowPrevIcon from 'styles/assets/svg/datepicker/arrow-prev.svg'
import ArrowNextIcon from 'styles/assets/svg/datepicker/arrow-next.svg'

const MILLISECONDS_IN_DAY = 86400000

class Datepicker extends Component {
  state = {
    focusDate: new Date(),
  }

  render() {
    const { focusDate } = this.state
    const { date: selectedDate, onChange, minDate, maxDate } = this.props

    return (
      <div className="datepicker">
        <div className="datepicker__controls-container">
          <div
            className="datepicker__control-arrow"
            onClick={this.moveFocusToPrevMonth}
          >
            <ArrowPrevIcon />
          </div>
          <div className="datepicker__current-month">
            {getMonthNameAndYear(focusDate)}
          </div>
          <div
            className="datepicker__control-arrow"
            onClick={this.moveFocusToNextMonth}
          >
            <ArrowNextIcon />
          </div>
        </div>
        <div className="datepicker__weekdays-container">
          <span className="datepicker__weekday">Пн</span>
          <span className="datepicker__weekday">Вт</span>
          <span className="datepicker__weekday">Ср</span>
          <span className="datepicker__weekday">Чт</span>
          <span className="datepicker__weekday">Пт</span>
          <span className="datepicker__weekday">Сб</span>
          <span className="datepicker__weekday">Вс</span>
        </div>
        <div className="datepicker__days-container">
          {getWindowOfDays(focusDate).map(date => (
            <span
              key={date}
              className={getDayClassName({
                date,
                focusDate,
                selectedDate,
                minDate,
                maxDate,
              })}
              onClick={() =>
                isOutsideOfMinMaxRange({ date, minDate, maxDate })
                  ? null
                  : onChange(date)
              }
            >
              {date.getDate()}
            </span>
          ))}
        </div>
      </div>
    )
  }

  moveFocusToPrevMonth = () => {
    const { focusDate } = this.state
    this.setState({
      focusDate: new Date(focusDate.getFullYear(), focusDate.getMonth() - 1, 1),
    })
  }

  moveFocusToNextMonth = () => {
    const { focusDate } = this.state
    this.setState({
      focusDate: new Date(focusDate.getFullYear(), focusDate.getMonth() + 1, 1),
    })
  }
}

const getDayClassName = ({ date, focusDate, selectedDate, minDate, maxDate }) =>
  cn('datepicker__day', {
    'datepicker__day--selected': selectedDate
      ? getMidnightDate(date).getTime() ===
        getMidnightDate(selectedDate).getTime()
      : false,
    'datepicker__day--disabled': isOutsideOfMinMaxRange({
      date,
      minDate,
      maxDate,
    }),
    'datepicker__day--today':
      getMidnightDate(new Date()).getTime() === getMidnightDate(date).getTime(),
    'datepicker__day--other-month': date.getMonth() !== focusDate.getMonth(),
  })

const getWindowOfDays = date => [
  ...getDaysUntilMonday(getFirstDayOfMonth(date)),
  ...getWholeMonthDays(date),
  ...getDaysUntilSunday(getLastDayOfMonth(date)),
]

const getDaysUntilMonday = date => {
  const daysCount = countDaysUntilMonday(date)

  return range(daysCount).map(
    (_, index) =>
      new Date(date.getTime() - (daysCount - index) * MILLISECONDS_IN_DAY)
  )
}

const getDaysUntilSunday = date =>
  range(countDaysUntilSunday(date)).map(
    (_, index) => new Date(date.getTime() + (index + 1) * MILLISECONDS_IN_DAY)
  )

const getWholeMonthDays = date => {
  const daysCount = getLastDayOfMonth(date).getDate()
  const firstDay = getFirstDayOfMonth(date)

  return range(daysCount).map(
    (_, index) => new Date(firstDay.getTime() + index * MILLISECONDS_IN_DAY)
  )
}

const countDaysUntilMonday = date => {
  const currentWeekDay = date.getDay()
  if (currentWeekDay === 0) return 6
  return currentWeekDay - 1
}

const countDaysUntilSunday = date => {
  const currentWeekDay = date.getDay()
  if (currentWeekDay === 0) return 0
  return 7 - currentWeekDay
}

const isOutsideOfMinMaxRange = ({ date, minDate, maxDate }) =>
  (maxDate ? getMidnightDate(date).getTime() > maxDate.getTime() : false) ||
  (minDate ? getMidnightDate(date).getTime() < minDate.getTime() : false)

const range = count => [...Array(count).keys()]

export default Datepicker
