import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  saveClient,
  saveDecision,
  validateClient,
  statusMapping,
} from 'main-v2/ducks/proposals'
import toJS from 'common/HOC/to-js'
import { hasErrors } from 'common/modules/validations'
import Select from 'common/components/Select'
import debounce from 'common/modules/debounce'

const mapDispatchToProps = dispatch => ({
  saveClient: (id, client) => dispatch(saveClient({ id, client })),
  debouncedSaveClient: debounce(
    (id, client) => dispatch(saveClient({ id, client })),
    1000
  ),
  saveDecision: (id, decision) => dispatch(saveDecision({ id, decision })),
})

const emptyObj = {}

const ClientForm = props => {
  const {
    cursor,
    autosave,
    willSendEmail,
    saveClient,
    saveDecision,
    onSuccess,
    callClient,
    debouncedSaveClient,
  } = props
  const propsProposal = props.proposal || emptyObj
  const [proposal, setProposal] = useState(propsProposal)
  const [client, setClient] = useState(proposal.client || {})
  const [isLoading, setIsLoading] = useState(false)
  const [decisionLoader, setDecisionLoader] = useState(false)

  const listenForChangeProposal = autosave ? proposal : propsProposal

  useEffect(() => setProposal(propsProposal), [cursor, listenForChangeProposal])
  useEffect(() => setClient(propsProposal.client || emptyObj), [
    cursor,
    listenForChangeProposal.client,
  ])

  const errors = validateClient(client)

  const onChange = e => {
    const { name, value } = e.target
    const newClient = { ...client, [name]: value }

    setClient(newClient)
    if (autosave) debouncedSaveClient(proposal.id, newClient)
  }

  const onDecisionChange = decision => {
    if (!autosave) return setProposal({ ...proposal, decision: decision.id })

    setDecisionLoader(true)
    saveDecision(proposal.id, decision.id)
      .then(() => {
        setDecisionLoader(false)
        setProposal({ ...proposal, decision: decision.id })
      })
      .catch(() => setDecisionLoader(false))
  }

  const saveAll = () => {
    setIsLoading(true)
    Promise.all([
      saveClient(proposal.id, client),
      proposal.id ? saveDecision(proposal.id, proposal.decision) : null,
    ])
      .then(() => {
        setIsLoading(false)
        onSuccess(willSendEmail && client.email)
      })
      .catch(() => setIsLoading(false))
  }

  return (
    <Fragment>
      <label className="label">
        <div className="text label__text">
          Название компании{willValidate(errors.companyName) && '*'}
        </div>
        <input
          className="input label__input"
          type="text"
          placeholder="Название компании"
          value={client.companyName || ''}
          name="companyName"
          onChange={onChange}
          disabled={isLoading}
        />
      </label>
      <label className="label">
        <div className="text label__text">
          ФИО контактного лица{willValidate(errors.firstName) && '*'}
        </div>
        <input
          type="text"
          className="input label__input"
          placeholder="Петров Василий Иванович"
          value={client.firstName || ''}
          name="firstName"
          onChange={onChange}
          disabled={isLoading}
        />
      </label>
      <label className="label">
        <div className="text label__text">Телефон</div>
        <input
          type="text"
          className="input label__input"
          placeholder="+7(953)213-11-22"
          value={client.phone || ''}
          name="phone"
          onChange={onChange}
          disabled={isLoading}
        />
        {callClient && (
          <button
            className="call-action label__call-action"
            onClick={callClient}
          />
        )}
      </label>
      <label className="label">
        <div className="text label__text">
          Электронная почта{willValidate(errors.email) && '*'}
        </div>{' '}
        <input
          type="text"
          className="input label__input"
          placeholder="petrovasiliy@redtaxi.ru"
          value={client.email || ''}
          name="email"
          onChange={onChange}
          disabled={isLoading}
        />
        {errors.email && (
          <div className="text text--error text--mtop text--small">
            {errors.email}
          </div>
        )}
      </label>
      {willSendEmail && (
        <div className="infoBanner">
          <div className="infoBanner__inner">
            На указанную электронную почту будет отправлено письмо с
            предложением
          </div>
        </div>
      )}
      <label className="label">
        <div className="text label__text">ИНН</div>
        <input
          type="text"
          className="input label__input"
          placeholder="7719067895"
          value={client.tin || ''}
          name="tin"
          onChange={onChange}
          disabled={isLoading}
        />
      </label>
      {proposal.id && (
        <label className="label">
          <div className="text label__text">Статус</div>
          <Select
            title={
              decisionLoader
                ? 'Загрузка...'
                : statusMapping[proposal.decision] || 'Не указан'
            }
            options={DECISION_OPTIONS}
            onClick={onDecisionChange}
          />
        </label>
      )}
      {proposal.id && (
        <label className="label">
          <div className="text label__text">Комментарий</div>
          <textarea
            name="note"
            rows="5"
            className="textarea textarea--big"
            placeholder="..."
            value={client.note || ''}
            onChange={onChange}
          />
        </label>
      )}

      {!autosave && (
        <div className="text text--right text--top">
          <button
            type="button"
            className="button"
            disabled={hasErrors(errors)}
            onClick={saveAll}
          >
            {isLoading
              ? 'Загрузка...'
              : proposal.id
              ? 'Сохранить'
              : 'Сформировать предложение'}
          </button>
        </div>
      )}
    </Fragment>
  )
}

const willValidate = error => error !== undefined

const DECISION_OPTIONS = Object.keys(statusMapping).map(id => ({
  id,
  name: statusMapping[id],
}))

export default connect(
  null,
  mapDispatchToProps
)(toJS(ClientForm))
