import { List } from 'immutable'
import { createSelector } from 'reselect'
import { getOption } from 'constructors/ducks/options/selectors'

export const getAllProductsById = state => {
  return state.getIn(['currentProposal', 'products', 'byId'])
}

export const getAllProducts = createSelector(getAllProductsById, byId =>
  byId.valueSeq()
)

export const getProductsByIds = (state, ids) => {
  if (!ids) return List()
  return List(ids.map(id => getProduct(state, id)))
}

// TODO: memoization
export const getProduct = (state, productId) => {
  return state.getIn(['currentProposal', 'products', 'byId', productId])
}

export const getProductOptionIds = createSelector(getProduct, product =>
  product.get('options')
)

export const getProductOptions = createSelector(
  state => state,
  getProductOptionIds,
  (state, optionIds) => {
    return optionIds.map(optionId => getOption(state, optionId))
  }
)

export const getProductServices = createSelector(getProductOptions, options =>
  options.filter(option => !option.get('equipment'))
)

// TODO: для radio все его опции должны быть одного типа.
// equipment: true может быть только у самого radio так как мы не ходим проверять его детей
export const getProductEquipment = createSelector(getProductOptions, options =>
  options.filter(option => option.get('equipment'))
)
