import React, { Component, createContext } from 'react'
import VideoModal from 'main/components/modals/video'
import bodyNoscroll from 'common/modules/body-noscroll'

const Context = createContext({
  video: null,
  open: () => {},
  close: () => {},
})

class Provider extends Component {
  open = video => {
    this.setState({
      video,
    })
    bodyNoscroll.block()
  }

  close = () => {
    this.setState({
      video: null,
    })
    bodyNoscroll.unblock()
  }

  state = {
    video: null,
    open: this.open,
    close: this.close,
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

const VideoModalWithContext = () => (
  <Context.Consumer>
    {({ video, close }) => <VideoModal video={video} close={close} />}
  </Context.Consumer>
)

export default {
  Provider,
  Consumer: Context.Consumer,
  Component: VideoModalWithContext,
}
