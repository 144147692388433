import React from 'react'
import Price from './Price'
import { getIntegerBetween } from 'common/modules/number-helper'
import Ticker from './Ticker'

const DEFAULT_MIN = 0
const DEFAULT_MAX = 10000

const Counter = ({ option, value, onChange }) => {
  return (
    <tr className={getClassName(option, value)}>
      <td className="options__field">
        <div className="options__count-block">
          <Ticker text={option.name} />
          <CounterInput onChange={onChange} option={option} value={value} />
        </div>
      </td>
      <td className="options__price">
        <div className="options__font">
          <div className="options__text">
            <Price price={option.price} value={value} />
          </div>
        </div>
      </td>
    </tr>
  )
}

export const CounterInput = ({ onChange, option, value }) => {
  return (
    <div className="options__counter">
      <div
        className={getIncreaseClass(option, value)}
        onClick={increaseValue(onChange, option, value)}
      />
      <div
        className={getDecreaseClass(option, value)}
        onClick={decreaseValue(onChange, option, value)}
      />
      <input
        className="options__input"
        type="text"
        value={value}
        disabled={isDisabled(option, value)}
        onChange={changeValue(onChange, option, value)}
      />
    </div>
  )
}

const getClassName = (option, value) => {
  let className = 'options__row'
  if (value > 0) {
    className += ' options__row--active'
  }
  if (option.disabled && value === 0) {
    className += ' options__row--disabled'
  }
  if (option.disabled && value > 0) {
    className += ' options__row--noneditable'
  }
  return className
}

const getIncreaseClass = (option, value) => {
  let className = 'options__increase'
  if (canIncrease(option, value)) {
    className += ' options__increase--active'
  }
  return className
}

const getDecreaseClass = (option, value) => {
  let className = 'options__decrease'
  if (canDecrease(option, value)) {
    className += ' options__decrease--active'
  }
  return className
}

const increaseValue = (onChange, option, value) => () => {
  if (canIncrease(option, value)) {
    return onChange(option.id, value + 1)
  }
}

const decreaseValue = (onChange, option, value) => () => {
  if (canDecrease(option, value)) {
    return onChange(option.id, value - 1)
  }
}

const canIncrease = (option, value) => {
  const max = option.max > -1 ? option.max : DEFAULT_MAX
  return value < max
}

const canDecrease = (option, value) => {
  const min = option.min || DEFAULT_MIN
  return value > min
}

const isDisabled = (option, value) => {
  return !canDecrease(option, value) && !canIncrease(option, value)
}

const changeValue = (onChange, option, value) => e => {
  const newValue = getIntegerBetween(
    e.target.value,
    option.min || DEFAULT_MIN,
    option.max || DEFAULT_MAX
  )
  if (newValue !== value) {
    onChange(option.id, newValue)
  }
}

export default Counter
