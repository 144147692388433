import React, { Component, Fragment } from 'react'

import VideoItem from './item'
import VideoModal from 'main/components/modals/video'

class Videos extends Component {
  constructor(props) {
    super(props)

    this.state = {
      video: null,
    }

    this.openVideo = this.openVideo.bind(this)
    this.closeVideo = this.closeVideo.bind(this)
  }

  render() {
    const { section } = this.props
    const { video } = this.state

    return (
      <Fragment>
        <div className="docs">
          <div className="docs__wrapper">
            {section.items.map((item, i) => (
              <VideoItem key={i} item={item} openVideo={this.openVideo} />
            ))}
          </div>
        </div>
        <VideoModal video={video} close={this.closeVideo} />
      </Fragment>
    )
  }

  openVideo(video) {
    this.setState({ video })
  }

  closeVideo() {
    this.setState({ video: null })
  }
}

export default Videos
