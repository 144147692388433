import React from 'react'
import Price from './Price'
import Ticker from './Ticker'

const Checkbox = ({ option, value, onChange }) => {
  return (
    <tr className={getClassName(option, value)}>
      <td className="options__field">
        <label className="options__label">
          <input
            className="options__checkbox-input"
            type="checkbox"
            checked={!!value}
            onChange={carriedOnChange(onChange, option, value)}
          />
          <div className="options__checkbox-block" />
          <Ticker text={option.name} />
        </label>
      </td>
      <td className="options__price">
        <div className="options__font">
          <div className="options__text">
            <Price price={option.price} value={value} />
          </div>
        </div>
      </td>
    </tr>
  )
}

const getClassName = (option, value) => {
  let className = 'options__row'
  if (value > 0) {
    className += ' options__row--active'
  }
  if (option.disabled && value === 0) {
    className += ' options__row--disabled'
  }
  if (option.disabled && value > 0) {
    className += ' options__row--noneditable'
  }
  return className
}

const carriedOnChange = (onChange, option, value) => () => {
  if (option.disabled) return
  if (value) return onChange(option.id, 0)
  return onChange(option.id, 1)
}

export default Checkbox
