import { connect } from 'react-redux'
import {
  getCurrentStageIndex,
  getStages,
} from 'constructors/ducks/stages/selectors'
import toJS from 'common/HOC/to-js'

import StageIndicator from 'constructors/components/StageIndicator'

const mapStateToProps = state => ({
  stages: getStages(state),
  currentStageIndex: getCurrentStageIndex(state),
})

const StageIndicatorContainer = connect(mapStateToProps)(toJS(StageIndicator))

export default StageIndicatorContainer
