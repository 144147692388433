import api from 'common/modules/api'
import appRoutes from 'main/constants/app-routes'
import { push } from 'react-router-redux'

export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'

export const resetPassword = (password, resetToken) => {
  return dispatch => {
    dispatch(requestPasswordReset())

    const body = {
      password,
      password_confirmation: password,
      reset_token: resetToken,
    }

    api
      .fetch(api.routes.passwordReset(), { body })
      .then(response => {
        if (response.status === 204) {
          dispatch(push(appRoutes.auth))
          dispatch(passwordResetSuccess())
        } else {
          dispatch(passwordResetError())
        }
      })
      .catch(() => dispatch(passwordResetError()))
  }
}

const requestPasswordReset = () => ({
  type: REQUEST_PASSWORD_RESET,
})

const passwordResetSuccess = () => ({
  type: PASSWORD_RESET_SUCCESS,
})

const passwordResetError = () => ({
  type: PASSWORD_RESET_ERROR,
})
