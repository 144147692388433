import React, { Component } from 'react'
import { connect } from 'react-redux'
import toJS from 'common/HOC/to-js'
import { showDateTime } from 'common/modules/date-helper'
import appRoutes from 'main-v2/pages/routes'
import { withRouter } from 'react-router'

import { getById, deleteById, fetchList } from 'main-v2/ducks/visits/list'

import Dropdown from 'main/components/common/dropdown/dropdown'
import DropdownItem from 'main/components/common/dropdown/dropdown-item'
import Modal from 'common/components/Modal'

const mapStateToProps = (state, ownProps) => ({
  data: getById(state, ownProps.id),
})

const mapDispatchToProps = dispatch => ({
  fetchList: () => dispatch(fetchList()),
  onDelete: id => dispatch(deleteById({ id })),
})

class VisitItem extends Component {
  state = {
    visitConfirmationIsOpen: false,
  }

  onDelete = () => {
    const { fetchList, onDelete, history, data } = this.props
    onDelete(data.id).then(() => {
      fetchList()
      history.push(appRoutes.profile.index({}, { resetParams: true }))
    })
  }

  render() {
    const { data, history } = this.props
    const { visitConfirmationIsOpen } = this.state

    return (
      <div className="visits__item visit-item">
        <div className="visit-item__date-actions">
          <div className="visit-item__date">
            {showDateTime(data.updatedAt, { todayOrYesterday: true })}
          </div>
          <div className="visit-item__actions">
            <Dropdown>
              <DropdownItem
                onClick={() =>
                  history.push(appRoutes.profile.visits.edit({ id: data.id }))
                }
              >
                Редактировать данные
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  history.push(appRoutes.profile.visits.result({ id: data.id }))
                }
              >
                {data.result ? 'Изменить результат' : 'Внести результат'}
              </DropdownItem>

              <DropdownItem
                type="warning"
                onClick={() => {
                  this.setState({
                    visitConfirmationIsOpen: !this.visitConfirmationIsOpen,
                  })
                }}
              >
                Удалить
              </DropdownItem>
              <Modal
                className="main-layout__modal"
                title="Удаление визита"
                isOpen={visitConfirmationIsOpen}
                onClose={() =>
                  this.setState({ visitConfirmationIsOpen: false })
                }
                size="auto"
              >
                <div className="text">{`Вы действительно хотите удалить отметку о визите в компанию «${
                  data.companyName
                }»?`}</div>
                <div className="text text--right text--top">
                  <button
                    type="button"
                    className="button button--warning"
                    onClick={() => {
                      this.onDelete()
                      this.setState({ visitConfirmationIsOpen: false })
                    }}
                  >
                    Удалить
                  </button>
                </div>
              </Modal>
            </Dropdown>
          </div>
        </div>
        <div className="visit-item__name">{data.companyName}</div>
        <div className="visit-item__address">{data.address}</div>
        <div className="visit-item__row">
          <div className="visit-item__title">Цель визита</div>
          <div className="visit-item__text">{data.goal}</div>
        </div>
        {!data.result && (
          <div className="visit-item__row visit-item__row--result">
            <button
              type="button"
              className="button button--ghost"
              name="button"
              onClick={() =>
                history.push(appRoutes.profile.visits.result({ id: data.id }))
              }
            >
              Внести результат
            </button>
          </div>
        )}
        {data.result && (
          <div className="visit-item__row visit-item__row--result">
            <div className="visit-item__title">Результат</div>
            <div className="visit-item__text">{data.result}</div>
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(withRouter(VisitItem)))
