import { connect } from 'react-redux'

import {
  getCurrentScriptPath,
  getSalesScripts,
} from 'main/selectors/sales-scripts/current-script'
import { setCurrentScriptPath } from 'main/actions/sales-scripts/current-script'

import ScriptSelect from 'main/components/sales-scripts/script-select'

const mapStateToProps = state => ({
  currentScriptPath: getCurrentScriptPath(state).toJS(),
  salesScripts: getSalesScripts(state),
})

const mapDispatchToProps = dispatch => ({
  onChange: path => dispatch(setCurrentScriptPath(path)),
})

const ScriptSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScriptSelect)

export default ScriptSelectContainer
