import api from 'common/modules/api'
import appRoutes from 'main/constants/app-routes'
import { push } from 'react-router-redux'
import currentUser from 'common/modules/current-user'
import currentUserActions from 'common/ducks/current-user/actions'

export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const login = (email, password) => {
  return dispatch => {
    dispatch(requestLogin())

    const body = {
      email,
      password,
    }

    api
      .fetch(api.routes.tokens(), { body })
      .then(response => {
        if (response.json.token) {
          dispatch(currentUserActions.isNotInitialized())

          currentUser.login(response.json.token)
          dispatch(loginSuccess())
          dispatch(currentUserActions.set(currentUser))
          dispatch(push(appRoutes.root))

          dispatch(currentUserActions.initializeCurrentUser())
        } else {
          dispatch(loginError())
        }
      })
      .catch(() => dispatch(loginError()))
  }
}

const requestLogin = () => ({
  type: REQUEST_LOGIN,
})

const loginSuccess = currentUser => ({
  type: LOGIN_SUCCESS,
})

const loginError = () => ({
  type: LOGIN_ERROR,
})
