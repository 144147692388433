import { fromJS } from 'immutable'
import { getQueryParams } from 'common/ducks/routing/selectors'
import { createSelector } from 'reselect'
import constructorsInitialState from 'entry-specific/apps/manager/constructors'
import { initialState as valuesInitialState } from 'constructors/ducks/values/reducers'
import { initialState as modelInitialState } from 'constructors/ducks/model/reducers'
import { initialState as clientInitialState } from 'constructors/ducks/client/reducers'
import { initialState as statsInitialState } from 'constructors/ducks/stats/reducers'
import buildConstructorInitialState from 'constructors/modules/build-constructor-initial-state'
import { getModel } from 'constructors/ducks/model/selectors'
import currentUser from 'common/modules/current-user'

export const getIsLoading = state =>
  state.getIn(['currentProposal', 'constructor', 'isLoading'])

export const getIsInitializing = state =>
  state.getIn(['currentProposal', 'constructor', 'isInitializing'])

export const getConstructorId = createSelector(
  getQueryParams,
  queryParams => queryParams.constructorId
)

export const canInit = createSelector(
  getConstructorId,
  constructorId => !!constructorsInitialState[constructorId]
)

export const getInitializationParams = state => {
  const model = getModel(state)

  return {
    branch: model.get('branch') || currentUser.branchName,
    branchId: model.get('branchId') || currentUser.branchId,
    mrd: model.get('mrd') || currentUser.macroregionalDivision,
    isProposalWizard: currentUser.isProposalWizard,
  }
}

export const getRawConstructor = state => {
  const rawConstructor = getRawConstructorInitialState(state)
  const params = getInitializationParams(state)

  return buildConstructorInitialState(rawConstructor, params)
}

const getRawConstructorInitialState = state => {
  return constructorsInitialState[
    getConstructorId(state) || getModel(state).get('constructorCode')
  ]
}

// TODO: for edit
export const getInitialValues = createSelector(
  getRawConstructor,
  rawConstructor => {
    return fromJS(valuesInitialState)
      .update('products', products =>
        products.push(getFirstDefaultSelectedProduct(rawConstructor))
      )
      .toJS()
  }
)

// TODO: продукт может не существовать в данном регионе. Берем из stage, но первый stage может быть не продукт
const getFirstDefaultSelectedProduct = rawConstructor =>
  rawConstructor.stages[0].productIds
    ? rawConstructor.stages[0].productIds[0]
    : rawConstructor.stages[0].productId

// TODO: for edit
export const getInitialModel = state => {
  return Object.assign(
    {},
    modelInitialState,
    { constructorCode: getConstructorId(state) },
    getInitializationParams(state)
  )
}

export const getInitialClient = state => {
  return Object.assign({}, clientInitialState)
}

export const getInitialStats = state => {
  return Object.assign({}, statsInitialState)
}
