import React, { Fragment, Component } from 'react'

class MultipleQuestion extends Component {
  handleChange = e => {
    const { question, answer = { variantIds: [] }, onChange } = this.props
    const variantId = e.target.value
    const index = answer.variantIds.indexOf(e.target.value)
    let variantIds = answer.variantIds

    if (index === -1 && !e.target.checked) return
    if (index !== -1 && e.target.checked) return
    if (index === -1 && e.target.checked) {
      variantIds.push(variantId)
    }
    if (index !== -1 && !e.target.checked) {
      variantIds.splice(index, 1)
    }

    onChange(question.id, 'variantIds', variantIds)
  }

  render() {
    const { question, answer = { variantIds: [] } } = this.props

    return (
      <Fragment>
        <div className="test-card__question">{question.text}</div>
        <ul className="test-card__answers">
          {question.variants &&
            question.variants.map(variant => (
              <li key={variant.id} className="test-card__item">
                <label className="test-card__answer">
                  <input
                    type="checkbox"
                    name={question.id}
                    value={variant.id}
                    className="test-card__input"
                    checked={answer.variantIds.indexOf(variant.id) !== -1}
                    onChange={this.handleChange}
                  />
                  <div className="test-card__checkbox" />
                  <div className="test-card__label">{variant.text}</div>
                </label>
              </li>
            ))}
        </ul>
      </Fragment>
    )
  }
}

export default MultipleQuestion
