import React from 'react'
import PdfPreview from './pdf-preview'

const Actions = ({ item, children }) => {
  return (
    <PdfPreview.Consumer>
      {({ open: openPreview }) => {
        const downloadUrl = !item.isNotDownloadable ? item.fileUrl : null

        const open =
          item.fileType && item.fileType.toLowerCase() === 'pdf'
            ? curriedOpen(item, openPreview)
            : null

        return children({ downloadUrl, open })
      }}
    </PdfPreview.Consumer>
  )
}

const curriedOpen = (item, open) => () => open(item)

export default Actions
