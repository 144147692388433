import { combineReducers } from 'redux-immutable'
import routing from './routing'
import auth from './auth'
import resetInstructions from './reset-instructions'
import passwordReset from './password-reset'

import currentUser from 'common/ducks/current-user/reducers'
import currentProposal from 'constructors/reducers'
import proposals from 'main-v2/ducks/proposals'
import adaptation from 'main-v2/ducks/adaptation/reducers'
import answers from 'main-v2/ducks/answers/reducers'
import failedQuestions from 'main-v2/ducks/failed-questions/reducers'
import branches from 'main-v2/ducks/branches/reducers'

import salesScripts from './sales-scripts/'
import productsInfo from './products-info'
import newsCompilations from './news-compilations'
import newsCategories from './news-categories'

import visits from 'main-v2/ducks/visits'

export default combineReducers({
  routing,
  auth,
  resetInstructions,
  passwordReset,

  currentUser,
  currentProposal,
  proposals,
  branches,

  adaptation,
  answers,
  failedQuestions,

  salesScripts,
  productsInfo,
  newsCompilations,
  newsCategories,

  visits,
})
