import { createSelector } from 'reselect'
import { getProposalFromCursor } from 'main-v2/ducks/proposals'

export const getFields = createSelector(
  getProposalFromCursor,
  state => state.getIn(['salesScripts', 'autofill']),
  (proposal, autofill) => autofill.merge(proposal)
)
export const getInputValue = createSelector(
  getFields,
  (_, keyPath) => keyPath,
  (fields, keyPath) => fields.getIn([...keyPath])
)

// (state, keyPath) =>
//   state.getIn(['salesScripts', 'autofill', ...keyPath])
