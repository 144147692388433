import jwtDecode from 'jwt-decode'
import appRoutes from 'main/constants/app-routes'
import { replace } from 'react-router-redux'

let instance = null

class CurrentUser {
  constructor() {
    if (!instance) {
      instance = this
      const token = localStorage.getItem('token')
      if (token) {
        this.setDataFromToken(token)
      }
    }

    this._type = 'CurrentUser'

    return instance
  }

  setUserData(data) {
    Object.keys(data).forEach(key => (this[key] = data[key]))
  }

  setDataFromToken(token) {
    const decodedToken = jwtDecode(token)
    this.id = decodedToken.id
    this.expired = decodedToken.exp
    this.token = token
  }

  login(token) {
    localStorage.setItem('token', token)
    this.setDataFromToken(token)
  }

  isLoggedIn() {
    if (!this.token || !this.expired) {
      return false
    }
    const currentTime = new Date()
    return currentTime.getTime() < this.expired * 1000
  }

  logout() {
    return dispatch => {
      localStorage.removeItem('token')
      dispatch(replace(appRoutes.auth))
      document.execCommand('ClearAuthenticationCache', 'false')
    }
  }
}

export default new CurrentUser()
