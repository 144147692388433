import React from 'react'
import { Route } from 'react-router-dom'

import appRoutes from 'main/constants/app-routes'
import Nav from './nav'

const ProductItem = ({ product }) => {
  return (
    <div className="product-layout__nav-block">
      <div className="product-layout__name">{product.title}</div>
      <Route
        path={appRoutes.products.section()}
        render={props => <Nav product={product} match={props.match} />}
      />
    </div>
  )
}

export default ProductItem
