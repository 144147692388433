import React from 'react'
import { connect } from 'react-redux'
import { changeOption } from 'constructors/ducks/options/actions'
import SpoilerNestedOption from './SpoilerNestedOption'

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggle: () =>
    dispatch(
      changeOption({
        optionId: ownProps.option.id,
        content: {
          isOpen: !ownProps.option.isOpen,
        },
      })
    ),
})

const Spoiler = ({ option, nestedOptionIds, toggle }) => (
  <tr className="options__row options__row-title">
    <td className="options__field options__field--title" colSpan="2">
      <div className={getSpoilerClassName(option)} onClick={toggle}>
        <span>{option.name}</span>
      </div>
      <div className="options-info">
        {nestedOptionIds.map(optionId => (
          <SpoilerNestedOption key={optionId} optionId={optionId} />
        ))}
      </div>
    </td>
  </tr>
)

const getSpoilerClassName = option => {
  let className = 'options__spoiler'
  if (!option.isOpen) className += ' options__spoiler--hidden'
  return className
}

export default connect(
  null,
  mapDispatchToProps
)(Spoiler)
