import React from 'react'

const ProductSectionInteractives = ({ section }) => {
  return (
    <iframe
      title="interactives"
      src={window.location.origin+section.items[0].courseUrl}
      width="740"
      height="611"
    />
  )
}

export default ProductSectionInteractives
