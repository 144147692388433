import React, { Component, Fragment } from 'react'
import appRoutes from 'main/constants/app-routes'

class PdfPreviewIframe extends Component {
  constructor(props) {
    super(props)

    this.iframeRef = React.createRef()
  }

  componentWillUnmount() {
    this.stopObserveIframe()
    this.props.iframeIsUnloaded()
  }

  onLoadIframe = () => {
    this.adjustIframeHeight()
    this.startObserveIframe()
    this.props.iframeIsLoaded()
    this.loadNextPage()
    this.focusIframe()
  }

  startObserveIframe = () => {
    const target = this.iframeRef.current.contentWindow.document.body

    this.iframeObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type !== 'childList') return
        this.adjustIframeHeight()
      })
    })

    this.iframeObserver.observe(target, {
      attributes: false,
      attributeOldValue: false,
      characterData: false,
      characterDataOldValue: false,
      childList: true,
      subtree: true,
    })
  }

  stopObserveIframe = () => {
    if (this.iframeObserver) this.iframeObserver.disconnect()
  }

  adjustIframeHeight = () => {
    const iframe = this.iframeRef.current
    iframe.height = iframe.contentWindow.document.body.scrollHeight
  }

  loadNextPage() {
    const contentWindow = this.iframeRef.current.contentWindow
    if (!contentWindow || !contentWindow.showNextPage) return
    contentWindow.showNextPage()
  }

  focusIframe() {
    const contentWindow = this.iframeRef.current.contentWindow
    contentWindow.focus()
  }

  render() {
    const { url } = this.props

    return (
      <Fragment>
        <iframe
          ref={this.iframeRef}
          title={document.title}
          src={appRoutes.pdfPreview(url)}
          onLoad={this.onLoadIframe}
          frameBorder="0"
          scrolling="no"
          height="600px"
          width="100%"
        />
      </Fragment>
    )
  }
}

export default PdfPreviewIframe
