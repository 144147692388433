import api from 'common/modules/api'

export const INIT_RESET_INSTRUCTIONS = 'INIT_RESET_INSTRUCTIONS'
export const REQUEST_RESET_INSTRUCTIONS = 'REQUEST_RESET_INSTRUCTIONS'
export const RESET_INSTRUCTIONS_SUCCESS = 'RESET_INSTRUCTIONS_SUCCESS'
export const RESET_INSTRUCTIONS_ERROR = 'RESET_INSTRUCTIONS_ERROR'

export const sendResetInstructions = email => {
  return dispatch => {
    dispatch(requestResetInstructions())

    const body = {
      email,
    }

    api
      .fetch(api.routes.resetInstructions(), { body })
      .then(response => {
        if (response.status === 204) {
          dispatch(resetInstructionsSuccess())
        } else {
          dispatch(resetInstructionsError())
        }
      })
      .catch(() => dispatch(resetInstructionsError()))
  }
}

const requestResetInstructions = () => ({
  type: REQUEST_RESET_INSTRUCTIONS,
})

const resetInstructionsSuccess = () => ({
  type: RESET_INSTRUCTIONS_SUCCESS,
})

const resetInstructionsError = () => ({
  type: RESET_INSTRUCTIONS_ERROR,
})
