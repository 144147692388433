import React from 'react'
import OptionContainer from './OptionContainer'

const SubBlock = ({ name, hasOptions, optionIds }) => {
  if (!hasOptions) return null

  return (
    <div className="proposal-info__subblock">
      <div className="proposal-info__subtitle">{name}</div>
      {optionIds.map(optionId => (
        <OptionContainer key={optionId} optionId={optionId} />
      ))}
    </div>
  )
}

export default SubBlock
