export const isLoggedIn = state => {
  const user = state.get('currentUser')
  if (!user.get('token')) return false
  if (!user.get('expired')) return false

  if (new Date(user.get('expired') * 1000) < new Date()) return false

  return true
}

export const getCurrentUser = state => state.get('currentUser')
export const getToken = state => state.getIn(['currentUser', 'token'])
export const isCurrentUserInitialized = state =>
  state.getIn(['currentUser', 'isInitialized'])

export default {
  isLoggedIn,
  getToken,
  isCurrentUserInitialized,
}
