import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import { setClient, changeClient } from './actions'

export const initialState = {
  firstName: '',
  lastName: '',
  middleName: '',
  companyName: '',
  tin: '',
}

const reducer = createReducer(
  {
    [setClient]: (state, payload = {}) => {
      return state.merge(payload)
    },
    [changeClient]: (state, payload = {}) => {
      return state.merge(payload)
    },
  },
  fromJS(initialState)
)

export default reducer
