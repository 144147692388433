import { createAction, createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import api from 'common/modules/api'

import { fetchList, setById } from 'main-v2/ducks/visits/list'

// initials
export const initialData = {
  id: null,
  companyName: '',
  goal: '',
  result: '',
  geo: {
    lat: '',
    lng: '',
  },
  address: '',
}

const initialState = {
  isLoading: false,
  data: initialData,
}

// actions
const setIsLoading = createAction('visits.current - isLoading')
export const setCurrent = createAction('visits.current - set')
export const mergeCurrent = createAction('visits.current - merge')

// selectors
export const getIsLoading = state =>
  state.getIn(['visits', 'current', 'isLoading'])
export const getData = state => state.getIn(['visits', 'current', 'data'])

// action creators
export const fetchCurrent = ({ id }) => dispatch => {
  dispatch(setIsLoading(true))

  return api.fetch(api.routes.visits.show({ id })).then(response => {
    dispatch(
      setCurrent({
        data: response.json,
      })
    )
    dispatch(setIsLoading(false))
  })
}

export const geocode = queryParams => dispatch => {
  return api.fetch(api.routes.geocode(), { queryParams }).then(response => {
    dispatch(
      mergeCurrent({
        data: {
          geo: { lat: queryParams.lat, lng: queryParams.lng },
          address: response.json.address,
        },
      })
    )
  })
}

export const save = data => dispatch => {
  dispatch(setIsLoading(true))

  const route = data.id
    ? api.routes.visits.edit({ id: data.id })
    : api.routes.visits.new()

  return api.fetch(route, { body: data }).then(response => {
    dispatch(
      setCurrent({
        data: response.json,
      })
    )
    dispatch(setIsLoading(false))
    data.id ? dispatch(setById(response.json)) : dispatch(fetchList())
  })
}

// reducer
export default createReducer(
  {
    [setIsLoading]: (state, payload) => state.set('isLoading', payload),
    [setCurrent]: (state, payload) => state.merge(fromJS(payload)),
    [mergeCurrent]: (state, payload) => state.mergeDeep(fromJS(payload)),
  },
  fromJS(initialState)
)
