import { connect } from 'react-redux'
import { getCurrentScript } from 'main/selectors/sales-scripts/current-script'
import { getCurrentCard } from 'main/selectors/sales-scripts/current-card'
import { getCurrentScriptPath } from 'main/selectors/sales-scripts/current-script'

import Contents from 'main/components/sales-scripts/contents'

const mapStateToProps = state => {
  const currentScript = getCurrentScript(state)
  let cards = []

  if (currentScript && currentScript.cards) {
    cards = currentScript.cards
  }

  return {
    cards,
    currentScriptPath: getCurrentScriptPath(state).toJS(),
    currentCard: getCurrentCard(state),
  }
}

const ContentsContainer = connect(mapStateToProps)(Contents)

export default ContentsContainer
