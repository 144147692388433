import React from 'react'
import SelectOneOptionContainer from 'constructors/components/Stages/SelectOneOptionContainer'
import SmallRadioButtonContainer from 'constructors/components/Options/SmallRadioButtonContainer'
import OptionsContainer from 'constructors/components/Options/Container'
import CardBlock from 'constructors/components/CardBlock'

export default ({ stage }) => {
  return stage.blocks.map((block, index) => {
    if (block.isHidden) return null

    switch (block.type) {
      case 'selectOneOption':
        return <SelectOneOptionContainer key={index} stage={block} />
      case 'smallRadioButton':
        return (
          <CardBlock key={index} title={block.title}>
            <SmallRadioButtonContainer key={index} stage={block} />
          </CardBlock>
        )
      case 'options':
        return (
          <CardBlock key={index} title={block.title}>
            <OptionsContainer optionIds={block.optionIds} />
          </CardBlock>
        )
      default:
        return null
    }
  })
}
