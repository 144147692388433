const safeGet = (object, path, defaultValue) => {
  let index = 0
  path = path.split('.')
  let length = path.length

  while (object != null && index < length) {
    object = object[path[index++]]
  }
  return index && index === length && object !== undefined && object !== null
    ? object
    : defaultValue
}

export default safeGet
