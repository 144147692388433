import React, { Component } from 'react'

class Pagination extends Component {
  state = {
    current: 0,
    lastClickedButtonType: '',
  }

  static getDerivedStateFromProps(props, state) {
    return {
      current: props.current,
    }
  }

  onInputChange = e => {
    const { totalPages } = this.props
    const newValue = parseInt(e.target.value, 10)
    if (!newValue) return this.setState({ current: '' })
    if (newValue < 1) return this.setState({ current: 1 })
    if (newValue > totalPages) return this.setState({ current: totalPages })
    this.setState({ current: newValue })
  }

  handleEnterPress = e => {
    if (e.key !== 'Enter') return null
    e.preventDefault()

    const { current } = this.state
    if (!current) return
    this.props.onChange(current)
  }

  onPrev = () => {
    const { current } = this.props
    if (!current || current === 1) return
    this.setState({ lastClickedButtonType: 'prev' })
    this.props.onChange(current - 1)
  }

  onNext = () => {
    const { current, totalPages } = this.props
    if (!current || current === totalPages) return
    this.setState({ lastClickedButtonType: 'next' })
    this.props.onChange(current + 1)
  }

  getButtonClassName = buttonType => {
    const { isLoading } = this.props
    const { lastClickedButtonType } = this.state
    let className = 'pagination__move'
    if (isLoading && buttonType === lastClickedButtonType)
      className += ' pagination__move--loading'
    return `${className} ${
      this.isButtonActive(buttonType) ? 'pagination__move--active' : ''
    }`
  }

  isButtonActive = buttonType => {
    const { current, totalPages, isLoading } = this.props
    if (isLoading) return false
    if (buttonType === 'next' && current < totalPages) return true
    if (buttonType === 'prev' && current > 1) return true
    return false
  }

  render() {
    const { totalPages, isLoading } = this.props
    const { current } = this.state

    if (totalPages < 2) return null

    return (
      <div className="pagination">
        <div className="pagination__wrapper">
          <button
            className={this.getButtonClassName('prev')}
            onClick={this.onPrev}
            disabled={!this.isButtonActive('prev')}
          >
            <span>&larr; Назад</span>
          </button>
          <input
            className="pagination__page input input--small"
            type="text"
            placeholder="0"
            value={current}
            onChange={this.onInputChange}
            onKeyPress={this.handleEnterPress}
            disabled={isLoading}
          />
          <div className="pagination__count">
            из <span className="pagination__count-val">{totalPages} стр.</span>
          </div>
          <button
            className={this.getButtonClassName('next')}
            onClick={this.onNext}
            disabled={!this.isButtonActive('next')}
          >
            <span>Вперед &rarr;</span>
          </button>
        </div>
      </div>
    )
  }
}

export default Pagination
