import React from 'react'

import AcceptedIcon from 'styles/assets/svg/decisions/accepted.svg'
import RefusedIcon from 'styles/assets/svg/decisions/refused.svg'
import ThinkingIcon from 'styles/assets/svg/decisions/thinking.svg'
import MissedIcon from 'styles/assets/svg/decisions/missed.svg'
import DuplicateIcon from 'styles/assets/svg/decisions/duplicate.svg'
import MailIcon from 'styles/assets/svg/decisions/mail.svg'
import NotificationIcon from 'styles/assets/svg/decisions/notification.svg'
import WorkIcon from 'styles/assets/svg/decisions/work.svg'
import RecallIcon from 'styles/assets/svg/decisions/recall.svg'


const DecisionIcon = ({ type }) => {
  if (type === 'accepted') return <AcceptedIcon />
  if (type === 'refused') return <RefusedIcon />
  if (type === 'rejected') return <RefusedIcon />
  if (type === 'thinking') return <ThinkingIcon />
  if (type === 'missed') return <MissedIcon />
  if (type === 'duplicate') return <DuplicateIcon />
  if (type === 'noDecisionMaker') return <DuplicateIcon />
  if (type === 'proposalSent') return <MailIcon />
  if (type === 'waitDecision') return <ThinkingIcon />
  if (type === 'waitRequisites') return <ThinkingIcon />
  if (type === 'contractApplication') return <NotificationIcon />
  if (type === 'refusedNoReason') return <RefusedIcon />
  if (type === 'refusedHighPrices') return <RefusedIcon />
  if (type === 'refusedOtherSupplier') return <RefusedIcon />
  if (type === 'refusedVAT') return <RefusedIcon />
  if (type === 'refusedOtherReason') return <RefusedIcon />
  if (type === 'postponed') return <ThinkingIcon />
  if (type === 'callback') return <RecallIcon />
  if (type === 'managerWorks') return <WorkIcon />
  if (type === 'contractSigned') return <AcceptedIcon />
  if (type === 'acceptedClassic') return <AcceptedIcon />
  if (type === 'accepted10') return <AcceptedIcon />
  if (type === 'accepted5') return <AcceptedIcon />
  if (type === 'acceptedTotality') return <AcceptedIcon />
  if (type === 'rejectedExpensive') return <RefusedIcon />
  if (type === 'rejectedContender') return <RefusedIcon />
  if (type === 'rejectedOther') return <RefusedIcon />
  if (type === 'wait') return <ThinkingIcon />

  return null
}

export default DecisionIcon
