import { createSelector } from 'reselect'
import { getAdaptationTest } from 'main-v2/ducks/adaptation/selectors'

export const getFailedQuestions = state => state.get('failedQuestions')

export const getFailedQuestionsByDayId = createSelector(
  getAdaptationTest,
  getFailedQuestions,
  (test, failedQuestions) => {
    if (!test) return null
    return failedQuestions.getIn(['fails', test.get('id')])
  }
)

export const getIsFirstAttemptByDayId = createSelector(
  getAdaptationTest,
  getFailedQuestions,
  (test, failedQuestions) => {
    if (!test) return null
    return failedQuestions.getIn(['firstAttempts', test.get('id')])
  }
)
