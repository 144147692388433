import React from 'react'

import { autofillText } from 'main/modules/autofill-helper'

import LineBreaker from 'main/components/common/line-breaker'

const Text = ({ content, autofill }) => {
  return (
    <div className="script__text">
      <LineBreaker value={autofillText(content.value, autofill)} />
    </div>
  )
}

export default Text
