export const getIntegerBetween = (value, start, end) => {
  if (!value) return start

  value = parseInt(value.toString().slice(0, end.toString().length), 10)
  if (!value) return start

  if (value < start) return start
  if (value > end) return end
  return value
}

export const numberAsWord = num => {
  if (!num) return ''
  if (num === 1) return 'первый'
  if (num === 2) return 'второй'
  if (num === 3) return 'третий'
  if (num === 4) return 'четвертый'
  if (num === 5) return 'пятый'
  if (num === 6) return 'шестой'
  if (num === 7) return 'седьмой'
  if (num === 8) return 'восьмой'
  if (num === 9) return 'девятый'
  if (num === 10) return 'десятый'
  return num + ''
}

export const wordWithCase = (n, w) => {
  // w=['голос', 'голоса', 'голосов'],
  n %= 100
  if (n > 19) {
    n %= 10
  }

  switch (n) {
    case 1:
      return w[0]

    case 2:
    case 3:
    case 4:
      return w[1]

    default:
      return w[2]
  }
}
