import api from 'common/modules/api'

export const NEWS_CATEGORIES_FETCH_START = 'NEWS_CATEGORIES_FETCH_START'
export const NEWS_CATEGORIES_FETCH_SUCCESS = 'NEWS_CATEGORIES_FETCH_SUCCESS'
export const NEWS_CATEGORIES_FETCH_ERROR = 'NEWS_CATEGORIES_FETCH_ERROR'

export const fetchNewsCategories = () => {
  return dispatch => {
    dispatch(newsCategoriesfetchStart())

    return api
      .fetch(api.routes.newsCategories.index())
      .then(response =>
        dispatch(newsCategoriesfetchSuccess(response.json || []))
      )
      .catch(error => dispatch(newsCategoriesfetchError(error)))
  }
}

const newsCategoriesfetchStart = () => ({
  type: NEWS_CATEGORIES_FETCH_START,
})

const newsCategoriesfetchSuccess = records => ({
  type: NEWS_CATEGORIES_FETCH_SUCCESS,
  records,
})

const newsCategoriesfetchError = error => ({
  type: NEWS_CATEGORIES_FETCH_ERROR,
  error,
})
