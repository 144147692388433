import React from 'react'
import AdaptationItem from './AdaptationItemContainer'

const Adaptation = ({ isLoading, adaptation }) => {
  if (isLoading) return null // TODO: загрузка
  if (!adaptation || !adaptation.days) return renderPlaceholder()

  return (
    <div className="main-layout__block center-block">
      <div className="main-layout__title">
        <div className="main-layout__title-text">Обучение по дням</div>
      </div>
      <div className="main-layout__subtitle">
        Порядок обучения и сроки вы выбираете сами
      </div>
      <ul className="adapt-daylinks main-layout__adapt-daylinks">
        {adaptation.days &&
          adaptation.days.map((day, index) => (
            <AdaptationItem key={day.id} day={day} index={index} />
          ))}
      </ul>
    </div>
  )
}

const renderPlaceholder = () => (
  <div className="main-layout__block center-block">
    <div className="card">
      <div className="placeholder-card placeholder-card--study">
        <div className="placeholder-card__title">Материалы не найдены</div>
        <div className="placeholder-card__text">
          Здесь вы могли бы увидеть структурованную программу по ежедневному
          обучению с тестированиями
        </div>
      </div>
    </div>
  </div>
)

export default Adaptation
