import { List } from 'immutable'

export const getAllMacroregionalDivisions = state =>
  state.getIn(['branches', 'macroregionalDivisions', 'byName'])

export const getMacroregionalDivisionByName = (state, name) =>
  state.getIn(['branches', 'macroregionalDivisions', 'byName', name])

export const getBranchById = (state, branchId) =>
  state.getIn(['branches', 'branches', 'byId', branchId])

export const getRelativeBranches = (state, branchId) => {
  const branch = getBranchById(state, branchId)
  if (!branch) return List()
  return getMacroregionalDivisionByName(
    state,
    branch.get('macroregionalDivision')
  )
    .get('branches')
    .map(branchId => getBranchById(state, branchId))
}
