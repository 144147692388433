import React, { Component } from 'react'

import appRoutes from 'main/constants/app-routes'
import { isEmail, isValidPassword } from 'main/modules/validations'

import { Link } from 'react-router-dom'
import AuthorizationLayout from 'main/components/authorization/layout'
import Button from 'main/components/authorization/button'

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      validationError: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleEnterPress = this.handleEnterPress.bind(this)
    this.submit = this.submit.bind(this)
  }

  handleInputChange(event) {
    this.setState({ validationError: false })
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    if (name === 'email') value = value.trim()

    this.setState({ [name]: value })
  }

  handleEnterPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.submit()
    }
  }

  submit() {
    const { onSubmit } = this.props
    const { email, password } = this.state

    if (!this.fieldsIsValid(email, password)) {
      return this.setState({ validationError: true })
    }

    onSubmit(email, password)
  }

  fieldsIsValid(email, password) {
    return isEmail(email) && isValidPassword(password)
  }

  render() {
    const { error, isLoading } = this.props
    const { email, password, validationError } = this.state

    const hasError = error || validationError

    return (
      <AuthorizationLayout>
        <form className="authorization-layout__form">
          <div className="authorization-layout__input-group">
            <input
              type="text"
              className="authorization-input authorization-layout__input-mail"
              placeholder="Электронная почта"
              name="email"
              autoComplete="email"
              value={email}
              onChange={this.handleInputChange}
              disabled={isLoading}
              onKeyPress={this.handleEnterPress}
            />
            <input
              type="password"
              className="authorization-input authorization-layout__input-password"
              placeholder="Пароль"
              name="password"
              autoComplete="password"
              value={password}
              onChange={this.handleInputChange}
              disabled={isLoading}
              onKeyPress={this.handleEnterPress}
            />
          </div>
          {hasError && (
            <div className="authorization-layout__error">
              Пароль или электронная почта введены неверно
            </div>
          )}
          <Button
            isLoading={isLoading}
            text="Войти в систему"
            onClick={this.submit}
          />
        </form>
        {!isLoading && (
          <div className="authorization-layout__more">
            <Link
              className="authorization-layout__link"
              to={appRoutes.resetInstructions}
            >
              Забыли пароль?
            </Link>
          </div>
        )}
      </AuthorizationLayout>
    )
  }
}

export default Login
