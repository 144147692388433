import React, { Fragment } from 'react'
import { getCardId } from 'main/modules/cards-helper'

import Item from 'main/components/sales-scripts/card/item'

const Card = ({ card, num }) => {
  const { subCards } = card

  return (
    <Fragment>
      {card.content && (
        <div id={getCardId(card.id)} className="card scripts-container__row">
          <div className="script">
            {/* <a className="script__anchor" name={getCardAnchor(card.id)}>
              {' '}
            </a> */}
            <div className="script__title">
              {num} {card.name}
            </div>
            {card.content.map((cardItem, index) => (
              <Item
                key={Math.random()} // Force rerender when script changes
                content={cardItem}
              />
            ))}
          </div>
        </div>
      )}
      {subCards &&
        Array.isArray(subCards) &&
        subCards.map((subCard, index) => (
          <Card key={subCard.id} card={subCard} num={`${num}${index + 1}.`} />
        ))}
    </Fragment>
  )
}

export default Card
