import { createAction } from 'redux-act'
import { getModel } from 'constructors/ducks/model/selectors'
import { getClient } from 'constructors/ducks/client/selectors'
import { getStats } from 'constructors/ducks/stats/selectors'
import {
  getAllValues,
  getPackageName,
  getSelectedProductNames,
} from 'constructors/ducks/values/selectors'
import { getAdditionalInfo } from 'constructors/ducks/values/selectors'
import { getFormattedInvoice } from 'constructors/ducks/invoice/selectors'
import api from 'common/modules/api'
import config from 'entry-specific/config'

export const setModel = createAction('set model')
export const setPreviewConfig = createAction('set preview config')
export const resetModel = createAction('reset model')

export const saveCommercialProposalStart = createAction(
  'start save commercial proposal'
)
export const saveCommercialProposalSuccess = createAction(
  'success save commercial proposal'
)
export const saveCommercialProposalError = createAction(
  'error save commercial proposal'
)

export const saveCommercialProposal = () => (dispatch, getState) => {
  dispatch(saveCommercialProposalStart())
  const state = getState()

  const model = getModel(state).toJS()
  const invoice = getFormattedInvoice(state).toJS()

  if (model.briefVersion === undefined) {
    model.briefVersion = false
  }

  const proposal = config('constructor.beforeSave', p => p)(
    {
      ...model,
      packageName: getPackageName(state),
      productNames: getSelectedProductNames(state).toJS(),
      client: getClient(state).toJS(),
      stats: getStats(state).toJS(),
      values: getAllValues(state).toJS(),
      invoice,
      additionalInfo: getAdditionalInfo(state).toJS(),
    },
    state
  )

  const route = model.id
    ? api.routes.proposals.edit(model.id)
    : api.routes.proposals.new()

  return api
    .fetch(route, {
      body: proposal,
    })
    .then(response => {
      if (!response.ok) {
        dispatch(saveCommercialProposalError())
        return response.json
      }
      dispatch(saveCommercialProposalSuccess(response.json))
      return response.json
    })
    .catch(error => {
      console.error(error)
      return dispatch(saveCommercialProposalError(error))
    })
}

export const saveIframeCommercialProposal = ({ body }) => dispatch => {
  const route = body.id
    ? api.routes.proposals.edit(body.id)
    : api.routes.proposals.new()

  return api
    .fetch(route, {
      body,
    })
    .then(response => {
      if (!response.ok) {
        dispatch(saveCommercialProposalError())
        return response.json
      }
      dispatch(saveCommercialProposalSuccess(response.json))
      return response.json
    })
    .catch(error => {
      console.error(error)
      return dispatch(saveCommercialProposalError(error))
    })
}
