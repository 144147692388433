import { combineReducers } from 'redux-immutable'

import autofill from './autofill'
import calculations from './calculations'
import currentScript from './current-script'
import currentCard from './current-card'

export default combineReducers({
  currentScript,
  currentCard,
  autofill,
  calculations,
})
