import React from 'react'
import { connect } from 'react-redux'
import { getSelectedProductsIds } from 'constructors/ducks/values/selectors'
import toJS from 'common/HOC/to-js'
import Product from './ProductContainer'

const mapStateToProps = state => ({
  productIds: getSelectedProductsIds(state),
})

const CustomOptions = ({ productIds }) => {
  return productIds.map(productId => (
    <Product key={productId} productId={productId} />
  ))
}

export default connect(mapStateToProps)(toJS(CustomOptions))
