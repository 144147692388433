import { connect } from 'react-redux'
import { getRadioOptions } from 'constructors/ducks/options/selectors'
import BigRadio from './BigRadio'
import toJS from 'common/HOC/to-js'

const mapStateToProps = (state, ownProps) => ({
  radioOptions: getRadioOptions(state, ownProps.option.id),
})

const BigRadioContainer = connect(mapStateToProps)(toJS(BigRadio))

export default BigRadioContainer
