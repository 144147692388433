import React, { Component } from 'react'

import appRoutes from 'main/constants/app-routes'
import { isEmail } from 'main/modules/validations'

import { Link } from 'react-router-dom'
import AuthorizationLayout from 'main/components/authorization/layout'
import Button from 'main/components/authorization/button'

class ResetInstructions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      validationError: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  handleInputChange(event) {
    this.setState({ validationError: false })
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    if (name === 'email') value = value.trim()

    this.setState({ [name]: value })
  }

  handleEnterPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.submit()
    }
  }

  submit() {
    const { onSubmit } = this.props
    const { email } = this.state

    if (!this.fieldsIsValid(email)) {
      return this.setState({ validationError: true })
    }

    onSubmit(email)
  }

  fieldsIsValid(email) {
    return isEmail(email)
  }

  render() {
    const { success } = this.props

    return (
      <AuthorizationLayout>
        {!success && this.renderForm()}
        {success && this.renderSuccess()}
      </AuthorizationLayout>
    )
  }

  renderForm() {
    const { error, isLoading } = this.props
    const { email, validationError } = this.state

    const hasError = error || validationError

    return (
      <div>
        <form className="authorization-layout__form">
          <div className="authorization-layout__input-group">
            <input
              type="text"
              className="authorization-input authorization-layout__input-mail"
              placeholder="Электронная почта"
              name="email"
              value={email}
              onChange={this.handleInputChange}
              disabled={isLoading}
              onKeyPress={this.handleEnterPress}
            />
          </div>
          {hasError && (
            <div className="authorization-layout__error">
              Электронная почта введена неверно
            </div>
          )}
          <Button
            isLoading={isLoading}
            text="Продолжить"
            onClick={this.submit}
          />
        </form>
        {!isLoading && (
          <div className="authorization-layout__more">
            Вспомнили пароль?{' '}
            <Link className="authorization-layout__link" to={appRoutes.auth}>
              Войти в систему
            </Link>
          </div>
        )}
      </div>
    )
  }

  renderSuccess() {
    const { email } = this.state

    return (
      <div>
        <div className="authorization-layout__mail-sent" />
        <div className="authorization-layout__more">
          Инструкции по изменению пароля были направлены <br />
          на <span className="authorization-layout__mail">{email}</span>
        </div>
      </div>
    )
  }
}

export default ResetInstructions
