import salesScriptsData from 'entry-specific/apps/manager/scripts'
import { createSelector } from 'reselect'
import { List } from 'immutable'

const addIdsToSalesScripts = scripts => scripts.map(addIdsToSalesScript)
const addIdsToSalesScript = script => {
  if (script.items) script.items = script.items.map(addIdsToSalesScript)
  if (script.cards)
    script.cards = script.cards.map((card, index) =>
      addIdsToCard([index + 1])(card)
    )
  return script
}
const addIdsToCard = idParts => card => {
  if (card.subCards)
    card.subCards = card.subCards.map((card, index) =>
      addIdsToCard([...idParts, index + 1])(card)
    )

  const id = card.id ? card.id : idParts.join('.')
  return { ...card, id }
}

const salesScriptsWithIds = addIdsToSalesScripts(salesScriptsData)

export const getSalesScripts = () => salesScriptsWithIds

export const getCurrentScriptPath = createSelector(
  state => state,
  getSalesScripts,
  (state, salesScripts) => {
    if (salesScripts && salesScripts.length === 1) return List([0])
    return state.getIn(['salesScripts', 'currentScript', 'path'])
  }
)

export const getCurrentScript = createSelector(
  getCurrentScriptPath,
  getSalesScripts,
  (currentScriptPath, salesScripts) => {
    // TODO: is mutable
    if (!currentScriptPath || !currentScriptPath.size) return null

    let selectedPart = {
      name: '...',
      items: salesScripts,
    }

    try {
      for (let i = 0; i < currentScriptPath.size; i++) {
        selectedPart = selectedPart.items[currentScriptPath.get(i)]
      }
    } catch (e) {
      return selectedPart
    }

    return selectedPart
  }
)

export const getScriptByPath = createSelector(
  (_, path) => path,
  getSalesScripts,
  (currentScriptPath, salesScripts) => {
    // TODO: is mutable
    if (!currentScriptPath || !currentScriptPath.length) return null

    let selectedPart = {
      name: '...',
      items: salesScripts,
    }

    try {
      for (let i = 0; i < currentScriptPath.length; i++) {
        selectedPart = selectedPart.items[currentScriptPath[i]]
      }
    } catch (e) {
      return selectedPart
    }

    return selectedPart
  }
)
