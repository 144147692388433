import { connect } from 'react-redux'
import Layout from './Layout'
import { getIsLoading } from 'constructors/ducks/constructor/selectors'
import { getIsInitializing } from 'constructors/ducks/constructor/selectors'

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  isInitializing: getIsInitializing(state),
})

const LayoutContainer = connect(mapStateToProps)(Layout)

export default LayoutContainer
