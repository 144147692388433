import React, { useState } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import routes from 'main-v2/pages/routes'
import constructorsInitialState from 'entry-specific/apps/manager/constructors'
import currentUser from 'common/modules/current-user'
import config from 'entry-specific/config'

import Select from 'common/components/Select'
import ClientForm from 'main-v2/components/proposals/ClientForm'
import Modal from 'common/components/Modal'

const mapDispatchToProps = dispatch => ({
  onClick: id =>
    dispatch(
      push(
        routes.proposals.new({
          params: { constructorId: id },
        })
      )
    ),
})

const NewButton = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  if (config('proposals.version') === 'callers') {
    return (
      <div className="select">
        <div className="select__box" onClick={() => setIsOpen(true)}>
          <span className="select__title">Сформировать новое КП</span>
        </div>
        <Modal
          className="main-layout__modal"
          title={emailSent ? 'Предложение отправлено!' : 'Новое предложение'}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false)
            setEmailSent(false)
          }}
          size="mid"
        >
          {emailSent ? (
            <EmailSent />
          ) : (
            <ClientForm
              willSendEmail={config('proposals.willSendEmails')}
              onSuccess={emailSent =>
                emailSent ? setEmailSent(true) : setIsOpen(false)
              }
            />
          )}
        </Modal>
      </div>
    )
  }

  if (Object.keys(constructorsInitialState).length === 0) return null

  if (Object.keys(constructorsInitialState).length === 1) {
    return (
      <div className="select">
        <div
          className="select__box"
          onClick={() => onClick(Object.keys(constructorsInitialState)[0])}
        >
          <span className="select__title">Сформировать новое КП</span>
        </div>
      </div>
    )
  }

  return (
    <Select
      title="Сформировать новое КП"
      options={getConstructorsOptions()}
      onClick={choice => onClick(choice.id)}
    />
  )
}

const getConstructorsOptions = () =>
  Object.keys(constructorsInitialState)
    .filter(key => !checkIfDisabled(constructorsInitialState[key]))
    .map(key => ({
      id: constructorsInitialState[key].id,
      name: constructorsInitialState[key].name,
    }))

const checkIfDisabled = constructor => {
  if (constructor.disabled === true) return true
  if (typeof constructor.disabled === 'function') {
    return constructor.disabled({
      isProposalWizard: currentUser.isProposalWizard,
      branch: currentUser.branchName,
    })
  }
  return false
}

const EmailSent = () => (
  <div className="sent">
    Предложение было только что отправлено на указанную электронную почту
    клиента
  </div>
)

export default connect(
  null,
  mapDispatchToProps
)(NewButton)
