import React from 'react'
import Options from 'constructors/components/Options/Container'

const Product = ({ product, isSelected, selectProduct, unselectProduct }) => {
  return (
    <div key={product.id} className="proposal-row card__row">
      <div
        className={getClassName(isSelected)}
        onClick={isSelected ? unselectProduct : selectProduct}
      >
        {product.name}
      </div>
      {isSelected && <Options productId={product.id} />}
    </div>
  )
}

const getClassName = isSelected => {
  return isSelected
    ? 'proposal-row__title proposal-row__title--remove'
    : 'proposal-row__title proposal-row__title--add'
}

export default Product
