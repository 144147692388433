import React, { Fragment } from 'react'

const LineBreaker = ({ value }) => {
  return (
    <Fragment>
      {value.split('\n').map((line, index) => (
        <p className="script__text-line" key={index}>
          {line}
        </p>
      ))}
    </Fragment>
  )
}

export default LineBreaker
