import React, { Fragment, useState } from 'react'

import { showDateTime } from 'common/modules/date-helper'
import appRoutes from 'main-v2/pages/routes'
import config from 'entry-specific/config'
import constructorsInitialState from 'entry-specific/apps/manager/constructors'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import {
  getProposal,
  getIndexById,
  deleteProposal,
  saveDecision,
  setSalesScriptCursor,
} from 'main-v2/ducks/proposals'
import toJS from 'common/HOC/to-js'
import { isMobileApp } from 'common/modules/mobile-helper'
import safeGet from 'common/modules/safe-get'
import { lessThan24HoursFromNow } from 'common/modules/date-helper'
import cn from 'classnames'

import DecisionIcon from 'main-v2/components/proposals/DecisionIcon'
import Actions from 'main-v2/components/proposals/Actions'
import Modal from 'common/components/Modal'
import Stats from 'main-v2/components/proposals/Stats'

const mapStateToProps = (state, ownProps) => ({
  cursor: getIndexById(state, ownProps.proposalId),
  proposal: getProposal(state, ownProps.proposalId),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDelete: () => dispatch(deleteProposal(ownProps.proposalId)),
  onDecisionChange: props => dispatch(saveDecision(props)),
  edit: () => dispatch(push(appRoutes.proposals.edit(ownProps.proposalId))),
  setSalesScriptCursor: cursor => dispatch(setSalesScriptCursor(cursor)),
})

const ListItem = props => {
  const { proposal, cursor, setSalesScriptCursor } = props

  return (
    <div className="card proposals__item">
      {renderDecision(proposal)}
      {renderName({ proposal, cursor, setSalesScriptCursor })}
      {renderServices(proposal)}
      {renderClient(proposal)}
      {renderViews(proposal)}
      <div className="proposals__column">
        <Actions {...props} />
      </div>
    </div>
  )
}

const renderDecision = proposal => {
  if (!proposal.decision)
    return (
      <div className="proposals__column">
        <div className="decision">
          <div className="decision__state" />
        </div>
      </div>
    )

  return (
    <div className="proposals__column">
      <div className="decision">
        <div
          className={`decision__state decision__state--${proposal.decision}`}
        >
          <DecisionIcon type={proposal.decision} />
        </div>

        <div className="decision__popover">
          <Info>
            {
              config('proposals.decisions', {
                accepted: 'Принято',
                refused: 'Отказ',
              })[proposal.decision]
            }
          </Info>
          <div className="decision__date">
            {showDateTime(proposal.decisionAt, { todayOrYesterday: true })}
          </div>
        </div>
      </div>
    </div>
  )
}

const renderName = ({ proposal, cursor, setSalesScriptCursor }) => (
  <div className="proposals__column">
    {config('proposals.scriptsLink') ? (
      <Info
        tag="a"
        className="proposals__info"
        onClick={() => setSalesScriptCursor(cursor)}
      >
        {proposal.client.companyName}
      </Info>
    ) : (
      <Info className="proposals__info">{proposal.client.companyName}</Info>
    )}

    <SubInfo>
      {renderTin(proposal.client.tin)}
      {proposal.client.lastName} {proposal.client.firstName}
    </SubInfo>
  </div>
)

const renderTin = tin => (tin ? `ИНН: ${tin}, ` : '')

const renderServices = proposal => {
  if (config('proposals.version', 'standart') === 'standart')
    return (
      <div className="proposals__column">
        <Info
          tag="a"
          className="proposals__info"
          href={appRoutes.proposals.preview(proposal.shortId || proposal.id)}
          target={isMobileApp() ? '' : '_blank'}
          rel="noopener noreferrer"
        >
          {renderProductNames(proposal)}
        </Info>
        <SubInfo>
          {renderPackageName(proposal)}
          {renderVersionName(proposal)}
        </SubInfo>
      </div>
    )

  return null
}

const renderProductNames = proposal => {
  return proposal.productNames
    ? proposal.productNames.join(' + ')
    : proposal.packageName
    ? proposal.packageName
    : '_'
}

const renderPackageName = proposal =>
  safeGet(constructorsInitialState, `${proposal.constructorCode}.name`, '')

const renderVersionName = proposal => {
  if (proposal.previewConfig.rigid) return ''

  return (
    ', ' +
    (proposal.briefVersion
      ? config('naming.kpPreview.versionsSelect.brief')
      : config('naming.kpPreview.versionsSelect.whole'))
  )
}

const renderClient = proposal =>
  config('proposals.version') === 'callers' ? (
    <div className="proposals__column">
      {config('proposals.callTracking') && proposal.client.phone ? (
        <Info
          tag="a"
          className="proposals__info"
          onClick={e => {
            e.preventDefault()
            alert('Данный функционал не подключен')
          }}
        >
          {proposal.client.phone || 'Нет телефона'}
        </Info>
      ) : (
        <Info>{proposal.client.phone || 'Нет телефона'}</Info>
      )}
      <SubInfo>{proposal.client.email || 'Нет электронной почты'}</SubInfo>
    </div>
  ) : null

const renderViews = proposal => {
  return (
    <div className="proposals__column">
      {renderViewsTitle(proposal)}
      {renderViewDate(proposal)}
    </div>
  )
}

const renderViewsTitle = proposal => {
  const [isOpen, setIsOpen] = useState(false)

  if (!hasViews(proposal)) return <Info muted>Нет просмотров</Info>

  return (
    <Fragment>
      <Info
        tag="a"
        hot={lessThan24HoursFromNow(proposal.stats.firstRead)}
        onClick={() => setIsOpen(true)}
      >
        {proposal.stats.totalViews === 1
          ? 'Просмотрено'
          : `Просмотров: ${proposal.stats.totalViews}`}
      </Info>
      <Modal
        className="main-layout__modal"
        title="Аналитика просмотров предложения"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="auto"
      >
        <Stats proposal={proposal} />
      </Modal>
    </Fragment>
  )
}

const renderViewDate = proposal => {
  if (!hasViews(proposal)) return null

  return (
    <SubInfo>
      {showDateTime(proposal.stats.lastRead, { todayOrYesterday: true })}
    </SubInfo>
  )
}

const Info = ({ tag = 'p', hot, muted, ...restProps }) =>
  React.createElement(tag, {
    className: cn('proposals__info', {
      'proposals__info--hot': hot,
      'proposals__info--muted': muted,
    }),
    ...restProps,
  })

const SubInfo = ({ children }) => (
  <p className="proposals__subinfo">{children}</p>
)

const hasViews = proposal => !!proposal.stats.totalViews

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(ListItem))
