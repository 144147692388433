import React from 'react'
import Block from './Block'
import { connect } from 'react-redux'
import {
  getInvoiceBlocks,
  hasInvoiceBlocks,
} from 'constructors/ducks/values/selectors'
import toJS from 'common/HOC/to-js'

const mapStateToProps = state => ({
  hasBlocks: hasInvoiceBlocks(state),
  blocks: getInvoiceBlocks(state),
})

const InvoiceBlocks = ({ hasBlocks, blocks }) => {
  if (!hasBlocks) return <div className="proposal-info__placeholder" />
  return blocks.map(block => <Block key={block.name} {...block} />)
}

export default connect(mapStateToProps)(toJS(InvoiceBlocks))
