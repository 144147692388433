import React, { Fragment, useState } from 'react'

import config from 'entry-specific/config'
import api from 'common/modules/api'

import Dropdown from 'main/components/common/dropdown/dropdown'
import DropdownItem from 'main/components/common/dropdown/dropdown-item'
import DropdownDelimiter from 'main/components/common/dropdown/dropdown-delimiter'
import DropdownTitle from 'main/components/common/dropdown/dropdown-title'
import ClientForm from 'main-v2/components/proposals/ClientForm'
import Modal from 'common/components/Modal'
import Confirmation from 'common/components/Confirmation'
import SendEmail from 'main-v2/components/proposal-script/SendEmail'

const ProposalActions = props => {
  const { proposal, onDecisionChange } = props

  return (
    <Dropdown>
      <DropdownTitle>Выберите статус</DropdownTitle>
      {Object.entries(
        config('proposals.decisions', {
          accepted: 'Принято',
          refused: 'Отказ',
        })
      ).map(([decision, name]) => (
        <DropdownItem
          key={decision}
          onClick={() =>
            proposal.decision !== decision
              ? onDecisionChange({ id: proposal.id, decision })
              : null
          }
          type={proposal.decision === decision ? 'active' : ''}
        >
          {name}
        </DropdownItem>
      ))}
      {config('proposals.callTracking') && proposal.client.phone && (
        <Fragment>
          <DropdownDelimiter />{' '}
          <DropdownItem onClick={() => alert('Данный функционал не подключен')}>
            Позвонить
          </DropdownItem>
        </Fragment>
      )}
      <DropdownDelimiter />
      {config('proposals.version') === 'callers' && (
        <SendEmail proposal={proposal} />
      )}
      <DropdownItem onClick={download(proposal.id)}>
        Скачать PDF версию
      </DropdownItem>
      {renderEditButton(props)}
      {renderDeleteButton(props)}
    </Dropdown>
  )
}

const renderEditButton = ({ proposal, edit }) => {
  const [isOpen, setIsOpen] = useState(false)

  if (config('proposals.version') === 'callers')
    return (
      <Fragment>
        <DropdownItem onClick={() => setIsOpen(!isOpen)}>
          Редактировать
        </DropdownItem>
        <Modal
          className="main-layout__modal"
          title="Редактирование предложения"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          size="mid"
        >
          <ClientForm proposal={proposal} onSuccess={() => setIsOpen(false)} />
        </Modal>
      </Fragment>
    )

  return (
    !proposal.frozen && (
      <DropdownItem onClick={edit}>Редактировать</DropdownItem>
    )
  )
}

const renderDeleteButton = ({ proposal, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Fragment>
      <DropdownItem
        type="warning"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        Удалить
      </DropdownItem>
      <Confirmation
        title="Удаление коммерческого предложения"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        text={`Вы действительно хотите удалить коммерческое предложение для компании «${
          proposal.client.companyName
        }»?`}
        actionText="Удалить"
        onSubmit={onDelete}
      />
    </Fragment>
  )
}

const download = previewWholeId => () => {
  window.open(`${api.routes.proposals.file(previewWholeId)}`, '_blank')
}

export default ProposalActions
