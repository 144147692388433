import { itemTypes } from './helpers'
import generateScript from './generate-script'

const cards = [
  {
    id: '2',
    name: 'Предложение услуги',
    subCards: [
      {
        id: '2.1',
        name: 'Статистика по звонкам',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. Как вы взаимодействуете с клиентами?
    2. Каким образом анализируете,  сколько входящих вызовов приняли ваши сотрудники, а сколько не успели принять (абонент отключился не дождавшись ответа)?
    3. Часто случается, что клиент не может дозвониться в компанию (например, линия занята или менеджер отошел от телефона и т.д.), а руководство не знает о таких прецедентах вообще или узнает случайно из жалобы клиента. Вы наверняка сталкивались с подобными ситуациями лично при попытку куда-то позвонить. А "примеряли" вы этот сценарий на свою компанию? Какое нашли решение?

    Мы можем настроить для вас статистику по всем входящим звонкам (принятым и непринятым) - в разрезе до каждого сотрудника`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value:
                  'Экономия ФОТ: за счет оптимизации работы - сотрудники начинают работать более эффективно. \n\nУвеличение доходов: за счет оптимизации кол-ва исполнения заказов. Чем меньше потерянных звонков - тем выше лояльность дозвонившихся клиентов и выше продажи\n\nМотивация: отчеты по звонкам можно использовать в мотивационных программах используя статистику как элемент управления сотрудником.',
              },
            ],
          },
        ],
      },
      {
        id: '2.2',
        name: 'Контроль качества обслуживания',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. Часто ли возникают спорные ситуации?
              2. Как вы их решаете? Сколько на это уходит времени и какие финансовые потери несет компания?
              3. Иногда предприниматели, которые работают в одной с вами сфере, жалуются на претензии, которые поступают от клиентов - очень много времени уходит на рассмотрение и подготовку аргументов. Особенно это важно, когда и сотрудник и клиент считают, что они правы. Вы таже наверняка выступали в роли арбитра в таких спорах. Как решали проблему?

              Функция "Запись разговоров"  позволяет записывать ВСЕ входящие звонки. Функция "Контроль качества" позволяет записывать ВСЕ входящие и исходящие звонки`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение доходов и качества услуг: сотрудники разговаривают вежливо и корректно предоставляют информацию.
                  Повышение лояльности клиентов и статуса компании: наличие записи разговоров свидетельствует, что компания заботится о клиентах.
                  Сокращение времени  и затрат при разборе претензий.`,
              },
            ],
          },
        ],
      },
      {
        id: '2.3',
        name: 'Защита клиентской базы',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. Уверены ли вы, что сотрудники не проводят двойные сделки или не "уводят" сделки из компании?
              2. Вы сталкивались со случаями, когда сотрудник увольняется из компании и забирает клиентскую базу? Или закрывает сделку  за спиной работодателя так, что выручка в компанию не поступает? Как вы боретесь с такими кейсами? Подсчитывали, сколько дохода теряете от таких сделок?

              При совершении исходящего звонка с телефона сотрудника производится подстановка единого номера компании. Клиент не видит фактический номер сотрудника и не может звонить ему напрямую.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение выручки:  за счет сохранения всех клиентов (партнеров) в компании. 
                Сокращение убытков: за счет борьбы с недобросовестными сотрудниками, которые могут проводить сделки "за спиной" работодателя. 
                Повышение лояльности клиентов, имиджа компании.`,
              },
            ],
          },
        ],
      },
      {
        id: '2.4',
        name: 'Имидж и узнаваемость',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `Вас заботит имидж и узнаваемость вашей компании?

              Часто бывает, что компания имеет единый контактный номер, на который звонят клиенты. При этом обратная связь (когда сотрудник компании звонит клиенту) определяются рабочие номера сотрудников, на которые клиент может не дозвониться. 
              Многоканальный номер - это единый номер, который связан с вашей компанией в восприятии клиента. 
              Даже при совершении исходящего звонка с телефона сотрудника производится подстановка единого номера компании. Сегодня многие пользуются приложениями (дубль гис, гугл дайлер, номеротека), которые  в момент входящего звонка определяют незнакомые номера,  сверяют их с базой общего телефонного справочника и подписывают имя владельца или название компании. Т.е. клиент сразу видит, кто ему звонит и может совершить ответный звонок`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение доходов и сокращение убытков: все коммуникации осуществляются с одного номера.
                Повышение имиджа компании: единый номер - это узнаваемость в клиентской среде.`,
              },
            ],
          },
        ],
      },
      {
        id: '2.5',
        name: 'Удаленное управление офисами',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `Вам приходится проводить удаленные совещания/собрания?

              Возможности многоканальных номеров от МТС позволяют быстро собирать на аудио-конференции неограниченное кол-во сотрудников. Также можно создавать множество переговорных аудио-комнат  и проводить  несколько совещаний одновременно.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение эффективности бизнеса: оперативное получение информации даже от удаленных офисов и представительств. 
                Сокращение расходов: на командировки`,
              },
            ],
          },
        ],
      },
      {
        id: '2.6',
        name: 'Повышение лояльности клиентов',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. Вы заинтересованы, чтобы клиенты приходили к вам снова?
              2. Как вы проводите опросы клиентов и замеряете их удовлетворенность?

              Мы можем настроить для вас функцию автоматических опросов клиентов. Вы всегда будете в курсе, пришел ли сотрудник к клиенту, насколько качественно выполнил работу, доволен ли клиент и готов ли он рекомендовать вас своему окружению.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Повышение лояльности клиентов, имиджа компании.
                Повышение выручки:  лояльные клиенты возвращаются к вам снова и рекомендую вашу компанию своим друзьям`,
              },
            ],
          },
        ],
      },
      {
        id: '2.7',
        name: 'Информирование клиентов',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. Часто в качестве контактного ваши клиенты/партнеры указывают городской телефон?
              2. Как вы информируете таких клиентов?

              Функция "Телеинфо"  поможет обзвонить таких клиентов в автоматическом режиме. Можно оценивать эффективность обзвона по продолжительности прослушивания. Можно настроить автоматический перевод на сотрудника вашей компании, если клиент дослушал предложение до конца.`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Экономия ресурсов и повышение доходов: возможность "достучаться" до клиентов, которым нельзя отправить СМС - и при этом не тратить ресурсы на обзвоны сотрудниками.
                Важно: услуга "телеинфо" и попадает под запреты Закона о рекламе и спаме`,
              },
            ],
          },
        ],
      },
      {
        id: '2.8',
        name: 'Сокращение расходов',
        content: [
          {
            type: itemTypes.speech,
            value: {
              text: `1. Вы планируете расширение бизнеса? Как вы будете обеспечивать их связью ?
              2. У вас есть сотрудники, работающие удаленно (к примеру, надомные или в других городах)?

              Виртуальная АТС не имеет ограничений по кол-ву линий. Вы можете включить всех новых и удаленных сотрудников в свою сеть БЕЗ ДОПОЛНИТЕЛЬНЫХ РАСХОДОВ (не нужно протягивать телефонные линии и приотбретать дополнительные платы для АТС).`,
            },
          },
          {
            type: itemTypes.spoiler,
            openText: 'Показать пользу для компании',
            closeText: 'Скрыть пользу для компании',
            value: [
              {
                type: itemTypes.text,
                value: `Экономия ресурсов: внедрение Виртуальной АТС не требует капитальных затрат.
                Простое внедрение: неограниченое кол-во телефонных линий - даже в условия отсутствия свободных портов. Легкое внедрение и настройка.`,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default generateScript({ cards })
