import React from 'react'
import currentUser from 'common/modules/current-user'
import { connect } from 'react-redux'

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(currentUser.logout())
  },
})

export default connect(
  null,
  mapDispatchToProps
)(({ logout }) => (
  <div className="profile">
    <div className="profile__name">{currentUser.name}</div>
    <div className="profile__row">
      <div className="profile__title">Роль</div>
      <div className="profile__text">{currentUser.role}</div>
    </div>
    <div className="profile__row">
      <div className="profile__title">Регион</div>
      <div className="profile__text">
        {currentUser.macroregionalDivision} - {currentUser.branchName}
      </div>
    </div>
    {currentUser.email && (
      <div className="profile__row">
        <div className="profile__title">Электронная почта</div>
        <div className="profile__text">{currentUser.email}</div>
      </div>
    )}
    {currentUser.phoneNumber && (
      <div className="profile__row">
        <div className="profile__title">Телефон</div>
        <div className="profile__text">{currentUser.phoneNumber}</div>
      </div>
    )}
    <button className="profile__logout button button--warning" onClick={logout}>
      Сменить аккаунт
    </button>
  </div>
))
