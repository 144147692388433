import { connect } from 'react-redux'
import { resetPassword } from 'main/actions/password-reset'
import PasswordResetComponent from 'main/components/authorization/password-reset.js'

const mapStateToProps = state => ({
  isLoading: state.getIn(['passwordReset', 'isLoading']),
  error: state.getIn(['passwordReset', 'error']),
})

const mapDispatchToProps = dispatch => ({
  onSubmit: (password, resetToken) => {
    dispatch(resetPassword(password, resetToken))
  },
})

const PasswordReset = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetComponent)

export default PasswordReset
