import React from 'react'
import SingleQuestion from './SingleQuestion'
import MultipleQuestion from './MultipleQuestion'
import TextQuestion from './TextQuestion'

const Question = props => {
  let Component = null
  const { question } = props

  switch (question.type) {
    case 'single':
      Component = SingleQuestion
      break
    case 'multiple':
      Component = MultipleQuestion
      break
    case 'text':
      Component = TextQuestion
      break
    default:
      return null
  }

  return <Component {...props} />
}

export default Question
