import { connect } from 'react-redux'
import { getOption } from 'constructors/ducks/options/selectors'
import { getOptionValue } from 'constructors/ducks/values/selectors'
import toJS from 'common/HOC/to-js'
import { CounterInput } from './Counter'

const mapStateToProps = (state, ownProps) => ({
  option: getOption(state, ownProps.optionId),
  value: getOptionValue(state, ownProps.optionId),
})

const CounterInputContainer = connect(mapStateToProps)(toJS(CounterInput))

export default CounterInputContainer
