import { Component } from 'react'
import currentUser from 'common/modules/current-user'
import config from 'entry-specific/config'
import { isMobileWidth } from 'common/modules/mobile-helper'

class Jivosite extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    if (process.env.NODE_ENV === 'development') return null
    if (!config('jivosite.widgetId')) return null
    if (!currentUser.isLoggedIn()) return null
    if (isMobileWidth()) return null

    var widget_id = config('jivosite.widgetId')
    var d = document
    var w = window

    function l() {
      var s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = '//code.jivosite.com/script/widget/' + widget_id
      var ss = document.getElementsByTagName('script')[0]
      ss.parentNode.insertBefore(s, ss)
    }

    window.jivo_onLoadCallback = () => {
      window.jivo_api.setContactInfo({
        name: currentUser.name,
        email: currentUser.email,
        phone: currentUser.phoneNumber,
        description: `${currentUser.branch} (${currentUser.mrd})`,
      })
    }

    if (d.readyState === 'complete') {
      l()
    } else {
      if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }

    return null
  }
}

export default Jivosite
