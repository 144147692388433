import api from 'common/modules/api'
import { camelcaseKeys } from 'main/modules/camelcase-keys'

export const NEWS_COMPILATIONS_FETCH_START = 'NEWS_COMPILATIONS_FETCH_START'
export const NEWS_COMPILATIONS_FETCH_SUCCESS = 'NEWS_COMPILATIONS_FETCH_SUCCESS'
export const NEWS_COMPILATIONS_FETCH_ERROR = 'NEWS_COMPILATIONS_FETCH_ERROR'

export const fetchNewsCompilations = () => {
  return dispatch => {
    dispatch(newsCompilationsfetchStart())

    api
      .fetch(api.routes.newsCompilations.index(), {
        queryParams: {
          per_page: 10,
        },
      })
      .then(response => {
        dispatch(
          newsCompilationsfetchSuccess(camelcaseKeys(response.json || []))
        )
      })
      .catch(error => dispatch(newsCompilationsfetchError(error)))
  }
}

const newsCompilationsfetchStart = () => ({
  type: NEWS_COMPILATIONS_FETCH_START,
})

const newsCompilationsfetchSuccess = records => ({
  type: NEWS_COMPILATIONS_FETCH_SUCCESS,
  records,
})

const newsCompilationsfetchError = error => ({
  type: NEWS_COMPILATIONS_FETCH_ERROR,
  error,
})
