import React, { Component } from 'react'

import Item from 'main/components/sales-scripts/card/item'

class Spoiler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isHidden: true,
    }
  }

  toggle() {
    this.setState({ isHidden: !this.state.isHidden })
  }

  render() {
    const { content } = this.props
    const { isHidden } = this.state

    let className = 'script__spoiler-content'
    let toggleText = content.closeText

    if (isHidden) {
      className += ' script__spoiler-content--hidden'
      toggleText = content.openText
    }

    return (
      <div className="script__spoiler">
        <div className="script__trigger">
          <div className="trigger" onClick={this.toggle.bind(this)}>
            {toggleText}
          </div>
        </div>
        <div className={className}>
          {content.value.map((item, itemIndex) => (
            <Item key={itemIndex} content={item} />
          ))}
        </div>
      </div>
    )
  }
}
export default Spoiler
