import { createStore, applyMiddleware, compose } from 'redux'
import { Map } from 'immutable'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'
import rootReducer from 'main/reducers'
import constructorsMutationsMiddleware from 'entry-specific/apps/manager/constructors/mutations-middleware'

export const history = createHistory()

const initialState = Map()
const middleware = [
  thunk,
  routerMiddleware(history),
  ...constructorsMutationsMiddleware,
]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
)

export default store
