import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import {
  saveCommercialProposalStart,
  saveCommercialProposalSuccess,
  saveCommercialProposalError,
} from 'constructors/ducks/model/actions'

export const initialState = {
  isLoading: false,
}

const reducer = createReducer(
  {
    [saveCommercialProposalStart]: state => {
      return state.set('isLoading', true)
    },
    [saveCommercialProposalSuccess]: state => {
      return state.set('isLoading', false)
    },
    [saveCommercialProposalError]: state => {
      return state.set('isLoading', false)
    },
  },
  fromJS(initialState)
)

export default reducer
