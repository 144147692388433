import React, { Component, createContext, Fragment } from 'react'
import PdfPreviewIframe from './pdf-preview-iframe'
import bodyNoscroll from 'common/modules/body-noscroll'

const PdfPreviewContext = createContext({
  isOpen: false,
  open: () => {},
  close: () => {},
})

class PdfPreviewProvider extends Component {
  open = document => {
    this.setState({
      isOpen: true,
      document,
    })
    bodyNoscroll.block()
  }

  close = () => {
    this.setState({
      isOpen: false,
      document: null,
    })
    bodyNoscroll.unblock()
  }

  state = {
    isOpen: false,
    open: this.open,
    close: this.close,
    document: null,
  }

  render() {
    return (
      <PdfPreviewContext.Provider value={this.state}>
        {this.props.children}
      </PdfPreviewContext.Provider>
    )
  }
}

class PdfPreview extends Component {
  constructor(props) {
    super(props)

    this.previewCloseRef = React.createRef()
    this.previewContainerRef = React.createRef()
    this.pdfPreviewRef = React.createRef()
  }

  state = {
    isLoading: true,
  }

  iframeIsLoaded = () => {
    this.setState({ isLoading: false })
  }

  iframeIsUnloaded = () => {
    this.setState({ isLoading: true })
  }

  componentDidMount() {
    this.previewContainerRef.current.addEventListener(
      'scroll',
      this.loadNextPage
    )
  }

  componentWillUnmount() {
    this.previewContainerRef.current.removeEventListener(
      'scroll',
      this.loadNextPage
    )
  }

  loadNextPage = () => {
    const pdfPreviewEl = this.pdfPreviewRef.current
    if (!pdfPreviewEl) return

    const containerEl = this.previewContainerRef.current
    if (
      containerEl.scrollHeight -
        containerEl.scrollTop -
        containerEl.clientHeight >
      100
    )
      return
    pdfPreviewEl.loadNextPage()
  }

  close = (close, e) => {
    if (
      e.target !== this.previewContainerRef.current &&
      e.target !== this.previewCloseRef.current
    )
      return
    close()
  }

  render() {
    const { isLoading } = this.state

    return (
      <PdfPreviewContext.Consumer>
        {({ isOpen, close, document }) => {
          let className = 'preview-box main-layout__preview-box'
          if (!isOpen) className += ' preview-box--hidden'
          if (!isLoading) className += ' preview-box--loaded'

          return (
            <div className={className}>
              <div className="preview-box__overlay" />
              <div
                ref={this.previewContainerRef}
                className="preview-box__container"
                onClick={this.close.bind(this, close)}
              >
                {document && (
                  <Fragment>
                    <div className="preview-box__actions">
                      <span
                        ref={this.previewCloseRef}
                        className="preview-box__action preview-box__action--close"
                        onClick={this.close.bind(this, close)}
                      />
                      {!document.isNotDownloadable && (
                        <a
                          href={document.fileUrl || document.downloadUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="preview-box__action preview-box__action--download"
                        >
                          {' '}
                        </a>
                      )}
                    </div>
                    <div className="preview-box__content">
                      <div className="preview-box__placeholder">
                        Загрузка документа...
                      </div>
                      <PdfPreviewIframe
                        ref={this.pdfPreviewRef}
                        url={document.fileUrl || document.previewUrl}
                        iframeIsLoaded={this.iframeIsLoaded}
                        iframeIsUnloaded={this.iframeIsUnloaded}
                      />
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )
        }}
      </PdfPreviewContext.Consumer>
    )
  }
}

export default {
  Provider: PdfPreviewProvider,
  Consumer: PdfPreviewContext.Consumer,
  Component: PdfPreview,
}
