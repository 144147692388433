let url = '/api'

if (process.env.NODE_ENV === 'development') {
  url = '//mts.oktaplan.ru/api'
}

let config = {
  url,
}

export default config
