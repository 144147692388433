import React from 'react'
import PdfPreview from 'main/components/products/sections/documents/pdf-preview'
import Video from 'main/components/modals/VideoContext'

const MaterialActions = ({ item, children }) => {
  const previewUrlCopy = item.previewUrl || ''
  const ext = previewUrlCopy.split('.').splice(-1, 1)[0]

  if (ext === 'mp4')
    return (
      <Video.Consumer>
        {({ open }) =>
          children({
            downloadUrl: item.downloadUrl,
            open: () => open({ fileUrl: item.previewUrl }),
          })
        }
      </Video.Consumer>
    )

  return (
    <PdfPreview.Consumer>
      {({ open: openPreview }) => {
        const downloadUrl = item.downloadUrl
        const open = item.previewUrl ? () => openPreview(item) : null

        return children({ downloadUrl, open })
      }}
    </PdfPreview.Consumer>
  )
}

export default MaterialActions
