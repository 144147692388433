import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getFields } from 'main/selectors/sales-scripts/autofill'

const mapStateToProps = state => ({
  autofill: getFields(state).toJS(),
})

class WithAutofill extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(this.props.autofill) !== JSON.stringify(nextProps.autofill)
    )
      return true
    return false
  }

  render() {
    const { children, autofill } = this.props

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { autofill })
    )

    return childrenWithProps
  }
}

export default connect(
  mapStateToProps,
  () => ({}) // prevent setting dispatch to props
)(WithAutofill)
