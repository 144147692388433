import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import { setModel, setPreviewConfig, resetModel } from './actions'

export const initialState = {
  id: null,
  createdAt: null,
  updatedAt: null,
  branch: null,
  mrd: null,
  constructorCode: null,
  previewConfig: {},
}

const reducer = createReducer(
  {
    [setModel]: (state, payload = {}) => {
      return state.merge(payload)
    },
    [setPreviewConfig]: (state, payload = {}) => {
      return state.mergeIn(['previewConfig'], payload)
    },
    [resetModel]: () => {
      return fromJS(initialState)
    },
  },
  fromJS(initialState)
)

export default reducer
